import { EnvironmentOutlined } from "@ant-design/icons";

import { CompanyHrProfile, Maybe } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import useWindowSize from "hooks/useWindowSize";

import SocialLink from "../SocialLink";
import "./style.scss";

type Props = {
  companyHrProfile: CompanyHrProfile;
  backTo?: Maybe<string>;
};

const CompanyHrHeader = ({ companyHrProfile }: Props): JSX.Element => {
  const { isMobile } = useWindowSize();

  const { name, jobTitle, company, linkedin, location, photoUrl } =
    companyHrProfile;

  return (
    <section
      style={
        isMobile
          ? {
              background: `transparent url(${
                photoUrl || DEFAULT_USER_PHOTO_URL
              }) center no-repeat`,
              backgroundSize: "cover",
            }
          : {}
      }
    >
      <div className="profile-preview__header">
        <div className="profile-preview__header-wrapper">
          {!isMobile && (
            <img
              src={photoUrl || DEFAULT_USER_PHOTO_URL}
              width={160}
              height={160}
              alt="Profile"
            />
          )}
          <div className="profile-preview__header-content">
            <div className="full-name">{name}</div>
            <div className="job-title">
              {jobTitle} <span>at {company.name}</span>
            </div>
            <div className="location">
              <EnvironmentOutlined /> {location}
            </div>
            <div className="social">
              <SocialLink link={company.web} network="web" size={33} />
              <SocialLink link={linkedin} network="linkedin" size={33} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyHrHeader;
