import React from "react";

import { Form, message } from "antd";
import { Button } from "antd";
import { useForm } from "antd/es/form/Form";
import clsx from "clsx";

import {
  CompanyHrProfile,
  useUpdateCompanyHrSettingsMutation,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";
import FormInput from "components/Form/Input";
import FormTextArea from "components/Form/Textarea";

import styles from "./styles.module.scss";

type Props = {
  profile: CompanyHrProfile;
};

const CompanyHrEditProfile = ({ profile }: Props): JSX.Element => {
  const { setUserProfile, userProfile } = useAuth();

  const [updateCompanyHrSettings, { loading }] =
    useUpdateCompanyHrSettingsMutation();

  const [form] = useForm();

  const onSubmit = async (values: any) => {
    const { data, errors } = await updateCompanyHrSettings({
      variables: {
        companyHrSettings: {
          ...values,
          companyHrProfileID: (userProfile as CompanyHrProfile)?.company?.id,
        },
      },
    });

    if (data?.updateCompanyHrSettings) {
      setUserProfile(data.updateCompanyHrSettings);
      message.success("Successfully updated");
    }

    if (errors) {
      for (let error of errors) {
        message.error(error.message);
      }
    }
  };

  return (
    <Form form={form} initialValues={profile} onFinish={onSubmit}>
      <FormInput name="location" label="Location" />

      <FormInput name="jobTitle" label="Job title" required={false} />

      <FormTextArea name="about" label="About" required={false} />

      <Button
        color="success"
        type="primary"
        htmlType="submit"
        block
        size="large"
        disabled={loading}
        loading={loading}
        className={clsx("margin-bottom-desktop", styles.hover)}
      >
        SAVE
      </Button>
    </Form>
  );
};

export default CompanyHrEditProfile;
