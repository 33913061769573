import { FunctionComponent, useEffect } from "react";

import { Card, message, Tag } from "antd";
import { Dialog } from "antd-mobile";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";

import {
  ApplicationStatusEnum,
  RoleEnum,
  useAddApplicationNoteMutation,
  useChangeApplicationStatusMutation,
  useGetApplicationQuery,
  useHireCandidateMutation,
} from "generated/graphql";

import JobTags from "pages/Job/JobTags";
import "pages/Job/style.scss";

import useAuth from "components/Auth/useAuth";
import CandidateHeader from "components/CandidateProfile/CandidateHeader";
import CandidateInformation from "components/CandidateProfile/CandidateInformation";
import EndorsedBy from "components/EndorsedBy";
import ApplicationNotFound from "components/Errors/ApplicationNotFound";
import JobHeader from "components/JobHeader";
import Loading from "components/Loading";
import NavBar from "components/NavBar";
import Note from "components/Note";
import SendButton from "components/SendButton";
import Stake from "components/Stake";
import StatusTag from "components/StatusTag/StatusTag";

import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

type Props = {
  applicationId?: string;
  rootPage?: string;
  showTags?: boolean;
};

const Application: FunctionComponent<Props> = ({
  applicationId,
  rootPage,
  showTags,
}) => {
  const { user, userProfile } = useAuth();
  const { isMobile } = useWindowSize();
  const params = useParams();

  const _applicationId = applicationId || params.applicationId!;

  const { data, loading, refetch, error } = useGetApplicationQuery({
    variables: { applicationId: _applicationId },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);
  useEffect(() => {
    console.log(data);
  }, [error]);
  const [
    changeStatus, // TODO: handle error
  ] = useChangeApplicationStatusMutation();

  const [addNote] = useAddApplicationNoteMutation();

  const [hireCandidate] = useHireCandidateMutation();

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <ApplicationNotFound />;
  }

  const { application } = data;

  if (!application) {
    return <ApplicationNotFound />;
  }
  const { candidate, reference, status, notes } = application;

  if (!candidate) {
    return <div>no user - should not happen</div>; // TODO: handle this case
  }

  const handleChangeStatus = async (newStatus: ApplicationStatusEnum) => {
    const isHire = newStatus === ApplicationStatusEnum.Hired;
    if (newStatus !== status) {
      const content = isHire
        ? "Are you sure you want to hire this candidate? This will end the job Ad."
        : `Are you sure you want to change the status to "${newStatus.replaceAll(
            "_",
            " "
          )}"?`;
      await Dialog.confirm({
        content,
        onConfirm: async () => {
          await changeStatus({
            variables: {
              applicationId: _applicationId,
              status: newStatus,
            },
          });
          if (isHire) {
            const res = await hireCandidate({
              variables: {
                hireData: {
                  applicationId: _applicationId,
                  jobId: application?.jobAd?.id,
                  keepOpen: false,
                  startingDate: new Date().toLocaleDateString(),
                  yearlySalary: 100000,
                },
              },
            });
          }
          await refetch();
        },
        title: "Change Status",
        confirmText: "Yes",
        cancelText: "Cancel",
      });
    }
  };

  const handleAddNote = async ({ message }: { message: string }) => {
    const { data } = await addNote({
      variables: {
        applicationId: _applicationId,
        text: message,
      },
    });
    await refetch();
    return data;
  };

  const temporaryProfile = {
    name: candidate.name,
    email: "a@a.com", // TODO: candidate.email,
    linkedin: candidate.linkedin,
  };

  const canEdit =
    (user?.activeRole === RoleEnum.CompanyUser ||
      user?.activeRole === RoleEnum.InternalRecruiter) &&
    status !== ApplicationStatusEnum.Hired &&
    status !== ApplicationStatusEnum.Canceled &&
    status !== ApplicationStatusEnum.Rejected;

  const isCandidate = application.candidate.id === userProfile?.id;

  if (isMobile) {
    return (
      <>
        <NavBar
          title={
            <>
              <span className="ellipsis">{application?.jobAd?.title}</span>
            </>
          }
          backTo={rootPage}
        />
        <main className="lg:py-8 lg:px-16">
          {canEdit && !isCandidate ? (
            <CandidateHeader
              candidateProfile={candidate || temporaryProfile}
              obfuscated={user?.activeRole === RoleEnum.Staker}
              backTo={rootPage}
            />
          ) : (
            <JobHeader
              jobAd={application.jobAd}
              canEdit={canEdit}
              applicationId={applicationId}
              canApply={false}
              backTo={rootPage}
            />
          )}

          <section className="children-bottom-margin responsive-padding">
            {!canEdit ? (
              <div className="flex-row">
                <div>Status:</div>
                <StatusTag type="application" status={status} />
              </div>
            ) : null}
            {canEdit && candidate && (
              <CandidateInformation
                candidateProfile={candidate}
                reference={reference}
              />
            )}

            {!canEdit && isCandidate && application.reference && (
              <EndorsedBy
                id={application.reference.referrer.id}
                name={application.reference.referrer.name}
              />
            )}

            {!canEdit &&
              application.reference &&
              application.reference.answers.map((answer: any) => {
                return (
                  <Card
                    key={answer.id}
                    title={answer.question.text}
                    bordered={false}
                  >
                    {answer.text}
                  </Card>
                );
              })}

            {application?.jobAd && (
              <>
                {application.jobAd.description && (
                  <Card title="Description" bordered={false}>
                    <ReactMarkdown className="markdown">
                      {application.jobAd.description}
                    </ReactMarkdown>
                  </Card>
                )}

                {application.jobAd.responsibilities && (
                  <Card title="Responsibilities" bordered={false}>
                    <ReactMarkdown className="markdown">
                      {application.jobAd.responsibilities}
                    </ReactMarkdown>
                  </Card>
                )}

                {application.jobAd.requirements && (
                  <Card title="Requirements" bordered={false}>
                    <ReactMarkdown className="markdown">
                      {application.jobAd.requirements}
                    </ReactMarkdown>
                  </Card>
                )}

                {application.jobAd.preferred && (
                  <Card title="Preferred" bordered={false}>
                    <ReactMarkdown className="markdown">
                      {application.jobAd.preferred}
                    </ReactMarkdown>
                  </Card>
                )}

                {application.jobAd.benefits && (
                  <Card title="Benefits" bordered={false}>
                    <ReactMarkdown className="markdown">
                      {application.jobAd.benefits}
                    </ReactMarkdown>
                  </Card>
                )}
              </>
            )}

            {canEdit ? (
              <>
                <h4>Change status</h4>
                <div className="ant-tag-group">
                  {Object.values(ApplicationStatusEnum).map(
                    (applicationStatus) => {
                      const isSelected =
                        status.toLowerCase() ===
                        applicationStatus.toLowerCase();
                      return (
                        <Tag
                          key={applicationStatus}
                          onClick={() => handleChangeStatus(applicationStatus)}
                          className={`custom-ant-tag selectable-tag ${
                            isSelected ? "ant-mobile-tag-selected" : ""
                          }`}
                        >
                          {applicationStatus.replaceAll("_", " ")}
                        </Tag>
                      );
                    }
                  )}
                </div>
              </>
            ) : null}
            {canEdit && notes ? (
              <>
                <h4>Notes</h4>
                <div>
                  {notes.map(({ id, text, date, writtenBy }) => (
                    <Note
                      key={id}
                      id={id}
                      userId={writtenBy.id}
                      name={writtenBy.name}
                      photoUrl={writtenBy.photoUrl}
                      date={date}
                      text={text}
                    />
                  ))}
                </div>
                <div className="add-note-wrapper">
                  <SendButton placeholder="Add Note" onSubmit={handleAddNote} />
                </div>
              </>
            ) : null}
            {user?.activeRole === RoleEnum.Staker ? <Stake /> : null}
          </section>
        </main>
      </>
    );
  }

  return (
    <main>
      {canEdit && !isCandidate ? (
        <CandidateHeader
          candidateProfile={candidate || temporaryProfile}
          obfuscated={user?.activeRole === RoleEnum.Staker}
          backTo={rootPage}
        />
      ) : (
        <JobHeader
          jobAd={application.jobAd}
          canEdit={canEdit}
          applicationId={applicationId}
          canApply={false}
          backTo={rootPage}
        />
      )}

      <section className="children-bottom-margin responsive-padding">
        {showTags ? <JobTags jobAd={application.jobAd} /> : null}

        {!canEdit ? (
          <div className="flex-row">
            <div>Status:</div>
            <StatusTag type="application" status={status} />
          </div>
        ) : null}

        {canEdit && candidate && (
          <CandidateInformation
            candidateProfile={candidate}
            reference={reference}
          />
        )}

        {!canEdit && isCandidate && application.reference && (
          <EndorsedBy
            id={application.reference.referrer.id}
            name={application.reference.referrer.name}
          />
        )}

        <div className="card-group">
          {!canEdit &&
            application.reference &&
            application.reference.answers.map((answer: any) => {
              return (
                <Card
                  key={answer.id}
                  title={answer.question.text}
                  bordered={false}
                >
                  {answer.text}
                </Card>
              );
            })}
        </div>

        {application?.jobAd && (
          <>
            {application.jobAd.description && (
              <ApplicationCardInfo
                title="Description"
                markdown={application.jobAd.description}
              />
            )}

            {application.jobAd.responsibilities && (
              <ApplicationCardInfo
                title="Responsibilities"
                markdown={application.jobAd.responsibilities}
              />
            )}

            {application.jobAd.requirements && (
              <ApplicationCardInfo
                title="Requirements"
                markdown={application.jobAd.requirements}
              />
            )}

            {application.jobAd.preferred && (
              <ApplicationCardInfo
                title="Preferred"
                markdown={application.jobAd.preferred}
              />
            )}

            {application.jobAd.benefits && (
              <ApplicationCardInfo
                title="Benefits"
                markdown={application.jobAd.benefits}
              />
            )}
          </>
        )}

        {canEdit ? (
          <>
            <h4>Change status</h4>
            <div className="ant-tag-group">
              {Object.values(ApplicationStatusEnum).map((applicationStatus) => {
                const isSelected =
                  status.toLowerCase() === applicationStatus.toLowerCase();
                return (
                  <Tag
                    key={applicationStatus}
                    onClick={() => handleChangeStatus(applicationStatus)}
                    className={`custom-ant-tag selectable-tag ${
                      isSelected ? "ant-mobile-tag-selected" : ""
                    }`}
                  >
                    {applicationStatus.replaceAll("_", " ")}
                  </Tag>
                );
              })}
            </div>
          </>
        ) : null}
        {canEdit && notes ? (
          <>
            <h4>Notes</h4>
            <div>
              {notes.map(({ id, text, date, writtenBy }) => (
                <Note
                  key={id}
                  id={id}
                  userId={writtenBy.id}
                  name={writtenBy.name}
                  photoUrl={writtenBy.photoUrl}
                  date={date}
                  text={text}
                />
              ))}
            </div>
            <div className="add-note-wrapper">
              <SendButton placeholder="Add Note" onSubmit={handleAddNote} />
            </div>
          </>
        ) : null}
        {user?.activeRole === RoleEnum.Staker ? <Stake /> : null}
      </section>
    </main>
  );
};

const ApplicationCardInfo = (props: { title: string; markdown: string }) => {
  return (
    <div className="bg-[#343141] p-6 rounded-md flex flex-col gap-5">
      <span className="text-grey text-base">{props.title}:</span>

      <ReactMarkdown className="markdown text-sm">
        {props.markdown}
      </ReactMarkdown>
    </div>
  );
};

export default Application;
