import { Fragment, useEffect } from "react";

import { Card } from "antd";
import clsx from "clsx";
import ReactMarkdown from "react-markdown";

import { CompanyHrProfile, JobAd, RoleEnum } from "generated/graphql";

import JobTags from "pages/Job/JobTags";

import useAuth from "components/Auth/useAuth";

import styles from "./JobInformation.module.scss";
import "./style.scss";

type Props = {
  jobAd: Partial<JobAd>;
};

const JobInformation = ({ jobAd }: Props): JSX.Element => {
  const { user, userProfile } = useAuth();

  const isAdmin =
    user?.activeRole === RoleEnum.InternalRecruiter ||
    (RoleEnum.CompanyUser &&
      (userProfile as CompanyHrProfile)?.company?.id === jobAd?.company?.id);

  return (
    <div className="children-bottom-margin">
      <JobTags jobAd={jobAd} />
      {jobAd.jobSkills && (
        <div className="flex gap-2 items-center">
          <span className="w-[50px] text-[12px] leading-[16px] font-normal font-sora">
            Skills:
          </span>
          <div className="flex flex-nowrap overflow-scroll uppercase gap-[6px] text-[12px] leading-[16px] font-sans">
            {jobAd.jobSkills.map((skill) => (
              <div
                key={skill}
                className="flex whitespace-nowrap items-center justify-center h-[18px] px-2 text-center bg-[#5362E9] rounded-sm"
              >
                {skill}
              </div> /* Auto layout */
            ))}
          </div>
        </div>
      )}
      {jobAd.description && (
        <div className={styles.card}>
          <div className={styles.title}>Description</div>
          <ReactMarkdown className={clsx(styles.contents, "markdown")}>
            {jobAd.description}
          </ReactMarkdown>
        </div>
      )}
      {jobAd.responsibilities && (
        <div className={styles.card}>
          <div className={styles.title}>Responsibilities</div>
          <ReactMarkdown className={clsx(styles.contents, "markdown")}>
            {jobAd.responsibilities}
          </ReactMarkdown>
        </div>
      )}
      {jobAd.requirements && (
        <div className={styles.card}>
          <div className={styles.title}>Requirements</div>
          <ReactMarkdown className={clsx(styles.contents, "markdown")}>
            {jobAd.requirements}
          </ReactMarkdown>
        </div>
      )}
      {jobAd.preferred && (
        <div className={styles.card}>
          <div className={styles.title}>Preferred</div>
          <ReactMarkdown className={clsx(styles.contents, "markdown")}>
            {jobAd.preferred}
          </ReactMarkdown>
        </div>
      )}
      {jobAd.benefits && (
        <div className={styles.card}>
          <div className={styles.title}>Benefits</div>
          <ReactMarkdown className={clsx(styles.contents, "markdown")}>
            {jobAd.benefits}
          </ReactMarkdown>
        </div>
      )}
      {jobAd.company?.description && (
        <div className={styles.card}>
          <div className={styles.title}>About {jobAd.company.name}</div>
          <ReactMarkdown className={clsx(styles.contents, "markdown")}>
            {jobAd.company.description}
          </ReactMarkdown>
        </div>
      )}
      {/*TODO: should everyone be able to see the questions? (they can still see them when clicking "refer")*/}
      {jobAd.referenceQuestions && isAdmin && (
        <div className={styles.card}>
          <div className={styles.title}>Endorsement questionnaire</div>

          {jobAd.referenceQuestions.map((question) => (
            <Fragment key={question.id}>
              <ReactMarkdown className={clsx(styles.contents, "markdown")}>
                {question.text}
              </ReactMarkdown>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default JobInformation;
