import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useReferCandidateWithoutJobMutation } from "generated/graphql";

import { ENDORSE } from "app-constants";

import useWindowSize from "./useWindowSize";

interface FormProp {
  name: string;
  email: string;
  linkedinUrl?: string;
  githubUrl?: string;
  relationship: string;
  stand_out: string;
  incognitoMode: boolean;
}

interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
const useEndorse = ({ page, setPage }: Props) => {
  const valueFromLocal = JSON.parse(localStorage?.getItem(ENDORSE) as string);

  const [selectedSkills, setSelectedSkills] = useState<string[]>(
    valueFromLocal?.selectedSkills ? valueFromLocal?.selectedSkills : []
  );
  const [isStarted, setIsStarted] = useState(false);

  //nohook
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm<FormProp>({
    mode: "onBlur",
    defaultValues: {
      name: valueFromLocal?.name ? (valueFromLocal?.name as string) : "",
      email: valueFromLocal?.email ? (valueFromLocal?.email as string) : "",
      linkedinUrl: valueFromLocal?.linkedinUrl
        ? (valueFromLocal?.linkedinUrl as string)
        : "",
      githubUrl: valueFromLocal?.githubUrl
        ? (valueFromLocal?.githubUrl as string)
        : "",
      relationship: valueFromLocal?.relationship
        ? (valueFromLocal?.relationship as string)
        : "",
      stand_out: valueFromLocal?.stand_out
        ? (valueFromLocal?.stand_out as string)
        : "",
      incognitoMode: String(valueFromLocal?.incognitoMode)
        ? Boolean(valueFromLocal?.incognitoMode as string)
        : false,
    },
  });

  const [toggle, setToggle] = useState(false);
  const { isMobile } = useWindowSize();

  const onSubmit = async () => {
    if (selectedSkills.length === 0) return;
    const { name, email, linkedinUrl, githubUrl, relationship, stand_out } =
      getValues();
    try {
      const res = await referCandidateWithoutJob({
        variables: {
          candidateData: {
            name,
            email,
            linkedinUrl,
            githubUrl,
            endorsementWithoutJob: {
              relationship,
              stand_out,
              skills: {
                skillIDs: selectedSkills,
              },
              incognitoMode: toggle,
            },
          },
        },
      });

      if (res?.errors?.[0]) {
        toast.error(res.errors[0].message);
      }
      if (res.data) {
        window?.localStorage?.removeItem(ENDORSE);
        toast.success("Success to Endorse");
        reset();
        setSelectedSkills([]);
        setPage((prev) => prev + 1);
        setSearchParams({ page: String(page + 1) });
      }
    } catch (e) {
      if ((e as Error).message.includes("duplicate")) {
        toast.error("This candidate has already been endorsed");
      } else {
        toast.error("There was an error endorsing this candidate");
      }
    }
  };
  const handleButton = () => {
    if (isMobile && !isStarted) {
      setPage(1);
      setIsStarted(true);
      setSearchParams({ page: "1" });
    }
    if (
      page === 1 &&
      (!getValues().email ||
        !getValues().name ||
        (!!errors.linkedinUrl && getValues().linkedinUrl) ||
        !!errors.email)
    )
      return;
    if (page === 2 && selectedSkills.length === 0) return;
    if (page === 3 && !getValues().relationship) return;
    if (page === 4 && !getValues().stand_out) return;

    if (page < 4) {
      setPage((prev) => prev + 1);
      setSearchParams({ page: String(page + 1) });

      localStorage.setItem(ENDORSE, JSON.stringify(getValues()));
    }
    if (page === 4) {
      try {
        onSubmit();
      } catch (err) {
        setPage(4);
        console.error(err);
        return;
      }
    }
    if (page === 5) {
      setPage(0);
      window?.localStorage?.removeItem(ENDORSE);

      setSearchParams({ page: String(0) });
    }
  };
  const handleBack = () => {
    if (isMobile && page === 1) {
      setIsStarted(false);
    }
    if (page > 0) {
      setPage((prev) => prev - 1);

      setSearchParams({ page: String(page - 1) });
    }
  };

  const handleAnonymous = (checked: boolean) => {
    setToggle(checked);
    localStorage.setItem(
      ENDORSE,
      JSON.stringify({ ...getValues(), incognitoMode: checked })
    );
  };

  const toggleTag = (id: string) => {
    if (selectedSkills.includes(id)) {
      setSelectedSkills(selectedSkills.filter((skill) => skill !== id));
      localStorage.setItem(
        ENDORSE,
        JSON.stringify({ ...getValues(), skillIDs: selectedSkills })
      );
    } else {
      setSelectedSkills([...selectedSkills, id]);
    }
  };
  //hook
  const [referCandidateWithoutJob] = useReferCandidateWithoutJobMutation();

  useEffect(() => {
    if (!searchParams?.get("page") || (!isStarted && isMobile)) {
      setPage(0);
      setSearchParams({ page: "0" });
    }
    if (searchParams?.get("page")) setPage(Number(searchParams?.get("page")));
  }, [searchParams?.get("page"), isStarted, isMobile]);

  return {
    register,
    handleButton,
    handleBack,
    handleAnonymous,
    toggle,
    errors,
    toggleTag,
    selectedSkills,
    searchParams,
    isStarted,
  };
};

export default useEndorse;
