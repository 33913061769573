import { Result } from "antd";

const ApplicationNotFound = (): JSX.Element => (
  <Result
    status="404"
    title="Application not found"
    subTitle="Sorry, this application does not exist"
  />
);

export default ApplicationNotFound;
