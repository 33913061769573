import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import cn from "clsx";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";

import { ONBOARDING_SKILLS } from "app-constants";

import { AddSkillDialog } from "components/modals/AddSkill";
import { OnboardingWrapper } from "components/onboarding/OnboardingWrapper";
import { OnboardingStepProps } from "components/onboarding/types";

import styles from "./styles.module.scss";

type ModalType = "softSkill" | "hardSkill";
interface Skills {
  soft: string[];
  tech: string[];
}

export const SkillsSelect: FunctionComponent<OnboardingStepProps> = ({
  decimalProgress,
  state,
  setState,
  setStep,
}) => {
  const [skills, setSkills] = useState<Skills>({
    soft: state.skills.softSkills,
    tech: state.skills.techSkills,
  });
  const {
    getValues: getFormValues,
    register,
    formState: { errors, touchedFields },
  } = useForm<{ about: string }>({
    mode: "all",
  });

  const saveState = () => {
    setState({
      ...state,
      skills: {
        ...state.skills,
        ...getFormValues(),
        techSkills: skills.tech,
        softSkills: skills.soft,
      },
    });
  };
  const getValues = () => {
    const values = {
      ...getFormValues(),
      techSkills: skills.tech,
      softSkills: skills.soft,
    };
    return values;
  };
  const onSubmit = () => {
    saveState();
    setStep((v) => v + 1);
  };
  const handleOnchange = () => {
    window?.localStorage?.setItem(
      ONBOARDING_SKILLS,
      JSON.stringify(getValues())
    );
  };
  useEffect(() => {
    handleOnchange();
  }, [skills]);
  return (
    <OnboardingWrapper
      decimalProgress={decimalProgress}
      title={"Tell us about your skills"}
    >
      <SkillSelection skills={skills} setSkills={setSkills} />
      <div className="mt-[20px] w-full">
        <label>
          <p className="font-[500] font-mono text-xs">ABOUT</p>
        </label>
        <TextareaAutosize
          defaultValue={state.skills.about}
          {...register("about", {
            onChange: handleOnchange,
          })}
          className="mt-[9px] text-xs rounded-[4px] p-2 w-full min-h-[140px] bg-[#4A465B] font-mono placeholder-gray-500 w-full"
        />
      </div>
      <div className={cn("mt-[148px] flex items-center justify-center w-full")}>
        <button
          onClick={() => onSubmit()}
          className={
            (clsx(
              "w-[327px] h-[54px] bg-primary rounded-full flex items-center justify-center  transition-opacity duration-200"
            ),
            styles.hover)
          }
        >
          <p className="font-mono font-[500] text-base">CONTINUE</p>
        </button>
      </div>
      <div className="mt-6 w-full flex items-center justify-center">
        <button
          className={clsx(" transition-opacity duration-200", styles.hover)}
          onClick={() => {
            saveState();
            setStep((v) => v - 1);
          }}
        >
          <p className="underline tracking-[-0.17] text-[#D0C9D6]">Back</p>
        </button>
      </div>
    </OnboardingWrapper>
  );
};

const SkillSelection: FunctionComponent<{
  skills: Skills;
  setSkills: Dispatch<SetStateAction<Skills>>;
}> = ({ skills, setSkills }) => {
  const [modalState, setModalState] = useState<{
    modal: ModalType;
    open: boolean;
  }>({ modal: "softSkill", open: false });

  const addSkill = React.useCallback(
    (newSkill: string) => {
      setSkills((prevState) => {
        if (
          modalState.modal === "softSkill"
            ? prevState.soft.includes(newSkill)
            : prevState.tech.includes(newSkill)
        ) {
          toast.error("Skill already exists");
          return prevState;
        } else {
          toast.success("Skill added");
          const target = modalState.modal === "softSkill" ? "soft" : "tech";
          return {
            ...prevState,
            [target]: [...prevState[target], newSkill],
          };
        }
      });
    },
    [modalState.modal]
  );

  return (
    <>
      <AddSkillDialog
        open={modalState.open}
        setOpen={(open: boolean) =>
          setModalState((prevState) => ({ ...prevState, open }))
        }
        addSkill={addSkill}
        title={`Add your ${
          modalState.modal === "softSkill" ? "soft" : "tech"
        } skills`}
      />
      <div
        className={cn("mt-[51px] w-full", {
          "pointer-events-none": modalState.open,
        })}
      >
        <label>
          <p className="font-[500] font-mono text-xs">TECH SKILLS</p>
        </label>
        <section className="mt-2">
          <ul className="flex items-start flex-wrap gap-2">
            <li>
              <button
                onClick={() =>
                  setModalState({ open: true, modal: "hardSkill" })
                }
                className={clsx(
                  "border border-grey p-3  transition-all duration-200",
                  styles.opacityAndColorHover
                )}
              >
                <p className=" text-xs text-grey font-mono leading-[16px] tracking-[-0.39px] transition-all duration-200">
                  +Add skill
                </p>
              </button>
            </li>
            {skills.tech.map((skill) => (
              <li className="max-w-[472px]" key={skill}>
                <button
                  onClick={() =>
                    setSkills((prevValue) => ({
                      ...prevValue,
                      tech: prevValue.tech.filter((v) => v !== skill),
                    }))
                  }
                  className={clsx(
                    "border border-grey p-3 transition-all duration-200 max-w-full",
                    styles.whiteColorHover
                  )}
                >
                  <p className=" text-xs text-grey font-mono leading-[16px] tracking-[-0.39px] transition-all duration-200 truncate">
                    {skill}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </section>
      </div>
      <div className="mt-[21px] w-full">
        <label>
          <p className="font-[500] font-mono text-xs">SOFT SKILLS</p>
        </label>
        <section className="mt-2">
          <ul className="flex items-start flex-wrap gap-2">
            <li>
              <button
                onClick={() =>
                  setModalState({ open: true, modal: "softSkill" })
                }
                className={clsx(
                  "border border-grey p-3 transition-all duration-200",
                  styles.opacityAndColorHover
                )}
              >
                <p className="text-xs text-grey font-mono leading-[16px] tracking-[-0.39px] transition-all duration-200">
                  +Add skill
                </p>
              </button>
            </li>
            {skills.soft.map((skill) => (
              <li key={skill} className="max-w-[472px]">
                <button
                  onClick={() =>
                    setSkills((prevValue) => ({
                      ...prevValue,
                      soft: prevValue.soft.filter((v) => v !== skill),
                    }))
                  }
                  className={
                    (clsx(
                      "border border-grey p-3 transition-all duration-200 w-full max-w-full"
                    ),
                    styles.whiteColorHover)
                  }
                >
                  <p className="text-xs text-grey font-mono leading-[16px] tracking-[-0.39px] transition-all duration-200 truncate">
                    {skill}
                  </p>
                </button>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </>
  );
};
