import clsx from "clsx";

import { useGetJobSkillsQuery } from "generated/graphql";

import For from "components/common/For";

import styles from "./styles.module.scss";

type Props = {
  selected: string[];
  handleToggle: (skill: string) => void;
};

const SkillsSelect = ({ selected, handleToggle }: Props) => {
  const { data } = useGetJobSkillsQuery({
    fetchPolicy: "cache-and-network",
  });
  const skills = data?.getListOfJobSkills ?? [];

  return (
    <For items={skills} keyFunc={(skill) => skill}>
      {(skill) => {
        const isSelected = selected.includes(skill);

        return (
          <button
            onClick={() => handleToggle(skill)}
            className={clsx(
              "p-3 capitalize font-sans text-[13px] font-normal leading-[17px] border flex-shrink-0",
              styles.hover,
              {
                "bg-[#5362E9] border-[#5362E9] text-white": isSelected,
                "border-[#928CA6] bg-transparent text-[#928CA6] ": !isSelected,
              }
            )}
          >
            {skill.replaceAll("_", " ")}
          </button>
        );
      }}
    </For>
  );
};

export default SkillsSelect;
