import { FunctionComponent } from "react";

import { Empty } from "antd";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { RoleEnum, useGetReferencesForRecruiterQuery } from "generated/graphql";

import "pages/Endorsements/style.scss";

import useAuth from "components/Auth/useAuth";
import For from "components/common/For";
import DesktopLayout from "components/DesktopLayout";
import EndorsementCard from "components/EndorsementCard";
import Loading from "components/Loading";

import useWindowSize from "hooks/useWindowSize";

import { getAvatar } from "utils";

const Endorsements: FunctionComponent = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  const location = useLocation();

  const isIndexPage =
    location.pathname === "/endorsements" ||
    location.pathname === "/endorsements/";

  const { data, loading } = useGetReferencesForRecruiterQuery({
    fetchPolicy: "network-only",
  });
  if (user?.activeRole !== RoleEnum.InternalRecruiter) {
    navigate("/");
  }

  if (loading) {
    return <Loading />;
  }
  // console.log(data?.referencesForRecruiter);
  if (isMobile) {
    if (!isIndexPage) {
      return <Outlet />;
    }

    return (
      <div className="px-5 flex flex-col gap-3 h-full overflow-y-scroll hide-scrollbar">
        <For
          items={data?.referencesForRecruiter ?? []}
          keyFunc={(referral) => referral.reference.id as string}
        >
          {(referral) => (
            <EndorsementCard
              key={referral.reference.id}
              id={referral.reference.id as string}
              link={`/endorsements/${referral.reference.id}`}
              photoUrl={getAvatar(referral.candidateName)}
              title={referral.candidateName}
              subtitle={`Endorsed by ${referral.reference.referrer?.name}`}
              footer={referral.jobAd.title}
              date={referral.reference.date}
            />
          )}
        </For>
      </div>
    );
  }

  const renderMainColumn = () => {
    if (!isIndexPage) {
      return <Outlet />;
    }

    if (data?.referencesForRecruiter?.[0]) {
      return (
        <Navigate
          to={`/endorsements/${data.referencesForRecruiter[0].reference.id}`}
          replace
        />
      );
    }

    return <Empty description={false} />;
  };

  return (
    <DesktopLayout
      leftColumn={
        <div className="py-6 flex flex-col gap-3">
          <For
            items={data?.referencesForRecruiter ?? []}
            keyFunc={(referral) => referral.reference.id as string}
          >
            {(referral) => (
              <div className="px-6">
                <EndorsementCard
                  key={referral.reference.id}
                  id={referral.reference.id as string}
                  link={`/endorsements/${referral.reference.id}`}
                  photoUrl={getAvatar(referral.candidateName)}
                  title={referral.candidateName}
                  subtitle={`Endorsed by ${referral.reference.referrer?.name}`}
                  footer={referral.jobAd.title}
                  date={referral.reference.date}
                />
              </div>
            )}
          </For>
        </div>
      }
      mainColumn={renderMainColumn()}
    />
  );
};

export default Endorsements;
