import { Navigate } from "react-router-dom";

import { ErrorMsg, useGetProfileQuery } from "generated/graphql";

import CandidateHeader from "components/CandidateProfile/CandidateHeader";
import CandidateInformation from "components/CandidateProfile/CandidateInformation";
import CompanyHrHeader from "components/CompanyHrProfile/CompanyHrHeader";
import CompanyHrInformation from "components/CompanyHrProfile/CompanyHrInformation";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import UserNotFound from "components/Errors/UserNotFound";
import InternalRecruiterHeader from "components/InternalRecruiterProfile/InternalRecruiterHeader";
import InternalRecruiterInformation from "components/InternalRecruiterProfile/InternalRecruiterInformation";
import Loading from "components/Loading";

type Props = {
  profileID: string;
};

const UserProfile = ({ profileID }: Props): JSX.Element => {
  const { data, error, loading } = useGetProfileQuery({
    variables: { profileID },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    if (error.graphQLErrors?.[0].message === ErrorMsg.UserNotFound) {
      return <UserNotFound />;
    }
    return <SomethingWentWrong />;
  }

  if (data?.profile?.__typename === "CandidateProfile") {
    return (
      <main className="lg:py-8 lg:px-16">
        <CandidateHeader candidateProfile={data.profile} backTo={null} />
        <section className="regular-padding">
          <CandidateInformation candidateProfile={data.profile} />
        </section>
      </main>
    );
  }

  if (data?.profile?.__typename === "CompanyHrProfile") {
    return (
      <main className="lg:py-8 lg:px-16">
        <CompanyHrHeader companyHrProfile={data.profile} backTo={null} />
        <section className="regular-padding">
          <CompanyHrInformation companyHrProfile={data.profile} />
        </section>
      </main>
    );
  }

  if (data?.profile?.__typename === "InternalRecruiterProfile") {
    return (
      <main className="lg:py-8 lg:px-16">
        <InternalRecruiterHeader
          internalRecruiterProfile={data.profile}
          backTo={null}
        />
        <section className="regular-padding">
          <InternalRecruiterInformation
            internalRecruiterProfile={data.profile}
          />
        </section>
      </main>
    );
  }

  // TODO: review
  return <Navigate to="/" />;
};

export default UserProfile;
