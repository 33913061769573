import { ReactNode } from "react";

import { Avatar, Tag } from "antd";
import classNames from "clsx";
import { useNavigate } from "react-router-dom";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import { ReactComponent as BackIcon } from "images/icons/back.svg";
import { ReactComponent as BellIcon } from "images/icons/bell.svg";

import useAuth from "components/Auth/useAuth";
import Button from "components/Button";

import useNotifications from "hooks/useNotifications";
import usePageTitle from "hooks/usePageTitle";
import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

type Props = {
  customTitle?: ReactNode;
  backTo?: string | false;
};

const DesktopTopBar = ({ customTitle, backTo }: Props): JSX.Element => {
  const { userProfile, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { unread } = useNotifications({
    subscribe: true,
    pageNumber: 0,
    resultsPerPage: 0,
  });

  const { title, subtitle } = usePageTitle();

  const navigateToProfile = () => {
    navigate("/profile");
  };
  if (!isMobile) {
    return (
      <nav
        className={classNames("desktop-topbar__wrapper", {
          "desktop-topbar__wrapper-override": !!customTitle,
          // "desktop-topbar__not-logged-in": !isLoggedIn,
        })}
      >
        <div className="topbar__title">
          {(subtitle || customTitle) && backTo !== false && (
            <BackIcon
              onClick={() => {
                navigate(-1);
              }}
            />
          )}
          <div className="topbar__title-text font-sora text-[28px] leading-[35px] text-white font-semibold">
            {customTitle || subtitle || title}
          </div>
        </div>
        {isLoggedIn && (
          <section className="desktop-topbar__right">
            <>
              {unread !== undefined && isLoggedIn && (
                <div
                  className={classNames("notifications-tag", {
                    "notifications-tag__unread": unread > 0,
                  })}
                  onClick={() => {
                    navigate("/notifications");
                  }}
                >
                  <BellIcon />
                  {unread}
                </div>
              )}
              {userProfile && (
                <>
                  <div onClick={navigateToProfile} className="clickable">
                    <Avatar
                      src={userProfile?.photoUrl || DEFAULT_USER_PHOTO_URL}
                      size={33}
                      className="user-avatar"
                    />
                  </div>
                  <div
                    onClick={navigateToProfile}
                    className="text-[14px] leading-[18px] font-normarl font-sora clickable"
                  >
                    {userProfile?.name?.split(" ")[0]}{" "}
                    {userProfile?.name?.split(" ")[1]}
                  </div>
                </>
              )}
            </>
          </section>
        )}
        {!isLoggedIn && (
          <section className="desktop-topbar__right">
            <div className="flex gap-2">
              <Button
                label={<div>LOGIN</div>}
                onClick={() => {
                  navigate("/signin");
                }}
                size="large"
              />
              <Button
                label={<div>SIGN UP</div>}
                onClick={() => {
                  navigate("/signup");
                }}
                type="simple"
                size="small"
              />
            </div>
          </section>
        )}
      </nav>
    );
  }

  return <></>;
};

export default DesktopTopBar;
