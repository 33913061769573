import {
  ApplicationStatusEnum,
  JobStatusEnum,
  ReferenceStatus,
} from "generated/graphql";

import {
  APPLICATION_STATUS_COLORS,
  APPLICATION_STATUS_FONT_COLORS,
  JOB_STATUS_COLORS,
  JOB_STATUS_FONT_COLORS,
  REFERENCE_STATUS_COLORS,
} from "app-constants";

import styles from "./StatusTag.module.scss";

type Props =
  | {
      type: "application";
      status: ApplicationStatusEnum;
    }
  | {
      type: "job";
      status: JobStatusEnum;
    }
  | {
      type: "reference";
      status: ReferenceStatus;
    };

const getColors = ({ type, status }: Props) => {
  switch (type) {
    case "application":
      return {
        color: APPLICATION_STATUS_COLORS[status],
        fontColor: APPLICATION_STATUS_FONT_COLORS[status],
      };
    case "job":
      return {
        color: JOB_STATUS_COLORS[status],
        fontColor: JOB_STATUS_FONT_COLORS[status],
      };

    case "reference":
      return {
        color: REFERENCE_STATUS_COLORS[status],
        fontColor: "white",
      };
  }
};

const StatusTag = (props: Props) => {
  const { color, fontColor } = getColors(props);

  return (
    <div
      style={{
        color: fontColor,
        backgroundColor: color,
      }}
      className={styles.statusTag}
    >
      {props.status.replaceAll("_", " ")}
    </div>
  );
};

export default StatusTag;
