import { Link, useLocation } from "react-router-dom";

import useAuth from "../Auth/useAuth";
import "./style.scss";

type Props = {
  id: string;
  name: string;
};

const EndorsedBy = ({ id, name }: Props): JSX.Element => {
  const { userProfile } = useAuth();
  const location = useLocation();

  const isOwn = userProfile?.id === id;

  return (
    <div className="application-referrer">
      Endorsed by{" "}
      {!isOwn ? (
        <Link
          className="application-referrer-link"
          to={`/profile/${id}`}
          state={{ prevPath: location.pathname }}
        >
          {name}
        </Link>
      ) : (
        "you"
      )}
    </div>
  );
};

export default EndorsedBy;
