import { FunctionComponent, ReactNode } from "react";

import "./style.scss";

type Props = {
  leftColumn?: ReactNode;
  mainColumn: ReactNode;
  rightColumn?: ReactNode;
};

const DesktopLayout: FunctionComponent<Props> = ({
  leftColumn,
  mainColumn,
  rightColumn,
}) => (
  <main className="desktop-layout__wrapper">
    {leftColumn && (
      <section className="desktop-layout__left-column">{leftColumn}</section>
    )}
    <section className="desktop-layout__main-column">{mainColumn}</section>
    {rightColumn && (
      <section className="desktop-layout__right-column">{rightColumn}</section>
    )}
  </main>
);

export default DesktopLayout;
