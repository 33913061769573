import { FunctionComponent } from "react";

export const TriangleSpinner: FunctionComponent<{
  width?: number;
  height?: number;
  label?: string;
  color?: string;
}> = ({ width = 64, height = 64, label = "", color = "#fff" }) => (
  <div className={"react-spinner-loader-svg"}>
    <svg
      id="triangle"
      width={width}
      height={height}
      viewBox="-3 -4 39 39"
      aria-label={label}
    >
      <polygon
        fill="transparent"
        stroke={color}
        strokeWidth="1"
        points="16,0 32,32 0,32"
      />
    </svg>
  </div>
);

export const BarLoader: FunctionComponent<{
  width?: number;
  height?: number;
  label?: string;
  color?: string;
}> = ({ width = 64, height = 64, label = "", color = "#fff" }) => (
  <div className={"react-spinner-loader-svg"}>
    <svg
      id="bar"
      width={width}
      height={height}
      viewBox="-3 -4 39 39"
      aria-label={label}
    >
      <polygon
        fill="transparent"
        stroke={color}
        strokeWidth="1"
        points="16,0 32,32 0,32"
      />
    </svg>
  </div>
);
