import { Form } from "antd";
import { Slider } from "antd-mobile";
import { RuleObject } from "antd/lib/form";

import "./style.scss";

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  requiredMessage?: string;
  validator?: (
    rule: RuleObject,
    value: any,
    callback: (error?: string | undefined) => void
  ) => any | undefined;
  step?: number;
  max?: number;
}

const FormSlider = ({
  name,
  label,
  required = true,
  requiredMessage,
  validator,
  step = 5_000,
  max = 400_000,
}: Props): JSX.Element => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required,
          validateTrigger: "onSubmit",
          message: requiredMessage || `${label ? label : "Field"} is missing`,
          validator,
        },
      ]}
    >
      <Slider range max={max} step={step} />
    </Form.Item>
  );
};

export default FormSlider;
