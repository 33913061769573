import { FunctionComponent, useState } from "react";

import "./style.scss";

type Props = {
  placeholder?: string;
  onChange?: (value: string) => void;
};

const SearchInput: FunctionComponent<Props> = ({ placeholder, onChange }) => {
  const [value, setValue] = useState("");
  return (
    <input
      type="text"
      className="search-input__wrapper"
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        setValue(e.target.value);
        onChange?.(e.target.value);
      }}
    />
  );
};

export default SearchInput;
