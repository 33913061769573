import { Result } from "antd";

const UserNotFound = (): JSX.Element => (
  <Result
    status="404"
    title="User not found"
    subTitle="Sorry, the user does not exist or does not have a profile yet."
  />
);

export default UserNotFound;
