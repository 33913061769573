import { ReactComponent as FiltersIcon } from "images/icons/filters.svg";
import { ReactComponent as SearchIcon } from "images/icons/search.svg";

import Show from "components/common/Show";

import styles from "./SearchFiltersInput.module.scss";

type Props = {
  open?: () => void;
  filterText: string;
  setFilterText: (text: string) => void;
  placeholder?: string;
  numberOfFiltersApplied?: number;
};

const SearchFiltersInput = ({
  open,
  filterText,
  setFilterText,
  placeholder = "Candidate name, keywords",
  numberOfFiltersApplied,
}: Props) => {
  const onChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterText(e.target.value);
  };
  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        <SearchIcon />
      </span>
      <input
        type="text"
        className={styles.input}
        value={filterText}
        placeholder={placeholder}
        onChange={onChangeText}
      />

      <span className={styles.icon}>
        <Show when={open !== undefined}>
          <span className="relative flex place-content-center">
            <button onClick={open}>
              <FiltersIcon />
            </button>
            <Show
              when={
                numberOfFiltersApplied !== undefined &&
                numberOfFiltersApplied > 0
              }
            >
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-[6px] text-xs leading-5 text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-[#ff4d4f] rounded-[10px] ring-1 ring-white">
                {numberOfFiltersApplied}
              </span>
            </Show>
          </span>
        </Show>
      </span>
    </div>
  );
};

export default SearchFiltersInput;
