import { ReactNode, useState } from "react";

import { DeleteFilled } from "@ant-design/icons";
import { Button, Card, message } from "antd";
import clsx from "clsx";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  JobAd,
  RoleEnum,
  useApplyToJobMutation,
  useApplyToJobThroughLeaderMutation,
  useDeleteJobAdMutation,
} from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import useAuth from "components/Auth/useAuth";
import JobTitle from "components/JobTitle";

import { useGetReferral } from "hooks/useGetReferral";
import useWindowSize from "hooks/useWindowSize";

import { formatNumber } from "utils";

import "./style.scss";
import styles from "./styles.module.scss";

type Props = {
  jobAd: Omit<JobAd, "createdBy">;
  isEditing?: boolean;
  toggleIsEditing?: VoidFunction;
  canEdit?: boolean;
  applicationId?: string | null;
  canApply?: boolean;
  backTo?: string;
  buttons?: ReactNode;
};

const JobHeader = ({
  jobAd,
  isEditing,
  toggleIsEditing,
  canEdit,
  applicationId,
  canApply = true,
  backTo,
  buttons,
}: Props): JSX.Element => {
  const { user, isLoggedIn } = useAuth();
  const { location, currency, minSalary, maxSalary, company } = jobAd;
  const navigate = useNavigate();

  const { isMobile } = useWindowSize();
  const referralId = useGetReferral();

  const [applySuccess, setApplySuccess] = useState(false);
  const [applyToJob, { loading: loadingApply }] = useApplyToJobMutation({
    variables: { jobAdId: jobAd.id },
    notifyOnNetworkStatusChange: true,
  });

  const [applyToJobWithReference, { loading: loadingApplyWithReference }] =
    useApplyToJobThroughLeaderMutation({
      variables: {
        jobAdId: jobAd.id,
        communityLeaderDomain: referralId,
      },
      notifyOnNetworkStatusChange: true,
      ignoreResults: !!referralId,
    });

  const [deleteJob, { loading: loadingDelete }] = useDeleteJobAdMutation({
    variables: {
      jobAdId: jobAd.id,
    },
    onCompleted: () => {
      message.success("Job successfully set as finished");
      navigate("/");
    },
    onError: () => {
      message.error("The job was not set as finished");
    },
  });

  const handleDeleteReference = async () => {
    deleteJob();
  };

  if (isMobile) {
    return (
      <>
        <div className="job__company-header-wrapper mb-[24px]">
          <div className="job__company-header-empty-placeholder" />
          <div className="flex flex-col justify-center items-center gap-[33px]">
            <img
              className="w-[92px] rounded-lg"
              src={company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
              alt="Company logo"
            />

            <div className="font-sora font-semibold text-[18px] leading-[16px] color-white text-center">
              {jobAd.title}
            </div>
          </div>

          {canEdit && !isEditing && toggleIsEditing ? (
            <div className="job__company-header-buttons">
              <Button danger type="primary" className={styles.opacityHover}>
                <DeleteFilled />
                <span>
                  <b>DELETE</b>
                </span>
              </Button>
              <Button
                type="primary"
                className={styles.opacityHover}
                onClick={toggleIsEditing}
              >
                <b>EDIT</b>
              </Button>
            </div>
          ) : (
            <div className="job__company-header-empty-placeholder" />
          )}
        </div>
        <div className="mb-[19px] overflow-scroll w-full min-h-[62px] sm:justify-between rounded-[5px] bg-[#343141] py-[14px] px-[12px] gap-2 flex-row items-start sm:gap-3">
          <div className="w-[500px] h-full flex gap-[20px] flex-col sm:flex-row">
            <div className="w-full flex flex-col gap-[6px] items-center sm:items-start justify-between h-full">
              <div className="text-[12px] leading-[15px] text-[#928CA6] font-sora">
                Company
              </div>
              <div className="font-medium text-[13px] leading-[15px] text-white font-sans">
                {company?.name}
              </div>
            </div>
            <div className="w-full flex flex-col gap-[6px] items-center sm:items-start justify-between h-full">
              <div className="text-[12px] leading-[15px] text-[#928CA6] font-sora">
                Location
              </div>
              <div className=" font-medium text-[13px] leading-[15px] text-white font-sans">
                {location}
              </div>
            </div>
            <div className="w-full flex flex-col gap-[6px] items-center sm:items-start justify-between h-full">
              <div className="text-[12px] leading-[15px] text-[#928CA6] font-sora">
                Salary
              </div>
              <div className="font-medium text-[13px] leading-[15px] text-white font-sans">
                {currency} {formatNumber(minSalary! / 1_000)}-
                {formatNumber(maxSalary, true)}
              </div>
            </div>
            <div className="w-full flex flex-col gap-[6px] items-center sm:items-start justify-between h-full">
              <div className="text-[12px] leading-[15px] text-[#928CA6] font-sora">
                Posted
              </div>
              <div className="font-medium text-[13px] leading-[15px] text-white font-sans">
                {new Date(jobAd.date).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderApplyButton = () => {
    let disabled = false;
    let label = "APPLY";
    if (applicationId) {
      label = "VIEW APPLICATION";
    }

    if (!canApply || applySuccess) {
      disabled = true;
      if (applySuccess) label = "APPLIED";
      else label = "APPLY";
    }

    const handleApply = async () => {
      if (!isLoggedIn) {
        navigate("/signin");
        return;
      }

      if (applicationId) {
        navigate(`/my-applications/${applicationId}`);
      } else {
        if (applySuccess) return;
        try {
          if (referralId) {
            const { data } = await applyToJobWithReference();
            if (data?.applyToJobThroughCommunityLeader) {
              setApplySuccess(true);
              toast.success("Application sent successfully", {
                autoClose: 2000,
              });
            }
          } else {
            const { data } = await applyToJob();
            if (data?.applyToJob) {
              setApplySuccess(true);
              toast.success("Application sent successfully", {
                autoClose: 2000,
              });
            }
          }
        } catch (ex: any) {
          console.warn(ex.messages);
        }
      }
    };

    return (
      <Button
        onClick={handleApply}
        type="primary"
        size="large"
        shape="round"
        disabled={disabled}
        loading={loadingApply}
        className={styles.opacityHover}
      >
        <b>{label}</b>
      </Button>
    );
  };

  const renderButtons = () => {
    if (buttons) {
      return buttons;
    }

    if (canEdit && !isEditing && toggleIsEditing) {
      return (
        <>
          <Button
            size="large"
            danger
            type="primary"
            shape="round"
            onClick={handleDeleteReference}
          >
            <DeleteFilled />
            <span>
              <b>DELETE</b>
            </span>
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={toggleIsEditing}
            shape="round"
          >
            <div className="px-3">
              <b>EDIT</b>
            </div>
          </Button>
        </>
      );
    }
    if (!isLoggedIn || user?.activeRole === RoleEnum.Candidate) {
      return (
        <>
          <Button
            type="primary"
            size="large"
            shape="round"
            className={clsx("gradient-background", styles.opacityHover)}
            onClick={() => {
              if (!isLoggedIn) navigate("/signin");
              else navigate(`/job/${jobAd.id}/endorse`);
            }}
          >
            <span>
              <b>ENDORSE</b>
            </span>
          </Button>
          {renderApplyButton()}
        </>
      );
    }
  };

  return (
    <>
      <div className="job__company-header-wrapper">
        {backTo && (
          <Link to={backTo} className="">
            <AiOutlineLeft className="text-lg text-white" />
          </Link>
        )}
        <img
          className="job__company-image"
          src={company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
          alt="Company logo"
        />
        <JobTitle
          title={jobAd.title}
          subtitle={`${jobAd.format.replaceAll("_", " ")} - ${jobAd?.location}`}
          noMargin
        />
        <div className="job__company-header-buttons">{renderButtons()}</div>
      </div>
      <Card className="job__job-summary-card" bordered={false}>
        <div className="job__job-summary-card-column">
          <div>Company</div>
          <div>{company?.name}</div>
        </div>
        <div className="job__job-summary-card-column">
          <div>Location</div>
          <div>{location}</div>
        </div>
        <div className="job__job-summary-card-column">
          <div>Salary</div>
          <div>
            {currency} {formatNumber(minSalary! / 1_000)}-
            {formatNumber(maxSalary, true)}
          </div>
        </div>
        <div className="job__job-summary-card-column">
          <div>Posted</div>
          <div>{new Date(jobAd.date).toLocaleDateString()}</div>
        </div>
      </Card>
    </>
  );
};

export default JobHeader;
