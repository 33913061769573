import { FunctionComponent } from "react";
import * as React from "react";

import cn from "clsx";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import useAuth from "components/Auth/useAuth";
import { TriangleSpinner } from "components/common/loaders";
import { DialogControls, DialogWrapper } from "components/modals/common";

import { emailRegex } from "utils";

import styles from "./modals.module.scss";

export const VerifiedEmailDialog: FunctionComponent<DialogControls> = ({
  open,
  setOpen,
}) => {
  let { isEmailVerified, emailVerificationRequest } = useAuth();
  const location = useLocation();

  React.useEffect(() => {
    const path = location.pathname.split("/");
    const id = path[path.length - 1];
    emailVerificationRequest(id);
  }, []);

  return (
    <DialogWrapper isUnclosable open={open} setOpen={setOpen}>
      <div className="fixed z-10 inset-0 flex items-center justify-center p-4">
        <div
          className={cn(
            "w-[465px] h-[394px] py-9 px-10 bg-[#121121] rounded-lg flex flex-col items-center"
          )}
        >
          <img className="w-[78px]" src="/icons/dorse_logo.svg" />
          {isEmailVerified === null || isEmailVerified === undefined ? (
            <VerifyLoading />
          ) : (
            <VerifyFailed />
          )}
        </div>
      </div>
    </DialogWrapper>
  );
};

const VerifyLoading: FunctionComponent = () => {
  return (
    <>
      <div className="mt-10">
        <TriangleSpinner width={48} height={48} />
      </div>
      <p className="mt-4 font-mono text-txtGrey text-sm">Verifying Email...</p>
    </>
  );
};

const VerifyFailed: FunctionComponent = () => {
  const navigate = useNavigate();
  const {
    getValues,
    register,
    formState: { errors, touchedFields },
  } = useForm<{ email: string }>({
    mode: "onBlur",
  });
  const { requestEmailVerification } = useAuth();

  const verifyEmailRequest = () => {
    const { email } = getValues();
    //  TODO : get verification method from backend
    requestEmailVerification(email);
  };

  return (
    <>
      <h3 className="mt-6 font-sora text-[20px] font-[600]">
        Oh crap, maybe the link expired 🤔
      </h3>
      <p className="mt-2 text-center text-sm">
        No worries, type your email here and we'll send you
        <br />a new one.
      </p>
      <section className="mt-4 pb-[46px] w-full relative">
        <label className={cn("text-sm", { "text-errorRed": errors.email })}>
          Email Address
        </label>
        <div
          className={cn("w-full border-primary border-b h-[30px] pr-10", {
            "border-errorRed": errors.email,
          })}
        >
          <input
            placeholder="Email"
            className="bg-transparent focused:text-sm focused:placeholder-text-sm text-sm placeholder-text-sm placeholder-opacity-50 placeholder-[#C4C4C4] text-[#C4C4C4] w-full h-full pb-2"
            {...register("email", {
              required: true,
              pattern: emailRegex,
            })}
          />
          <img
            className={cn(
              "absolute right-[16px] top-[50%] translate-y-[-50%]",
              !errors.email && touchedFields.email ? "" : "hidden"
            )}
            src="/icons/form-tick.svg"
          />
        </div>
        <p
          className={cn(
            "absolute left-0.5 bottom-[25px] font-light leading-none text-[13px] text-errorRed",
            { hidden: !errors.email }
          )}
        >
          The email address is incomplete
        </p>
      </section>
      <button
        onClick={verifyEmailRequest}
        className={
          (clsx(
            "w-full h-[54px] flex items-center justify-center rounded-3xl bg-primary  transition-opacity duration-200"
          ),
          styles.hover)
        }
      >
        <p className="font-mono font-[500] text-white">RESEND EMAIL</p>
      </button>
      <button className="mt-6" onClick={() => navigate("/signin")}>
        <p
          className={
            (clsx(
              "underline text-xs text-[#D0C9D6] transition-opacity duration-200"
            ),
            styles.hover)
          }
        >
          Back to login
        </p>
      </button>
    </>
  );
};
