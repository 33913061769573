import { PublicKey } from "@solana/web3.js";
import { PhantomProvider } from "types";

/**
 * @description gets Phantom provider, if it exists
 */
export const getProvider = (): PhantomProvider | undefined => {
  if ("solana" in window) {
    // @ts-ignore
    const provider = window.solana as any;
    if (provider.isPhantom) return provider as PhantomProvider;
  }
};

export const connectToWallet = async (): Promise<PublicKey | undefined> => {
  if ("solana" in window) {
    // @ts-ignore
    const provider = window.solana as any;

    try {
      const response = await provider.connect();
      return Promise.resolve(response.publicKey);
    } catch (err) {
      return Promise.reject(err);
    }
  } else {
    return Promise.reject("Please install the Phantom Browser extension");
  }
};
