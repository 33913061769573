import styles from "./BlueTag.module.scss";

type Props = {
  tag: string;
};

const BlueTag = ({ tag }: Props) => {
  return <div className={styles.tag}>{tag.replaceAll("_", " ")}</div>;
};

export default BlueTag;
