import clsx from "clsx";

import {
  ExperienceEnum,
  FieldEnum,
  JobFilters,
  JobTypeEnum,
} from "generated/graphql";

import For from "components/common/For";
import InputSlider from "components/InputSlider/InputSlider";

import { formatNumber } from "utils";

import styles from "./FiltersBody.module.scss";

type Props = {
  filters: JobFilters;
  handleToggleField: (field: FieldEnum) => void;
  handleToggleExperience: (experience: ExperienceEnum) => void;
  handleToggleFormat: (format: JobTypeEnum) => void;
  handleChangeMinSalary: (minSalary: number) => void;
  isCategory?: boolean;
  isSalary?: boolean;
  isExperience?: boolean;
  isType?: boolean;
};

const FiltersBody = ({
  filters,
  handleToggleField,
  handleToggleExperience,
  handleToggleFormat,
  handleChangeMinSalary,
  isCategory = true,
  isSalary = true,
  isExperience = true,
  isType = true,
}: Props) => {
  return (
    <div
      className="flex flex-col gap-[26px] font-sans"
      onTouchMove={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className={clsx("flex flex-col gap-[11px]", {
          hidden: !isCategory,
        })}
      >
        <span className="text-[12px] font-medium leading-3 text-white uppercase">
          Job category
        </span>
        <div className={"flex gap-2 flex-wrap"}>
          <For items={Object.values(FieldEnum)} keyFunc={(field) => field}>
            {(field) => {
              const isSelected = filters.fields?.includes(field) ?? false;
              return (
                <Tag
                  onClick={() => handleToggleField(field)}
                  isSelected={isSelected}
                  text={field}
                />
              );
            }}
          </For>
        </div>
      </div>

      <div
        className={clsx("flex flex-col gap-[11px]", {
          hidden: !isSalary,
        })}
      >
        <div className="flex justify-between  items-center text-[12px] font-medium leading-3 text-white uppercase">
          <span>Min salary</span>
          <span>${formatNumber(filters.minSalary ?? 0)}</span>
        </div>
        <div>
          <InputSlider
            min={0}
            max={200_000}
            step={5_000}
            value={filters.minSalary ?? 0}
            onChange={(value) => handleChangeMinSalary(value)}
          />
        </div>
      </div>

      <div
        className={clsx("flex flex-col gap-[11px]", {
          hidden: !isExperience,
        })}
      >
        <span className="text-[12px] font-medium leading-3 text-white uppercase">
          Experience level
        </span>
        <div className={"flex gap-2 flex-wrap"}>
          <For
            items={Object.values(ExperienceEnum)}
            keyFunc={(experience) => experience}
          >
            {(experience) => {
              const isSelected =
                filters.experiences?.includes(experience) ?? false;
              return (
                <Tag
                  onClick={() => handleToggleExperience(experience)}
                  key={experience}
                  isSelected={isSelected}
                  text={experience}
                />
              );
            }}
          </For>
        </div>
      </div>

      <div
        className={clsx("flex flex-col gap-[11px]", {
          hidden: !isType,
        })}
      >
        <span className="text-[12px] font-medium leading-3 text-white uppercase">
          Job type
        </span>
        <div className={"flex gap-2 flex-wrap"}>
          <For items={Object.values(JobTypeEnum)} keyFunc={(format) => format}>
            {(format) => {
              const isSelected = filters.formats?.includes(format) ?? false;
              return (
                <Tag
                  onClick={() => handleToggleFormat(format)}
                  key={format}
                  isSelected={isSelected}
                  text={format}
                />
              );
            }}
          </For>
        </div>
      </div>
    </div>
  );
};

export default FiltersBody;

const Tag = (props: {
  text: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <button
      onClick={() => props.onClick()}
      className={clsx(
        "p-[11px] capitalize text-[13px] border flex-shrink-0",
        styles.hover,
        {
          "bg-[#5362E9] border-[#5362E9] text-white": props.isSelected,
          "border-[#928CA6] bg-transparent text-[#928CA6]": !props.isSelected,
        }
      )}
    >
      {props.text.replaceAll("_", " ")}
    </button>
  );
};
