import { FunctionComponent } from "react";

import clsx from "clsx";

export const Progressbar: FunctionComponent<{
  decimalProgress: number;
  width: string;
  className?: string;
}> = ({ decimalProgress, width, className }) => {
  return (
    <div
      style={{ width }}
      className={clsx("relative h-[5px] bg-[#E5DDFC] rounded-full ", className)}
    >
      <div
        style={{ width: `${decimalProgress * 100}%` }}
        className={clsx(
          "absolute left-0 top-0 h-full bg-[#9C81EA] rounded-full transition-all"
        )}
      />
    </div>
  );
};
