import { Button } from "antd";
import { OnboardingNextStep } from "types";

import { ReferenceQuestionnaire } from "generated/graphql";

import FormTextArea from "components/Form/Textarea";

type Props = {
  next: OnboardingNextStep;
  question: ReferenceQuestionnaire;
  submit?: boolean;
};

const QuestionStep = ({ next, question, submit }: Props): JSX.Element => {
  const onClickNext = () => {
    next([question.id]);
  };

  return (
    <div className="onboarding-step">
      <section className="lg:py-8 lg:px-16">
        <div className="onboarding-step__title">{question.text}</div>
        <FormTextArea name={question.id} />
        <Button
          block
          type="primary"
          onClick={onClickNext}
          size="large"
          shape="round"
        >
          {submit ? "Finish" : "Next"}
        </Button>
      </section>
    </div>
  );
};

export default QuestionStep;
