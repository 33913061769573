import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

type Props = {
  createdJobId: string;
};

const AddedJob = ({ createdJobId }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="grid w-full h-full place-content-center">
      <div className="grid gap-7 justify-items-center">
        <h3 className={styles.addedJobTitle}>Job post added successfully!</h3>
        <h5 className={styles.addedJobSubtitle}>
          You can always add or modify information about your job post.
        </h5>
        <Button
          className={styles.doneButton}
          type="primary"
          shape="round"
          onClick={() => {
            navigate(`/job/${createdJobId}`);
          }}
          size="large"
        >
          DONE
        </Button>
      </div>
    </div>
  );
};

export default AddedJob;
