import { Avatar } from "antd";
import { Tag } from "antd-mobile";
import { Link, useLocation } from "react-router-dom";

import {
  ApplicationDataFragment,
  JobAdDataFragment,
  RoleEnum,
} from "generated/graphql";

import {
  DEFAULT_COMPANY_PHOTO_URL,
  DEFAULT_USER_PHOTO_URL,
} from "app-constants";

import ActiveMark from "components/ActiveMark";
import useAuth from "components/Auth/useAuth";
import Card from "components/common/Card/Card";
import Show from "components/common/Show";
import StatusTag from "components/StatusTag/StatusTag";

import { formatNumber, formatRelativeDate } from "utils";

type Props = {
  applications: (ApplicationDataFragment & { jobAd?: JobAdDataFragment })[]; //GetCandidateApplicationsQuery["candidateApplications"];
  photoType?: "candidate" | "company";
  titleType?: "candidate" | "job";
  subtitleType?: "location" | "company" | "format";
  footerType?: "referred" | "jobTitle" | "location";
  tabType?: "salary" | "score";
  narrow?: boolean;
  jobId?: string;
};

const ApplicationsList = ({
  applications,
  photoType = "candidate",
  titleType = "candidate",
  subtitleType = "location",
  footerType = "referred",
  tabType,
  narrow = false,
  jobId,
}: Props): JSX.Element => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <div className="w-full flex flex-col gap-3">
      {applications.map(
        ({ id, date, fosterScore, candidate, status, reference, jobAd }) => {
          const renderPhoto = () => {
            const photoUrl =
              photoType === "candidate" || !jobAd
                ? candidate.photoUrl || DEFAULT_USER_PHOTO_URL
                : jobAd.company.photoUrl || DEFAULT_COMPANY_PHOTO_URL;

            return <Avatar src={photoUrl} size={60} shape="square" />;
          };

          const renderTitle = () => {
            if (titleType === "job" && jobAd) {
              return jobAd.title;
            }
            return candidate.name;
          };

          const renderSubtitle = () => {
            if (subtitleType === "company" && jobAd) {
              return jobAd.company.name;
            }
            if (subtitleType === "format" && jobAd) {
              return jobAd.format.replaceAll("_", " ");
            }
            return candidate.location;
          };

          const renderFooter = () => {
            if (footerType === "referred" && reference) {
              return `by ${reference.referrer.name}`;
            }
            if (footerType === "jobTitle" && jobAd) {
              return jobAd.title;
            }
            if (footerType === "location" && jobAd) {
              return jobAd.location;
            }
            return <wbr />;
          };

          const renderTopRight = () => {
            if (tabType === "salary" && jobAd) {
              return (
                <Tag
                  round
                  className="status-tag"
                  style={{
                    backgroundColor: "#951ED0",
                    width: "unset",
                    color: "#fff",
                  }}
                >{`${jobAd.currency} ${formatNumber(
                  jobAd.minSalary / 1000
                )}-${formatNumber(jobAd.maxSalary / 1000)}`}</Tag>
              );
            }
            if (tabType === "score") {
              return (
                <div className="job-application__score">{`${fosterScore}% MATCH`}</div>
              );
            }
            return null;
          };

          const linkRoot =
            user?.activeRole === RoleEnum.Candidate
              ? "my-applications"
              : `jobs/${jobId}/application`;

          return (
            <Link key={id} to={`/${linkRoot}/${id}`} className="relative">
              <Show when={location.pathname.includes(id)}>
                <ActiveMark scroll left="calc(-24px - 38px / 2)" />
              </Show>

              <Card
                left={renderPhoto()}
                center={{
                  first: <div className="job-card-title">{renderTitle()}</div>,
                  second: (
                    <div className="job-card-subtitle">{renderSubtitle()}</div>
                  ),
                  third: (
                    <div className="job-card-footer">{renderFooter()}</div>
                  ),
                }}
                right={{
                  first: renderTopRight(),
                  second: <StatusTag type="application" status={status} />,
                  third: (
                    <div className="job-application__date">
                      {formatRelativeDate(date)}
                    </div>
                  ),
                }}
              />
            </Link>
          );
        }
      )}
    </div>
  );
};

export default ApplicationsList;
