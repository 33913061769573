import React, { useState } from "react";

import { Form, message, Select } from "antd";
import { Button } from "antd";
import { useForm } from "antd/es/form/Form";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import {
  BlockchainEnum,
  CandidateProfile,
  ExperienceEnum,
  FieldEnum,
  Maybe,
  useUpdateCandidateSettingsMutation,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";
import FormCheckbox from "components/Form/Checkbox";
import FormInput from "components/Form/Input";
import Input from "components/Form/Input";
import FormRadio from "components/Form/Radio";
import FormTagList from "components/Form/TagList";
import FormTextarea from "components/Form/Textarea";

import useWindowSize from "hooks/useWindowSize";

import styles from "./styles.module.scss";

const { Option } = Select;

type Props = {
  profile: CandidateProfile;
};

const CandidateEditProfile = ({ profile }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { setUserProfile } = useAuth();
  const [activeBlockchain, setActiveBlockchain] = useState<BlockchainEnum>(
    profile.walletInfo?.blockchain || BlockchainEnum.Solana
  );
  const [selectedFields, setSelectedFields] = useState<string[]>(
    profile.field || []
  );

  const [selectedExperience, setSelectedExperience] = useState<
    Maybe<string> | undefined
  >(profile.experience);

  const [updateCandidateSettings, { loading }] =
    useUpdateCandidateSettingsMutation();

  const getSelectedFields = (field: string): string[] => {
    if (selectedFields?.includes(field)) {
      return selectedFields.filter((f) => f !== field);
    }

    return [...selectedFields, field];
  };

  const [form] = useForm();

  const onSubmit = async (values: any) => {
    const { data, errors } = await updateCandidateSettings({
      variables: {
        candidateSettings: {
          ...values,
          blockchain: activeBlockchain,
        },
      },
    });

    if (data?.updateCandidateSettings) {
      setUserProfile(data.updateCandidateSettings);
      message.success("Successfully updated");
    }

    if (errors) {
      for (let error of errors) {
        message.error(error.message);
      }
    }
  };

  const onChangeBlockchain = (blockchain: BlockchainEnum) => {
    form.setFieldsValue({
      blockchain,
    });
    setActiveBlockchain(blockchain);
  };

  const onUpdateTags = (name: string, tags: string[]) => {
    form.setFieldsValue({
      [name]: tags,
    });
  };

  const initialValues = {
    ...profile,
    blockchain: profile.walletInfo?.blockchain,
    blockchainAddress: profile.walletInfo?.walletAddress,
  };

  return (
    <Form form={form} initialValues={initialValues} onFinish={onSubmit}>
      <FormInput name="location" label="Location" />
      <FormInput name="companyName" label="Company" required={false} />
      <FormInput name="web" label="Web" required={false} />
      <FormInput name="linkedin" label="LinkedIn" />
      <FormInput name="github" label="Github" required={false} />

      <Input
        name="blockchainAddress"
        label="Blockchain public address"
        blockchain={activeBlockchain}
        required={false}
        addonBefore={
          <Select
            defaultValue={BlockchainEnum.Solana}
            value={activeBlockchain}
            style={{ width: "50px" }}
            onChange={(blockchain) => {
              onChangeBlockchain(blockchain);
            }}
          >
            <Option value={BlockchainEnum.Solana}>SOL</Option>
            <Option value={BlockchainEnum.Ethereum}>ETH</Option>
            <Option value={BlockchainEnum.Bitcoin}>BTC</Option>
          </Select>
        }
      />

      <FormCheckbox
        name="field"
        label="Field"
        values={Object.values(FieldEnum)}
        selectedValues={selectedFields as (keyof typeof FieldEnum)[]}
        onSelectValue={(key) => {
          const newSelectedFields = getSelectedFields(key);
          form.setFieldsValue({ field: newSelectedFields });
          setSelectedFields(newSelectedFields);
        }}
      />

      <FormInput name="jobTitle" label="Job title" required={false} />

      <FormRadio
        name="experience"
        label="Experience"
        values={Object.values(ExperienceEnum)}
        selectedValue={selectedExperience as keyof typeof ExperienceEnum}
        onSelectValue={(key) => {
          form.setFieldsValue({ experience: key });
          setSelectedExperience(key);
        }}
      />

      <FormTagList
        name="techSkills"
        onUpdateTags={onUpdateTags}
        initialValues={profile.techSkills || []}
        modalTitle="Add new tech skill"
        addLabel="ADD SKILL"
        sectionTitle="Tech skills"
      />

      <FormTagList
        name="softSkills"
        onUpdateTags={onUpdateTags}
        initialValues={profile.softSkills || []}
        modalTitle="Add your soft skills"
        addLabel="ADD SKILL"
        sectionTitle="Soft skills"
      />

      <FormTextarea name="about" label="About" required={false} />

      {isMobile ? (
        <Button
          color="success"
          type="primary"
          htmlType="submit"
          block
          size="large"
          disabled={loading}
          loading={loading}
          className={clsx("margin-bottom-desktop", styles.hover)}
        >
          SAVE
        </Button>
      ) : (
        <div className="flex justify-end">
          <button
            disabled={loading}
            className="bg-transparent text-[#5362E9] px-[49px] py-[16px] rounded-[40px] font-mono font-bold text-base leading-[21px]"
            onClick={() => {
              navigate("/profile");
            }}
          >
            CANCEL
          </button>
          <button
            type="submit"
            disabled={loading}
            className={clsx(
              "bg-[#5362E9] text-white px-[49px] py-[16px] rounded-[40px] font-mono font-bold text-base leading-[21px]",
              styles.hover
            )}
          >
            SAVE
          </button>
        </div>
      )}
    </Form>
  );
};

export default CandidateEditProfile;
