import { Link, useLocation } from "react-router-dom";

import {
  ApplicationStatusEnum,
  CandidateProfile,
  Hire,
  JobTypeEnum,
} from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import { EndorsementType } from "pages/MyEndorsements/index";

import ActiveMark from "components/ActiveMark";
import Card from "components/common/Card/Card";
import For from "components/common/For";
import Show from "components/common/Show";
import StatusTag from "components/StatusTag/StatusTag";

import { formatRelativeDate, getAvatar } from "utils";

import styles from "./EndorsementList.module.scss";

type Props = {
  endorsements: Array<EndorsementType>;
};

const getId = (endorsement: EndorsementType): string => {
  switch (endorsement.__typename) {
    case "JobApplication":
      return endorsement.id;

    case "ReferenceForReferrer":
      return endorsement.reference.id;

    case "ReferralWithoutJobResponse":
      return endorsement.id;

    default:
      return Math.random().toString();
  }
};

const getData = (
  endorsement: EndorsementType
): {
  id: string;
  link: string;
  title: string;
  subtitle: string;
  footer: string | null | undefined;
  dorseScore: number | undefined;
  status: ApplicationStatusEnum;
  photoUrl: string;
  date: string;
  format: JobTypeEnum | undefined;
  hire: Partial<Hire> | null | undefined;
} => {
  const id = getId(endorsement);

  switch (endorsement.__typename) {
    // Accepted endorsement
    case "JobApplication":
      return {
        id,
        link: `application/${id}`,
        title: endorsement.candidate.name,
        subtitle: endorsement.jobAd.company.name,
        footer: endorsement.jobAd.location,
        dorseScore: endorsement.fosterScore,
        status: endorsement.status,
        photoUrl: endorsement.candidate.photoUrl || DEFAULT_USER_PHOTO_URL,
        date: endorsement.date,
        format: endorsement.jobAd.format,
        hire: endorsement.hire,
      };

    // Light endorsement
    case "ReferralWithoutJobResponse":
      return {
        id,
        link: `endorsement/${id}`,
        title: endorsement.name,
        subtitle: endorsement.email,
        footer: "Light endorsed",
        status: ApplicationStatusEnum.Pending,
        photoUrl: getAvatar(endorsement.name),
        date: endorsement.date,
        format: undefined,
        dorseScore: undefined,
        hire: undefined,
      };

    // Pending endorsement
    case "ReferenceForReferrer":
      return {
        id,
        link: `referral/${id}`,
        title: endorsement.candidateName,
        subtitle: (endorsement.referee as CandidateProfile)?.location || "-",
        footer: endorsement.jobAd.title,
        photoUrl: endorsement.referee?.photoUrl
          ? endorsement.referee.photoUrl
          : getAvatar(endorsement.reference.id),
        date: endorsement.reference.date,
        dorseScore: undefined,
        format: undefined,
        status: ApplicationStatusEnum.Pending,
        hire: undefined,
      };

    default:
      return {
        id,
        link: "#",
        title: "Unknown",
        subtitle: "Unknown",
        footer: "Unknown",
        date: "Unknown",
        dorseScore: undefined,
        format: undefined,
        status: ApplicationStatusEnum.Pending,
        hire: undefined,
        photoUrl: DEFAULT_USER_PHOTO_URL,
      };
  }
};

const EndorsementList = ({ endorsements }: Props) => {
  const location = useLocation();

  return (
    <div className="flex flex-col gap-3">
      <For items={endorsements.map(getData)} keyFunc={(data) => data.id}>
        {({
          date,
          id,
          link,
          title,
          subtitle,
          footer,
          dorseScore,
          status,
          photoUrl,
        }) => {
          return (
            <Link to={link} className="relative">
              <Show when={location.pathname.includes(id)}>
                <ActiveMark scroll left="calc(-24px - 38px / 2)" />
              </Show>

              <Card
                left={
                  <div
                    className="ant-avatar ant-avatar-square align-self-center"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <img
                      src={photoUrl}
                      alt={photoUrl}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DEFAULT_USER_PHOTO_URL;
                      }}
                    />
                  </div>
                }
                center={{
                  first: <span className={styles.title}>{title}</span>,
                  second: <span className={styles.subtitle}>{subtitle}</span>,
                  third: <span className={styles.footer}>{footer}</span>,
                }}
                right={{
                  first: (
                    <span className={styles.score}>
                      {dorseScore ? `${dorseScore}% MATCH` : ""}
                    </span>
                  ),
                  second: <StatusTag type="application" status={status} />,
                  third: (
                    <span className={styles.date}>
                      {formatRelativeDate(date)}
                    </span>
                  ),
                }}
              />
            </Link>
          );
        }}
      </For>
    </div>
  );
};

export default EndorsementList;
