import { FunctionComponent, MutableRefObject, useEffect, useRef } from "react";

import { Avatar } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  GetConversationsDocument,
  GetMessageCreatedDocument,
  Maybe,
  useGetConversationQuery,
  useSendMessageMutation,
} from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import MessageItem from "pages/Conversation/MessageItem";

import useAuth from "components/Auth/useAuth";
import Loading from "components/Loading";
import NavBar from "components/NavBar";
import SendButton from "components/SendButton";

import useWindowSize from "../../hooks/useWindowSize";
import "./style.scss";

const ConversationTitle = ({
  photoUrl,
  name,
}: {
  photoUrl?: Maybe<string>;
  name?: Maybe<string>;
}): JSX.Element => {
  return (
    <div className="conversation-title-wrapper">
      <Avatar
        src={photoUrl || DEFAULT_USER_PHOTO_URL}
        shape="square"
        size={40}
      />
      <div className="conversation-title-main">
        <div className="conversation-title-main-name">{name}</div>
        {/*TODO:*/}
        {/*<div className="conversation-title-main-last-seen">Last seen 14:05</div>*/}
      </div>
    </div>
  );
};

type Props = {
  profileId?: string;
};

const Conversation: FunctionComponent<Props> = ({ profileId }): JSX.Element => {
  const { userProfile, token } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useWindowSize();

  const _profileId = profileId || params.otherProfileId;

  const bottomRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement | null>;

  const { data, loading, error, subscribeToMore } = useGetConversationQuery({
    variables: { otherProfileId: _profileId! },
    onCompleted: () => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    },
  });

  useEffect(() => {
    subscribeToMore({
      document: GetMessageCreatedDocument,
      variables: {
        jwtToken: token,
      },
      onError: (e) => {
        console.log(e);
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        // @ts-ignore
        const newMessage = subscriptionData?.data?.messageCreated;

        return Object.assign({}, prev, {
          conversation: {
            ...prev.conversation,
            // @ts-ignore
            messages: [newMessage, ...prev?.conversation.messages],
          },
        });
      },
    });
  }, [subscribeToMore, token]);

  const [sendMessage] = useSendMessageMutation({
    notifyOnNetworkStatusChange: true,
    onError: () => {
      toast.error("Error sending message. Please try again.");
    },
    onCompleted: () => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    refetchQueries: [
      {
        query: GetConversationsDocument,
      },
    ],
  });

  // TODO: handle errors

  if (error) {
    navigate("/chat", { state: { prevPath: location.pathname } });
  }

  if (loading) {
    return <Loading />;
  }

  const onSubmit = async ({ message }: { message: string }) => {
    const { data: sendMessageData } = await sendMessage({
      variables: { toProfileId: data?.conversation?.with?.id!, text: message },
    });

    return sendMessageData;
  };

  const otherUser = data?.conversation?.with;

  return (
    <>
      {isMobile && (
        <NavBar
          title={
            <ConversationTitle
              photoUrl={otherUser?.photoUrl || DEFAULT_USER_PHOTO_URL}
              name={otherUser?.name}
            />
          }
          hasBorder
          backTo="/chat"
        />
      )}
      <div className="hidden lg:flex h-[104px] w-full items-center gap-[12px] p-6 border-b border-opacity-40 border-[#4A465B]">
        <img
          className="w-[56px] h-[56px] rounded-[5px]"
          src={otherUser?.photoUrl || DEFAULT_USER_PHOTO_URL}
          alt="Other user profile"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DEFAULT_USER_PHOTO_URL;
          }}
        />
        <div className="conversation-title-main-name">{otherUser?.name}</div>
      </div>
      <div className="absolute max-h-[calc(100vh-190px)] lg:max-h-[calc(100vh-298px)] w-full overflow-y-auto">
        <ul
          className="w-full flex flex-col-reverse px-6 pt-6"
          onTouchMove={(e) => {
            e.stopPropagation();
          }}
        >
          {data?.conversation?.messages.map((message) => {
            const isOwn = message.from.id === userProfile?.id;

            return (
              <MessageItem
                key={message.id}
                messageId={message.id}
                isOwn={isOwn}
                text={message.text}
                date={message.date}
              />
            );
          })}
        </ul>
        <div ref={bottomRef} />
      </div>
      <div className="absolute bottom-0 right-0 w-full lg:w-[calc(100vw-694px)]">
        <div className="w-full h-12 lg:h-[79px] py-[9px] lg:pt-[18px] lg:pb-[22px] pl-[9px] pr-4 border-t border-[#4A465B]">
          <SendButton
            placeholder="Type a message..."
            onSubmit={onSubmit}
            autoFocus
          />
        </div>
      </div>
    </>
  );
};

export default Conversation;
