import { useEffect, useState } from "react";

import classNames from "clsx";

import {
  JobFilters,
  JobStatusEnum,
  useGetJobsForCompanyQuery,
} from "generated/graphql";

import JobList from "components/CardList/JobList";
import Tabs from "components/common/Tabs/Tabs";
import Loading from "components/Loading";
import SearchFilters, { defaultFilters } from "components/SearchFilters";

import useFilterJobs from "hooks/useFilterJobs";

import { getNumberOfFiltersApplied } from "utils";

import styles from "./styles.module.scss";

const JobsForCompanyMobile = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState("active");
  const [loadingRefetch, setLoadingRefetch] = useState<boolean>(false);
  const [filters, setFilters] = useState(defaultFilters);

  const { data, loading, refetch } = useGetJobsForCompanyQuery({
    variables: {},
    fetchPolicy: "cache-and-network",
  });

  const [search, setSearch] = useState<string>("");

  const filteredJobs = useFilterJobs(data?.jobsForCompany || [], search);

  const activeJobs = filteredJobs.filter(
    (jobAd) => jobAd.status === JobStatusEnum.Active
  );

  const finishedJobs = filteredJobs.filter(
    (jobAd) => jobAd.status === JobStatusEnum.Finished
  );

  const handleApplyFilters = async (appliedFilters: JobFilters) => {
    setFilters(appliedFilters);
  };

  useEffect(() => {
    (async () => {
      setLoadingRefetch(true);
      await refetch({
        filters,
      });
      setLoadingRefetch(false);
    })();
  }, [filters, refetch]);

  const onChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const renderFilters = () => (
    <>
      <SearchFilters
        filters={filters}
        numberOfFilters={getNumberOfFiltersApplied(filters)}
        filterText={search}
        setFilterText={setSearch}
        onApply={handleApplyFilters}
        loading={loading || loadingRefetch}
      />
    </>
  );

  return (
    <div className="h-full w-full overflow-y-hidden">
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        tabs={[
          {
            key: "active",
            title: "Active",
            content: (
              <div
                className={classNames("p-4 h-full grid gap-4", styles.jobsTab)}
              >
                {renderFilters()}
                <div className="w-full overflow-y-scroll p-0 hide-scrollbar">
                  {data ? <JobList jobs={activeJobs!} /> : <Loading />}
                </div>
              </div>
            ),
          },
          {
            key: "finished",
            title: "Finished",
            content: (
              <div
                className={classNames("p-4 h-full grid gap-4", styles.jobsTab)}
              >
                {renderFilters()}
                <div className="h-full w-full overflow-y-scroll p-0 hide-scrollbar">
                  {data ? <JobList jobs={finishedJobs!} /> : <Loading />}
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default JobsForCompanyMobile;
