import clsx from "clsx";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import { JobPublicFragment } from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import For from "components/common/For";
import Show from "components/common/Show";

import { formatNumber, formatRelativeDate } from "utils";
import cutAndEllipsis from "utils/style/cutAndEllipsis";

import styles from "./JobGrid.module.scss";

const JobGrid = ({
  jobs,
  moreFirstSpacing,
}: {
  jobs: JobPublicFragment[];
  moreFirstSpacing?: boolean;
}) => {
  return (
    <div className={styles.wrapper}>
      <div
        // className="gap-1.5 overflow-y-scroll hide-scrollbar sm:overflow-auto sm:grid grid-flow-col auto-cols-auto auto-rows-auto"

        className={clsx(
          styles.grid,
          moreFirstSpacing ? styles.moreFirstSpacing : null
        )}
      >
        <For items={jobs} keyFunc={(job) => job.id}>
          {(job, i) => (
            <Link className={styles.card} to={`/job/${job.id}`}>
              <div className="w-full h-full flex flex-col ">
                <div className="flex justify-between w-full gap-y-2">
                  <img
                    src={job.company.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
                    alt={job.company.name}
                    className={styles.logo}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = DEFAULT_COMPANY_PHOTO_URL;
                    }}
                  />

                  <div
                    className={clsx(styles.earningTag, {
                      "bg-[#9C81EA]": i % 2 === 0,
                      "bg-[#A4EB99]": i % 2 === 1,
                    })}
                  >
                    💰 Earn up to {(job.minSalary * 0.1) / 1000}K
                    {/* {job.currency} */}
                  </div>
                </div>
                <div className={clsx(styles.jobName, "line-clamp-2")}>
                  {job.title}
                </div>

                <div className={styles.tagWrapper}>
                  <span className={styles.salaryTag}>
                    {formatNumber(job.minSalary, true)}
                    {" - "}
                    {formatNumber(job.maxSalary, true)} {job.currency}
                  </span>

                  {job.isRemote ? (
                    <span className={styles.remote}>REMOTE</span>
                  ) : null}
                </div>

                <div className={styles.secondTagWrapper}>
                  {job.location?.split(", ").map((location) => (
                    <span key={location} className={styles.location}>
                      {location}
                    </span>
                  ))}
                </div>

                <div className={styles.details}>
                  <ReactMarkdown className="markdown line-clamp-4">
                    {cutAndEllipsis({ line: job.description, limit: 110 })}
                  </ReactMarkdown>
                </div>
                <div className="flex flex-col justify-between items-start">
                  <span className={styles.time}>
                    {formatRelativeDate(job.date)}
                  </span>
                </div>
              </div>
            </Link>
          )}
        </For>
        <Show when={jobs.length > 4}>
          <div className="md:hidden" />
        </Show>
      </div>
    </div>
  );
};

export default JobGrid;
