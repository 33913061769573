import { DownCircleFilled } from "@ant-design/icons";
import { Select as AntSelect, Form, InputProps } from "antd";

import "./style.scss";

type Option = {
  id: string;
  name: string;
};

interface Props extends InputProps {
  name: string;
  label?: string;
  required?: boolean;
  requiredMessage?: string;
  placeholder?: string;
  options: (any & Option)[];
}

const FormSelect = ({
  name,
  label,
  required = true,
  requiredMessage,
  placeholder,
  options,
}: Props): JSX.Element => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: requiredMessage || `${label ? label : "Field"} is missing`,
          validateTrigger: "onSubmit",
        },
      ]}
    >
      <AntSelect
        showSearch
        size="large"
        placeholder={placeholder}
        optionFilterProp="value"
        filterOption={(input: any, option: any) => {
          return option?.value?.indexOf(input) >= 0;
        }}
        className="custom-form-select"
        suffixIcon={<DownCircleFilled style={{ fontSize: "16px" }} />}
      >
        {options.map((option) => (
          <AntSelect.Option key={option.id} value={option.id}>
            {option.name}
          </AntSelect.Option>
        ))}
      </AntSelect>
    </Form.Item>
  );
};

export default FormSelect;
