import React from "react";

import { Button } from "antd";
import { Result } from "antd-mobile";
import useWindowSize from "hooks/useWindowSize";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";

import { Maybe } from "generated/graphql";

type Props = {
  referralId?: string;
  errorMessage: Maybe<string>;
  jobAdId: string;
};

const CompleteOnboardingMobile = ({
  referralId,
  errorMessage,
  jobAdId,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  return (
    <div className="lg:py-8 lg:px-16">
      <Result
        icon={<div />}
        status={errorMessage ? "error" : "success"}
        title={
          errorMessage
            ? "Endorsement was not created"
            : "Endorsement completed!"
        }
        description={
          errorMessage
            ? errorMessage
            : "You will be notified about the status of the endorsement once your candidate accepts it"
        }
        style={{
          backgroundColor: "transparent",
        }}
      />
      <div className="flex-centered">
        <Button
          block={true}
          type="primary"
          shape="round"
          size="large"
          style={{ maxWidth: "50%", minWidth: "240px", margin: "auto" }}
          onClick={() => {
            if (referralId) {
              navigate(`/my-endorsements/referral/${referralId}`);
            } else {
              navigate(`/job/${jobAdId}`);
            }
          }}
        >
          {errorMessage ? "BACK" : "DONE"}
        </Button>
      </div>
      {!errorMessage ? (
        <Confetti
          width={width}
          height={height ? height - 80 - 70 : 0}
          numberOfPieces={300}
          colors={["#CD94EB", "#9A2ED1", "#7C588F", "#281830"]}
          recycle={false}
          gravity={0.08}
          initialVelocityY={0}
        />
      ) : null}
    </div>
  );
};

export default CompleteOnboardingMobile;
