import { CandidateProfile } from "generated/graphql";

import BlueTag from "components/BlueTag/BlueTag";

import { isDef } from "utils";

type Props = {
  candidateProfile: Partial<CandidateProfile>;
};

const CandidateTags = ({ candidateProfile }: Props): JSX.Element => {
  const { available, field, experience } = candidateProfile;

  const fields = field ?? [];

  return (
    <div className="flex gap-3">
      {[available ? "Available" : null, ...fields, experience]
        .filter(isDef)
        .map((tag) => (
          <BlueTag tag={tag} key={tag} />
        ))}
    </div>
  );
};

export default CandidateTags;
