import { useCallback, useRef } from "react";

import { FormOutlined } from "@ant-design/icons";
import { Button, Form, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ErrorMsg,
  JobAd,
  JobAdDataFragmentDoc,
  JobForCandidateApplicationFragment,
  Maybe,
  ReferenceQuestionnaire,
  RoleEnum,
  useApplyToJobMutation,
  useEditJobAdMutation,
} from "generated/graphql";

import EditJob, { getDefaultEditJob } from "pages/Job/EditJob";
import JobInformation from "pages/Job/JobInformation";

import useAuth from "components/Auth/useAuth";
import JobAdNotFound from "components/Errors/JobAdNotFound";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import JobHeader from "components/JobHeader";
import Loading from "components/Loading";
import NavBar from "components/NavBar";

import { useGetJobAd } from "hooks/useGetJobAd";
import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

type WrapperProps = {
  edit?: boolean;
};

const JobWrapper = ({ edit = false }: WrapperProps): JSX.Element => {
  const { jobAdId } = useParams();

  const { jobAd, error, loading, application } = useGetJobAd({
    jobAdId: jobAdId!,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    if (error?.graphQLErrors?.[0].message === ErrorMsg.JobAdNotFound) {
      return <JobAdNotFound />;
    }
    return <SomethingWentWrong />;
  }

  if (!jobAd) {
    return <JobAdNotFound />;
  }

  return <Job isEditing={edit} jobAd={jobAd!} application={application} />;
};

type Props = {
  isEditing: boolean;
  jobAd: Omit<JobAd, "createdBy">;
  application?: Maybe<JobForCandidateApplicationFragment>;
};

const Job = ({ isEditing, jobAd, application }: Props): JSX.Element => {
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const jobRef = useRef<HTMLDivElement>(null);
  const initialValues = getDefaultEditJob(jobAd);

  const { isMobile } = useWindowSize();

  const [applyToJob, { loading: loadingApply }] = useApplyToJobMutation({
    variables: { jobAdId: jobAd.id },
    notifyOnNetworkStatusChange: true,
  });

  const [editJobAd] = useEditJobAdMutation({
    update: (client, { data }) => {
      if (!data?.editJobAd) {
        return client;
      }

      client.updateFragment(
        { id: client.identify(jobAd), fragment: JobAdDataFragmentDoc },
        (curr) => curr
      );
    },
  });

  const toggleIsEditing = () => {
    if (isEditing) navigate(`/job/${jobAd.id}`);
    else navigate(`/job/${jobAd.id}/edit`);
  };

  const handleApply = useCallback(async () => {
    if (!isLoggedIn) {
      navigate("/signin");
      return;
    }
    if (application?.id) {
      navigate(`/my-applications/${application.id}`);
    } else {
      const { data } = await applyToJob();
      if (data?.applyToJob) {
        toast.success("Applied successfully");
      }
    }
  }, [isLoggedIn, application]);

  const cancelEditing = () => {
    form.setFieldsValue(initialValues);
    navigate(`/job/${jobAd.id}`);
  };

  const saveEditing = async (
    values: ReturnType<typeof getDefaultEditJob>
  ): Promise<void> => {
    const { data, errors } = await editJobAd({
      variables: {
        jobAdId: jobAd.id,
        jobAdData: {
          benefits: values.benefits,
          description: values.description,
          companyId: values.companyId,
          currency: values.currency,
          editable: values.editable,
          experience: values.experience,
          field: values.field,
          format: values.format,
          location: values.location ?? "",
          minSalary: values.minSalary,
          maxSalary: values.maxSalary,
          isRemote: values.isRemote,
          referenceQuestions: values.referenceQuestions.map(
            (question: ReferenceQuestionnaire) => ({ text: question.text })
          ),
          preferred: values.preferred,
          requirements: values.requirements,
          responsibilities: values.responsibilities,
          title: values.title,
          jobSkills: values.jobSkills,
        },
      },
    });

    if (errors) {
      console.log(errors);
      message.error(errors[0].message);
    } else if (data) {
      message.success("Job successfully updated");
      navigate(`/job/${jobAd.id}`);
    }
  };

  const canEdit =
    user?.activeRole === RoleEnum.CompanyUser ||
    user?.activeRole === RoleEnum.InternalRecruiter;

  return (
    <div
      ref={jobRef}
      className="h-full overflow-y-scroll max-w-[1200px] m-auto"
    >
      <NavBar
        // title={
        //   <>
        //     <span className="ellipsis">{jobAd?.title}</span>
        //   </>
        // }
        backArrow={
          isEditing ? (
            <h6 style={{ marginBottom: 0, fontSize: "16px" }}>Cancel</h6>
          ) : undefined
        }
        onBack={isEditing ? cancelEditing : undefined}
        right={
          canEdit && !isEditing ? (
            <Button
              className="job__view-edit-job"
              onClick={() => {
                navigate(`/job/${jobAd.id}/edit`);
              }}
            >
              <FormOutlined />
            </Button>
          ) : undefined
        }
      />
      <main className="lg:py-8 lg:px-16 regular-padding job__wrapper ">
        <JobHeader
          jobAd={jobAd}
          isEditing={isEditing}
          toggleIsEditing={toggleIsEditing}
          canEdit={canEdit}
          applicationId={application?.id}
          backTo={isMobile ? `/jobs/${jobAd.id}` : `/`}
        />
        {!isEditing ? (
          <JobInformation jobAd={jobAd} />
        ) : (
          <EditJob
            jobAd={jobAd}
            form={form}
            isEditing={isEditing}
            cancelEditing={cancelEditing}
            saveEditing={saveEditing}
          />
        )}
        {(user?.activeRole === RoleEnum.Candidate || !isLoggedIn) &&
        isMobile ? (
          <div className="job__buttons-wrapper">
            <Button
              className="gradient-background tall-button"
              block
              type="primary"
              shape="round"
              onClick={() => {
                if (!isLoggedIn) navigate("/signin");
                else navigate(`/job/${jobAd.id}/endorse`);
              }}
            >
              ENDORSE
            </Button>

            <Button
              className="tall-button"
              color="primary"
              block
              type="primary"
              shape="round"
              loading={loadingApply}
              disabled={loadingApply}
              onClick={handleApply}
            >
              {application?.id ? "VIEW APPLICATION" : "APPLY"}
            </Button>
          </div>
        ) : null}
      </main>
    </div>
  );
};

export default JobWrapper;
