import { useParams } from "react-router-dom";

import {
  OrderEnum,
  SortJobsByKeyEnum,
  useGetJobsForCandidateQuery,
} from "generated/graphql";

import JobsForCandidateList from "components/CardList/JobsForCandidateList";
import Loading from "components/Loading";

import useFilterJobs from "hooks/useFilterJobs";

const JobsForCandidate = () => {
  const { jobAdId } = useParams();

  const { data, loading } = useGetJobsForCandidateQuery({
    variables: {
      sortBy: { key: SortJobsByKeyEnum.Created, order: OrderEnum.Desc },
    },
  });

  const jobs = data?.jobsForCandidate?.map((i) => i.jobAd) || [];

  if (loading) return <Loading />;

  return (
    <div className="grid grid-rows-1 h-full">
      <div className={`grid gap-2`}>
        <div className="overflow-auto p-6 flex flex-col">
          <JobsForCandidateList jobs={jobs} selectedJobId={jobAdId} />
        </div>
      </div>
    </div>
  );
};

export default JobsForCandidate;
