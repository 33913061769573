import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
  ApplicationStatusEnum,
  MyEndorsementsCandidateReferralFragment,
  MyEndorsementsMyPendingReferencesFragment,
  MyEndorsementsMyReferralsWithoutJobFragment,
  useGetReferralsForCandidateQuery,
} from "generated/graphql";

import EndorsementList from "pages/MyEndorsements/EndorsementList";

import DesktopLayout from "components/DesktopLayout";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import Loading from "components/Loading";
import TwoRows from "components/TwoRows/TwoRows";

import useWindowSize from "hooks/useWindowSize";

import Tabs from "./Tabs";

export type EndorsementType =
  | MyEndorsementsCandidateReferralFragment
  | MyEndorsementsMyReferralsWithoutJobFragment
  | MyEndorsementsMyPendingReferencesFragment;

const MyEndorsements = (): JSX.Element => {
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const isIndexPage =
    location.pathname === "/my-endorsements" ||
    location.pathname === "/my-endorsements/";

  const { data, loading, error, refetch } = useGetReferralsForCandidateQuery({
    fetchPolicy: "network-only",
    variables: {},
  });

  if (error) {
    return <SomethingWentWrong />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <SomethingWentWrong />;
  }

  if (isMobile) {
    if (isIndexPage) {
      return (
        <>
          {data.candidateReferrals && (
            <Tabs
              endorsements={[
                ...data.candidateReferrals,
                ...data.myPendingReferences,
                ...data.myReferralsWithoutJob,
              ].filter(Boolean)}
              refetch={refetch}
              loading={loading}
            />
          )}
        </>
      );
    }
    return <Outlet />;
  }

  const endorsements = [
    ...data.candidateReferrals,
    ...data.myPendingReferences,
    ...data.myReferralsWithoutJob,
  ].filter(Boolean);

  const activeEndorsements = endorsements.filter((endorsement) => {
    if (endorsement.__typename === "JobApplication") {
      return [
        ApplicationStatusEnum.Interviewing,
        ApplicationStatusEnum.TechnicalChallenge,
        ApplicationStatusEnum.OnSite,
        ApplicationStatusEnum.Offer,
        ApplicationStatusEnum.Pending,
        ApplicationStatusEnum.InReview,
        "",
      ].includes(endorsement.status);
    }
    return true;
  });

  const finishedEndorsements = endorsements.filter((endorsement) => {
    if (endorsement.__typename === "JobApplication") {
      return [
        ApplicationStatusEnum.Canceled,
        ApplicationStatusEnum.Rejected,
        ApplicationStatusEnum.Hired,
        ApplicationStatusEnum.Invalid,
      ].includes(endorsement.status);
    }
    return false; // These are, by definition, "pending" references
  });

  const renderFirstReferral = () => {
    if (!endorsements[0]) {
      return null;
    }
    switch (endorsements[0].__typename) {
      case "JobApplication":
        return <Navigate to={`application/${endorsements[0].id}`} replace />;

      case "ReferenceForReferrer":
        return (
          <Navigate to={`referral/${endorsements[0].reference.id}`} replace />
        );
      case "ReferralWithoutJobResponse":
        return <Navigate to={`endorsement/${endorsements[0].id}`} replace />;

      default:
        return null;
    }
  };

  return (
    <DesktopLayout
      leftColumn={
        <div className="grid gap-3 h-full">
          <TwoRows
            first={{
              showWhen: activeEndorsements.length > 0,
              title: "Active",
              component: <EndorsementList endorsements={activeEndorsements} />,
            }}
            second={{
              showWhen: finishedEndorsements.length > 0,
              title: "Finished",
              component: (
                <EndorsementList endorsements={finishedEndorsements} />
              ),
            }}
          />
        </div>
      }
      mainColumn={isIndexPage ? renderFirstReferral() : <Outlet />}
    />
  );
};

export default MyEndorsements;
