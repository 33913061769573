import { useEffect, useState } from "react";

import { useReadMessageMutation } from "generated/graphql";

import { formatDate } from "utils";

import "./style.scss";

type Props = {
  messageId: string;
  isOwn: boolean;
  text: string;
  date: string;
};

const MessageItem = ({ messageId, isOwn, text, date }: Props): JSX.Element => {
  const [formattedDate, setFormattedDate] = useState<string>(formatDate(date));

  const [readMessage] = useReadMessageMutation({ variables: { messageId } });

  useEffect(() => {
    if (!isOwn) {
      readMessage().then(() => {
        // TODO: do anything when message is read?
      });
    }
  }, [readMessage, isOwn]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFormattedDate(formatDate(date));
    }, 30_000);

    return () => clearInterval(interval);
  }, [date]);

  return (
    <li
      className={`conversation-message ${
        isOwn ? "conversation-message-own" : ""
      }`}
    >
      <div className="break-all	">{text}</div>
      <div className="conversation-message-date">{formattedDate}</div>
    </li>
  );
};

export default MessageItem;
