import { useState } from "react";

import {
  ExperienceEnum,
  FieldEnum,
  JobFilters,
  JobTypeEnum,
} from "generated/graphql";

import useWindowSize from "hooks/useWindowSize";

import DesktopSearchFilter from "./Desktop";
import MobileSearchFilter from "./Mobile";

export const defaultFilters: JobFilters = {
  fields: [],
  experiences: [],
  formats: [],
  minSalary: 0,
};

export type SearchFiltersProps = {
  filterText: string;
  setFilterText: (text: string) => void;
  onApply: (appliedFilters: JobFilters) => void;
  loading: boolean;
  placeholder?: string;
  filters: JobFilters;
  numberOfFilters: number;
  isCategory?: boolean;
  isSalary?: boolean;
  isExperience?: boolean;
  isType?: boolean;
};

const SearchFilters = (props: SearchFiltersProps): JSX.Element => {
  const { onApply } = props;
  const { isMobile } = useWindowSize();

  const [filters, setFilters] = useState(props.filters);

  const handleReset = () => {
    setFilters(defaultFilters);
  };

  const handleToggleField = (field: FieldEnum) => {
    const isSelected = filters.fields?.includes(field) ?? false;
    const fields = isSelected
      ? filters.fields?.filter((selected) => selected !== field)
      : [...(filters.fields ?? []), field];

    setFilters({
      ...filters,
      fields,
    });
  };

  const handleToggleExperience = (experience: ExperienceEnum) => {
    const isSelected = filters.experiences?.includes(experience) ?? false;
    const experiences = isSelected
      ? filters.experiences?.filter((selected) => selected !== experience)
      : [...(filters.experiences ?? []), experience];

    setFilters({
      ...filters,
      experiences,
    });
  };

  const handleToggleFormat = (format: JobTypeEnum) => {
    const isSelected = filters.formats?.includes(format) ?? false;
    const formats = isSelected
      ? filters.formats?.filter((selected) => selected !== format)
      : [...(filters.formats ?? []), format];

    setFilters({
      ...filters,
      formats,
    });
  };

  const handleChangeMinSalary = (minSalary: number) => {
    setFilters({
      ...filters,
      minSalary: minSalary,
    });
  };

  const handleClear = (callback?: VoidFunction) => {
    onApplyFilters(defaultFilters);
    handleReset();
    callback?.();
  };

  const onApplyFilters = (newFilters: typeof defaultFilters) => {
    onApply(newFilters);
  };

  const childProps = {
    handleToggleField,
    handleToggleExperience,
    handleToggleFormat,
    handleChangeMinSalary,
    handleClear,
    filters,
    onApplyFilters,
    setFilters,
  };

  if (isMobile) {
    return <MobileSearchFilter {...props} {...childProps} />;
  }

  return <DesktopSearchFilter {...props} {...childProps} />;
};

export default SearchFilters;
