import { Navigate, Outlet, useParams } from "react-router-dom";

import Conversation from "pages/Conversation";

import DesktopLayout from "components/DesktopLayout";
import Loading from "components/Loading";
import SearchInput from "components/SearchInput";

import useConversations from "hooks/useConversations";
import { useSearchChat } from "hooks/useSearchChat";
import useWindowSize from "hooks/useWindowSize";

import ChatItem from "./ChatItem";
import NoChatsAvailable from "./NoChatsAvailable";
import "./style.scss";
import styles from "./styles.module.scss";

const Chat = (): JSX.Element => {
  const {
    conversations,
    loading,
    selectedConversation,
    setSelectedConversation,
  } = useConversations();

  const { otherProfileId } = useParams();

  const { isMobile } = useWindowSize();
  const { setFilterText, filteredConversations } = useSearchChat(conversations);

  const onSearchChat = (value: string) => {
    setFilterText(value);
  };

  if (isMobile) {
    return <Outlet />;
  }

  const renderFirstConversation = () => {
    if (loading) {
      return <Loading />;
    }

    if (otherProfileId) {
      return <Conversation profileId={otherProfileId} />;
    }

    if (!conversations || conversations.length === 0) {
      return <NoChatsAvailable />;
    }

    return <Navigate to={`/chat/${conversations[0].with.id}`} />;
  };

  const _selectedConversation =
    otherProfileId || selectedConversation || conversations?.[0]?.with.id;

  const renderConversations = () => {
    if (!filteredConversations || filteredConversations?.length === 0) {
      return (
        <>
          <div className="p-6">
            <h3 className={styles.emptyMessageList}>There are no chats</h3>
          </div>
        </>
      );
    }

    return (
      <>
        {filteredConversations?.map((conversation) => {
          return (
            <ChatItem
              key={conversation.with.id}
              conversation={conversation}
              selected={conversation.with.id === _selectedConversation}
              onClick={() => {
                setSelectedConversation(conversation.with.id);
              }}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="grid grid-cols-[422px,1fr] gap-0 h-full overflow-scroll">
      <main className="border-r border-[#4A465B] h-full overflow-scroll">
        {loading ? (
          <Loading />
        ) : (
          <div className="overflow-scroll h-full">
            <div className="chat__search-wrapper">
              <SearchInput placeholder="Search chat" onChange={onSearchChat} />
            </div>
            {renderConversations()}
          </div>
        )}
      </main>
      <div className="h-full relative">{renderFirstConversation()}</div>
    </div>
  );
};

export default Chat;
