import { useMemo } from "react";

import { EnvironmentOutlined } from "@ant-design/icons";
import { Button } from "antd";
import clsx from "clsx";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { CandidateProfile, Maybe } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import anonymous from "images/anonymous.png";

import "pages/Profile/style.scss";

import useAuth from "components/Auth/useAuth";
import Show from "components/common/Show";
import SocialLink from "components/SocialLink";

import useWindowSize from "hooks/useWindowSize";

import { getAvatar } from "utils";

import styles from "./CandidateHeader.module.scss";

type Props = {
  candidateProfile: Partial<CandidateProfile>;
  obfuscated?: boolean;
  backTo?: Maybe<string>;
  hidePhoto?: boolean;
  hideChat?: boolean;
};

const CandidateHeader = ({
  candidateProfile,
  obfuscated = false,
  hidePhoto = false,
  hideChat = false,
  backTo,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  const { isMobile } = useWindowSize();

  const ownId = userProfile?.id;

  const { id, name, jobTitle, companyName, web, linkedin, github, location } =
    candidateProfile;

  const photoUrl = useMemo(() => {
    if (hidePhoto) {
      return getAvatar(id || name || linkedin || "dorse");
    }
    if (obfuscated) {
      return anonymous;
    }
    return candidateProfile.photoUrl || DEFAULT_USER_PHOTO_URL;
  }, [candidateProfile.photoUrl, id, linkedin, name, hidePhoto, obfuscated]);

  return (
    <div
      style={
        isMobile
          ? {
              background: `transparent url(${
                photoUrl || DEFAULT_USER_PHOTO_URL
              }) center no-repeat`,
              backgroundSize: "cover",
            }
          : {}
      }
    >
      <div className="profile-preview__header">
        <div className="flex gap-3 items-center">
          {backTo && !isMobile && (
            <Link to={backTo}>
              <AiOutlineLeft className="text-lg text-white" />
            </Link>
          )}
          <div className="profile-preview__header-wrapper">
            {!isMobile && (
              <img
                src={photoUrl || DEFAULT_USER_PHOTO_URL}
                width={160}
                height={160}
                alt="Profile"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = DEFAULT_USER_PHOTO_URL;
                }}
              />
            )}
            <div className="flex flex-col gap-2 justify-end">
              <div className="font-sora font-semibold text-2xl leading-[30px]">
                {!obfuscated ? name : "-"}
              </div>
              <div className="font-mono font-normal text-base leading-4">
                {jobTitle}
              </div>
              <Show when={!obfuscated && companyName !== undefined}>
                <div className="font-mono font-normal text-base leading-4 tracking-[-0.005em] text-[#928CA6]">
                  at {companyName}
                </div>
              </Show>

              <Show when={!obfuscated}>
                <div className="grid grid-flow-col auto-cols-max gap-[11px]">
                  {id !== ownId && candidateProfile.__typename && !hideChat && (
                    <Link
                      to={`/chat/${id}`}
                      className={clsx(
                        "h-[42px] w-[110px] rounded-[3px] bg-secondary px-5 py-[7px] flex items-center justify-center",
                        styles.chatLinkButton
                      )}
                    >
                      <p className="font-mono text-[13px] leading-[26px] tracking-[-0.28px] color-white">
                        OPEN CHAT
                      </p>
                    </Link>
                  )}
                  <div className="h-full w-full flex items-center justify-center">
                    <SocialLink link={web} network="web" size={33} />
                  </div>
                  <div className="h-full w-full flex items-center justify-center">
                    <SocialLink link={linkedin} network="linkedin" size={33} />
                  </div>
                  <div className="h-full w-full flex items-center justify-center">
                    <SocialLink link={github} network="github" size={33} />
                  </div>
                </div>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateHeader;
