import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Form, Input, message, Tag } from "antd";
import { Button as MobileButton } from "antd-mobile";
import { AddOutline } from "antd-mobile-icons";
import { FloatingPanelRef } from "antd-mobile/es/components/floating-panel";

import FloatingPanel from "components/FloatingPanel";

import "./style.scss";

type Props = {
  name: string;
  modalTitle: string;
  addLabel: string;
  sectionTitle?: string;
  onUpdateTags: (name: string, tags: string[]) => void;
  initialValues: string[];
  required?: boolean;
};

export const TagList = ({
  name,
  modalTitle,
  addLabel,
  sectionTitle,
  onUpdateTags,
  initialValues,
  required,
}: Props): JSX.Element => {
  const [tags, setTags] = useState<string[]>(initialValues);
  const [left, setLeft] = useState<number>(0);
  const [skillValue, setSkillValue] = useState<string>("");

  const ref = useRef<FloatingPanelRef>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSkillValue(e.target.value);
  };

  const open = useCallback(() => {
    ref.current?.setHeight(300);
  }, []);

  useEffect(() => {
    function handleResize() {
      setLeft(window.innerWidth > 428 ? (window.innerWidth - 428) / 2 : 0);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const close = useCallback(() => {
    setSkillValue("");
    ref.current?.setHeight(0);
  }, []);

  const setRef = useCallback((node) => {
    // @ts-ignore
    ref.current = node;
  }, []);

  const handleAdd = (value: string) => {
    if (tags.indexOf(value) > -1) {
      message.error(`${value} has already been added`);
    } else {
      const newTags = [...tags, value];
      setTags(newTags);
      onUpdateTags(name, newTags);
      close();
    }
  };

  const handleRemove = (index: number) => {
    const newTags = tags.filter((tag, idx) => index !== idx);
    setTags(newTags);
    onUpdateTags(name, newTags);
  };

  const anchors = [0, 300];

  return (
    <section className="tag-list-wrapper">
      {sectionTitle && (
        <div className="ant-form-item-label">
          <label>{sectionTitle}</label>
          {required ? <sup>*</sup> : null}
        </div>
      )}

      <div className="ant-tag-group">
        {tags.map((tag, index) => (
          <Tag
            key={tag}
            className="custom-ant-tag ant-mobile-tag-selected"
            closable
            onClose={() => handleRemove(index)}
          >
            {tag}
          </Tag>
        ))}
      </div>
      <Tag className="custom-ant-tag selectable-tag" onClick={open}>
        <AddOutline /> {addLabel}
      </Tag>
      <FloatingPanel
        ref={setRef}
        anchors={anchors}
        style={{
          left: left,
          padding: "16px",
        }}
      >
        <div className="children-bottom-margin centered">
          <h1>{modalTitle}</h1>
          <Input
            value={skillValue}
            onChange={onChange}
            autoFocus
            size="large"
            className="custom-simple-input"
            placeholder="Add skill"
          />
          <MobileButton
            color="primary"
            size="large"
            block
            onClick={() => {
              handleAdd(skillValue);
            }}
          >
            ADD SKILL
          </MobileButton>
          <MobileButton
            className="button__background-color"
            size="large"
            block
            onClick={close}
          >
            CANCEL
          </MobileButton>
        </div>
      </FloatingPanel>
    </section>
  );
};

interface FormTagListProps {
  name: string;
  label?: ReactNode;
  required?: boolean;
  requiredMessage?: string;
  onUpdateTags: (name: string, tags: string[]) => void;
  initialValues: string[];
  modalTitle: string;
  addLabel: string;
  sectionTitle?: string;
}

const FormTagList = ({
  name,
  onUpdateTags,
  initialValues,
  modalTitle,
  addLabel,
  sectionTitle,
}: FormTagListProps): JSX.Element => {
  return (
    <Form.Item name={name}>
      <TagList
        name={name}
        modalTitle={modalTitle}
        addLabel={addLabel}
        sectionTitle={sectionTitle}
        onUpdateTags={onUpdateTags}
        initialValues={initialValues}
      />
    </Form.Item>
  );
};

export default FormTagList;
