import { useMemo, useState } from "react";

import Fuse from "fuse.js";

import {
  CandidateProfile,
  ExperienceEnum,
  JobFilters,
  Maybe,
} from "generated/graphql";

export const useFilterCandidates = (
  applications: CandidateProfile[],
  searchText: string,
  filters: JobFilters
) => {
  const options = {
    includeScore: true,
    minMatchCharLength: 2,
    threshold: 0.3,
    keys: [
      { name: "jobTitle", weight: 4 },

      { name: "field", weight: 2 },
      { name: "name", weight: 2 },
      { name: "experience", weight: 2 },
    ],
  };
  const fuse = new Fuse(applications, options);

  const filteredApplications = () => {
    if (searchText.length < 2) {
      return applications;
    }

    return fuse.search(searchText).map(({ item }) => item);
  };

  return filteredApplications();
};

export default useFilterCandidates;
