import { useEffect, useState } from "react";
import { useRef } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useGetHardSkillsInHighDemandQuery,
  useReferCandidateWithoutJobMutation,
} from "generated/graphql";

import { ENDORSE } from "app-constants";

import { ReactComponent as Back } from "images/icons/back.svg";

import { Progressbar } from "components/common";

import useEndorse from "hooks/useEndorse";
import useScrollToView from "hooks/useScrollToView";
import useWindowSize from "hooks/useWindowSize";

import Step from "./Step";
import styles from "./style.module.scss";

const Endorse = () => {
  //nohook
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 0
  );
  const { isMobile } = useWindowSize();

  //hook
  const { data } = useGetHardSkillsInHighDemandQuery();
  useScrollToView({ selectedRef, page });

  const {
    register,
    handleButton,
    handleBack,
    handleAnonymous,
    errors,
    toggleTag,
    selectedSkills,
    toggle,
    isStarted,
  } = useEndorse({ page, setPage });
  if (isMobile && (!isStarted || page === 0))
    return (
      <div className="flex flex-col justify-center items-center gap-[173px] px-3  pt-[60px] pb-[146px]">
        <div className="flex flex-col justify-center gap-4 ">
          <h1 className={styles.mobileTitle}>
            Endorse now
            <br />& earn up to 20k
          </h1>
          <p className="leading-[18px] xs:leading-5 text-[13px] font-sora tracking-[-0.6px] px-7 xs:px-3 text-center xs:text-[16px] ">
            If something pops up we’ll just simply present an
            <strong className="text-[#9C81EA] font-sora"> opportunity </strong>
            {"   "}
            to them to see whether it’s something they’d like to hear more
            about, just help us with a couple of questions:
          </p>
        </div>
        <div className="flex flex-col gap-[61px] max-w-[307px] ">
          <h2
            className={clsx(
              "uppercase w-full text-[#9C81EA] font-semibold text-[14px] leading-[18px] font-sora text-center",
              styles.fontColor
            )}
          >
            HOW ENDORSE WORKS?
          </h2>

          <div className="flex flex-col gap-[28px]">
            <div className="flex items-center w-full gap-2">
              <img
                className="w-[47px]"
                src="/images/mobile-endorse-1.png"
                alt=""
              />
              <div className="flex flex-col gap-1">
                <h4 className="font-sora text-[14px] leading-[18px] tracking-[-0.03em] font-bold">
                  1. You Endorse
                </h4>
                <p className="text-[12px] tracking-[-0.02em] leading-[14px] font-sans">
                  In other words :{" "}
                  <strong className="text-[#9C81EA] ">Endorse them!</strong>
                </p>
              </div>
            </div>
            <div className="flex items-center w-full gap-2">
              <img
                className="w-[47px] h-[47px]"
                src="./images/mobile-endorse-2.png"
                alt=""
              />

              <div className="flex flex-col gap-1">
                <h4 className=" capitalize font-sora text-[14px] leading-[18px] tracking-[-0.03em] font-bold">
                  2. We Match
                </h4>
                <p className="text-[12px] tracking-[-0.02em] leading-[14px] font-sans">
                  We use your endorsement to match candidates to the best job
                  gigs for them.
                </p>
              </div>
            </div>
            <div className="flex items-center w-full gap-2">
              <img
                className="w-[47px] h-[47px]"
                src="/images/mobile-endorse-3.png"
                alt=""
              />

              <div className="flex flex-col gap-1">
                <h4 className="capitalize font-sora text-[14px] leading-[18px] tracking-[-0.03em] font-bold">
                  3. You Get Paid
                </h4>
                <p className="text-[12px] tracking-[-0.02em] leading-[14px] font-sans">
                  After someone gets an offer through your endorsement, you get
                  paid the moment they start working on the job you got them.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={handleButton}
          className={clsx(
            "uppercase w-full  max-w-[310px]  bottom-[100px] fixed  font-bold text-[16px] leading-5  h-[56px] flex flex-col justify-center items-center rounded-[40px]",
            styles.linear,
            styles.hover
          )}
        >
          I HAVE A COLLEAGUE 💸
        </button>
      </div>
    );

  return (
    <div className="flex flex-col relative  items-center px-2 lg:px-0 lg:pt-[70px]  ">
      <span ref={selectedRef} />
      {page > 4 || isMobile ? null : (
        <>
          <Back
            className="absolute left-[10px] lg:left-[41px] top-[60px] cursor-pointer"
            onClick={handleBack}
          />
          <Progressbar
            width={"80%"}
            decimalProgress={0.2 * (page + 1)}
            className="mb-[50px]"
          />
        </>
      )}

      <Step
        isMobile={isMobile}
        page={page}
        handleBack={handleBack}
        handleAnonymous={handleAnonymous}
        toggle={toggle}
        errors={errors}
        toggleTag={toggleTag}
        handleButton={handleButton}
        list={data?.getHardSkillsInHighDemand as { ID: string; Name: string }[]}
        register={register}
        selectedList={selectedSkills}
      />
    </div>
  );
};

export default Endorse;
