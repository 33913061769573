import { useState } from "react";

import { Button } from "antd";
import { PopupModal } from "react-calendly";

import {
  CandidateProfile,
  InternalRecruiterAssignedToJobFragment,
  Maybe,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";

import "./style.scss";

type Props = {
  internalRecruiter: Maybe<InternalRecruiterAssignedToJobFragment>;
  buttonLabel?: string;
  a1?: string;
  a2?: string;
  a3?: string;
  a4?: string;
  a5?: string;
  a6?: string;
};

const Calendly = ({
  internalRecruiter,
  buttonLabel = "Schedule meeting",
  a1,
  a2,
  a3,
  a4,
  a5,
}: Props): JSX.Element => {
  const { user, userProfile } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // if (!internalRecruiter) {
  //   return <span>ERROR</span>;
  // }

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button onClick={onOpen} type="link" style={{ padding: 0 }}>
        {buttonLabel}
      </Button>
      <PopupModal
        url={internalRecruiter?.calendlyUrl || "blasrodri"}
        onModalClose={onClose}
        open={isOpen}
        rootElement={document.getElementById("root")!}
        prefill={{
          name: userProfile?.name,
          email: user?.email,
          location: (userProfile as CandidateProfile)?.location,
          customAnswers: {
            a1: a1 || "",
            a2: a2 || "",
            a3: a3 || "",
            a4: a4 || "",
            a5: a5 || "",
          },
        }}
      />
    </>
  );
};

export default Calendly;
