import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import AuthProvider from "components/Auth";
import ErrorBoundary from "components/Errors/ErrorBoundary";

import { createLinks } from "utils/graphql";

import App from "./App";
import generatedIntrospection from "./fragmentTypes.json";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE &&
  process.env.REACT_APP_SENTRY_DSN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-PZ18SKK51Q");
}

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: function (number: any, withoutSuffix: boolean) {
      if (withoutSuffix) {
        return "now";
      }
      return "a few sec";
    },
    m: "a min",
    mm: "%d min",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const client = new ApolloClient({
  link: createLinks(),
  cache: new InMemoryCache({
    typePolicies: {
      Application: {
        fields: {
          status: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          conversations: {
            merge: (existing, incoming) => {
              return incoming;
            },
          },
        },
      },
    },
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <AuthProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </AuthProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
