import { FunctionComponent, useEffect } from "react";

import { useParams } from "react-router-dom";

import {
  ErrorMsg,
  JobStatusEnum,
  useGetJobForCompanyQuery,
} from "generated/graphql";

import JobAdNotFound from "components/Errors/JobAdNotFound";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import Unauthorized from "components/Errors/Unauthorized";
import JobDropdownOptions from "components/JobDropdownOptions/JobDropdownOptions";
import LoadingCentered from "components/Loading/LoadingCentered";

import ActiveJob from "./ActiveJob";
import FinishedJob from "./FinishedJob";
import styles from "./styles.module.scss";

type Props = {
  jobAdId?: string;
};

const JobForCompanyPage: FunctionComponent<Props> = ({ jobAdId }) => {
  const params = useParams();

  const _jobAdId = jobAdId || params.jobAdId;

  const { data, error } = useGetJobForCompanyQuery({
    variables: { jobAdId: _jobAdId! },
  });

  if (error) {
    const message = error.graphQLErrors?.[0]?.message;

    if (message === ErrorMsg.JobAdNotFound)
      return <JobAdNotFound jobAdId={_jobAdId} />;

    if (message === ErrorMsg.Unauthorized)
      return (
        <Unauthorized message="Sorry, this job belongs to another company" />
      );

    return <SomethingWentWrong />;
  }

  const job = data?.jobForCompany;

  if (!job) return <LoadingCentered />;

  return (
    <div className="flex w-full  flex-col gap-[52px] ">
      <div className=" flex h-full  justify-between overflow-x-scroll overflow-y-visible">
        <div className=" w-full flex gap-5 items-end " overflow-y-visible>
          {job.jobAd.company?.photoUrl && (
            <img
              className="w-[65px] h-[65px]"
              src={job.jobAd.company?.photoUrl}
              alt=""
            />
          )}

          <div className="grid gap-2">
            <h3 className={styles.jobTitle}>{job.jobAd.title}</h3>
            <div className={styles.jobSubTitle}>
              {job.jobAd?.format?.replace("_", " ")} - {job.jobAd.location}
              {job?.jobAd?.isRemote ? ` & Remote` : null}
            </div>
          </div>
        </div>
      </div>
      <JobDropdownOptions jobId={job.jobAd.id} />

      {job.jobAd.status !== JobStatusEnum.Finished ? (
        <FinishedJob job={job} />
      ) : (
        // <ActiveJob job={job} />
        <FinishedJob job={job} />
      )}
    </div>
  );
};

export default JobForCompanyPage;
