import { Avatar } from "antd";
import clsx from "clsx";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { JobAdCompany } from "types";

import { CompanyHrProfile } from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import ActiveMark from "components/ActiveMark";
import useAuth from "components/Auth/useAuth";
import Show from "components/common/Show";
import NoJobsAvailable from "components/NoJobsAvailable";

import { formatNumber, formatRelativeDate } from "utils";

import styles from "./JobList.module.scss";
import "./style.scss";

type Props = {
  jobs: JobAdCompany[];
  selectedJobId?: JobAdCompany["id"];
};

const JobList = ({ jobs, selectedJobId }: Props): JSX.Element => {
  const { userProfile } = useAuth();

  if (jobs.length === 0) {
    return <NoJobsAvailable />;
  }

  return (
    <div className="flex flex-col gap-4 ">
      {jobs.map((jobAd) => {
        const {
          id,
          title,
          format,
          location,
          currency,
          minSalary,
          maxSalary,
          company,
          date,
          numberOfApplications,
        } = jobAd;

        const isSelected = id === selectedJobId;

        return (
          <Link key={id} to={`/jobs/${id}`} className={styles.container}>
            <Show when={isSelected}>
              <ActiveMark scroll left="calc(-24px - 38px / 2)" />
            </Show>
            <div className={styles.left}>
              {(userProfile as CompanyHrProfile)?.company?.id === id ? (
                <Avatar
                  src={company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
                  size={60}
                />
              ) : null}

              <div className="flex flex-col ">
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>
                  {format.replace("_", " ")}
                </div>
                <div className={styles.subtitle}>{location}</div>
              </div>
            </div>
            <div className="flex flex-col items-start font-sans">
              <div
                className={clsx(
                  "bg-[#00e0b8] rounded-[5.3px] text-black text-ellipsis overflow-hidden  w-[100%] h-[17px] px-2 py-[0px] font-medium text-[11.6px] leading-[17px] mb-[5px]",
                  {
                    "text-left": window?.innerWidth < 1600,
                    "text-center": window?.innerWidth >= 1600,
                  }
                )}
              >
                {`${currency} ${formatNumber(minSalary, true)} - ${formatNumber(
                  maxSalary,
                  true
                )}`}
              </div>

              <div className="job-card-footer text-[11px] w-[90px] leading-[17px] text-ellipsis overflow-hidden font-normal tracking-[-0.03em] text-white mb-[2px]">
                {pluralize("candidate", numberOfApplications, true)}
              </div>
              <div className="job-card-footer font-medium text-[11px] leading-[15px] tracking-[-0.03em] text-white">
                {formatRelativeDate(date)}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default JobList;
