import { useEffect, useState } from "react";

import { ApplicationStatusEnum, JobFilters } from "generated/graphql";

import EndorsementList from "pages/MyEndorsements/EndorsementList";
import { EndorsementType } from "pages/MyEndorsements/index";

import Tabs from "components/common/Tabs/Tabs";
import SearchFilters, { defaultFilters } from "components/SearchFilters";

import { useFilterEndorsements } from "hooks/useFilterEndorsements";

import { getNumberOfFiltersApplied } from "utils";

type Props = {
  endorsements: Array<EndorsementType>;
  refetch: Function; // TODO: set proper type
  loading: boolean;
  // photoType?: "candidate" | "company";
  // titleType?: "job" | "candidate";
  // subtitleType?: "location" | "company";
  // footerType?: "referred" | "jobTitle";
  // tabType?: "salary" | "score";
};

const ApplicationTabs = ({
  endorsements,
  refetch,
  loading,
}: // photoType = "candidate",
// titleType = "job",
// subtitleType = "location",
// footerType = "referred",
// tabType = "salary",
Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState("active");
  const [loadingRefetch, setLoadingRefetch] = useState<boolean>(false);
  const [filters, setFilters] = useState(defaultFilters);

  const { filterText, setFilterText, filteredEndorsements } =
    useFilterEndorsements(endorsements);

  const activeEndorsements = filteredEndorsements.filter((endorsement) => {
    let status: ApplicationStatusEnum;
    switch (endorsement.__typename) {
      case "JobApplication": {
        status = endorsement.status;
        break;
      }
      case "ReferralWithoutJobResponse": {
        status = ApplicationStatusEnum.InReview;
        break;
      }
      case "ReferenceForReferrer": {
        status = ApplicationStatusEnum.Pending;
        break;
      }
      default: {
        status = ApplicationStatusEnum.Pending;
        break;
      }
    }

    return [
      ApplicationStatusEnum.Interviewing,
      ApplicationStatusEnum.TechnicalChallenge,
      ApplicationStatusEnum.OnSite,
      ApplicationStatusEnum.Offer,
      ApplicationStatusEnum.Pending,
      ApplicationStatusEnum.InReview,
      "",
    ].includes(status);
  });

  const finishedEndorsements = filteredEndorsements.filter((endorsement) => {
    let status: ApplicationStatusEnum;
    if (endorsement.__typename === "JobApplication") {
      status = endorsement.status;
    } else {
      status = ApplicationStatusEnum.Pending;
    }

    return [
      ApplicationStatusEnum.Canceled,
      ApplicationStatusEnum.Rejected,
      ApplicationStatusEnum.Hired,
      ApplicationStatusEnum.Invalid,
    ].includes(status as ApplicationStatusEnum);
  });

  const handleApplyFilters = async (appliedFilters: JobFilters) => {
    setFilters(appliedFilters);
  };

  useEffect(() => {
    (async () => {
      setLoadingRefetch(true);
      await refetch({
        filters,
      });
      setLoadingRefetch(false);
    })();
  }, [filters, refetch]);

  const onChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const renderFilters = () => (
    <div>
      <SearchFilters
        filters={filters}
        numberOfFilters={getNumberOfFiltersApplied(filters)}
        filterText={filterText}
        setFilterText={setFilterText}
        onApply={handleApplyFilters}
        loading={loading || loadingRefetch}
      />
    </div>
  );

  return (
    <div className="overflow-hidden w-full h-full">
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        tabs={[
          {
            title: "Active",
            key: "active",
            content: (
              <div className="p-4 flex flex-col gap-3 h-full">
                {renderFilters()}
                <div className="overflow-y-scroll hide-scrollbar">
                  <EndorsementList endorsements={activeEndorsements} />
                </div>
              </div>
            ),
          },
          {
            title: "Finished",
            key: "finished",
            content: (
              <div className="p-4 flex flex-col gap-3 h-full">
                {renderFilters()}
                <div className="overflow-y-scroll hide-scrollbar">
                  <EndorsementList endorsements={finishedEndorsements} />
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ApplicationTabs;
