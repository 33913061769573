import styles from "./InputSlider.module.scss";

const InputSlider = (props: {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
}) => {
  const getBackgroundSize = () => {
    return { backgroundSize: `${(props.value * 100) / props.max}% 100%` };
  };
  return (
    <input
      className={styles.slider}
      type="range"
      min={props.min}
      max={props.max}
      step={props.step}
      value={props.value}
      onChange={(e) => props.onChange(Number(e.target.value))}
      style={getBackgroundSize()}
    />
  );
};

export default InputSlider;
