import { useMemo, useState } from "react";

import dayjs from "dayjs";

import {
  GetConversationsQuery,
  useGetConversationsQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";

const useConversations = (): {
  conversations?: GetConversationsQuery["conversations"];
  loading: boolean;
  unread?: number;
  refetch: VoidFunction;
  selectedConversation: string | null;
  setSelectedConversation: React.Dispatch<React.SetStateAction<string | null>>;
} => {
  const { userProfile } = useAuth();
  const { data, loading, refetch } = useGetConversationsQuery({
    fetchPolicy: "network-only",
  });

  const [selectedConversation, setSelectedConversation] = useState<
    string | null
  >(null);

  const unread = useMemo(() => {
    if (data?.conversations) {
      return data.conversations.reduce((acc, curr) => {
        const hasUnreadMessage = !!curr.messages.find(
          (message) => message.from.id !== userProfile?.id && !message.read
        );

        if (!hasUnreadMessage) {
          return acc;
        }

        return acc + 1;
      }, 0);
    }
    return 0;
  }, [data, userProfile?.id]);

  const sortedConversations = useMemo(() => {
    if (!data?.conversations) return [];

    return data.conversations.sort((a, b) =>
      dayjs(a.messages[0].date).isAfter(dayjs(b.messages[0].date)) ? -1 : 1
    );
  }, [data]);

  return {
    conversations: sortedConversations,
    loading,
    unread,
    refetch,
    selectedConversation,
    setSelectedConversation,
  };
};

export default useConversations;
