import { Dispatch, FunctionComponent, SetStateAction } from "react";

import { Dialog } from "@headlessui/react";

export type DialogControls = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>> | ((bool: boolean) => void);
  isUnclosable?: boolean;
};

export const DialogWrapper: FunctionComponent<DialogControls> = ({
  children,
  open,
  setOpen,
  isUnclosable = false,
}) => {
  return (
    <Dialog
      className="relative z-50"
      open={open}
      onClose={() => {
        if (!isUnclosable) setOpen(false);
      }}
    >
      <div
        aria-hidden="true"
        className="pointer-events-none fixed top-0 left-0 z-1 w-full h-full opacity-80 bg-[#282646] blur-sm"
      />
      {open && children}
    </Dialog>
  );
};
