import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as BackIcon } from "images/icons/back.svg";
import { ReactComponent as MessageIcon } from "images/icons/message.svg";

import useAuth from "components/Auth/useAuth";
import Button from "components/Button";

import usePageTitle from "hooks/usePageTitle";
import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

const MobileTopBar = (): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { isMobile, loadingIsMobile } = useWindowSize();

  const location = useLocation();

  const { title, subtitle } = usePageTitle();

  const renderLeft = () => {
    // @ts-ignore
    if (subtitle || location?.state?.prevPath) {
      return (
        <BackIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        />
      );
    }
  };

  const renderRight = () => {
    if (!isLoggedIn) {
      return (
        // <section className="desktop-topbar__right">
        <div className="flex gap-2">
          <Button
            label={<div>LOGIN</div>}
            onClick={() => {
              navigate("/signin");
            }}
            size="large"
          />
          <Button
            label={<div>SIGN UP</div>}
            onClick={() => {
              navigate("/signup");
            }}
            type="simple"
            size="small"
          />
        </div>
        // </section>
      );
    }

    const isActive =
      location.pathname.includes("conversation") ||
      location.pathname.includes("chat");
    // TODO: use different icon when active
    // TODO: maybe allow starting new conversation?

    if (isActive) {
      return null;
    }

    const ChatIcon = isActive ? MessageIcon : MessageIcon;

    return (
      <ChatIcon
        className="mobile__message-icon"
        onClick={() => {
          navigate("/chat", { state: { prevPath: location.pathname } });
        }}
      />
    );
  };

  if (loadingIsMobile) {
    return <div />;
  }

  if (isMobile) {
    return (
      <nav className={"mobile-topbar__wrapper"}>
        <div className="mobile-topbar__left">{renderLeft()}</div>
        <div className="topbar__title">
          <div className="topbar__title-text">{subtitle || title}</div>
        </div>
        <section className="mobile-topbar__right">{renderRight()}</section>
      </nav>
    );
  }

  return <></>;
};

export default MobileTopBar;
