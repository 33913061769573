import { useNavigate } from "react-router-dom";

import logo from "images/dorse_logo.svg";
import { ReactComponent as LoginIcon } from "images/landing/login.svg";

import BurgerMenu from "pages/Landing/Navbar/BurgerMenu";

import useAuth from "components/Auth/useAuth";

import styles from "./Navbar.module.scss";

const Navbar = (): JSX.Element => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  return (
    <header className={styles.container}>
      <img src={logo} alt="logo" height={28} className="z-20" />
      <BurgerMenu />
      <div className="hidden md:flex items-center gap-3">
        <a
          href="https://staking-ui-flax.vercel.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="min-w-[150px] min-h-[46px] rounded-full flex gap-1 items-center justify-center degraded"
        >
          <p className="font-mono font-[700]">STAKE</p>
        </a>
        {!isLoggedIn && (
          <button
            className="min-w-[168px] min-h-[46px] border-white border-[1.3px] rounded-full flex gap-1 items-center justify-center"
            onClick={() => {
              navigate("/signin");
            }}
          >
            <LoginIcon fill="red" width={20} height={20} />
            <span className="font-[400] text-sm">LOGIN</span>
          </button>
        )}
        <button
          onClick={() => navigate("/find-jobs?isRemote=true")}
          className="min-w-[150px] min-h-[46px] rounded-full flex gap-1 items-center justify-center degraded"
        >
          <p className="font-mono font-[700]">FIND JOBS</p>
        </button>
      </div>
    </header>
  );
};

export default Navbar;
