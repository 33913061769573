import { ReactNode, useMemo } from "react";

import clsx from "clsx";
import classNames from "clsx";
import { NavLink } from "react-router-dom";

import { RoleEnum } from "generated/graphql";

import dorseLogo from "images/dorse_logo.svg";
import { ReactComponent as AddActiveIcon } from "images/icons/add-active.svg";
import { ReactComponent as AddIcon } from "images/icons/add.svg";
import { ReactComponent as ApplicationsActiveIcon } from "images/icons/applications-active.svg";
import { ReactComponent as ApplicationsIcon } from "images/icons/applications.svg";
import { ReactComponent as EndorseIcon } from "images/icons/endorse.svg";
import { ReactComponent as EndorsementsActiveIcon } from "images/icons/endorsements-active.svg";
import { ReactComponent as EndorsementsIcon } from "images/icons/endorsements.svg";
import { ReactComponent as FindJobsActiveIcon } from "images/icons/find-jobs-active.svg";
import { ReactComponent as FindJobsIcon } from "images/icons/find-jobs.svg";
import { ReactComponent as JobsActiveIcon } from "images/icons/jobs-active.svg";
import { ReactComponent as JobsIcon } from "images/icons/jobs.svg";
import { ReactComponent as LogoutIcon } from "images/icons/logout.svg";
import { ReactComponent as MessageIcon } from "images/icons/message.svg";
import { ReactComponent as MyEndorsementsActiveIcon } from "images/icons/my-endorsements-active.svg";
import { ReactComponent as MyEndorsementsIcon } from "images/icons/my-endorsements.svg";
import { ReactComponent as NotificationsActiveIcon } from "images/icons/notifications-active.svg";
import { ReactComponent as NotificationsIcon } from "images/icons/notifications.svg";
import { ReactComponent as ProfileActiveIcon } from "images/icons/profile-active.svg";
import { ReactComponent as ProfileIcon } from "images/icons/profile.svg";
import { ReactComponent as SidebarIcon } from "images/icons/sidebar-button.svg";

import ActiveMark from "components/ActiveMark";
import useAuth from "components/Auth/useAuth";
import For from "components/common/For";
import Show from "components/common/Show";

import useWindowSize from "hooks/useWindowSize";

import styles from "./TabBar.module.scss";

export enum TabKeys {
  jobs = "jobs",
  findJobs = "find-jobs",
  add = "add",
  notifications = "notifications",
  profile = "profile",
  myApplications = "my-applications",
  chat = "chat",
  conversation = "conversation",
  myEndorsements = "my-endorsements",
  endorse = "endorse",
  endorsements = "endorsements",
  searchCandidates = "search-candidates",
}

export const isTabKey = (key: string): key is TabKeys =>
  Object.values(TabKeys).includes(key as TabKeys);

type Tab = {
  key: TabKeys;
  title: string;
  icon: ReactNode;
  activeIcon: ReactNode;
};

const getTabs = ({
  isLoggedIn,
  isMobile,
  role,
}: {
  isMobile: boolean;
  isLoggedIn: boolean;
  role: RoleEnum | undefined | null;
}) => {
  const jobsTab = {
    key: TabKeys.jobs,
    title: "My Job posts",
    icon: <JobsIcon />,
    activeIcon: <JobsActiveIcon />,
  };

  const findJobsTab = {
    key: TabKeys.findJobs,
    title: "Find Jobs",
    icon: <FindJobsIcon />,
    activeIcon: <FindJobsActiveIcon />,
  };

  const addTab = {
    key: TabKeys.add,
    title: isMobile ? "Add" : "Add a job post",
    icon: <AddIcon />,
    activeIcon: <AddActiveIcon />,
  };

  const notificationsTab = {
    key: TabKeys.notifications,
    title: "Notifications",
    icon: <NotificationsIcon />,
    activeIcon: <NotificationsActiveIcon />,
  };

  const profileTab = {
    key: TabKeys.profile,
    title: "Profile",
    icon: <ProfileIcon />,
    activeIcon: <ProfileActiveIcon />,
  };

  const myApplicationsTab = {
    key: TabKeys.myApplications,
    title: "Applications",
    icon: <ApplicationsIcon />,
    activeIcon: <ApplicationsActiveIcon />,
  };

  const myEndorsementsTab = {
    key: TabKeys.myEndorsements,
    title: "Endorsements",
    icon: <MyEndorsementsIcon />,
    activeIcon: <MyEndorsementsActiveIcon />,
  };

  const endorsementsTab = {
    key: TabKeys.endorsements,
    title: "Endorsements",
    icon: <EndorsementsIcon />,
    activeIcon: <EndorsementsActiveIcon />,
  };

  const searchCandidatesTab = {
    key: TabKeys.searchCandidates,
    title: "Candidates",
    icon: <EndorsementsIcon />,
    activeIcon: <EndorsementsActiveIcon />,
  };

  const companyHrTabs: Tab[] = [
    findJobsTab,
    jobsTab,
    addTab,
    notificationsTab,
    profileTab,
  ];

  const internalRecruiterTabs: Tab[] = [
    findJobsTab,
    jobsTab,
    addTab,
    endorsementsTab,
    notificationsTab,
    profileTab,
    searchCandidatesTab,
  ];

  const candidateTabs: Tab[] = [
    findJobsTab,
    myEndorsementsTab,
    myApplicationsTab,
    notificationsTab,
    profileTab,
  ];

  const notLoggedInTabs: Tab[] = [findJobsTab];

  if (!isLoggedIn) {
    return notLoggedInTabs;
  }

  switch (role) {
    case RoleEnum.CompanyUser:
      return companyHrTabs;

    case RoleEnum.InternalRecruiter:
      return internalRecruiterTabs;

    case RoleEnum.Candidate:
      return candidateTabs;

    default:
      return companyHrTabs;
  }
  // TODO: add staker tabs
};

const TabBar = (): JSX.Element => {
  const { user, isLoggedIn, logout } = useAuth();
  const { isMobile } = useWindowSize();

  const tabs = useMemo(
    () =>
      getTabs({
        isLoggedIn,
        isMobile,
        role: user?.activeRole,
      }),
    [isLoggedIn, isMobile, user?.activeRole]
  );

  if (isMobile) {
    if (!isLoggedIn) {
      return <></>;
    }

    return (
      <div className={styles.mobileWrapper}>
        <div className={styles.mobileInnerWrapper}>
          {(user?.activeRole === RoleEnum.Candidate || !isLoggedIn) && (
            <NavLink to={"/endorse?page=0"} className={styles.mobileItem}>
              {({ isActive }) =>
                isActive ? (
                  <MyEndorsementsActiveIcon />
                ) : (
                  <>
                    <EndorsementsIcon className="icon-fill-white w-[14px] whitespace-nowrap " />
                    <div className="whitespace-nowrap">Let's endorse</div>
                  </>
                )
              }
            </NavLink>
          )}
          <For items={tabs} keyFunc={(item) => item.key}>
            {(item) => (
              <NavLink to={item.key} className={styles.mobileItem}>
                {({ isActive }) =>
                  isActive ? (
                    <>{item.activeIcon}</>
                  ) : (
                    <>
                      <div>{item.icon}</div>
                      <div className="whitespace-nowrap">{item.title}</div>
                    </>
                  )
                }
                {/* {({ isActive }) => (
                  <Show when={!isActive} fallback={<>{item.activeIcon}</>}>
                    <>
                      <div>{item.icon}</div>
                      <div className="whitespace-nowrap">{item.title}</div>
                    </>
                  </Show>
                )} */}
              </NavLink>
            )}
          </For>
        </div>
      </div>
    );
  }

  const chatTab = {
    key: TabKeys.chat,
    title: "Chat",
    icon: <MessageIcon />,
  };
  //29 64
  return (
    <nav className={styles.desktopWrapper}>
      <div className="grid place-content-center">
        <NavLink to={"/find-jobs"}>
          <img src={dorseLogo} className="h-7" alt="logo" />
        </NavLink>
      </div>

      <div
        className={classNames(
          styles.responsiveHeight,
          "flex w-[212px] flex-col item-start gap-[42px] ",
          {
            "gap-[42px]": window?.innerHeight > 700,

            "overflow-y-scroll": window?.innerHeight <= 700,
          },

          {
            "mt-[41px]": user?.activeRole === RoleEnum.Candidate || !isLoggedIn,
            "mt-[86px]": !(
              user?.activeRole === RoleEnum.Candidate || !isLoggedIn
            ),
          }
        )}
      >
        {(user?.activeRole === RoleEnum.Candidate || !isLoggedIn) && (
          <NavLink
            to={"/endorse?page=0"}
            className={({ isActive }) =>
              clsx(
                "w-[212px] h-[45.19px] py-4",
                "flex items-center justify-center gap-3",
                "bg-opacity-80 ",
                styles.opacityHover,
                {
                  "bg-opacity-100": isActive,
                },
                "rounded-[3.58px] bg-[#9A2ED1] font-semibold font-sora text-white hover:text-white text-[12.9px] leading-4"
              )
            }
          >
            <EndorsementsIcon className="icon-fill-white w-[14px] " />
            LET'S ENDORSE
          </NavLink>
        )}

        <For items={tabs} keyFunc={(tab) => tab.key}>
          {(item) => (
            <NavLink
              className={({ isActive }) =>
                clsx(
                  "relative ml-[25px]",
                  "flex items-center gap-6",
                  styles.svgHover,
                  "text-white hover:text-[#9C81EA] font-[Sora] text-sm font-normal leading-[18px] tracking-[0.44px]",
                  "icon-fill-white ",
                  {
                    "text-[#9C81EA] icon-fill-violet": isActive,
                  }
                )
              }
              to={item.key}
            >
              {({ isActive }) =>
                isActive && <ActiveMark left="calc( -49px - 30.53px )" />
              }
              {item.icon} {item.title}
            </NavLink>
          )}
        </For>
        {isLoggedIn && (
          <NavLink
            className={({ isActive }) =>
              clsx(
                "relative ml-[25px]",
                "flex items-center gap-6",
                "text-white  font-[Sora] text-sm font-normal leading-[18px] tracking-[0.44px]",
                "icon-fill-white ",
                styles.svgHover,
                {
                  "text-[#9C81EA] icon-fill-violet": isActive,
                }
              )
            }
            to={chatTab.key}
          >
            {({ isActive }) =>
              isActive && <ActiveMark left="calc( -49px - 30.53px )" />
            }
            {chatTab.icon} {chatTab.title}
          </NavLink>
        )}
      </div>

      {isLoggedIn && (
        <button
          className={clsx(
            "flex space-x-[70px] items-center absolute bottom-[72px] left-[57px]",
            "text-white font-sora hover:text-[#9C81EA] text-sm font-normal leading-[18px] tracking-[0.44px]",
            "icon-fill-white ",
            styles.svgHover,
            {
              "bottom-[72px]": window?.innerHeight > 700,

              "bottom-[20px]": window?.innerHeight <= 700,
            }
          )}
          onClick={logout}
        >
          <div className={clsx("flex space-x-6 items-center")}>
            <LogoutIcon />
            <span>Log Out</span>
          </div>
        </button>
      )}
    </nav>
  );
};

export default TabBar;
