import { useCallback, useRef, useState } from "react";

import { AutoCenter, Button, Card, NavBar } from "antd-mobile";
import { CheckCircleOutline } from "antd-mobile-icons";
import { FloatingPanelRef } from "antd-mobile/es/components/floating-panel";
import { toast } from "react-toastify";

import { RoleEnum, useSwitchRoleMutation } from "generated/graphql";

import useAuth from "components/Auth/useAuth";
import FloatingPanel from "components/FloatingPanel";

import "./style.scss";

type Props = {
  roles: RoleEnum[];
  activeRole: RoleEnum;
  setOpen: (fn: VoidFunction) => void;
  setClose: (fn: VoidFunction) => void;
};

const SelectRole = ({
  roles,
  activeRole,
  setOpen,
  setClose,
}: Props): JSX.Element => {
  const { setUserProfile, setUser } = useAuth();
  const [selectedRole, setSelectedRole] = useState<RoleEnum>(activeRole);
  const [loading, setLoading] = useState<boolean>(false);

  const ref = useRef<FloatingPanelRef>(null);

  const [switchRole] = useSwitchRoleMutation();

  const open = useCallback(() => {
    setSelectedRole(activeRole);
    ref.current?.setHeight(window.innerHeight * 0.85);
  }, [activeRole]);

  const close = useCallback(() => {
    ref.current?.setHeight(0);
  }, []);

  const setRef = useCallback(
    (node) => {
      if (node) {
        setOpen(() => open);
        setClose(() => close);
      }

      // @ts-ignore
      ref.current = node;
    },
    [setOpen, setClose, open, close]
  );

  const onApplySwitchRole = () => {
    setLoading(true);
    switchRole({ variables: { newRole: selectedRole } })
      .then(({ data, errors }) => {
        if (errors) {
          errors.forEach((error) => {
            toast.error(error.message);
          });
        }
        if (data?.switchRole) {
          setUserProfile(data.switchRole.profile);
          setUser(data.switchRole.user);
          close();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const anchors = [0, window.innerHeight * 0.85];

  return (
    <FloatingPanel
      ref={setRef}
      anchors={anchors}
      className="select-role__wrapper"
    >
      <NavBar onBack={close} right={<div />}>
        Choose a role
      </NavBar>
      <div className="select-role__role-cards-wrapper">
        {roles.map((role) => (
          <Card
            key={role}
            onClick={() => setSelectedRole(role)}
            extra={
              <CheckCircleOutline
                fontSize={24}
                color={role === selectedRole ? "default" : "transparent"}
              />
            }
            // style={{
            //   background: `transparent url(${DorseHR}) center no-repeat`,
            //   backgroundSize: "contain",
            // }}
            className="select-role__role-card"
          >
            <AutoCenter>{role.replaceAll("_", " ")}</AutoCenter>
          </Card>
        ))}
      </div>
      <Button
        color="primary"
        block
        size="large"
        onClick={onApplySwitchRole}
        loading={loading}
        disabled={loading}
      >
        APPLY
      </Button>
    </FloatingPanel>
  );
};

export default SelectRole;
