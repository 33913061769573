import { useMemo } from "react";

import { Avatar } from "antd";
import { Button, Tag } from "antd-mobile";
import classNames from "clsx";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import {
  ApplicationStatusEnum,
  FullProfileFragment,
  Maybe,
  Notification,
  NotificationTypeEnum,
  RoleEnum,
  User,
  useReadNotificationMutation,
} from "generated/graphql";

import {
  APPLICATION_STATUS_COLORS,
  APPLICATION_STATUS_FONT_COLORS,
  DEFAULT_USER_PHOTO_URL,
} from "app-constants";

import useAuth from "components/Auth/useAuth";

import { formatRelativeDate, getAvatar } from "utils";

import "./style.scss";

type AvatarType = {
  id: string;
  name: string;
  photoUrl: string;
};

const renderAvatar = (
  notification: Maybe<Notification>,
  user: Maybe<User>,
  userProfile: FullProfileFragment
) => {
  if (!notification) return null;
  const application = notification;
  let avatar1: AvatarType | null;
  let avatar2: AvatarType | null;
  switch (notification.type) {
    case NotificationTypeEnum.Hire: {
      if (!notification.triggerUserProfile) {
        return null;
      }

      avatar1 = {
        id: notification.triggerUserProfile.id,
        name: notification.triggerUserProfile.name,
        photoUrl:
          notification.triggerUserProfile.photoUrl || DEFAULT_USER_PHOTO_URL,
      };

      avatar2 = {
        id: userProfile.id,
        name: userProfile.name,
        photoUrl: userProfile.photoUrl || DEFAULT_USER_PHOTO_URL,
      };

      break;
    }
    case NotificationTypeEnum.Reference:
      if (!notification.triggerUserProfile) {
        return null;
      }

      const isIR = user?.activeRole === RoleEnum.InternalRecruiter;

      avatar1 = {
        id: notification.triggerUserProfile.id,
        name: notification.triggerUserProfile.name,
        photoUrl:
          notification.triggerUserProfile.photoUrl || DEFAULT_USER_PHOTO_URL,
      };

      avatar2 = {
        id: userProfile.id,
        name: userProfile.name,
        photoUrl: userProfile.photoUrl || DEFAULT_USER_PHOTO_URL,
      };

      if (isIR) {
        avatar2 = {
          id: userProfile.id,
          name: notification.info!,
          photoUrl: getAvatar(notification.info!),
        };
      }

      break;
    case NotificationTypeEnum.ReferenceWithoutJob:
      avatar1 = {
        id: Math.random().toString(), // TODO: check if an id is needed here
        name: "", // TODO: check if name is needed here
        photoUrl: DEFAULT_USER_PHOTO_URL,
      };
      avatar2 = null;
      break;
    case NotificationTypeEnum.Application:
      if (!application) {
        return null;
      }
      avatar1 = {
        id: application.application?.candidate.id as string,
        name: application.application?.candidate.name as string,
        photoUrl:
          (application.application?.candidate.photoUrl as string) ||
          DEFAULT_USER_PHOTO_URL,
      };
      avatar2 = null;
      break;
    case NotificationTypeEnum.ApplicationStatusChange:
      if (!application || !notification.triggerUserProfile) {
        return null;
      }

      avatar1 = {
        id: notification.triggerUserProfile.id,
        name: notification.triggerUserProfile.name,
        photoUrl:
          notification.triggerUserProfile.photoUrl || DEFAULT_USER_PHOTO_URL,
      };
      avatar2 = null;
      break;
    case NotificationTypeEnum.NewNote:
      if (!application || !notification.triggerUserProfile) {
        return null;
      }

      avatar1 = {
        id: notification.triggerUserProfile.id,
        name: notification.triggerUserProfile.name,
        photoUrl:
          notification.triggerUserProfile.photoUrl || DEFAULT_USER_PHOTO_URL,
      };
      avatar2 = {
        id: application.application?.candidate.id as string,
        name: application.application?.candidate.name as string,
        photoUrl:
          (application.application?.candidate.photoUrl as string) ||
          DEFAULT_USER_PHOTO_URL,
      };
      break;
    default:
      if (!application) {
        return null;
      }
      avatar1 = {
        id: application.application?.candidate.id as string,
        name: application.application?.candidate.name as string,
        photoUrl:
          (application.application?.candidate.photoUrl as string) ||
          DEFAULT_USER_PHOTO_URL,
      };
      avatar2 = null;
  }

  if (!avatar2) {
    return (
      <Avatar
        src={avatar1.photoUrl}
        size={48}
        alt={`picture of ${avatar1.name}`}
      />
    );
  }

  return (
    <div className="stacked-avatar-wrapper">
      <Avatar
        className="stacked-avatar-left"
        src={avatar1.photoUrl}
        size={32}
        alt={`picture of ${avatar1.name}`}
      />
      <Avatar
        className="stacked-avatar-right"
        src={avatar2.photoUrl}
        size={32}
        alt={`picture of ${avatar2.name}`}
      />
    </div>
  );
};

const renderPerson = (
  args: Maybe<{ id?: string; name?: string } | undefined>
): JSX.Element => {
  if (!args) {
    return <span className="notification-text">Someone</span>;
  }
  const { id, name } = args;

  if (!id && !name) {
    return <span className="notification-text">Someone</span>;
  }

  if (!id) {
    return <span className="notification-text">{name}</span>;
  }

  return (
    <Link to={`/profile/${id}`} className="notification-text-link">
      {name}
    </Link>
  );
};

const renderJobPosition = (
  args: { id?: string; title?: string } | undefined
): JSX.Element => {
  if (!args) {
    return <span className="notification-text">a position</span>;
  }
  const { id, title } = args;

  if (!id && !title) {
    return <span className="notification-text">a position</span>;
  }

  if (!id && title) {
    return (
      <>
        the position of <span className="notification-text">{title}</span>
      </>
    );
  }

  return (
    <span>
      the position of{" "}
      <Link to={`/job/${id}`} className="notification-text-link">
        {title}
      </Link>
    </span>
  );
};

const renderStatus = (status?: Maybe<ApplicationStatusEnum>): JSX.Element => {
  return (
    <Tag
      color={
        APPLICATION_STATUS_COLORS[status || ApplicationStatusEnum.Rejected]
      }
      round
      className="uppercase"
      style={{
        color:
          APPLICATION_STATUS_FONT_COLORS[
            status || ApplicationStatusEnum.Rejected
          ],
      }}
    >
      {status?.replaceAll("_", " ") || "Unknown"}
    </Tag>
  );
};

const renderText = (
  notification: Maybe<Notification>,
  user: Maybe<User>,
  userProfile: FullProfileFragment
): JSX.Element => {
  if (!notification) return <></>;
  const referrer = notification.application?.reference?.referrer;
  const candidate = notification.application?.candidate;
  const jobAd = notification.application?.jobAd;

  switch (notification.type) {
    case NotificationTypeEnum.Hire: {
      if (!notification.application) {
        return <></>;
      }

      if (!notification.triggerUserProfile) {
        return <></>;
      }

      const isOwn = candidate?.id === userProfile.id;

      const personHired = {
        id: isOwn ? undefined : notification.application.candidate.id,
        name: isOwn ? "You" : notification.application.candidate.name,
      };

      const personRecruiter = {
        id: notification.triggerUserProfile.id,
        name: notification.triggerUserProfile.name,
      };

      return (
        <>
          {renderPerson(personHired)} {isOwn ? "have" : "has"} been hired by{" "}
          {renderPerson(personRecruiter)}
        </>
      );
    }

    case NotificationTypeEnum.Application: {
      const isOwn = candidate?.id === userProfile.id;
      const person = {
        id: isOwn ? undefined : candidate?.id,
        name: isOwn ? "You" : candidate?.name,
      };

      return (
        <>
          {renderPerson(person)} {isOwn ? "have" : "has"} applied for{" "}
          {renderJobPosition(jobAd)}
        </>
      );
    }
    case NotificationTypeEnum.ReferenceWithoutJob: {
      const endorser = {
        name: notification.info || "Someone",
      };
      return (
        <>
          {renderPerson(notification.triggerUserProfile)} has endorsed{" "}
          {renderPerson(endorser)}
        </>
      );
    }
    case NotificationTypeEnum.Reference:
      const isIR = user?.activeRole === RoleEnum.InternalRecruiter;
      if (isIR) {
        return (
          <>
            {renderPerson(notification.triggerUserProfile)} has endorsed{" "}
            {notification.info}
          </>
        );
      }
      return (
        <>
          {renderPerson(notification.triggerUserProfile)} has endorsed you for{" "}
          {renderJobPosition({ title: notification.info! })}
        </>
      );
    case NotificationTypeEnum.NewNote:
      const notes = notification.application?.notes;
      if (!notes) {
        return <></>;
      }
      return (
        <>
          {renderPerson(notification.triggerUserProfile)} has added a note for{" "}
          {renderPerson(candidate)}
        </>
      );
    case NotificationTypeEnum.ApplicationStatusChange:
      const { info, triggerUserProfile } = notification;
      return (
        <>
          {renderPerson(triggerUserProfile)} has changed the status for{" "}
          {renderPerson(candidate)} to{" "}
          {renderStatus(info as ApplicationStatusEnum)}
        </>
      );
    case NotificationTypeEnum.PaymentDue:
      return (
        <>
          Time to pay {renderPerson(referrer)} for the endorsement of{" "}
          {renderPerson(candidate)} to {renderJobPosition(jobAd)}
        </>
      );
    default:
      return <>other - TODO</>;
  }
};

const createCandidateLink = (
  notification: Maybe<Notification>,
  profileId: string
): string => {
  if (!notification) return "";
  switch (notification.type) {
    case NotificationTypeEnum.NewNote:
      const lastNote =
        notification.application?.notes?.[
          notification.application?.notes?.length - 1
        ];
      return `/my-applications/${notification.linkID}#note-${lastNote?.id}`;
    case NotificationTypeEnum.Reference:
      if (notification.triggerUserProfile?.id === profileId) {
        return `/my-endorsements/${notification.linkID}`;
      }
      return `/referral/${notification.linkID}`;
    case NotificationTypeEnum.ReferenceWithoutJob:
      return `/my-endorsements/referral/${notification.linkID}`;
    default:
      return `/my-applications/${notification.linkID}`;
  }
};

const createCompanyUserLink = (
  notification: Maybe<Notification>,
  profileId: string
): string => {
  if (!notification) return "";
  switch (notification.type) {
    case NotificationTypeEnum.NewNote:
      const lastNote =
        notification.application?.notes?.[
          notification.application?.notes?.length - 1
        ];
      return `/jobs/${notification.application?.jobAd.id}/application/${notification.linkID}#note-${lastNote?.id}`;
    case NotificationTypeEnum.Reference:
      return `/referral/${notification.linkID}`;
    default:
      return `/jobs/${notification.application?.jobAd.id}/application/${notification.linkID}`;
  }
};

const createIRLink = (
  notification: Maybe<Notification>,
  profileId: string
): string => {
  if (!notification) return "";
  switch (notification.type) {
    case NotificationTypeEnum.NewNote:
      const lastNote =
        notification.application?.notes?.[
          notification.application?.notes?.length - 1
        ];
      return `/jobs/${notification.application?.jobAd.id}/application/${notification.linkID}#note-${lastNote?.id}`;
    case NotificationTypeEnum.Reference:
      return `/referral/${notification.linkID}`;
    case NotificationTypeEnum.ReferenceWithoutJob:
      return `/endorsements/${notification.linkID}`;
    default:
      return `/jobs/${notification.application?.jobAd.id}/application/${notification.linkID}`;
  }
};

const createStakerLink = createCandidateLink; // TODO: implement

type Props = {
  notification: Maybe<Notification> | any;
  refetchNotifications: VoidFunction;
};

const NotificationItem = ({
  notification,
  refetchNotifications,
}: Props): JSX.Element => {
  const { user, userProfile } = useAuth();

  const [readNotification] = useReadNotificationMutation({
    variables: { notificationId: notification?.id },
  });

  const createLink = useMemo(() => {
    if (user?.activeRole === RoleEnum.Candidate) {
      return createCandidateLink;
    } else if (user?.activeRole === RoleEnum.InternalRecruiter) {
      return createIRLink;
    } else if (user?.activeRole === RoleEnum.CompanyUser) {
      return createCompanyUserLink;
    }
    return createStakerLink;
  }, [user?.activeRole]);

  if (!userProfile) {
    return <div />; // TODO: some placeholder?
  }

  return (
    <div
      className={classNames("notification-preview-wrapper", {
        "notification-preview-unread": !notification?.read,
      })}
    >
      {renderAvatar(notification, user, userProfile!)}
      <div
        className={classNames("notification-preview-center", {
          "notification-preview-unread": !notification?.read,
        })}
      >
        {renderText(notification, user, userProfile)}
      </div>
      <div className="notification-preview-right">
        <div>{formatRelativeDate(notification?.date)}</div>
        <HashLink to={createLink(notification, userProfile.id)}>
          <Button
            className="notification__view-button"
            onClick={async () => {
              await readNotification();
              refetchNotifications();
            }}
            color="success"
            size="small"
            fill="outline"
          >
            View
          </Button>
        </HashLink>
      </div>
    </div>
  );
};

export default NotificationItem;
