import { FunctionComponent } from "react";

import { ApolloError } from "@apollo/client";

import {
  JobAd,
  JobForCandidateApplicationFragment,
  Maybe,
  RoleEnum,
  useGetJobForCandidateQuery,
  useGetJobForCompanyQuery,
  useGetJobForPublicQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";

import {
  useCachedGetJobForCandidateQuery,
  useCachedGetJobForCompanyQuery,
  useCachedGetJobForPublicQuery,
} from "./cached-hooks/useJobHooks";

type Props = {
  jobAdId: string;
};

export type GetJobAdReturnType = {
  jobAd?: Maybe<Omit<JobAd, "createdBy">>;
  error?: ApolloError;
  loading: boolean;
  application?: Maybe<JobForCandidateApplicationFragment>;
};

export const GetJobAdElement: FunctionComponent<{ jobAdId: string }> = ({
  jobAdId,
}) => {
  useGetJobAd({ jobAdId });
  return <></>;
};

export const useGetJobAd = ({ jobAdId }: Props): GetJobAdReturnType => {
  const { user, isLoggedIn } = useAuth();

  const {
    data: jobForPublic,
    loading: jobForPublicLoading,
    error: jobForPublicError,
  } = useCachedGetJobForPublicQuery(jobAdId, isLoggedIn);

  const {
    data: jobForCandidateData,
    loading: jobForCandidateLoading,
    error: jobForCandidateError,
  } = useCachedGetJobForCandidateQuery(jobAdId, user);

  const {
    data: jobForCompanyData,
    loading: jobForCompanyLoading,
    error: jobForCompanyError,
  } = useCachedGetJobForCompanyQuery(jobAdId, user);

  return {
    jobAd:
      jobForCompanyData?.jobForCompany?.jobAd ||
      jobForCandidateData?.jobForCandidate?.jobAd ||
      jobForPublic?.jobForPublic,
    application: jobForCandidateData?.jobForCandidate?.application,
    error: jobForCompanyError || jobForCandidateError || jobForPublicError,
    loading:
      jobForCompanyLoading || jobForCandidateLoading || jobForPublicLoading,
  };
};
