import { from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { ErrorMsg } from "generated/graphql";

import { TOKEN_KEY } from "app-constants";

const errorLink = () =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors);
      graphQLErrors.forEach(({ message }) => {
        // TODO: handle
        if (message === ErrorMsg.Unauthorized) {
          console.log("unauthenticated");
        }
      });
    }
  });

const wsLink = () =>
  new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_GRAPHQL_WS || "ws://localhost:8080/graphql",
      connectionParams: async () => {
        return {
          authToken: localStorage.getItem(TOKEN_KEY)
            ? `Bearer ${localStorage.getItem(TOKEN_KEY)}`
            : "",
        };
      },
      // connectionParams: {
      //   authToken: localStorage.getItem(TOKEN_KEY)
      //     ? `Bearer ${localStorage.getItem(TOKEN_KEY)}`
      //     : "",
      // },
    })
  );

export const createLinks = () => from([errorLink(), authLink.concat(wsLink())]);

// const uploadLink = createPersistedQueryLink({ sha256 }).concat(
//   createUploadLink({
//     uri: process.env.REACT_APP_GRAPHQL_URI || "http://localhost:8080/graphql",
//   })
// );

const authLink = setContext((_: any, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authToken: token ? `Bearer ${token}` : "",
    },
  };
});

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     console.log(definition);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   // @ts-ignore
//   uploadLink
// );
