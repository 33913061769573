import React, { ReactNode } from "react";

import { Form, Radio, Tag } from "antd";
import { NamePath } from "rc-field-form/es/interface";

import { Maybe } from "generated/graphql";

import "./style.scss";

interface Props<T> {
  name: NamePath;
  label?: ReactNode;
  required?: boolean;
  requiredMessage?: string;
  values: T[];
  selectedValue?: Maybe<any>;
  onSelectValue?: (value: T) => void;
}

const FormRadio = <T extends string>({
  name,
  label,
  required = true,
  requiredMessage,
  values,
  selectedValue,
  onSelectValue,
}: Props<T>): JSX.Element => {
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <div>
            {label}
            {required ? <sup>*</sup> : null}
          </div>
        ) : undefined
      }
      rules={[
        {
          required,
          message: requiredMessage || `${label ? label : "Field"} is missing`,
          validateTrigger: "onSubmit",
        },
      ]}
    >
      <Radio.Group className="ant-tag-group">
        {values.map((value) => {
          const isSelected = value === selectedValue;
          return (
            <Radio key={value} value={value}>
              <Tag
                className={`custom-ant-tag selectable-tag ${
                  isSelected ? "ant-mobile-tag-selected" : ""
                }`}
                onClick={() => {
                  if (onSelectValue && typeof onSelectValue === "function") {
                    onSelectValue(value);
                  }
                }}
              >
                {value.replaceAll("_", " ") as T}
              </Tag>
            </Radio>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};

export default FormRadio;
