import { Avatar } from "antd";
import classNames from "clsx";
import { useNavigate } from "react-router-dom";

import { ConversationDataFragment } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import useAuth from "components/Auth/useAuth";

import { formatDate } from "utils";

import "./style.scss";

type Props = {
  conversation: ConversationDataFragment;
  selected?: boolean;
  onClick?: (id: string) => void;
};

const ChatItem = ({ conversation, selected, onClick }: Props): JSX.Element => {
  const { userProfile } = useAuth();
  const navigate = useNavigate();
  const { messages } = conversation;
  const person = conversation.with;
  const unreadMessages = messages.filter(
    (message) => !message.read && message.from.id !== userProfile?.id
  ).length;

  return (
    <div
      className={classNames("conversation-preview-wrapper", {
        "conversation-preview-unread": unreadMessages > 0,
        selected: selected,
      })}
      onClick={() => {
        if (onClick) {
          onClick(person.id);
        }
        navigate(`/chat/${person.id}`);
      }}
    >
      <Avatar
        src={person?.photoUrl || DEFAULT_USER_PHOTO_URL}
        shape="square"
        size={60}
      />
      <div className="conversation-preview-center">
        <div className="conversation-preview-title">{person.name}</div>
        {/*<div className="conversation-preview-subtitle">/!*{person.}*!/</div>*/}
        {messages.length > 0 ? (
          <div className="conversation-preview-footer">{messages[0].text}</div>
        ) : null}
      </div>
      <div className="conversation-preview-right">
        <div>{formatDate(messages[0]?.date)}</div>
        {unreadMessages > 0 ? (
          <div className="unread-messages">{unreadMessages}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatItem;
