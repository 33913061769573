import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { GetJobForCompanyQuery } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import SocialLink from "components/SocialLink";
import StatusTag from "components/StatusTag/StatusTag";

import { formatRelativeDate } from "utils";

import styles from "./styles.module.scss";

type Props = {
  job: GetJobForCompanyQuery["jobForCompany"];
};

const FinishedJob = ({ job }: Props) => {
  const navigate = useNavigate();

  if (!job) return null;

  const applications = job?.applications ?? [];

  return (
    <div className="w-full overflow-scroll">
      <table className="w-full ">
        <thead>
          <tr className="font-sans font-medium text-[14px] leading-[37px]">
            <th className="min-w-[250px] text-left">Candidates</th>
            <th className="min-w-[120px] text-left">Reference</th>
            <th className="min-w-[120px] text-left">Status</th>
            <th className="min-w-[120px] text-left">Dated</th>
            <th className="min-w-[120px] text-left">Social</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((application) => (
            <tr
              key={application.id}
              className="border-t border-b border-[#4A465B] cursor-pointer"
              onClick={() =>
                navigate(`/jobs/${job?.jobAd.id}/application/${application.id}`)
              }
            >
              <td className="p-[17px]">
                <div className="flex items-start gap-4">
                  <img
                    className="w-11 h-11 rounded-[4px] object-cover text-[10px]"
                    src={
                      application.candidate.photoUrl || DEFAULT_USER_PHOTO_URL
                    }
                    alt=""
                  />
                  <div className="flex flex-col  font-sans gap-[6px]">
                    <span className="font-medium text-[13px] text-left leading-[14px]">
                      {application.candidate.name}
                    </span>
                    <span className={styles.jobSubTitle}>
                      {application.candidate.location}
                    </span>
                  </div>
                </div>
              </td>

              <td>
                {application.reference ? (
                  <Link to={`/profile/${application.reference?.referrer.id}`}>
                    <span className={styles.reference}>
                      by{" "}
                      <span className="underline">
                        {application.reference?.referrer.name}
                      </span>
                    </span>
                  </Link>
                ) : (
                  <span className={styles.noReference}>-</span>
                )}
              </td>
              <td>
                <StatusTag type="application" status={application.status} />
              </td>
              <td className="text-[11px] leading-3">
                {formatRelativeDate(application.date)}
              </td>
              <td>
                <div className="flex items-center gap-4">
                  <SocialLink link={application.candidate.web} network="web" />
                  <SocialLink
                    link={application.candidate.linkedin}
                    network="linkedin"
                  />
                  <SocialLink
                    link={application.candidate.github}
                    network="github"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {applications.length === 0 && (
        <div className="flex flex-col gap-3 text-center place-content-center items-center">
          <div className={styles.emptyJobsTitle}>
            Oh crap, you've got nothing
          </div>
          <div className={styles.emptyJobsSubtitle}>
            When somebody applies you will see something cool.
          </div>
        </div>
      )}
    </div>
  );
};

export default FinishedJob;
