import { FunctionComponent, useEffect, useState } from "react";

import cn from "clsx";
import clsx from "clsx";
import startCase from "lodash/startCase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CreateCandidateProfileData,
  CreateCandidateProfileMutation,
  Maybe,
  useCreateCandidateProfileMutation,
} from "generated/graphql";

import { TOKEN_KEY } from "app-constants";

import useAuth from "components/Auth/useAuth";
import { OnboardingWrapper } from "components/onboarding/OnboardingWrapper";
import { OnboardingInputs } from "components/onboarding/types";

import { useGetReferral } from "hooks/useGetReferral";

import { sendToSubDomainLoggedIn } from "utils";

import styles from "./styles.module.scss";

export const CompleteOnboarding: FunctionComponent<{
  state: OnboardingInputs;
}> = ({ state }) => {
  const { user, setUser, setToken, setUserProfile, email } = useAuth();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const referralId = useGetReferral();
  console.log("referralID", referralId);
  const [createProfile] = useCreateCandidateProfileMutation({
    onCompleted: (data: CreateCandidateProfileMutation) => {
      if (data.createCandidateProfile) {
        const { user, profile, token } = data.createCandidateProfile;
        setUser(user);
        setUserProfile(profile);
        setToken(token);
        localStorage.setItem(TOKEN_KEY, token);
      }
      toast.success("Profile created successfully");
      setSuccess(true);
      if (referralId) {
        sendToSubDomainLoggedIn(referralId);
        return;
      }
      navigate("/profile");
    },
    onError: ({ graphQLErrors }) => {
      for (let error of graphQLErrors) {
        toast.error(error.message);
        setErrorMessage(error.message);
      }
    },
  });

  const onSubmit = async () => {
    const basic = state.basic;
    const id = localStorage.getItem(`onboarding-user-id`);
    if (id) {
      basic.photoUrl = localStorage.getItem(`linkedin-photo-url-${id}`) + "";
    }
    const values: CreateCandidateProfileData = {
      ...state.basic,
      ...state.skills,
      ...state.field,
    };
    if (!/^http(s)?/.test(values.linkedin))
      values.linkedin = `https://${values.linkedin}`;
    createProfile({
      variables: { candidateData: values },
    });
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <OnboardingWrapper
      decimalProgress={1}
      title={errorMessage ? "Onboarding Error" : "Onboarding Complete"}
    >
      <div className="mt-[125px] w-full">
        {!success ? (
          <div className="flex flex-col items-center">
            <img
              className={cn("mb-5", {
                "animate-spin": !errorMessage && !success,
              })}
              src="/icons/dorse_sublogo.svg"
            />
            <p className="font-mono">
              {errorMessage ? startCase(errorMessage) : "Generating account"}
            </p>
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">
            <p className="font-mono text-lg text-center">
              Congratulations! <br />
              You are now ready to Dorse!
            </p>
          </div>
        )}
        <div className="mt-10 w-full flex items-center justify-center">
          {success && !errorMessage && (
            <button
              onClick={() => navigate("/profile/preview")}
              className={
                (clsx(
                  "px-10 py-3 border border-primary  transition-opacity duration-200"
                ),
                styles.opacityHover)
              }
            >
              <p className="font-mono text-white">To Profile</p>
            </button>
          )}
          {errorMessage && (
            <button
              onClick={() => navigate("/signup")}
              className={
                (clsx(" transition-opacity duration-200"), styles.opacityHover)
              }
            >
              <p className="underline text-grey font-mono">Back to Signup</p>
            </button>
          )}
        </div>
      </div>
    </OnboardingWrapper>
  );
};
