import { Dispatch, Fragment, SetStateAction, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import FiltersBody from "components/SearchFilters/FiltersBody";
import SearchFiltersInput from "components/SearchFilters/SearchFiltersInput";

import { defaultFilters, SearchFiltersProps } from "./index";
import styles from "./styles.module.scss";

interface SearchFiltersMobileProps extends SearchFiltersProps {
  handleToggleField: (field: FieldEnum) => void;
  handleToggleExperience: (experience: ExperienceEnum) => void;
  handleToggleFormat: (format: JobTypeEnum) => void;
  handleChangeMinSalary: (minSalary: number) => void;
  handleClear: (callback?: VoidFunction) => void;
  filters: typeof defaultFilters;
  setFilters: Dispatch<SetStateAction<typeof defaultFilters>>;
  onApplyFilters: (newFilters: typeof defaultFilters) => void;
  placeholder?: string;
  numberOfFilters: number;
  isCategory?: boolean;
  isSalary?: boolean;
  isExperience?: boolean;
  isType?: boolean;
}

const SearchFilters = ({
  filters,
  filterText,
  setFilterText,
  handleToggleField,
  handleToggleExperience,
  handleToggleFormat,
  handleChangeMinSalary,
  handleClear,
  onApplyFilters,
  placeholder,
  numberOfFilters,
  isCategory,
  isSalary,
  isExperience,
  isType,
}: SearchFiltersMobileProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleApply = () => {
    onApplyFilters(filters);
    close();
  };

  return (
    <>
      <SearchFiltersInput
        open={open}
        filterText={filterText}
        setFilterText={setFilterText}
        placeholder={placeholder}
        numberOfFiltersApplied={numberOfFilters}
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#343141]" />
          </Transition.Child>

          <div className="fixed w-full bottom-0">
            <div className="flex ">
              <Transition.Child
                as={Fragment}
                enter="ease-in duration-300"
                enterFrom="translate-y-[1000px]"
                enterTo="translate-y-0"
                leave="ease-in duration-300"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-[1000px]"
              >
                <Dialog.Panel className="p-4 flex flex-col gap-14 w-full rounded-2xl bg-[#121121]">
                  <Dialog.Title as="div" className="">
                    <div className="w-full flex justify-between items-center">
                      <button
                        onClick={close}
                        className={clsx("opacity-90", styles.hover)}
                      >
                        <BackIcon />
                      </button>
                      <div className="font-bold text-base">Filters</div>
                      <button
                        className={clsx(
                          "text-xs text-[#9C81EA] opacity-90  disabled:opacity-50",
                          styles.hover
                        )}
                        onClick={() => handleClear()}
                        disabled={numberOfFilters === 0}
                      >
                        CLEAR
                      </button>
                    </div>
                  </Dialog.Title>

                  <FiltersBody
                    filters={filters}
                    handleToggleField={handleToggleField}
                    handleToggleExperience={handleToggleExperience}
                    handleToggleFormat={handleToggleFormat}
                    handleChangeMinSalary={handleChangeMinSalary}
                    isCategory={isCategory}
                    isSalary={isSalary}
                    isExperience={isExperience}
                    isType={isType}
                  />

                  <div className="">
                    <button
                      className="bg-[#5362E9] p-4 rounded-[40px] w-full"
                      onClick={handleApply}
                    >
                      APPLY
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SearchFilters;
