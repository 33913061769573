import { useLocation } from "react-router-dom";

import { TabKeys } from "components/TabBar";

const TITLES: Record<TabKeys, string> = {
  add: "Add a job post",
  jobs: "My job posts",
  "find-jobs": "Find Jobs",
  notifications: "Notifications",
  profile: "Profile",
  "my-applications": "My applications",
  "my-endorsements": "My endorsements",
  chat: "Chat",
  conversation: "Conversation",
  endorsements: "Endorsements",
  endorse: "Endorse",
  "search-candidates": "Search Candidates",
};

const usePageTitle = (): {
  title: string;
  subtitle: string | undefined;
} => {
  const { pathname } = useLocation();

  const getSubtitle = (root: TabKeys, split: string[]): string | undefined => {
    switch (root) {
      case "add":
      case "my-applications":
      case "my-endorsements":
      case "notifications":
      case "jobs":
        return undefined;
      case "profile":
        if (split[2]?.toLowerCase() === "settings") {
          return split[2];
        }
        return undefined;
      default:
        return undefined;
    }
  };

  const split = pathname.split("/");
  const path = split[1] as TabKeys;
  const title = TITLES[path] || path;
  const subtitle = getSubtitle(path, split);

  return { title, subtitle };
};

export default usePageTitle;
