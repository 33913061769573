import React, { ReactNode } from "react";

import { Form, Tag } from "antd";
import { NamePath } from "rc-field-form/es/interface";

import { Maybe } from "generated/graphql";

import "./style.scss";

interface Props<T> {
  name: NamePath;
  label?: ReactNode;
  required?: boolean;
  requiredMessage?: string;
  values: T[];
  selectedValues?: Maybe<any[]>;
  onSelectValue?: (value: T) => void;
}

const FormCheckbox = <T extends string>({
  name,
  label,
  required = true,
  requiredMessage,
  values,
  selectedValues,
  onSelectValue,
}: Props<T>): JSX.Element => {
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <div>
            {label}
            {required ? <sup>*</sup> : null} (you can select multiple)
          </div>
        ) : undefined
      }
      rules={[
        {
          required,
          message: requiredMessage || `${label ? label : "Field"} is missing`,
          validateTrigger: "onSubmit",
          validator: (a, values) => {
            if (values.length === 0) {
              return Promise.reject("asd!");
            }

            return Promise.resolve("qwe");
          },
        },
      ]}
    >
      <div className="ant-tag-group">
        {values.map((value) => {
          const isSelected = selectedValues?.includes(value);
          return (
            <Tag
              key={value}
              className={`custom-ant-tag selectable-tag ${
                isSelected ? "ant-mobile-tag-selected" : ""
              }`}
              onClick={() => {
                if (onSelectValue && typeof onSelectValue === "function") {
                  onSelectValue(value);
                }
              }}
            >
              {value.replaceAll("_", " ")}
            </Tag>
          );
        })}
      </div>
    </Form.Item>
  );
};

export default FormCheckbox;
