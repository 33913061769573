import React from "react";

import {
  GetJobForCandidateQuery,
  GetJobForCompanyQuery,
  GetJobForPublicQuery,
  Maybe,
  RoleEnum,
  useGetJobForCandidateQuery,
  useGetJobForCompanyQuery,
  useGetJobForPublicQuery,
  User,
} from "generated/graphql";

enum CacheKey {
  public = "public",
  candidate = "candidate",
  company = "company",
}
const cacheMap = {
  [CacheKey.public]: new Map<string, GetJobForPublicQuery>(),
  [CacheKey.candidate]: new Map<string, GetJobForCandidateQuery>(),
  [CacheKey.company]: new Map<string, GetJobForCompanyQuery>(),
};
export const useCachedGetJobForPublicQuery = (
  jobAdId: string,
  isLoggedIn: boolean
) => {
  const { data, loading, error } = useGetJobForPublicQuery({
    skip: isLoggedIn || cacheMap[CacheKey.public].has(jobAdId),
    variables: {
      jobAdId,
    },
  });
  React.useEffect(() => {
    if (!data) return;
    cacheMap[CacheKey.public].set(jobAdId, data);
  }, [data]);
  return {
    data: cacheMap[CacheKey.public].has(jobAdId)
      ? cacheMap[CacheKey.public].get(jobAdId)
      : data,
    loading,
    error,
  };
};

export const useCachedGetJobForCandidateQuery = (
  jobAdId: string,
  user: Maybe<User>
) => {
  const { data, loading, error } = useGetJobForCandidateQuery({
    // fetchPolicy: "network-only",
    skip:
      user?.activeRole !== RoleEnum.Candidate ||
      cacheMap[CacheKey.candidate].has(jobAdId),
    variables: {
      jobAdId,
    },
  });
  React.useEffect(() => {
    if (!data) return;
    cacheMap[CacheKey.candidate].set(jobAdId, data);
  }, [data]);
  return {
    data: cacheMap[CacheKey.candidate].has(jobAdId)
      ? cacheMap[CacheKey.candidate].get(jobAdId)
      : data,
    loading,
    error,
  };
};

export const useCachedGetJobForCompanyQuery = (
  jobAdId: string,
  user: Maybe<User>
) => {
  const { data, loading, error } = useGetJobForCompanyQuery({
    // fetchPolicy: "network-only",
    skip:
      (user?.activeRole !== RoleEnum.CompanyUser &&
        user?.activeRole !== RoleEnum.InternalRecruiter) ||
      cacheMap[CacheKey.company].has(jobAdId),
    variables: {
      jobAdId,
    },
  });
  React.useEffect(() => {
    if (!data) return;
    cacheMap[CacheKey.company].set(jobAdId, data);
  }, [data]);
  return {
    data: cacheMap[CacheKey.company].has(jobAdId)
      ? cacheMap[CacheKey.company].get(jobAdId)
      : data,
    loading,
    error,
  };
};
