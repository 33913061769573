import * as React from "react";
import { FunctionComponent } from "react";

import clsx from "clsx";

import { DialogControls, DialogWrapper } from "components/modals/common";

import styles from "./modals.module.scss";

export const VerifyEmailDialog: FunctionComponent<
  DialogControls & {
    resendVerificationEmail: () => void;
    email: string;
  }
> = ({ open, setOpen, resendVerificationEmail, email }) => {
  return (
    <DialogWrapper open={open} setOpen={setOpen}>
      <div className="fixed z-10 inset-0 flex items-center justify-center p-4">
        <div className="h-[309px] w-[465px] flex flex-col items-center py-9 px-10 bg-[#121121] rounded-lg">
          <img className="w-[78px]" src="/icons/dorse_logo.svg" />
          <h3 className="mt-6 font-sora font-[600] text-[20px]">
            Verification email sent!
          </h3>
          <div className="mt-2 w-full">
            <p className="text-center font-mono text-xs leading-[20.3px]">
              Almost there! You need to verify your email address to Sign up
              into Dorse.
            </p>
          </div>
          <button
            onClick={resendVerificationEmail}
            tabIndex={0}
            className={
              (clsx(
                "mt-6 bg-primary rounded-3xl w-full h-[54px] flex items-center justify-center  transition-opacity duration-200"
              ),
              styles.hover)
            }
          >
            <p className="font-mono text-sm text-[#F5F5F5] font-[500]">
              RESEND EMAIL
            </p>
          </button>
          <a
            onClick={() => setOpen(false)}
            tabIndex={2}
            className={clsx(
              "mt-6  underline text-xs text-[#D0C9D6] cursor-pointer",
              styles.hover
            )}
          >
            Back to Sign up
          </a>
        </div>
      </div>
    </DialogWrapper>
  );
};
