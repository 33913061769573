import { useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import useAuth from "components/Auth/useAuth";
import Loading from "components/Loading";

import "./style.scss";

const SignInWithLinkedin = (): JSX.Element => {
  const { continueWithLinkedin } = useAuth();

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  useEffect(() => {
    if (code && continueWithLinkedin) {
      continueWithLinkedin(code);
    }
  }, [code, continueWithLinkedin]);

  return <Loading />;
};

export default SignInWithLinkedin;
