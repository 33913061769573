import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import cn from "clsx";
import startCase from "lodash/startCase";
import { useSearchParams } from "react-router-dom";

import {
  CandidateProfile,
  ExperienceEnum,
  RoleEnum,
  useGetCandidatesQuery,
} from "generated/graphql";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import ActiveMark from "components/ActiveMark";
import useAuth from "components/Auth/useAuth";
import LoadingCentered from "components/Loading/LoadingCentered";
import SearchFilters from "components/SearchFilters";
import SkillsSelect from "components/SkillsSelect/SkillsSelect";

import { useCheckAuth } from "hooks/useCheckAuth";
import useFilterCandidates from "hooks/useFilterCandidates";
import { useFiltersAndSortForQuery } from "hooks/useFilterJobsAndQuery";

import { getNumberOfFiltersApplied } from "utils";

import { DisplayCandidate } from "./DisplayCandidate";
import styles from "./styles.module.scss";

type QueriedCandidateProfile = {
  __typename?: "CandidateProfile";
  id: string;
  name: string;
  photoUrl?: string | null;
  field: Array<string>;
  jobTitle: string;
  github?: string | null;
  available?: boolean | null;
  experience?: ExperienceEnum | null;
};

export const SearchCandidatesPage = () => {
  useCheckAuth("/find-jobs", RoleEnum.InternalRecruiter);
  const { user } = useAuth();

  return user?.activeRole === RoleEnum.InternalRecruiter ? (
    <SearchCandidates />
  ) : (
    <LoadingCentered />
  );
};

const SearchCandidates: FunctionComponent = () => {
  const [params, setParams] = useSearchParams();

  const id = params.get("id") ?? ("" as string);
  const { data, loading } = useGetCandidatesQuery();
  const [selected, setSelected] = useState<string>(id);
  const { filters, onToggleSkill, handleApplyFilters, search, setSearch } =
    useFiltersAndSortForQuery(false);

  const isFilterEmpty =
    filters.experiences?.length === 0 &&
    filters.fields?.length === 0 &&
    filters.skills?.length === 0;
  const searchKey = params.get("search") ?? "";
  useEffect(() => {
    if (selected && id !== selected)
      setParams({
        ...(selected ? { id: selected } : null),
        ...(params?.get("skills")
          ? { skills: params?.get("skills") as string }
          : null),
        ...(params?.get("experiences")
          ? { experiences: params?.get("experiences") as string }
          : null),
        ...(params?.get("fields")
          ? { fields: params?.get("fields") as string }
          : null),
        search: searchKey,
      });
    // navigate(location.search + `?id=${selected}`);
  }, [selected, id]);

  const unselect = () => setSelected("");
  const filteredApplications = useFilterCandidates(
    data?.getCandidates as CandidateProfile[],
    search,
    filters
  );

  const skillFilter = (item: any) => {
    if (filters?.skills?.length === 0 || item?.techSkills?.length === 0)
      return true;
    return item?.techSkills?.some(
      (ele: any) => filters?.skills?.indexOf(ele) !== -1
    );
  };
  const experiencesFilter = (item: any) => {
    if (filters?.experiences?.length === 0) return true;
    return filters?.experiences?.some((ele) => ele === item?.experience);
  };
  const fieldsFilter = (item: any) => {
    if (filters?.fields?.length === 0) return true;
    return filters?.fields?.some((ele) => item?.field.includes(ele));
  };
  const applications = isFilterEmpty
    ? filteredApplications
    : filteredApplications
        ?.filter(skillFilter)
        ?.filter(experiencesFilter)
        ?.filter(fieldsFilter);

  useEffect(() => {}, [filters]);
  return (
    <div className="w-full flex justify-start  lg:h-[100vh]">
      <div
        className={cn(
          "w-full lg:max-w-[43%] lg:min-w-[400px] h-full lg:border-r pt-[26px]  border-[#4A465B]",
          selected ? "hidden lg:block" : "block"
        )}
      >
        <div className="pl-[22px] pr-[15px]">
          <SearchFilters
            filters={filters}
            numberOfFilters={getNumberOfFiltersApplied(filters)}
            filterText={search}
            setFilterText={setSearch}
            onApply={handleApplyFilters}
            loading={loading}
            placeholder="Job title, keywords, skills"
            isSalary={false}
            isType={false}
          />
        </div>
        <div className="flex flex-col lg:h-[180px] gap-2 my-4 pl-[22px] pr-[15px] ">
          <span className="px-4 lg:px-0 text-base tracking-tight">Skills</span>
          <div className="flex gap-2 overflow-x-scroll hide-scrollbar  lg:flex-wrap">
            <SkillsSelect
              selected={filters.skills ?? []}
              handleToggle={onToggleSkill}
            />
          </div>
        </div>
        <div className="h-[560px] overflow-scroll">
          <CandidateList
            selected={selected}
            setSelected={setSelected}
            candidates={applications as QueriedCandidateProfile[]}
            loading={loading}
          />
        </div>
      </div>
      <div className="flex flex-col w-full ">
        <CandidateDisplay candidateId={selected} unselect={unselect} />
      </div>
    </div>
  );
};

const CandidateDisplay: FunctionComponent<{
  candidateId: string;
  unselect: () => void;
}> = ({ candidateId, unselect }) => {
  return (
    <div
      className={cn("w-full ", {
        "hidden lg:block": candidateId === "",
      })}
    >
      <BackIcon
        className="block lg:hidden cursor-pointer"
        onClick={() => {
          unselect();
        }}
      />
      <div className="py-4 pl-6 w-full h-full relative  ">
        {candidateId === "" ? (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <h3 className="text-[26px] font-sora font-[700] mb-[14px]">
              You haven't selected any candidate
            </h3>
            <p className="font-mono text-xs text-grey text-center">
              You can select one by clicking
              <br /> on the candidate profile
            </p>
          </div>
        ) : (
          <DisplayCandidate candidateId={candidateId} />
        )}
      </div>
    </div>
  );
};

const CandidateList: FunctionComponent<{
  candidates: QueriedCandidateProfile[] | null | undefined;
  loading: boolean;
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
}> = ({ candidates, loading, setSelected, selected }) => {
  const data = candidates as CandidateProfile[];
  return (
    <div className="py-5 flex  flex-col">
      <div className="w-full ">
        {loading ? (
          <LoadingCentered />
        ) : (
          <ul className="flex h-full overflow-scroll flex-col items-center gap-4 px-4 ">
            {data.map((candidate) => (
              <CandidateCard
                onClick={() => {
                  if (selected === candidate.id) {
                    setSelected("");
                  } else {
                    setSelected(candidate.id);
                  }
                }}
                candidate={candidate}
                key={candidate.id}
                selected={selected === candidate.id}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const CandidateCard: FunctionComponent<{
  onClick: () => void;
  candidate: QueriedCandidateProfile;
  selected: boolean;
}> = ({ candidate, onClick, selected }) => {
  return (
    <li
      className={cn(
        "w-full flex items-center justify-between h-[82px] bg-[#4A465B] rounded-[5px] relative transition-opacity duration-200 ",
        styles.opacityHover,
        selected ? "bg-[#676182]" : ""
      )}
    >
      {selected && (
        <div className="absolute left-[-35px]">
          <ActiveMark />
        </div>
      )}
      <button
        onClick={onClick}
        className="grid grid-cols-[75%,25%] w-full h-full py-3 pl-[12px] pr-[13px] text-left"
      >
        <div className="h-full border-r border-white border-opacity-25 pr-2">
          <p className="text-sm font-mono leading-[16px] font-[500] truncate">
            {candidate.jobTitle}
          </p>
          <p className="mt-1 text-xs font-mono leading-[16px]">
            {startCase(candidate.experience + "")}
          </p>
          <p className="mt-0.5 text-xs font-mono leading-[16px] truncate w-[75%]">
            {candidate.field.join(", ")}
          </p>
        </div>
        <div className="h-full flex flex-col items-start justify-between pl-2">
          <p className={cn("font-mono text-left text-xs tracking-[-1px]")}>
            {candidate.name ? candidate.name : "<NoName>"}
          </p>
        </div>
      </button>
    </li>
  );
};
