import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import { localStorage } from "utils/data/localStorage";

export const useGetReferral = () => {
  const [searchParams, setSearchparams] = useSearchParams();
  const [id, setId] = useState(
    searchParams.get("referralID") || localStorage.get("referralID", "referral")
  );
  const [expire, setExpire] = useState(
    localStorage.get("referralExpiry", "referral")
  );
  useEffect(() => {
    if (
      !searchParams.get("referralId") &&
      id &&
      dayjs().isAfter(dayjs(Number(expire ? expire * 1000 : 0)))
    ) {
      localStorage.unset("referralID", "referral");
      localStorage.unset("referralExpiry", "referral");
      setId(null);
      setExpire(null);
      console.log("expired");
    }
  }, [id, expire]);
  return id;
};
