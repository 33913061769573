import { useEffect } from "react";

import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import { localStorage } from "utils/data/localStorage";

export const useReferrals = () => {
  const [searchParams, setSearchparams] = useSearchParams();
  useEffect(() => {
    const id = searchParams.get("referralID");
    if (!id) return;
    searchParams.delete("referralID");
    setSearchparams(searchParams);
    if (localStorage.get("referralID", "referral") === id) return;
    localStorage.set("referralID", id, "referral");
    localStorage.set(
      "referralExpiry",
      dayjs().add(3, "day").unix(),
      "referral"
    );
  }, [searchParams]);
};
