import { JobAd } from "generated/graphql";

import "./style.scss";

type Props = {
  jobAd: Partial<JobAd>;
};

const JobTags = ({ jobAd }: Props): JSX.Element => {
  const { experience, field, format, isRemote } = jobAd;

  return (
    <div className="flex gap-2">
      <div className="w-[50px] text-[12px] leading-[16px] font-normal font-sora">
        Tag:
      </div>
      <div className="overflow-scroll flex uppercase gap-[6px] text-[12px] leading-[16px] font-sans">
        {[experience, field, format, isRemote ? "Remote" : ""]
          .filter(Boolean)
          .map((tag) => {
            if (tag) {
              return (
                <div
                  key={tag}
                  className="flex whitespace-nowrap items-center justify-center h-[18px] px-2 text-center bg-[#5362E9] rounded-sm"
                >
                  {tag.replaceAll("_", " ")}
                </div>
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default JobTags;
