import { useState } from "react";

import {
  FileTextOutlined,
  FormOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  ApplicationStatusEnum,
  ErrorMsg,
  JobStatusEnum,
  useGetJobForCompanyQuery,
} from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import ApplicationsList from "components/CardList/ApplicationsList";
import For from "components/common/For";
import Tabs from "components/common/Tabs/Tabs";
import JobAdNotFound from "components/Errors/JobAdNotFound";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import Unauthorized from "components/Errors/Unauthorized";
import Hired from "components/Hired";
import Loading from "components/Loading";
import NavBar from "components/NavBar";

import { useFilterCompanyApplications } from "hooks/useFilterApplications";

import "../style.scss";

const JobPageMobile = (): JSX.Element => {
  const [ongoingActiveTab, setOngoingActiveTab] = useState("endorsements");
  const [finishedActiveTab, setFinishedActiveTab] = useState("hired");
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const { jobAdId } = useParams();

  const { data, error } = useGetJobForCompanyQuery({
    variables: { jobAdId: jobAdId! },
  });

  const job = data?.jobForCompany;

  const { filterText, setFilterText, filteredApplications } =
    useFilterCompanyApplications(job?.applications || []);

  const handleBack = () => {
    navigate("/jobs");
  };

  if (error) {
    const { message } = error.graphQLErrors?.[0];

    if (message === ErrorMsg.JobAdNotFound) {
      return <JobAdNotFound jobAdId={jobAdId} />;
    }
    if (message === ErrorMsg.Unauthorized) {
      return (
        <Unauthorized message="Sorry, this job belongs to another company" />
      );
    }

    return <SomethingWentWrong />;
  }

  if (!job) {
    return (
      <>
        <NavBar title={null} right={<div />} />
        <Loading />
      </>
    );
  }

  const { applications, hires, jobAd } = job;

  const onChangeOngoingTab = (tab: string) => {
    setOngoingActiveTab(tab);
  };

  const onChangeFinishedTab = (tab: string) => {
    setFinishedActiveTab(tab);
  };

  const candidateApplications = filteredApplications.filter(
    (application) => !application.reference
  );
  const referredApplications = filteredApplications.filter(
    (application) => application.reference
  );

  const renderFilters = () => {
    if (job?.applications?.length === 0) return null;
    return (
      <Input
        className="custom-input"
        prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
        value={filterText}
        suffix={<div />}
        onChange={(e) => setFilterText(e.target.value)}
      />
    );
  };

  const renderOngoingJob = () => {
    return (
      <Tabs
        activeKey={ongoingActiveTab}
        onChange={onChangeOngoingTab}
        tabs={[
          {
            title: "Endorsements",
            key: "endorsements",
            content: (
              <div className="flex flex-col gap-3 p-4">
                {renderFilters()}
                <ApplicationsList
                  applications={referredApplications}
                  jobId={jobAd.id}
                />
              </div>
            ),
          },
          {
            title: "Candidates",
            key: "candidates",
            content: (
              <div className="flex flex-col gap-3 p-4">
                {renderFilters()}

                <ApplicationsList
                  applications={candidateApplications}
                  jobId={jobAd.id}
                />
              </div>
            ),
          },
          {
            title: "All",
            key: "all",
            content: (
              <div className="flex flex-col gap-3 p-4">
                {renderFilters()}
                <ApplicationsList
                  applications={filteredApplications}
                  jobId={jobAd.id}
                />
              </div>
            ),
          },
        ]}
      />
    );
  };

  const renderFinishedJob = () => {
    const rejectedApplications = applications
      ? applications.filter(
          (application) => application.status === ApplicationStatusEnum.Rejected
        )
      : [];

    return (
      <Tabs
        activeKey={finishedActiveTab}
        onChange={onChangeFinishedTab}
        tabs={[
          {
            title: "Hired",
            key: "hired",
            content: (
              <For items={hires ?? []} keyFunc={(hire) => hire.id}>
                {(hire) => <Hired hire={hire} format={jobAd.format} />}
              </For>
            ),
          },
          {
            title: "Rejected",
            key: "rejected",
            content: <ApplicationsList applications={rejectedApplications} />,
          },
        ]}
      />
    );
  };

  return (
    <div className="h-full">
      <>
        <Modal
          title="Basic Modal"
          visible={showMore}
          onCancel={() => setShowMore(false)}
          modalRender={() => {
            if (job.jobAd) {
              return (
                <div className="job__more-modal">
                  <img
                    src={
                      job.jobAd.company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL
                    }
                    alt="Company logo"
                  />
                  <div className="job__modal-title">{job.jobAd.title}</div>
                  <div className="job__modal-subtitle">
                    {job?.jobAd?.format?.replace("_", " ")}
                  </div>
                  <div className="job__modal-subtitle">
                    {job.jobAd.location}
                    {job?.jobAd?.isRemote ? ` & Remote` : null}
                  </div>
                  <Link to={`/job/${jobAdId}`}>
                    <FileTextOutlined />
                    <span>View job</span>
                  </Link>
                  <Link to={`/job/${jobAdId}/edit`} state={{ edit: true }}>
                    <FormOutlined />
                    <span>Edit job</span>
                  </Link>
                </div>
              );
            }
            return null;
          }}
        />
        <NavBar
          title={job.jobAd.title}
          onBack={handleBack}
          right={
            <Button
              type="text"
              className="job__view-edit-job"
              onClick={toggleShowMore}
            >
              <MoreOutlined />
            </Button>
          }
        />
      </>
      {job.jobAd.status !== JobStatusEnum.Finished
        ? renderOngoingJob()
        : renderFinishedJob()}
    </div>
  );
};

export default JobPageMobile;
