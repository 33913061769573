import { useState } from "react";

import { Button, Card, message, Tag } from "antd";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import {
  CandidateProfile,
  JobAdDataFragment,
  ReferenceAnswer,
  ReferenceStatus,
  useAcceptReferenceMutation,
  useGetInternalRecruitersAssignedToJobQuery,
} from "generated/graphql";

import { REFERENCE_STATUS_COLORS } from "app-constants";

import JobInformation from "pages/Job/JobInformation";

import Calendly from "components/Calendly";
import EndorsedBy from "components/EndorsedBy";
import JobHeader from "components/JobHeader";
import NavBar from "components/NavBar";

import { pickRandom } from "utils";

import useWindowSize from "../../hooks/useWindowSize";
import "./style.scss";

type Props = {
  jobAd: JobAdDataFragment;
  referrer: CandidateProfile;
  referenceId: string;
  answers: Omit<ReferenceAnswer, "application">[];
  status: ReferenceStatus;
  rootPage?: string;
};

const Referee = ({
  jobAd,
  referrer,
  referenceId,
  answers,
  status,
  rootPage,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
  const { isMobile } = useWindowSize();

  const [acceptReference] = useAcceptReferenceMutation({
    variables: { referenceId },
  });

  const { data } = useGetInternalRecruitersAssignedToJobQuery({
    variables: {
      jobId: jobAd.id,
    },
  });

  const handleAcceptReference = async () => {
    setLoadingAccept(true);
    const { data } = await acceptReference();
    setLoadingAccept(false);
    if (data?.acceptReference) {
      message.success("Reference successfully accepted");
      navigate(`/my-applications/${data.acceptReference}`);
    }
  };

  return (
    <>
      <NavBar title={<>{jobAd.title}</>} backTo={rootPage} />

      <div className="lg:py-8 lg:px-16 regular-padding">
        <JobHeader
          jobAd={jobAd}
          canEdit={false}
          canApply={false}
          buttons={
            !isMobile && status === ReferenceStatus.Pending ? (
              <Button
                onClick={handleAcceptReference}
                loading={loadingAccept}
                disabled={loadingAccept}
                type="primary"
                shape="round"
                size="large"
              >
                APPROVE
              </Button>
            ) : null
          }
        />
        <div className="flex-row">
          Status:
          <Tag
            color={REFERENCE_STATUS_COLORS[status]}
            className="uppercase marginless"
          >
            {status}
          </Tag>
        </div>

        <br />

        <EndorsedBy id={referrer.id} name={referrer.name} />
        <br />
        <div>
          {answers.map((answer) => (
            <Card key={answer.id} title={answer.question.text} bordered={false}>
              <ReactMarkdown className="markdown">{answer.text}</ReactMarkdown>
            </Card>
          ))}
        </div>
        <br />
        <JobInformation jobAd={jobAd} />
        {isMobile && status === ReferenceStatus.Pending ? (
          <footer className="padding-vertical responsive-buttons-wrapper">
            <Button
              onClick={handleAcceptReference}
              loading={loadingAccept}
              disabled={loadingAccept}
              type="primary"
              shape="round"
              block
              size="large"
            >
              APPROVE
            </Button>
          </footer>
        ) : null}
        <br />
        {data?.getInternalRecruitersAssignedToJob && (
          <div>
            Not sure?{" "}
            <Calendly
              internalRecruiter={pickRandom(
                data.getInternalRecruitersAssignedToJob
              )}
              buttonLabel="Schedule a short meeting with us"
              a1={jobAd.title}
              a2={jobAd.company.name}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Referee;
