import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { ReactComponent as EmptySkull } from "images/icons/404-skull.svg";

type Props = {
  // title: string | number;
  buttonText?: string;
  onClick?: VoidFunction;
};

const EmptyPage = ({ buttonText, onClick }: Props): JSX.Element => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex h-full justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <EmptySkull className="w-[100px] mb-[-30px] md:mb-0 md:w-full" />
        <p className="font-sora font-semibold text-[#9A2ED1] text-[140px] leading-[140px] md:text-[282px] md:leading-[282px] ">
          404
        </p>

        <p className="text-[16px] md:text-[26px] leading-[20px] md:leading-[32px] text-center font-sora">
          It's just a 404 error!
          {window.innerWidth < 768 ? <br /> : null} The page is not available
        </p>
        <p className="font-sora font-semibold text-[17px] md:text-[34px] leading-[43px] text-center md:mt-4 md:mb-6">
          Don't cry
        </p>
        <button
          type="button"
          onClick={() => window.history.back()}
          className="uppercase w-[calc(100%-30px)]  sm:px-0 sm:w-[326px] h-[42px] sm:h-[54px] rounded-[40px] font-sans text-[13px] sm:text-[16px] bg-[#5362E9] cursor leading-[21px] font-medium"
        >
          GO BACK
        </button>
      </div>
    </div>
  );
};

export default EmptyPage;
