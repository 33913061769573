import React, { useRef } from "react";

import clsx from "clsx";
import { Link } from "react-router-dom";
import { JobAdCandidate } from "types";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import Card from "components/common/Card/Card";
import For from "components/common/For";

import { GetJobAdElement } from "hooks/useGetJobAd";
import { RunWhenOnScreen } from "hooks/useOnScreen";

import { formatNumber, formatRelativeDate } from "utils";

import styles from "./JobsListDesktop.module.scss";

type Props = {
  jobs: JobAdCandidate[];
};

const JobsListDesktop = ({ jobs }: Props): JSX.Element => {
  return (
    <div className={styles.jobs}>
      <For items={jobs} keyFunc={(job) => job.id}>
        {(job) => <JobDisplay job={job} />}
      </For>
    </div>
  );
};

export const JobDisplay = ({ job }: { job: JobAdCandidate }) => {
  const ref =
    useRef<HTMLAnchorElement>() as React.MutableRefObject<HTMLAnchorElement>;

  return (
    <Link ref={ref} to={`/job/${job.id}`} className="relative">
      <RunWhenOnScreen observingRef={ref}>
        <GetJobAdElement jobAdId={job.id} />
      </RunWhenOnScreen>

      <div
        className={clsx(
          "flex justify-between  w-[calc(100vw-20px)] lg:w-full p-3 max-w-[450px] lg:max-w-full ml-2 mr-4 lg:m-0 h-[82px] bg-[#343141]  gap-2 rounded-[5px]",
          styles.hover
        )}
      >
        <div className="flex gap-2  overflow-hidden">
          <figure className="flex items-center justify-center max-w-[110px] h-[60px]">
            <img
              className="min-w-[20px] max-w-[70px] max-h-[60px] rounded-md"
              src={job.company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
              alt={job.company.name}
            />
          </figure>

          <div className="h-full flex flex-col overflow-scroll justify-start gap-2">
            <span className="font-sora text-[12px] whitespace-nowrap overflow-hidden text-ellipsis leading-4">
              {job.title}
            </span>
            <div className="flex gap-1 overflow-x-scroll ">
              <span className="whitespace-nowrap h-[15px] px-2 bg-[#951ED0] rounded-[5px] font-sans font-medium text-[10px] leading-[15px]">
                {formatNumber(job.minSalary, true)}-
                {formatNumber(job.maxSalary, true)} {job.currency}
              </span>
              {job.isRemote ? (
                <span className="px-2 bg-[#6F6C99] rounded-[5px] font-sans font-medium text-[10px] leading-[15px]">
                  REMOTE
                </span>
              ) : null}

              {job.location?.split(",").map((item) => (
                <span
                  key={item}
                  className="whitespace-nowrap  uppercase px-2 bg-[#6F6C99] rounded-[5px] font-sans font-medium text-[10px] leading-[15px] h-[15px]"
                >
                  {item}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between w-[120px] items-end">
          <span className="text-[8px] rounded-[17px] bg-[#9C81EA] leading-[10px] tracking-[-0.4px] text-[#121121] font-semibold px-2 py-1 text-ellipsis overflow-hidden whitespace-nowrap text-center">
            💰 Earn up to {(job.minSalary * 0.1) / 1000}K
          </span>

          <span className="text-[9px] text-right text-[#C4C4C4] leading-3">
            {formatRelativeDate(job.date)}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default JobsListDesktop;
