import { Card, Tag } from "antd";
import ReactMarkdown from "react-markdown";

import {
  CandidateProfile,
  JobAdDataFragment,
  ReferenceAnswer,
  ReferenceStatus,
} from "generated/graphql";

import { REFERENCE_STATUS_COLORS } from "app-constants";

import JobInformation from "pages/Job/JobInformation";

import CandidateHeader from "components/CandidateProfile/CandidateHeader";
import EndorsedBy from "components/EndorsedBy";
import NavBar from "components/NavBar";

import "./style.scss";

type Props = {
  jobAd: JobAdDataFragment;
  referrer: CandidateProfile;
  referee: CandidateProfile;
  referenceId: string;
  answers: Omit<ReferenceAnswer, "application">[];
  status: ReferenceStatus;
  rootPage?: string;
};

const Referee = ({
  jobAd,
  referrer,
  referee,
  answers,
  status,
  rootPage,
}: Props): JSX.Element => {
  return (
    <>
      <NavBar title={<>{jobAd.title}</>} backTo={rootPage} />

      <div className="lg:py-8 lg:px-16 regular-padding">
        <CandidateHeader candidateProfile={referee} />
        <div className="flex-row">
          Status:
          <Tag
            color={REFERENCE_STATUS_COLORS[status]}
            className="uppercase marginless"
          >
            {status}
          </Tag>
        </div>

        <br />

        <EndorsedBy id={referrer.id} name={referrer.name} />
        <br />
        <div>
          {answers.map((answer) => (
            <Card key={answer.id} title={answer.question.text} bordered={false}>
              <ReactMarkdown className="markdown">{answer.text}</ReactMarkdown>
            </Card>
          ))}
        </div>
        <br />
        <JobInformation jobAd={jobAd} />
        <br />
        <br />
      </div>
    </>
  );
};

export default Referee;
