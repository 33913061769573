import { useEffect } from "react";

import { toast } from "react-toastify";

import { useGetUserQuery } from "generated/graphql";

import useAuth from "components/Auth/useAuth";

const useGetUserOnStart = () => {
  const { setUser, setUserProfile, token, setToken, isLoggedIn } = useAuth();

  const query = useGetUserQuery({
    variables: {
      renewToken: token ?? "",
    },
    skip: !token,
    onCompleted: (data) => {
      if (!data?.getUser) return;
      setUser(data.getUser.user);

      if (!data.getUser.profile) return;
      setUserProfile(data.getUser.profile);
    },
    onError: ({ message }) => {
      if (message === "jwt expired") {
        toast.error("Session expired");
        setToken(null);
        setUser(null);
        setUserProfile(null);
      }
    },
  });
  useEffect(() => {
    if (token) return;
    query.client.resetStore();
    query.client.cache.reset();
  }, [token]);

  if (!query.called) return true;
  if (query.loading) return false;
  if (query.error) return true;
  if (isLoggedIn) return true;
  return false;
};

export default useGetUserOnStart;
