import { Avatar } from "antd";
import { AutoCenter, Button, Card, Empty, Tag } from "antd-mobile";
import { Link } from "react-router-dom";
import { JobAdCandidate } from "types";

import { GetJobsForCandidateQuery } from "generated/graphql";

import { DEFAULT_COMPANY_PHOTO_URL } from "app-constants";

import ActiveMark from "components/ActiveMark";
import Show from "components/common/Show";
import Loading from "components/Loading";

import { formatNumber, formatRelativeDate } from "utils";

import "./style.scss";

type Props = {
  jobs?: JobAdCandidate[];
  loading?: boolean;
  openFilters?: VoidFunction;
  hasFilters?: boolean;
  selectedJobId?: GetJobsForCandidateQuery["jobsForCandidate"][number]["jobAd"]["id"];
};

const JobsForCandidateList = ({
  jobs,
  loading = false,
  hasFilters = false,
  openFilters,
  selectedJobId,
}: Props): JSX.Element => {
  if (!jobs) {
    throw Error;
  }
  if (loading) {
    return <Loading />;
  }

  if (jobs.length === 0) {
    return (
      <Empty
        style={{ height: "100%" }}
        description={
          <div>
            <AutoCenter>
              <h3>No jobs found</h3>
            </AutoCenter>
            {hasFilters && openFilters ? (
              <Button color="primary" onClick={openFilters}>
                Try other filters
              </Button>
            ) : null}
          </div>
        }
      />
    );
  }

  // TODO: add filters/sort by
  return (
    <div className="flex flex-col gap-3">
      {jobs.map(
        ({
          id,
          title,
          format,
          location,
          currency,
          minSalary,
          maxSalary,
          company,
          date,
        }) => {
          const isSelected = id === selectedJobId;

          return (
            <Link key={id} to={`/job/${id}`} className="relative">
              <Show when={isSelected}>
                <ActiveMark scroll left="calc(-24px - 38px / 2)" />
              </Show>

              <Card className="job-card-wrapper">
                <Avatar
                  src={company?.photoUrl || DEFAULT_COMPANY_PHOTO_URL}
                  size={60}
                />
                <div className="job-card-center">
                  <div className="job-card-title">{title}</div>
                  <div className="job-card-subtitle">
                    {format.replace("_", " ")}
                  </div>
                  <div className="job-card-footer">{location}</div>
                </div>
                <div className="job-card-right">
                  <Tag
                    round
                    className="status-tag uppercase"
                    style={{
                      backgroundColor: "#951ED0",
                      color: "#fff",
                      border: "none",
                      width: "unset",
                    }}
                  >
                    {currency} {formatNumber(minSalary, true)}-
                    {formatNumber(maxSalary, true)}
                  </Tag>
                  {/*<div className="job-card-footer">*/}
                  {/*  {application ? `applied` : null}*/}
                  {/*  /!*{pluralize("candidate", applications?.length || 0, true)}*!/*/}
                  {/*</div>*/}
                  <div className="job-card-footer">
                    {formatRelativeDate(date)}
                  </div>
                </div>
              </Card>
            </Link>
          );
        }
      )}
    </div>
  );
};

export default JobsForCandidateList;
