import { FunctionComponent, useRef, useState } from "react";

import classNames from "clsx";
import { useNavigate } from "react-router-dom";

import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "./BurgerMenu.module.scss";

type Props = {};

const BurgerMenu: FunctionComponent<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const burgerRef = useRef(null);
  const burgerLineRef1 = useRef(null);
  const burgerLineRef2 = useRef(null);
  const burgerLineRef3 = useRef(null);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  useOnClickOutside(
    menuRef,
    (event: any) => {
      event.stopPropagation();
      setIsOpen(false);
    },
    [burgerRef, burgerLineRef1, burgerLineRef2, burgerLineRef3]
  );

  return (
    <>
      <div
        className={styles.burgerContainer}
        onClick={toggleMenu}
        ref={burgerRef}
      >
        <div className={styles.burgerLine} ref={burgerLineRef1} />
        <div className={styles.burgerLine} ref={burgerLineRef2} />
        <div className={styles.burgerLine} ref={burgerLineRef3} />
      </div>
      <div
        ref={menuRef}
        className={classNames(styles.content, {
          [styles.contentOpen]: isOpen,
        })}
      >
        <div>
          <a
            href="https://staking-ui-flax.vercel.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            STAKE
          </a>
        </div>
        <div>
          <span
            onClick={() => {
              navigate("/signin");
            }}
          >
            LOGIN
          </span>
        </div>
        <div>
          <span
            onClick={() => {
              navigate("/find-jobs");
            }}
          >
            FIND JOBS
          </span>
        </div>
      </div>
    </>
  );
};

export default BurgerMenu;
