import { FunctionComponent } from "react";

import classNames from "clsx";

import "./style.scss";

type Props = {
  title: string;
  subtitle: string;
  noMargin?: boolean;
};

const JobTitle: FunctionComponent<Props> = ({ title, subtitle, noMargin }) => {
  return (
    <div
      className={classNames("job-header__wrapper", { marginless: noMargin })}
    >
      <div className="job-header__title">{title}</div>
      <div className="job-header__subtitle">{subtitle}</div>
    </div>
  );
};

export default JobTitle;
