import { Fragment, useEffect } from "react";

import { ApolloError } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { AiFillCaretDown } from "react-icons/ai";
import { Link, useSearchParams } from "react-router-dom";

import {
  GetJobsPublicQuery,
  SortJobsByKeyEnum,
  useGetJobsPublicQuery,
} from "generated/graphql";

import monkeyIcon from "images/nfts/monkey-endorse.json";

import JobsListDesktop from "components/CardList/JobsListDesktop";
import Show from "components/common/Show";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import JobGrid from "components/JobGrid/JobGrid";
import LoadingCentered from "components/Loading/LoadingCentered";
import SearchFilters from "components/SearchFilters";
import SkillsSelect from "components/SkillsSelect/SkillsSelect";

import useFilterJobs from "hooks/useFilterJobs";
import { useFiltersAndSortForQuery } from "hooks/useFilterJobsAndQuery";
import useWindowSize from "hooks/useWindowSize";

import { getNumberOfFiltersApplied } from "utils";

import styles from "./FindJobs.module.scss";

const transformSortText = (sortBy: SortJobsByKeyEnum) => {
  switch (sortBy) {
    case SortJobsByKeyEnum.Created:
      return "Date Posted";

    case SortJobsByKeyEnum.Title:
      return "Title";

    case SortJobsByKeyEnum.Experience:
      return "Experience";

    case SortJobsByKeyEnum.MaxSalary:
      return "Max Salary";

    case SortJobsByKeyEnum.MinSalary:
      return "Min Salary";
  }
};

const isValidSearchParam = (searchParam: [string, string | undefined]) => {
  return searchParam[1] !== undefined;
};

const FindJobs = () => {
  const { isMobile } = useWindowSize();

  const {
    filters,
    sortBy,
    onSetSortBy,
    onToggleRemote,
    onToggleSkill,
    handleApplyFilters,
    search,
    setSearch,
  } = useFiltersAndSortForQuery();

  const { data, loading, error } = useGetJobsPublicQuery({
    variables: {
      sortBy,
    },
    fetchPolicy: "cache-and-network",
  });

  const jobs = data?.jobsPublic ?? [];

  const filteredJobs = useFilterJobs(jobs, search);

  const skillFilter = (item: any) => {
    if (filters?.skills?.length === 0) return true;
    return item?.jobSkills?.some(
      (ele: any) => filters?.skills?.indexOf(ele) !== -1
    );
  };
  const experiencesFilter = (item: any) => {
    if (filters?.experiences?.length === 0) return true;
    return filters?.experiences?.some((ele) => ele === item?.experience);
  };
  const fieldsFilter = (item: any) => {
    if (filters?.fields?.length === 0) return true;
    return filters?.fields?.some((ele) => ele === item?.field);
  };
  const formatsFilter = (item: any) => {
    if (filters?.formats?.length === 0) return true;
    return filters?.formats?.some((ele) => ele === item?.format);
  };
  const minSalaryFilter = (item: any) => {
    if (!filters?.minSalary) return true;
    return item?.minSalary > filters?.minSalary;
  };

  // const sortByDateAsc = (a: any, b: any) => dayjs(b.date).diff(dayjs(a.date));
  const secondFilteredJobs = filteredJobs
    ?.filter(skillFilter)
    ?.filter(experiencesFilter)
    ?.filter(fieldsFilter)
    ?.filter(formatsFilter)
    ?.filter(minSalaryFilter);
  if (error) {
    throw error;
  }

  return (
    <main className={clsx("py-9 lg:py-11", styles.container)}>
      <div className="flex flex-col gap-5 lg:gap-11">
        <div className="w-full lg:grid grid-cols-[1fr,auto]  gap-8 lg:px-10">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col px-4 lg:px-0">
              <p className="font-sora font-semibold text-[24px] lg:text-[28px] leading-tight">
                Start endorsing colleagues
                <span className="hidden lg:block text-[#9d9d9d]">
                  Or start looking for jobs
                </span>
                <span className="block lg:hidden text-[#9d9d9d]">
                  Find jobs
                </span>
              </p>
            </div>
            <div className="px-4 lg:px-0">
              <SearchFilters
                filters={filters}
                numberOfFilters={getNumberOfFiltersApplied(filters)}
                filterText={search}
                setFilterText={setSearch}
                onApply={handleApplyFilters}
                loading={loading}
                placeholder="Job title, keywords, skills"
              />
            </div>
            <div className="px-4 lg:px-0 flex gap-2 h-fit">
              <Listbox value={sortBy.key} onChange={onSetSortBy}>
                <div className="relative">
                  <Listbox.Button
                    className={clsx(
                      "flex items-center gap-3",
                      "px-[14px] py-2 lg:px-[14px] ",
                      "border border-[#9C81EA] rounded-2xl lg:rounded-[21px]",
                      "text-[#9C81EA] text-[11px] leading-[15px] tracking-[-0.4px]",
                      "lg:text-[12.7px] lg:leading-[17px]",
                      "cursor-pointer",
                      styles.hover
                    )}
                  >
                    <span className="capitalize">
                      {transformSortText(sortBy.key)}
                    </span>
                    <span className="pointer-events-none ">
                      <AiFillCaretDown className="w-2 h-2" aria-hidden="true" />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="appearance-none min-w-full z-10 absolute p-0 mt-1 bg-[#121121] border border-[#9C81EA] rounded-md text-base shadow-lg">
                      {Object.values(SortJobsByKeyEnum).map((key) => (
                        <Listbox.Option
                          key={key}
                          className={({ active }) =>
                            clsx(
                              "appearance-none cursor-pointer select-none py-1 px-2",
                              {
                                "text-[#9C81EA]": !active,
                                "text-[#fff] bg-[#9C81EA]": active,
                              }
                            )
                          }
                          value={key}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate capitalize ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {transformSortText(key)}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              <label
                htmlFor="isRemoteCheckbox"
                className={clsx(
                  "px-6 py-2 lg:px-[14px] ",
                  "border border-[#9C81EA] rounded-2xl lg:rounded-[21px]",
                  "text-[#9C81EA] text-[11px] leading-[15px] tracking-[-0.4px]",
                  "lg:text-[12.7px] lg:leading-[17px] cursor-pointer ",
                  styles.hover,
                  {
                    "bg-[#9C81EA] text-[#fff]": filters.isRemote,
                  }
                )}
              >
                <input
                  id="isRemoteCheckbox"
                  type="checkbox"
                  className="hidden"
                  checked={filters.isRemote ?? false}
                  onChange={onToggleRemote}
                />
                Remote
              </label>
            </div>
          </div>

          <div className="hidden lg:flex flex-col gap-5 max-w-md">
            <EndorseBanner />
            <RewardsPaidBanner />
          </div>
          <div className="flex flex-col col-span-2 gap-2 w-full mt-[18px] lg:mt-[-20px]">
            <span className="px-4 lg:px-0 text-base tracking-tight">
              Skills
            </span>
            <div
              className={clsx(
                "flex gap-2 overflow-x-scroll hide-scrollbar lg:flex-wrap",
                styles.skillsListSpacing
              )}
            >
              <SkillsSelect
                selected={filters.skills ?? []}
                handleToggle={onToggleSkill}
              />
            </div>
          </div>
          <div className="lg:hidden px-4 my-8">
            <EndorseBanner />
          </div>
        </div>

        <div className="lg:px-10">
          <Show when={!!data || !loading} fallback={<LoadingCentered />}>
            <Show
              when={secondFilteredJobs.length > 0}
              fallback={
                <div className="font-bold text-2xl px-4 lg:px-0">
                  No Jobs Found
                </div>
              }
            >
              <JobsListDesktop jobs={secondFilteredJobs} />
            </Show>
          </Show>
        </div>
      </div>
    </main>
  );
};

export default FindJobs;

const EndorseBanner = () => {
  return (
    <Link to="/endorse">
      <div
        className={clsx(
          "lg:w-[378px] lg:h-[138px] font-sora text-black rounded-xl bg-[#00F4C8] flex items-center  transition-transform ease-in",
          styles.scaleHover
        )}
      >
        <div className="flex flex-col gap-2 p-5 pr-0">
          <div className="text-[20px] opacity-80 leading-[15px]">
            Endorse a Colleague
          </div>
          <div>
            <span className="font-bold text-[24px]">Earn up to $20K</span> cash
            on every successful <span className="font-bold">endorsement</span>
          </div>
        </div>
        <div className="self-end h-[138px]">
          <img
            src={monkeyIcon}
            alt="monkey icon"
            className="max-w-[130px] lg:max-w-[150px] h-[138px]"
          />
        </div>
      </div>
    </Link>
  );
};

const RewardsPaidBanner = () => {
  return (
    <Link to="/endorse">
      <div
        className={clsx(
          "w-[378px] h-[138px] py-5 px-8 font-sora text-white text-[21px] leading-[26px] rounded-xl flex items-center justify-between transition-transform ease-in",
          styles.scaleHover,
          styles.bannerBackground
        )}
      >
        <div className="">
          <div className="font-semibold">Rewards</div>
          <div className="font-normal">Paid</div>
          <div className="font-semibold text-[34px] leading-[43px]">125K</div>
        </div>
        <div className="">
          <div className="font-normal">Outstanding</div>
          <div className="font-semibold">Rewards</div>
          <div className="font-semibold text-[#00E0B8] text-[34px] leading-[43px]">
            20k+
          </div>
        </div>
      </div>
    </Link>
  );
};
