import { Switch } from "antd";
import clsx from "clsx";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";

import { emailRegex, linkedinRegex } from "utils";

import { Input, InputMultiline } from "./Components";
import styles from "./style.module.scss";

interface FormProp {
  name: string;
  email: string;
  linkedinUrl?: string;
  githubUrl?: string;
  relationship: string;
  stand_out: string;
  incognitoMode: boolean;
}

interface StepProps {
  handleButton: () => void;
  register: UseFormRegister<FormProp>;
  errors: FieldErrorsImpl<DeepRequired<FormProp>>;
  list: { ID: string; Name: string }[];
  toggleTag: (id: string) => void;
  selectedList: string[];
  handleAnonymous: (checked: boolean) => void;
  toggle: boolean;
  page: number;
  isMobile: boolean;
  handleBack: () => void;
}
const Step = ({
  page,
  handleButton,
  register,
  errors,
  list,
  toggleTag,
  selectedList,
  handleAnonymous,
  isMobile,
  handleBack,
}: StepProps) => {
  if (page === 0 && !isMobile)
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          src="/images/horse.png"
          alt=""
          className="w-[134px] h-[116px] mb-[21px]"
        />
        <h1 className="font-sora text-[28px] font-bold leading-[35px] tracking-[0.4px] mb-4">
          Great start!
        </h1>
        <div className="w-[327px] h-[96px] text-center font-mono text-[12px] leading-4 text-[#DADADA] mb-[34px]">
          We’ve developed a questionnaire for the role.
          <br />
          <br />
          Won’t take too much if you time. Good luck!
        </div>

        <div
          onClick={handleButton}
          className={clsx(
            "font-mono font-medium text-white flex justify-center items-center uppercase w-[327px] h-[58px] rounded-[40px] bg-[#5362E9]",
            styles.hover
          )}
        >
          CONTINUE
        </div>
      </div>
    );

  if (page === 1)
    return (
      <div className="flex flex-col justify-center items-center w-full max-w-[472px]">
        <h1 className=" text-center font-sora text-[20px] lg:text-[26px] font-bold leading-[27px] lg:leading-[33px] tracking-[0.4px] mb-[30px] lg:mb-3 pt-[20px] lg:pt-0">
          Find the profile of the person you want to endorse
        </h1>
        <div className="hidden lg:block w-[392px] h-[36px] leading-[18px] text-center tracking-[-0.24px] font-mono text-[14px] mb-[32px] text-[#DADADA] ">
          Write the name and email of the person you would like to refer, or
          paste their LinkdIn link
        </div>

        <div className="flex flex-col gap-[17px] w-full mb-[40px] lg:mb-[123px]">
          <Input
            title="name"
            required={true}
            register={register}
            isError={!!errors.name}
          />
          <Input
            required={true}
            title="email"
            register={register}
            isError={!!errors.email}
            regex={emailRegex}
          />
          <Input
            title="linkedinUrl"
            required={false}
            register={register}
            isError={!!errors.linkedinUrl}
            regex={linkedinRegex}
          />
          <Input
            title="githubUrl"
            register={register}
            required={false}
            isError={!!errors.githubUrl}
          />
        </div>
        <div className="flex justify-between items-center gap-8 mb-[100px] lg:mb-[165px]">
          <button
            type="button"
            onClick={handleBack}
            className={clsx(
              "lg:hidden font-mono font-medium border-[white] border text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px] rounded-[40px] bg-transparent ",
              styles.hover
            )}
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={handleButton}
            className={clsx(
              "font-mono font-medium text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px] rounded-[40px] bg-[#5362E9] ",
              styles.hover
            )}
          >
            NEXT
          </button>
        </div>
      </div>
    );
  if (page === 2)
    return (
      <div className="flex flex-col justify-center items-center w-full max-w-[472px]">
        <h1 className=" text-center font-sora text-[20px] lg:text-[26px] font-bold leading-[27px] lg:leading-[33px] tracking-[0.4px] mb-[30px] lg:mb-3 pt-[20px] lg:pt-0">
          Tell us about this person’s skills
        </h1>

        <div className="flex flex-col gap-[17px] w-full mb-[40px] lg:mb-[123px]">
          <div className="flex flex-col justify-center w-full gap-[14px] ">
            <p
              className={clsx(
                "font-mono font-medium text-[12px] leading-4 uppercase"
              )}
            >
              Skills*
            </p>
            <div className="flex flex-wrap gap-2 ">
              {list?.map((item) => {
                const isSelected = selectedList.includes(item.ID);

                return (
                  <div
                    key={item.ID}
                    onClick={() => toggleTag(item.ID)}
                    className={clsx(
                      "p-3  capitalize cursor-pointer max-h-[40px] border   font-mono text-[12px] leading-4 tracking-[-0.4px]  flex justify-center items-center ",
                      {
                        "bg-[#5362E9]": isSelected,
                        "text-white": isSelected,
                        "border-[#5362E9]": isSelected,
                        "text-[#928CA6]": !isSelected,
                        "border-[#928CA6]": !isSelected,
                        "bg-transparent": !isSelected,
                      },
                      styles.tagHover
                    )}
                  >
                    {item.Name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col justify-center w-full gap-[14px]">
            <p
              className={clsx(
                "font-mono font-medium text-[12px] leading-4 uppercase"
              )}
            >
              Stay anonymous
            </p>
            <Switch
              className=" w-[50px]"
              onClick={(checked: boolean) => handleAnonymous(checked)}
            />
          </div>
        </div>
        <div className="flex justify-between items-center gap-8 mb-[100px] lg:mb-[165px]">
          <button
            type="button"
            onClick={handleBack}
            className={clsx(
              "lg:hidden font-mono font-medium border-[white] border text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px] rounded-[40px] bg-transparent ",
              styles.hover
            )}
          >
            BACK
          </button>
          <button
            type="button"
            onClick={handleButton}
            className={clsx(
              "font-mono font-medium text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px]  rounded-[40px] bg-[#5362E9]  ",
              styles.hover
            )}
          >
            NEXT
          </button>
        </div>
      </div>
    );
  if (page === 3)
    return (
      <div className="flex flex-col justify-center items-center w-full max-w-[472px]">
        <h1 className=" text-center font-sora text-[20px] lg:text-[26px] font-bold leading-[27px] lg:leading-[33px] tracking-[0.4px] mb-[30px] lg:mb-3 pt-[20px] lg:pt-0">
          How do you know each other ?
        </h1>
        <div className="hidden lg:block w-[392px] h-[36px] leading-[18px] text-center tracking-[-0.24px] font-mono text-[14px] mb-[32px] text-[#DADADA] ">
          Anything that gives us insight on your relationship is super helpful!
        </div>
        <InputMultiline
          title="relationship"
          className=" mb-[40px] lg:mb-[123px]"
          register={register}
          isError={!!errors.relationship}
          required
        />
        <div className="flex justify-between items-center gap-8 mb-[100px] lg:mb-[165px]">
          <button
            type="button"
            onClick={handleBack}
            className={clsx(
              "lg:hidden font-mono font-medium border-[white] border text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px]  rounded-[40px] bg-transparent ",
              styles.hover
            )}
          >
            BACK
          </button>
          <button
            type="button"
            onClick={handleButton}
            className={clsx(
              "font-mono font-medium text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px] rounded-[40px] bg-[#5362E9]",
              styles.hover
            )}
          >
            NEXT
          </button>
        </div>
      </div>
    );
  if (page === 4)
    return (
      <div className="flex flex-col justify-center items-center w-full max-w-[472px]">
        <h1 className=" text-center font-sora text-[20px] lg:text-[26px] font-bold leading-[27px] lg:leading-[33px] tracking-[0.4px] mb-[30px] lg:mb-3 pt-[20px] lg:pt-0">
          What's so special about this candidate?
        </h1>
        <div className="hidden lg:block w-[392px] h-[36px] leading-[18px] text-center tracking-[-0.24px] font-mono text-[14px] mb-[32px] text-[#DADADA] ">
          Tell us what makes the candidate stand out
        </div>
        <InputMultiline
          title="stand_out"
          className=" mb-[40px] lg:mb-[123px]"
          register={register}
          required
          isError={!!errors.stand_out}
        />

        <div className="flex justify-between items-center gap-8 mb-[100px] lg:mb-[165px]">
          <button
            type="button"
            onClick={handleBack}
            className={clsx(
              "lg:hidden font-mono font-medium border-[white] border text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px]  rounded-[40px] bg-transparent  ",
              styles.hover
            )}
          >
            BACK
          </button>
          <div
            onClick={handleButton}
            className={clsx(
              "font-mono font-medium text-white flex justify-center items-center uppercase w-[138px] lg:w-[327px] h-[54px] lg:h-[58px] rounded-[40px] bg-[#5362E9] ",
              styles.linear,
              styles.hover
            )}
          >
            ENDORSE 💸
          </div>
        </div>
      </div>
    );

  return (
    <>
      {isMobile ? (
        <img
          src="/images/mobile-fanfare.svg"
          alt=""
          className="absolute top-0 "
        />
      ) : (
        <img src="/images/fanfare.svg" alt="" className="absolute top-0 " />
      )}
      <div className="absolute top-[145px] lg:top-[300px]  flex justify-center flex-col items-center">
        <h1 className="font-sora z-[300] text-[28px] font-bold leading-[35px] tracking-[-0.4px] mb-[14px] text-center">
          Application completed!
        </h1>
        <p className="w-[282px] font-mono text-[12px] leading-4 text-center text-[#A8A8A8] mb-[48px]">
          You will be notifed within one week about the status of the
          application of your endorsement
        </p>
        <button
          type="submit"
          onClick={handleButton}
          className={clsx(
            "font-mono font-medium text-white flex justify-center items-center uppercase w-[327px] h-[56px] lg:h-[58px]  rounded-[40px] bg-[#5362E9] mmb-[100px] lg:mb-[165px]",
            styles.hover
          )}
        >
          DONE
        </button>
      </div>
    </>
  );
};

export default Step;
