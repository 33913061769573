import classNames from "clsx";

import { RoleEnum } from "generated/graphql";

import useAuth from "components/Auth/useAuth";

import styles from "./styles.module.scss";

const NoChatsAvailable = () => {
  const { user } = useAuth();

  const renderContent = () => {
    switch (user?.activeRole) {
      case RoleEnum.Candidate:
        return (
          <>
            <span className={styles.emptyMessageTitle}>
              You haven't received any messages yet
            </span>
            <div
              className={classNames(
                "grid gap-4 w-2/4",
                styles.emptyMessageSubtitle
              )}
            >
              <span>
                As well as making applications on Dorse, you can be contacted by
                companies interested in your profile.
              </span>
              <span>
                You currently don't have an application made, so you won’t be
                contacted yet.
              </span>
            </div>
          </>
        );
      case RoleEnum.CompanyUser:
      case RoleEnum.InternalRecruiter:
        return (
          <>
            <span className={styles.emptyMessageTitle}>
              You haven't sent any messages yet
            </span>

            <span className={classNames(styles.emptyMessageSubtitle, "w-2/3")}>
              You can create one by clicking ‘Chat’ on the candidate profile
            </span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="grid gap-5 w-full h-full place-content-center justify-items-center">
      {renderContent()}
    </div>
  );
};

export default NoChatsAvailable;
