import { CSSProperties, ReactNode } from "react";

import { NavBar as AntNavBar } from "antd-mobile";
import classNames from "clsx";
import useWindowSize from "hooks/useWindowSize";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as MessageIcon } from "images/icons/message.svg";

import useAuth from "components/Auth/useAuth";
import Button from "components/Button";
import DesktopTopBar from "components/TopBar/Desktop";

type Props = {
  title?: ReactNode;
  left?: string;
  right?: ReactNode;
  backTo?: string | null;
  hasBorder?: boolean;
  onBack?: VoidFunction;
  style?: CSSProperties;
  className?: string;
  sticky?: boolean;
  backArrow?: ReactNode;
  relative?: boolean;
};

const NavBar = ({
  title,
  left,
  right,
  backTo,
  onBack,
  style,
  hasBorder = false,
  sticky = false,
  className,
  backArrow,
  relative,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useWindowSize();
  const { isLoggedIn } = useAuth();

  const _onBack = () => {
    if (backTo) {
      navigate(backTo);
    } else {
      navigate(-1);
    }
  };

  if (!isMobile) {
    return <DesktopTopBar customTitle={title} backTo={backTo || false} />;
  }

  const renderChat = () => {
    const isActive =
      location.pathname.includes("conversation") ||
      location.pathname.includes("chat");

    if (isActive) {
      return null;
    }

    return (
      <MessageIcon
        className="mobile__message-icon"
        onClick={() => {
          navigate("/chat", { state: { prevPath: location.pathname } });
        }}
      />
    );
  };

  const renderAuthButtons = () => {
    return (
      <div className="flex gap-2">
        <Button
          label={<div>LOGIN</div>}
          onClick={() => {
            navigate("/signin");
          }}
          size="large"
        />
        <Button
          label={<div>SIGN UP</div>}
          onClick={() => {
            navigate("/signup");
          }}
          type="simple"
          size="small"
        />
      </div>
    );
  };

  return (
    <div
      className={classNames("mobile-navbar", className, {
        "mobile-navbar-border-bottom": hasBorder,
        "sticky-navbar": sticky,
        "mobile-navbar-relative": relative,
        "mobile-not-logged-in": !isLoggedIn,
      })}
      style={style}
    >
      <AntNavBar
        onBack={onBack || _onBack}
        right={!isLoggedIn ? renderAuthButtons() : right || renderChat()}
        back={left}
        backArrow={backArrow || backTo !== null}
      >
        {title}
      </AntNavBar>
    </div>
  );
};

export default NavBar;
