import { useState } from "react";

import { Form } from "antd";
import { useNavigate } from "react-router-dom";

import { ReferenceQuestionnaire } from "generated/graphql";

import { Progressbar } from "components/common/Progressbar";

import useWindowSize from "../../hooks/useWindowSize";
import "./style.scss";

type StepType = {
  component: any;
  initialValues: Record<string, any>;
  question?: ReferenceQuestionnaire;
};

type Props = {
  steps: StepType[];
  completeStep: any;
  onSubmit: (values: Record<string, any>) => Promise<void>;
};

export interface Progress {
  current: number;
  total: number;
}

const MultiStepForm = ({
  steps,
  completeStep,
  onSubmit,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const { isMobile } = useWindowSize();

  const [form] = Form.useForm();

  const initialValues = steps
    .map(({ initialValues }) => initialValues)
    .reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {});

  const onFinish = async () => {
    const fields = Object.keys(initialValues);
    const values = form.getFieldsValue(fields);

    await onSubmit(values);
  };

  // TODO: get proper type for fields
  const next = (fields: (string | number)[]) => {
    form.validateFields(fields).then(async (isValid) => {
      if (isValid) {
        if (step === steps.length - 1) {
          await onFinish();
        }
        setStep((curr) => {
          return curr + 1;
        });
      }
    });
  };

  const back = () => {
    if (step > 0) {
      setStep((curr) => curr - 1);
    } else {
      navigate(-1);
    }
  };

  const extendedStep = {
    ...steps[step],
    progress: { current: step, total: steps.length },
  };
  const { component: Step, ...others } = extendedStep || {};

  return (
    <>
      {steps[step] ? (
        <Step
          next={next}
          form={form}
          submit={step === steps.length - 1}
          {...others}
        />
      ) : (
        completeStep
      )}
    </>
  );
};

export default MultiStepForm;
