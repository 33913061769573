import { ReactComponent as SocialCV } from "images/icons/social-cv.svg";
import { ReactComponent as SocialGithub } from "images/icons/social-github.svg";
import { ReactComponent as SocialLinkedIn } from "images/icons/social-linked-in.svg";
import { ReactComponent as SocialWeb } from "images/icons/social-web.svg";

import "./style.scss";

type Network = "web" | "linkedin" | "github" | "cv";

type Props = {
  link?: string | null;
  network: Network;
  size?: number;
};

const SocialLink = ({ link, network, size = 19 }: Props) => {
  const Icon = () => {
    if (network === "web") return <SocialWeb width={size} height={size} />;
    if (network === "linkedin")
      return <SocialLinkedIn width={size} height={size} />;
    if (network === "github")
      return <SocialGithub width={size} height={size} />;

    if (network === "cv") return <SocialCV width={size} height={size} />;

    return (
      <div className={`w-[${size}px] h-[${size}px]  rounded-[100%] bg-white`} />
    );
  };

  if (!link || link.length === 0) {
    return null;
  }

  return (
    <a
      className="w-fit h-fit"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {Icon()}
    </a>
  );
};

export default SocialLink;
