import { FunctionComponent } from "react";

export const ProfileCard: FunctionComponent<{
  title: string;
  content: string;
}> = ({ title, content }) => {
  return (
    <section className="w-full p-6 bg-[#343141]">
      <p className="font-sora text-[#D0C9D6] text-sm mb-3">{title}</p>
      <p className="font-mono text-sm leading-[18.46px]">{content}</p>
    </section>
  );
};
