import { FunctionComponent, useEffect } from "react";

import { Card, message, Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  GetReferenceForInternalRecruiterQuery,
  GetReferralWithoutJobQuery,
  ReferenceForRecruiter,
  ReferralWithoutJobResponse,
  useGetReferenceForInternalRecruiterQuery,
  useGetReferralWithoutJobQuery,
} from "generated/graphql";

import "pages/Job/style.scss";

import BlueTag from "components/BlueTag/BlueTag";
import CandidateHeader from "components/CandidateProfile/CandidateHeader";
import { JobDisplay } from "components/CardList/JobsListDesktop";
import EndorsedBy from "components/EndorsedBy";
import EndorsementNotFound from "components/Errors/EndorsementNotFound";
import Loading from "components/Loading";
import NavBar from "components/NavBar";
import SocialLink from "components/SocialLink";

import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

type Props = {
  endorsementId?: string;
  rootPage?: string;
};

const Endorsement: FunctionComponent<Props> = ({ endorsementId, rootPage }) => {
  const params = useParams();

  const _endorsementId = endorsementId || params.endorsementId!;

  const { data, loading, error } = useGetReferralWithoutJobQuery({
    variables: { referralWithoutJobId: _endorsementId },
  });

  const {
    data: data2,
    loading: loading2,
    error: error2,
  } = useGetReferenceForInternalRecruiterQuery({
    variables: { referenceId: _endorsementId },
  });

  useEffect(() => {
    if (error && error2) {
      toast.error(error.message);
    }
  }, [error]);

  if (!(data || data2) && (loading || loading2)) {
    return <Loading />;
  }

  if (error && error2) {
    return <EndorsementNotFound />;
  }

  if (data) {
    return <EndorsementNoJob data={data} rootPage={rootPage + ""} />;
  }
  return (
    <EndorsementHasJob
      data={data2 as GetReferenceForInternalRecruiterQuery}
      rootPage={rootPage + ""}
    />
  );
};

const EndorsementNoJob: FunctionComponent<{
  data: any; //  ReferralWithoutJobQuery
  rootPage: string;
}> = ({ data, rootPage }) => {
  const { isMobile } = useWindowSize();
  const { referralWithoutJob } = data;

  const {
    referrer,
    name,
    email,
    linkedinUrl,
    githubUrl,
    relationshipWithCandidate,
    standOut,
    // isIncognito,
    hardSkills,
  } = referralWithoutJob;

  if (!name || !email) {
    throw Error;
  }

  return (
    <>
      {isMobile ? <NavBar title={name} backTo={rootPage} /> : null}

      <main>
        <CandidateHeader
          candidateProfile={{
            name,
            linkedin: linkedinUrl,
            github: githubUrl,
          }}
          hidePhoto
        />

        <br />

        <section className="children-bottom-margin regular-padding-horizontal">
          <div className="flex gap-3">
            <div>Skills:</div>
            {hardSkills.map((skill: string) => (
              <BlueTag tag={skill} key={skill} />
            ))}
          </div>

          <EndorsedBy id={referrer.id} name={referrer.name} />

          <Card
            title="Tell us how do you know each other. Anything that gives us insight on your relationship is super helpful!"
            bordered={false}
          >
            {relationshipWithCandidate}
          </Card>

          <Card
            title="What's so special about this candidate? Tell us what makes the candidate stand out."
            bordered={false}
          >
            {standOut}
          </Card>
        </section>
        <br />
      </main>
    </>
  );
};

const EndorsementHasJob: FunctionComponent<{
  data: GetReferenceForInternalRecruiterQuery;
  rootPage: string;
}> = ({ data, rootPage }) => {
  const { isMobile } = useWindowSize();
  const { referenceForRecruiter } = data;
  const r = referenceForRecruiter as ReferenceForRecruiter;
  return (
    <>
      {isMobile ? <NavBar title={r.candidateName} backTo={rootPage} /> : null}
      <div>
        <CandidateHeader
          candidateProfile={{
            name: r.candidateName,
            linkedin: "",
            github: "",
          }}
          hidePhoto
        />
        <div>
          <p>
            {r.referee?.name ? (
              <>
                Claimed by candidate <br />
                <Link
                  className="underline"
                  to={`/search-candidates?id=${r.referee.id}`}
                >
                  View Candidate
                </Link>
              </>
            ) : (
              <>
                Awaiting to be claimed at
                <br />
                {r.email}
              </>
            )}
          </p>
        </div>
        {r.linkedinUrl && (
          <div className="mt-2 mb-1">
            <SocialLink link={r.linkedinUrl} network="linkedin" size={33} />
          </div>
        )}
        <section className="children-bottom-margin regular-padding-horizontal">
          <EndorsedBy
            id={r.reference.referrer.id}
            name={r.reference.referrer.name}
          />
        </section>
        <div className="w-full mb-2">
          <p>Endorsed For</p>
        </div>
        <JobDisplay job={r.jobAd} />
        <br />
      </div>
    </>
  );
};

export default Endorsement;
