import { DeleteOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CandidateProfile,
  GetReferralsForCandidateDocument,
  JobAdDataFragment,
  ReferenceAnswer,
  ReferenceStatus,
  useDeleteReferenceMutation,
} from "generated/graphql";

import Button from "components/Button";
import CandidateHeader from "components/CandidateProfile/CandidateHeader";
import CandidateTags from "components/CandidateProfile/CandidateTags";
import JobTitle from "components/JobTitle";
import NavBar from "components/NavBar";
import StatusTag from "components/StatusTag/StatusTag";

import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

type Props = {
  jobAd: JobAdDataFragment;
  referee: Partial<CandidateProfile>;
  status: ReferenceStatus;
  answers: Omit<ReferenceAnswer, "application">[];
  referenceId: string;
  rootPage?: string;
};

const Referrer = ({
  jobAd,
  referee,
  status,
  answers,
  referenceId,
  rootPage,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const { isMobile } = useWindowSize();

  const [deleteReference, { loading: loadingDelete }] =
    useDeleteReferenceMutation({
      variables: {
        referenceId,
      },
      refetchQueries: [GetReferralsForCandidateDocument],
      onCompleted: () => {
        toast.success("Reference successfully deleted");
        navigate("/my-endorsements");
      },
      onError: () => {
        toast.error("Reference could not be deleted");
      },
    });

  const handleDeleteReference = async () => {
    deleteReference();
  };

  const renderDeleteButton = () => (
    <Button
      onClick={handleDeleteReference}
      disabled={loadingDelete}
      color="danger"
      label={
        <div className="flex gap-2 items-center">
          <DeleteOutlined />
          Delete Reference
        </div>
      }
    />
  );

  return (
    <div>
      {isMobile ? (
        <NavBar
          title={
            <>
              <div>{referee.name}</div>
            </>
          }
          backTo={rootPage}
        />
      ) : (
        <div className="flex-row flex-space-between">
          <JobTitle
            title={jobAd.title}
            subtitle={`${jobAd.format.replaceAll("_", " ")} - ${
              jobAd.location
            }`}
          />
          <span className="flex-row">
            <Button
              onClick={() => {
                navigate(`/job/${jobAd.id}`);
              }}
              label="VIEW JOB"
            />
            {renderDeleteButton()}
          </span>
        </div>
      )}

      <CandidateHeader candidateProfile={referee} />
      <div className="responsive-padding children-bottom-margin">
        <CandidateTags candidateProfile={referee} />
        <div className="referral__referrer-status-wrapper">
          <div className="color__gray-1 centered referral__referrer-status">
            <h4>Dorse score</h4>
            <div>85%</div>
          </div>
          <div className="flex flex-col items-center">
            <h4 className="color__gray-1">Status</h4>
            <StatusTag type="reference" status={status} />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {answers.map((answer) => (
            <div
              key={answer.id}
              className="flex flex-col gap-3 bg-[#4A465B] bg-opacity-60 p-5 rounded-md"
            >
              <div className="text-[#D0C9D6] text-sm">
                {answer.question.text}
              </div>

              <ReactMarkdown className="markdown">{answer.text}</ReactMarkdown>
            </div>
          ))}
        </div>
      </div>
      {isMobile && status === ReferenceStatus.Pending ? (
        <footer className="responsive-padding">
          <Button
            onClick={handleDeleteReference}
            // loading={loadingDelete}
            disabled={loadingDelete}
            color="danger"
            size="large"
            label={
              <div className="flex gap-2 items-center">
                <DeleteOutlined />
                Delete Reference
              </div>
            }
          />
        </footer>
      ) : null}
    </div>
  );
};

export default Referrer;
