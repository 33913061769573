import { useEffect, useState } from "react";

import {
  ApplicationStatusEnum,
  GetCandidateApplicationsQuery,
} from "generated/graphql";

import ApplicationsList from "components/CardList/ApplicationsList";
import Tabs from "components/common/Tabs/Tabs";
import SearchFilters, { defaultFilters } from "components/SearchFilters";

import { useFilterCandidateApplications } from "hooks/useFilterApplications";

import { getNumberOfFiltersApplied } from "utils";

type Props = {
  applications: GetCandidateApplicationsQuery["candidateApplications"];
  refetch: Function; // TODO: set proper type
  loading: boolean;
  photoType?: "candidate" | "company";
  titleType?: "job" | "candidate";
  subtitleType?: "location" | "company";
  footerType?: "referred" | "jobTitle";
  tabType?: "salary" | "score";
};

const ApplicationTabs = ({
  applications,
  refetch,
  loading,
  photoType = "candidate",
  titleType = "job",
  subtitleType = "location",
  footerType = "referred",
  tabType = "salary",
}: Props): JSX.Element => {
  const [activeTab, setActiveTab] = useState("active");
  const [loadingRefetch, setLoadingRefetch] = useState<boolean>(false);
  const [filters, setFilters] = useState(defaultFilters);

  const { filterText, setFilterText, filteredApplications } =
    useFilterCandidateApplications(applications);

  console.log(filterText, setFilterText, filteredApplications);
  const activeApplications = filteredApplications.filter((application) =>
    [
      ApplicationStatusEnum.Interviewing,
      ApplicationStatusEnum.TechnicalChallenge,
      ApplicationStatusEnum.OnSite,
      ApplicationStatusEnum.Offer,
      ApplicationStatusEnum.Pending,
      ApplicationStatusEnum.InReview,
      "",
    ].includes(application.status)
  );

  const finishedApplications = filteredApplications.filter((application) =>
    [
      ApplicationStatusEnum.Canceled,
      ApplicationStatusEnum.Rejected,
      ApplicationStatusEnum.Hired,
      ApplicationStatusEnum.Invalid,
    ].includes(application.status)
  );

  const handleApplyFilters = async (appliedFilters: typeof defaultFilters) => {
    setFilters(appliedFilters);
  };

  useEffect(() => {
    (async () => {
      setLoadingRefetch(true);
      await refetch({
        filters,
      });
      setLoadingRefetch(false);
    })();
  }, [filters, refetch]);

  const onChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const renderFilters = () => (
    <div>
      <SearchFilters
        filters={filters}
        numberOfFilters={getNumberOfFiltersApplied(filters)}
        filterText={filterText}
        setFilterText={setFilterText}
        onApply={handleApplyFilters}
        loading={loading || loadingRefetch}
      />
    </div>
  );

  return (
    <div className="h-full w-full">
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        tabs={[
          {
            key: "active",
            title: "Active",
            content: (
              <div className="p-4 w-full h-full flex flex-col gap-3">
                {renderFilters()}
                <div className="overflow-y-scroll hide-scrollbar">
                  <ApplicationsList
                    applications={activeApplications}
                    photoType={photoType}
                    titleType={titleType}
                    subtitleType={subtitleType}
                    footerType={footerType}
                    tabType={tabType}
                  />
                </div>
              </div>
            ),
          },
          {
            key: "finished",
            title: "Finished",
            content: (
              <div className="p-4 w-full h-full flex flex-col gap-3">
                {renderFilters()}
                <div className="overflow-y-scroll hide-scrollbar">
                  <ApplicationsList
                    applications={finishedApplications}
                    photoType={photoType}
                    titleType={titleType}
                    subtitleType={subtitleType}
                    footerType={footerType}
                    tabType={tabType}
                  />
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default ApplicationTabs;
