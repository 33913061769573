import { Result } from "antd";

const EndorsementNotFound = (): JSX.Element => (
  <Result
    status="404"
    title="Endorsement not found"
    subTitle="Sorry, this endorsement does not exist"
  />
);

export default EndorsementNotFound;
