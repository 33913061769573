import Loading from "components/Loading";

const LoadingCentered = () => {
  return (
    <div className="w-full h-full relative">
      <Loading />
    </div>
  );
};

export default LoadingCentered;
