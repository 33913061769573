import { useState } from "react";

import {
  EyeOutlined,
  SettingOutlined, // UploadOutlined,
} from "@ant-design/icons";
import { SwapOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import { RoleEnum } from "generated/graphql";

import ActiveMark from "components/ActiveMark";
import useAuth from "components/Auth/useAuth";
import Show from "components/common/Show";
import ListItem from "components/ListItem";
import SelectRole from "components/SelectRole";

import styles from "./settingsMenuStyle.module.scss";

const SettingsMenu = () => {
  const { userProfile, user } = useAuth();
  const [openSwitchRole, setOpenSwitchRole] = useState<VoidFunction>();
  const location = useLocation();

  const items = [
    { label: "Preview profile", icon: EyeOutlined, link: "/profile/preview" },
    { label: "Edit profile", icon: SettingOutlined, link: "/profile/edit" },
  ];

  return (
    <div className={styles.wrapper}>
      {items.map((item) => (
        <div key={item.link} className="relative">
          <Show when={location.pathname.includes(item.link)}>
            <ActiveMark left="calc(-24px - 38px / 2)" />
          </Show>

          <ListItem icon={item.icon} link={item.link}>
            {item.label}
          </ListItem>
        </div>
      ))}
      {/*TODO: hide this before launching*/}
      {userProfile?.role !== RoleEnum.Candidate && (
        <ListItem icon={SwapOutlined} onClick={openSwitchRole}>
          Switch role
        </ListItem>
      )}
      <SelectRole
        roles={user?.roles!}
        activeRole={user?.activeRole!}
        setOpen={setOpenSwitchRole}
        setClose={() => {}}
      />
    </div>
  );
};

export default SettingsMenu;
