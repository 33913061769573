import { useEffect } from "react";

interface Props {
  selectedRef: React.MutableRefObject<HTMLDivElement | null>;
  page?: number;
}
const useScrollToView = ({ selectedRef, page }: Props) => {
  useEffect(() => {
    if (selectedRef) {
      selectedRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [page]);
};

export default useScrollToView;
