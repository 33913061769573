import useWindowSize from "hooks/useWindowSize";

import DesktopTopBar from "components/TopBar/Desktop";
import MobileTopBar from "components/TopBar/Mobile";

const TopBar = (): JSX.Element => {
  const { isMobile } = useWindowSize();
  if (isMobile) {
    return <MobileTopBar />;
  }

  return <DesktopTopBar />;
};

export default TopBar;
