import { useRef, useState } from "react";

import { Button, Form, FormInstance } from "antd";
import { OnboardingNextStep } from "types";

import {
  CandidateProfile,
  GetUserSuggestionsQuery,
  useGetUserSuggestionsLazyQuery,
} from "generated/graphql";

import FormAutocomplete from "components/Form/Autocomplete";
import FormInput from "components/Form/Input";

import "./style.scss";

type Props = {
  next: OnboardingNextStep;
  form: FormInstance<any>;
};

const MainInfo = ({ next, form }: Props): JSX.Element => {
  const [nameOptions, setNameOptions] = useState<
    GetUserSuggestionsQuery["userSuggestions"]
  >([]);
  const [emailOptions, setEmailOptions] = useState<
    GetUserSuggestionsQuery["userSuggestions"]
  >([]);
  const timeout = useRef<any>();

  const [getUserSuggestions] = useGetUserSuggestionsLazyQuery();

  const debounceGetUserSuggestions = async (
    text: string,
    type: "email" | "name"
  ) => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      getUserSuggestions({ variables: { text } }).then(({ data, error }) => {
        if (data?.userSuggestions) {
          if (type === "email") {
            setEmailOptions(data.userSuggestions);
          } else {
            setNameOptions(data.userSuggestions);
          }
        } else if (error) {
          if (type === "email") {
            setEmailOptions([]);
          } else {
            setNameOptions([]);
          }
        }
      });
    }, 300);
  };

  const onClickNext = () => {
    next(["name", "email", "linkedin"]);
  };

  const onSelect = (id: string, type: "email" | "name") => {
    const options = type === "email" ? emailOptions : nameOptions;
    const user = options.find((option) => option.id === id);
    if (user) {
      form.setFieldsValue({
        name: user.name,
        email: user.email,
        linkedin: (user.profile as CandidateProfile).linkedin,
      });
    }
  };

  const onChange = async (value: string, type: "name" | "email") => {
    if (!value) {
      if (type === "email") {
        setEmailOptions([]);
      } else {
        setNameOptions([]);
      }
    } else {
      await debounceGetUserSuggestions(value, type);
    }
  };

  return (
    <div className="onboarding-step">
      <section className="lg:py-8 lg:px-16">
        <Form form={form}>
          <div className="onboarding-step__title">Endorse a colleague</div>
          <div className="onboarding-step__subtitle">
            Write the name and email of the person you would like to refer, or
            paste their LinkdedIn url
          </div>
          <FormAutocomplete
            name="name"
            label="Name"
            options={nameOptions}
            shouldUpdate={(prev, curr) => {
              return prev.name !== curr.name;
            }}
            onChange={(value) => onChange(value, "name")}
            onSelect={(id) => onSelect(id, "name")}
          />
          <FormAutocomplete
            name="email"
            label="Email"
            validator={(_: any, email: string): Promise<any> => {
              if (!email) {
                return Promise.reject("Email is missing");
              }
              if (
                !email.match(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
              ) {
                return Promise.reject("Email is invalid");
              }
              return Promise.resolve();
            }}
            options={emailOptions}
            shouldUpdate={(prev, curr) => {
              return prev.email !== curr.email;
            }}
            onChange={(value) => onChange(value, "email")}
            onSelect={(id) => onSelect(id, "email")}
          />
          <FormInput
            name="linkedin"
            label="LinkedIn URL"
            shouldUpdate={(prev, curr) => {
              return prev.linkedin !== curr.linkedin;
            }}
          />
          <Button
            block
            type="primary"
            onClick={onClickNext}
            shape="round"
            size="large"
          >
            Next
          </Button>
        </Form>
      </section>
    </div>
  );
};

export default MainInfo;
