import { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  InputAnswer,
  Maybe,
  useGetJobForCandidateQuery,
  useReferCandidateMutation,
} from "generated/graphql";

import MultiStepForm from "pages/MultiStepForm";
import QuestionStep from "pages/MultiStepForm/QuestionStep";

import Loading from "components/Loading";

import CompleteRefer from "./CompleteRefer";
import MainInfo from "./MainInfo";
import "./style.scss";

const Refer = (): JSX.Element => {
  const { jobAdId } = useParams();
  const navigate = useNavigate();
  const [createdReferralId, setCreatedReferralId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const { data, error } = useGetJobForCandidateQuery({
    variables: { jobAdId: jobAdId! },
  });

  const [referCandidate] = useReferCandidateMutation({
    onCompleted: () => {
      // console.log(data.referCandidate);
    },
  });

  if (error) {
    navigate("/");
    // TODO: handle error
  }

  const onSubmit = async (values: Record<string, any>) => {
    console.log("submit", values);
    const { name, email, linkedin, ...others } = values;

    const answers: InputAnswer[] = Object.keys(others).map((key) => ({
      questionId: key,
      text: others[key],
    }));

    try {
      const res = await referCandidate({
        variables: {
          referenceData: { jobId: jobAdId!, name, email, linkedin, answers },
        },
      });

      if (res.data) {
        toast.success("Referral completed successfully");
        setCreatedReferralId(res.data.referCandidate);
      }
    } catch (err: any) {
      for (let e of err.graphQLErrors) {
        // TODO: add back error
        // if (e.extensions.code === ErrorMsg.AlreadyApplied) {
        console.log("graphql error >>>> ", e.message);
        setErrorMessage(e.message);
        // toast.error("Candidate has already applied for this job");
        // }
      }
    }
  };

  const steps = data?.jobForCandidate?.jobAd
    ? [
        {
          component: MainInfo,
          initialValues: { name: "", email: "", linkedin: "" },
        },
        ...data.jobForCandidate.jobAd.referenceQuestions.map((question) => ({
          component: QuestionStep,
          initialValues: { [question.id]: "" },
          question,
        })),
      ]
    : [];

  return (
    <>
      {data?.jobForCandidate ? (
        <MultiStepForm
          steps={steps}
          completeStep={
            <CompleteRefer
              referralId={createdReferralId}
              errorMessage={errorMessage}
              jobAdId={jobAdId!}
            />
          }
          onSubmit={onSubmit}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Refer;
