import { useEffect, useRef, useState } from "react";

import { AccountLayout, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
import { PhantomProvider } from "types";

import { connectToWallet, getProvider } from "utils/crypto";

type Props = {
  network?: "testnet" | "devnet";
};

const usePhantomWallet = (
  props?: Props
): {
  provider: PhantomProvider | undefined;
  triggerGetProvider: () => Promise<boolean>;
  walletPublicKeyString: string | undefined;
  walletPublicKey: PublicKey | undefined;
  connection: Connection | undefined;
  balance: number | undefined;
} => {
  const network = props?.network || "testnet";

  const [provider, setProvider] = useState<PhantomProvider | undefined>(
    undefined
  );

  const [walletPublicKey, setWalletPublicKey] = useState<PublicKey | undefined>(
    undefined
  );
  const [walletPublicKeyString, setWalletPublicKeyString] = useState<
    string | undefined
  >(undefined);
  const [balance, setBalance] = useState<number | undefined>(undefined);

  const connection = useRef<Connection>(new Connection(clusterApiUrl(network)));

  useEffect(() => {
    if (walletPublicKey) {
      // Get balance
      connection.current.getBalance(walletPublicKey).then(setBalance);

      connection.current
        .getTokenAccountsByOwner(walletPublicKey, {
          programId: TOKEN_PROGRAM_ID,
        })
        .then((res) => {
          res.value.forEach((e) => {
            const accountInfo = AccountLayout.decode(e.account.data);
            console.log(
              `${new PublicKey(accountInfo.mint)}   ${accountInfo.amount}`
            );
          });
        });
    }
  }, [walletPublicKey]);

  const triggerGetProvider = async (): Promise<boolean> => {
    const phantomProvider = await getProvider();

    if (phantomProvider) {
      setProvider(phantomProvider);
      try {
        const key = await connectToWallet();

        setWalletPublicKey(key);
        setWalletPublicKeyString(key?.toString());
        return Promise.resolve(true);
      } catch (e) {
        setWalletPublicKey(undefined);
        setWalletPublicKeyString(undefined);
        return Promise.reject(e);
      }
    } else {
      return Promise.reject("Please install the Phantom Browser extension");
    }
  };

  return {
    provider,
    triggerGetProvider,
    walletPublicKeyString,
    walletPublicKey,
    connection: connection.current,
    balance,
  };
};

export default usePhantomWallet;
