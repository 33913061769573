import { RoleEnum } from "generated/graphql";

import JobsForCandidate from "pages/JobPosts/desktop/JobsForCandidate";
import JobsForCompany from "pages/JobPosts/desktop/JobsForCompany";
import JobsForInternalRecruiter from "pages/JobPosts/desktop/JobsForInternalRecruiter";

import useAuth from "components/Auth/useAuth";

const JobPosts = () => {
  const { user } = useAuth();

  switch (user?.activeRole) {
    case RoleEnum.CompanyUser:
      return <JobsForCompany />;

    case RoleEnum.InternalRecruiter:
      return <JobsForInternalRecruiter />;

    case RoleEnum.Candidate:
      return <JobsForCandidate />;

    default:
      return null;
  }
};

export default JobPosts;
