import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";

import { RadioGroup } from "@headlessui/react";
import cn from "clsx";
import clsx from "clsx";
import startCase from "lodash/startCase";
import { useForm } from "react-hook-form";

import { ExperienceEnum, FieldEnum } from "generated/graphql";

import { ONBOARDING_FIELD } from "app-constants";

import { OnboardingInput } from "components/onboarding/BasicInfo";
import { OnboardingWrapper } from "components/onboarding/OnboardingWrapper";
import {
  OnboardingFields,
  OnboardingInputs,
  OnboardingStepProps,
} from "components/onboarding/types";

import styles from "./styles.module.scss";

export const FieldSelect: FunctionComponent<OnboardingStepProps> = ({
  decimalProgress,
  setState,
  setStep,
  state,
}) => {
  const [experience, setExperience] = React.useState<ExperienceEnum>(
    state.field.experience
  );
  const [fields, setFields] = React.useState<FieldEnum[]>(state.field.field);

  const {
    getValues: rawGetValues,
    register,
    formState: { errors, touchedFields },
  } = useForm<{ jobTitle: string; companyName: string }>({ mode: "onBlur" });

  const getValues = () => {
    const values = { ...rawGetValues(), experience, field: fields };

    return values;
  };

  const ready = fields.length > 0;
  const saveState = () => {
    setState({
      ...state,
      field: {
        ...getValues(),
      },
    });
  };

  const handleOnchange = () => {
    window?.localStorage?.setItem(
      ONBOARDING_FIELD,
      JSON.stringify(getValues())
    );
  };
  useEffect(() => {
    handleOnchange();
  }, [fields, experience]);
  return (
    <OnboardingWrapper
      decimalProgress={decimalProgress}
      title="Which field/s do you work in?"
    >
      <div className="mt-[51px]">
        <label
          className={cn("h-4 font-mono font-[500] text-xs leading-[16px]")}
        >
          FIELD* (YOU CAN SELECT MULTIPLE)
        </label>
        <div className="mt-[14px]">
          <FieldMultiSelect fields={fields} setFields={setFields} />
        </div>
        <div className="mt-5">
          <OnboardingInput label="JOB TITLE">
            <input
              {...register("jobTitle", {
                onChange: handleOnchange,
              })}
              defaultValue={state.field.jobTitle}
              className="bg-transparent font-mono placeholder-gray-500 mt-[1px] w-full h-[22px]"
              type="text"
              placeholder="Senior Backend Developer"
            />
          </OnboardingInput>
        </div>
        <div className="mt-5">
          <OnboardingInput label="COMPANY NAME">
            <input
              {...register("companyName", {
                onChange: handleOnchange,
              })}
              defaultValue={state.field.companyName}
              className="bg-transparent font-mono placeholder-gray-500 mt-[1px] w-full h-[22px]"
              type="text"
              placeholder="Dorse"
            />
          </OnboardingInput>
        </div>
        <div className="mt-[21px]">
          <ExperienceSingleSelect
            experience={experience}
            setExperience={setExperience}
          />
        </div>
      </div>
      <div
        className={cn("mt-[139px] flex items-center justify-center w-full", {
          "cursor-not-allowed opacity-50": !ready,
        })}
      >
        <div
          className={cn(ready ? "pointer-events-auto" : "pointer-events-none")}
        >
          <NextButton
            next={() => setStep((v) => v + 1)}
            ready={ready}
            getValues={getValues}
            setState={setState}
          />
        </div>
      </div>
      <div className="mt-6 w-full flex items-center justify-center">
        <button
          className={clsx(
            "transition-opacity duration-200",
            styles.opacityHover
          )}
          onClick={() => {
            saveState();
            setStep((v) => v - 1);
          }}
        >
          <p className="underline tracking-[-0.17] text-[#D0C9D6]">Back</p>
        </button>
      </div>
    </OnboardingWrapper>
  );
};

const NextButton: FunctionComponent<{
  next: () => void;
  ready: boolean;
  getValues: () => OnboardingFields;
  setState: Dispatch<SetStateAction<OnboardingInputs>>;
}> = ({ getValues, setState, ready, next }) => {
  const onSubmit = useCallback(() => {
    if (!ready) return;
    const values = getValues();
    setState((prevState) => ({
      ...prevState,
      field: values,
    }));
    next();
  }, [ready]);
  return (
    <button
      onClick={onSubmit}
      className={
        (clsx(
          "w-[327px] h-[54px] bg-primary rounded-full flex items-center justify-center transition-opacity duration-200"
        ),
        styles.hover)
      }
    >
      <p className="font-mono font-[500] text-base">CONTINUE</p>
    </button>
  );
};

const ExperienceSingleSelect: FunctionComponent<{
  experience: ExperienceEnum;
  setExperience: Dispatch<SetStateAction<ExperienceEnum>>;
}> = ({ experience, setExperience }) => {
  return (
    <RadioGroup value={experience} onChange={setExperience}>
      <RadioGroup.Label
        className="mb-[14px] h-4 font-mono text-xs leading-[16px]"
        as="p"
      >
        EXPERIENCE LEVEL*
      </RadioGroup.Label>
      <ul className="w-full flex items-start flex-wrap gap-2">
        {Object.values(ExperienceEnum).map((value) => (
          <RadioGroup.Option key={value} value={value} as="li">
            <button
              className={cn(
                "py-[14px] px-[13.9px] border  transition-all duration-200",
                styles.opacityAndColorhover,
                value === experience
                  ? "border-primary text-white bg-primary"
                  : "text-grey border-grey "
              )}
            >
              <p className="capitalize tracking-[-0.48px] font-mono text-xs leading-[15.83px]">
                {startCase(value)}
              </p>
            </button>
          </RadioGroup.Option>
        ))}
      </ul>
    </RadioGroup>
  );
};

/**
 * TODO : figure out how to use generic enums
 * -> doesn't seem to be implemented in ts yet though https://github.com/microsoft/TypeScript/issues/30611
 */
const FieldMultiSelect: FunctionComponent<{
  fields: FieldEnum[];
  setFields: Dispatch<SetStateAction<FieldEnum[]>>;
}> = ({ fields, setFields }) => {
  const selectField = (selectedField: FieldEnum) => {
    setFields((prevFields) => {
      if (prevFields.includes(selectedField)) {
        return prevFields.filter((field) => field !== selectedField);
      } else {
        return [...prevFields, selectedField];
      }
    });
  };
  return (
    <ul className="w-full flex items-start flex-wrap gap-2">
      {Object.values(FieldEnum).map((value) => (
        <li key={value}>
          <button
            onClick={() => selectField(value as FieldEnum)}
            className={cn(
              "py-[14px] px-[13.9px] border  transition-all duration-200",
              styles.opacityAndColorhover,
              fields.includes(value)
                ? "border-primary text-white bg-primary"
                : "text-grey border-grey "
            )}
          >
            <p className="capitalize tracking-[-0.48px] font-mono text-xs leading-[15.83px]">
              {value}
            </p>
          </button>
        </li>
      ))}
    </ul>
  );
};
