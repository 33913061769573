import { MouseEventHandler, ReactNode } from "react";

import classNames from "clsx";

import styles from "./styles.module.scss";

type Props = {
  label: ReactNode;
  color?: "primary" | "secondary" | "danger" | "degraded" | "ghost";
  type?: "rounded" | "square" | "simple";
  size?: "small" | "medium" | "large";
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};
const Button = ({
  label,
  color = "primary",
  type = "rounded",
  size = "medium",
  onClick,
  disabled = false,
}: Props) => {
  return (
    <button
      onClick={disabled ? () => {} : onClick}
      className={classNames(styles.button, {
        [styles.small]: size === "small",
        [styles.medium]: size === "medium",
        [styles.large]: size === "large",
        [styles.danger]: color === "danger",
        [styles.primary]: color === "primary",
        [styles.secondary]: color === "secondary",
        [styles.degraded]: color === "degraded",
        [styles.ghost]: color === "ghost",
        [styles.rounded]: type === "rounded",
        [styles.square]: type === "square",
        [styles.simple]: type === "simple",
      })}
    >
      {label}
    </button>
  );
};

export default Button;
