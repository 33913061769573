import { FunctionComponent } from "react";

import { Empty } from "antd";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
  ApplicationStatusEnum,
  useGetCandidateApplicationsQuery,
} from "generated/graphql";

import ApplicationTabs from "components/ApplicationsTabs";
import ApplicationsList from "components/CardList/ApplicationsList";
import DesktopLayout from "components/DesktopLayout";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import Loading from "components/Loading";
import TwoRows from "components/TwoRows/TwoRows";

import useWindowSize from "hooks/useWindowSize";

import "./style.scss";

const MyApplications: FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const isIndexPage =
    location.pathname === "/my-applications" ||
    location.pathname === "/my-applications/";

  const { data, error, loading, refetch } = useGetCandidateApplicationsQuery({
    variables: {},
    fetchPolicy: "network-only",
  });

  if (error) {
    return <SomethingWentWrong />;
  }

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <SomethingWentWrong />;
  }

  if (isMobile) {
    if (isIndexPage) {
      return (
        <ApplicationTabs
          applications={data.candidateApplications}
          refetch={refetch}
          loading={loading}
          photoType="company"
          titleType="job"
          subtitleType="company"
          footerType="referred"
          tabType="score"
        />
      );
    }

    return <Outlet />;
  }

  const activeApplications = data.candidateApplications.filter((application) =>
    [
      ApplicationStatusEnum.Interviewing,
      ApplicationStatusEnum.TechnicalChallenge,
      ApplicationStatusEnum.OnSite,
      ApplicationStatusEnum.Offer,
      ApplicationStatusEnum.Pending,
      ApplicationStatusEnum.InReview,
      "",
    ].includes(application.status)
  );

  const finishedApplications = data.candidateApplications.filter(
    (application) =>
      [
        ApplicationStatusEnum.Canceled,
        ApplicationStatusEnum.Rejected,
        ApplicationStatusEnum.Hired,
        ApplicationStatusEnum.Invalid,
      ].includes(application.status)
  );

  const renderMainColumn = () => {
    if (!isIndexPage) {
      return <Outlet />;
    }

    if (activeApplications?.[0])
      return (
        <Navigate to={`/my-applications/${activeApplications[0].id}`} replace />
      );

    if (finishedApplications?.[0])
      return (
        <Navigate
          to={`/my-applications/${finishedApplications[0].id}`}
          replace
        />
      );

    return <Empty description={false} />;
  };

  return (
    <DesktopLayout
      leftColumn={
        <div className="h-full">
          <TwoRows
            first={{
              showWhen: activeApplications.length > 0,
              title: "Active",
              component: (
                <ApplicationsList
                  applications={activeApplications}
                  photoType="company"
                  titleType="job"
                  subtitleType="format"
                  footerType="location"
                  narrow
                />
              ),
            }}
            second={{
              showWhen: finishedApplications.length > 0,
              title: "Finished",
              component: (
                <ApplicationsList
                  applications={finishedApplications}
                  photoType="company"
                  titleType="job"
                  subtitleType="format"
                  footerType="location"
                  narrow
                />
              ),
            }}
          />
        </div>
      }
      mainColumn={renderMainColumn()}
    />
  );
};

export default MyApplications;
