import { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useForm } from "react-hook-form";

import { useRegeneratePasswordRequestMutation } from "generated/graphql";

import { ReactComponent as VerificationArrow } from "images/icons/verification-arrow.svg";

import useAuth from "components/Auth/useAuth";

import { emailRegex } from "utils";

export default function ForgotPasswordModal() {
  let [isOpen, setIsOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<{ email: string }>({
    mode: "onBlur",
  });
  const { regeneratePasswordRequest } = useAuth();
  useRegeneratePasswordRequestMutation();
  const { email } = getValues();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onSubmit = async () => {
    try {
      if (errors.email) return;
      await regeneratePasswordRequest(email);
      setIsSent(true);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (isSent && !isOpen) setTimeout(() => setIsSent(false), 2000);
  }, [isSent, isOpen]);

  return (
    <>
      <p
        onClick={openModal}
        className="text-primary mt-2 text-[16px] leading-5 cursor-pointer"
      >
        Forgot Password?
      </p>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="fixed inset-0 flex items-center justify-center bg-[#23223F] opacity-50" />

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col justify-center items-center px-[18px] pt-[24px] pb-[24px] sm:px-[40px] sm:py-[36px] shadow-[0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)]  w-full max-w-[487px]  transform overflow-hidden rounded-[8px] bg-[#121121] text-left align-middle  transition-all">
                  <img
                    src="/icons/dorse_logo.svg"
                    className="w-[78px] h-[17px] mb-6 self-center"
                    alt=""
                  />
                  <Dialog.Title
                    as="h3"
                    className="color-white font-sora font-semibold leading-5 lg:leading-6 mb-2 text-center text-[16px] lg:text-[20px]"
                  >
                    {isSent ? "Check your email" : "Forgot Password"}
                  </Dialog.Title>
                  <div className="m4-[6px] lg:mt-2">
                    <p className="color-[#DADADA] font-sans font-normal text-[12px] leading-4 lg:leading-[22px] lg:text-[14px] mb-[17px] lg:mb-6 text-center">
                      {isSent ? (
                        <>
                          We have sent a password reset link to{" "}
                          <span className="font-semibold">{email}</span>
                        </>
                      ) : (
                        "No worries, we'll send you reset instructions"
                      )}
                    </p>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={clsx("flex flex-col mb-6 w-full", {
                      ["hidden"]: isSent,
                    })}
                  >
                    <label
                      className={clsx(
                        "text-[12px] lg:text-[16px] font-sans leading-3 lg:leading-4 ",
                        {
                          "text-errorRed": errors.email,
                          "text-[#5362E9]": !errors.email,
                        }
                      )}
                    >
                      Email Address
                    </label>
                    <div
                      className={clsx(
                        "flex space-between w-full border-b border-[#5362E9] pt-[8px]",
                        {
                          "border-errorRed": errors.email,
                        }
                      )}
                    >
                      <input
                        placeholder="Email"
                        className="bg-transparent  h-[33px] text-[14px] w-full leading-4 font-sans color-[#C4C4C4]"
                        {...register("email", {
                          required: true,
                          pattern: emailRegex,
                        })}
                      />

                      <VerificationArrow
                        className={clsx("mt-[9px] ", {
                          ["hidden"]: errors.email,
                        })}
                      />
                    </div>{" "}
                    <p
                      className={clsx(
                        "text-[13px] font-sans leading-[15px] font-light color-[#E63D70] text-errorRed mt-[6px]",
                        {
                          ["hidden"]: !errors.email,
                        }
                      )}
                    >
                      The email address is incomplete
                    </p>
                  </form>

                  <button
                    type="button"
                    onClick={isSent ? closeModal : onSubmit}
                    className={clsx(
                      "flex justify-center text-center py-[11px] lg:py-[18px] align-center  text-[14px] font-medium font-sans rounded-[40px] w-full max-w-[365px]",
                      {
                        "cursor-default bg-[#323C92] text-[#7C82B9]":
                          errors.email,
                        "cursor-pointer bg-[#5362E9] text-[#f5f5f5]":
                          !errors.email,
                      }
                    )}
                    // onClick={closeModal}
                  >
                    {isSent ? "GO BACK TO SIGN IN" : "SEND INSTRUCTIONS"}
                  </button>
                  <p
                    className={clsx(
                      "mt-6 text-[12px] text-center font-sans color-primary",
                      {
                        ["hidden"]: !isSent,
                      }
                    )}
                  >
                    <span className="text-white">
                      Didn't receive the email?
                    </span>
                    <strong
                      onClick={onSubmit}
                      className="text-[12px] font-semibold cursor-pointer"
                    >
                      {"  "}
                      Click to resent
                    </strong>
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
