import { defaultAddJob } from ".";
import { Button } from "antd";
import classNames from "clsx";
import clsx from "clsx";

import deleteIcon from "images/icons/delete.svg";

import FormTextArea from "components/Form/Textarea";

import styles from "./styles.module.scss";

type Props = {
  loading: boolean;
  onBack: VoidFunction;
  onAddQuestion: (text?: string) => void;
  onRemoveQuestion: (id: string) => void;
  addJobData: typeof defaultAddJob;
};

const Step2 = ({
  loading,
  onBack,
  onAddQuestion,
  onRemoveQuestion,
  addJobData,
}: Props): JSX.Element => {
  const { referenceQuestions } = addJobData;

  return (
    <>
      <div>
        {referenceQuestions.map((question, idx) => {
          return (
            <div key={question.tempId}>
              <FormTextArea
                name={["referenceQuestions", idx, "text"]}
                label={
                  <div className={styles.questionLabel}>
                    <div>
                      {`Question ${idx + 1}`}
                      <sup>*</sup>
                    </div>
                    <div>
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        className={styles.deleteQuestionIcon}
                        onClick={() => onRemoveQuestion(question.tempId)}
                      />
                    </div>
                  </div>
                }
                hideSup
                requiredMessage={`Text is missing for question ${idx + 1}`}
              />
            </div>
          );
        })}
        <button
          className={classNames(
            "mb-4 text-white  border border-[#4A465B] rounded-full px-6 py-3",
            styles.opacityHover
          )}
          onClick={() => onAddQuestion()}
        >
          ADD QUESTION
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          className={clsx("color-primary", styles.opacityHover)}
          onClick={onBack}
          htmlType="button"
          type="text"
          color="primary"
          shape="round"
          size="large"
        >
          BACK
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          disabled={loading}
          loading={loading}
          shape="round"
          className={clsx(styles.opacityHover)}
        >
          CREATE JOB
        </Button>
      </div>
    </>
  );
};

export default Step2;
