import { FunctionComponent } from "react";

import upperCase from "lodash/upperCase";

export const DisplayTags: FunctionComponent<{
  items: string[];
  title?: string;
}> = ({ items, title }) => {
  return (
    <div className="gap-3 grid grid-cols-[fit-content(100%),fit-content(100%)]">
      {title && (
        <div className="flex items-center justify-center">
          <p className="font-sora text-sm leading-4 text-[#D0C9D6]">{title}</p>
        </div>
      )}
      <ul className="flex gap-3 items-center basis-full flex-wrap">
        {items.map((item) => (
          <li key={item} className="py-[1px] px-[5px] bg-primary rounded-sm">
            <p className="font-mono text-[15px] truncate">{upperCase(item)}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
