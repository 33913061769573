import styles from "./Card.module.scss";

type Props = {
  left: React.ReactNode;
  center: {
    first: React.ReactNode;
    second: React.ReactNode;
    third: React.ReactNode;
  };
  right: {
    first: React.ReactNode;
    second: React.ReactNode;
    third: React.ReactNode;
  };
};

const Card = ({ left, center, right }: Props) => {
  return (
    <div className={styles.card}>
      <div className={styles.left}>{left}</div>
      <div className={styles.center}>
        <div>{center.first}</div>
        <div>{center.second}</div>
        <div> {center.third}</div>
      </div>
      <div className={styles.right}>
        <div>{right.first}</div>
        <div>{right.second}</div>
        <div>{right.third}</div>
      </div>
    </div>
  );
};

export default Card;
