import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import Loading from "../../components/Loading";
import SearchInput from "../../components/SearchInput";
import useConversations from "../../hooks/useConversations";
import { useSearchChat } from "../../hooks/useSearchChat";
import ChatItem from "./ChatItem";
import NoChatsAvailable from "./NoChatsAvailable";

const ChatList: FunctionComponent = () => {
  const params = useParams();
  const { conversations, loading, selectedConversation } = useConversations();

  const { setFilterText, filteredConversations } = useSearchChat(conversations);

  const onSearchChat = (value: string) => {
    setFilterText(value);
  };

  if (loading) {
    return <Loading />;
  }

  const _selectedConversation =
    params.otherProfileId ||
    selectedConversation ||
    conversations?.[0]?.with.id;

  if (!conversations || conversations.length === 0) {
    return <NoChatsAvailable />;
  }

  return (
    <>
      <div className="chat__search-wrapper">
        <SearchInput placeholder="Search chat" onChange={onSearchChat} />
      </div>
      {filteredConversations?.map((conversation) => {
        return (
          <ChatItem
            key={conversation.with.id}
            conversation={conversation}
            selected={conversation.with.id === _selectedConversation}
          />
        );
      })}
    </>
  );
};

export default ChatList;
