import { JobStatusEnum, useGetJobsForCompanyQuery } from "generated/graphql";

import JobListSideBar from "pages/JobPosts/desktop/JobListSideBar";

import Loading from "components/Loading";
import SearchFilters from "components/SearchFilters";
import SkillsSelect from "components/SkillsSelect/SkillsSelect";

import useFilterJobs from "hooks/useFilterJobs";
import { useFiltersAndSortForQuery } from "hooks/useFilterJobsAndQuery";

import { getNumberOfFiltersApplied } from "utils";

const JobsForCompany = (): JSX.Element => {
  const {
    filters,
    sortBy,
    onSetSortBy,
    onToggleRemote,
    onToggleSkill,
    handleApplyFilters,
    search,
    setSearch,
  } = useFiltersAndSortForQuery();
  const { data, loading } = useGetJobsForCompanyQuery({
    variables: {},
    fetchPolicy: "network-only",
  });

  const jobs = data?.jobsForCompany || [];
  const filteredJobs = useFilterJobs(jobs, search);
  const activeJobs = jobs.filter(
    (jobAd) => jobAd.status === JobStatusEnum.Active
  );

  const finishedJobs = jobs.filter(
    (jobAd) => jobAd.status === JobStatusEnum.Finished
  );

  if (loading) return <Loading />;
  return (
    <div>
      <div className="flex flex-col gap-4 pl-[22px] pr-[15px] pt-[26px]">
        <SearchFilters
          filters={filters}
          numberOfFilters={getNumberOfFiltersApplied(filters)}
          filterText={search}
          setFilterText={setSearch}
          onApply={handleApplyFilters}
          loading={loading}
          placeholder="Job title, keywords, skills"
        />
        <div className="flex flex-col gap-2 mb-4">
          <span className="px-4 lg:px-0 text-base tracking-tight">Skills</span>
          <div className="flex gap-2 overflow-x-scroll hide-scrollbar lg:flex-wrap">
            <SkillsSelect
              selected={filters.skills ?? []}
              handleToggle={onToggleSkill}
            />
          </div>
        </div>
      </div>
      <JobListSideBar activeJobs={activeJobs} finishedJobs={finishedJobs} />
    </div>
  );
};

export default JobsForCompany;
