import { ReactNode } from "react";

import classNames from "clsx";

import For from "components/common/For";

import styles from "./Tabs.module.scss";

type Tab = {
  title: ReactNode;
  key: string;
  content: ReactNode;
};

type TabsProps = {
  activeKey: string | null;
  onChange: (key: string) => void;
  tabs: Tab[];
};

const Tabs = ({ tabs, activeKey, onChange }: TabsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <For items={tabs} keyFunc={(item) => item.key}>
          {(tab) => (
            <div
              className={classNames(styles.headerTitleContainer, {
                [styles.selected]: tab.key === activeKey,
              })}
              onClick={() => onChange(tab.key)}
            >
              <span className={styles.headerTitle}>{tab.title}</span>
              <div />
            </div>
          )}
        </For>
      </div>
      <div>{tabs.find((tab) => tab.key === activeKey)?.content}</div>
    </div>
  );
};

export default Tabs;
