import { FunctionComponent, useMemo } from "react";

import { Spin } from "antd";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { ExperienceEnum, useGetCandidateQuery } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import { ProfileCard } from "components/common";
import { DisplayTags } from "components/common/DisplayTags";

import styles from "./styles.module.scss";

type CandidateQuery = {
  __typename?: "CandidateProfile";
  id: string;
  name: string;
  photoUrl?: string | null;
  field: Array<string>;
  jobTitle: string;
  github?: string | null;
  location: string;
  linkedin?: string | null;
  experience?: ExperienceEnum | null;
  techSkills?: Array<string> | null;
  softSkills?: Array<string> | null;
  candidateSkills?: Array<string> | null;
  about?: string | null;
  web?: string | null;
  companyName?: string | null;
  available?: boolean | null;
};

export const DisplayCandidate: FunctionComponent<{ candidateId: string }> = ({
  candidateId,
}) => {
  const { data, loading } = useGetCandidateQuery({
    variables: { id: candidateId },
  });
  const candidate = data?.getCandidate as CandidateQuery;

  const candidateTags = useMemo(() => {
    if (!candidate) return [];
    const arr = [];
    if (candidate?.available) arr.push("Available");
    arr.push(...(candidate.field ?? []), candidate.experience);
    return arr as string[];
  }, [candidate]);
  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="">
          <h2 className="mt-7 font-sora font-[600] text-[28px]">
            Profile{" "}
            <Link
              className={clsx("cursor-pointer", styles.opacityHover)}
              to={`/profile/${candidate.id}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 inline"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
            </Link>
          </h2>
          <div className="mt-[42px] grid grid-cols-[187px,1fr] gap-4">
            <figure className="border-secondary border">
              <img
                src={candidate.photoUrl || DEFAULT_USER_PHOTO_URL}
                className="w-full h-full "
                alt="candidate"
              />
            </figure>
            <div>
              <h4 className="font-sora text-[24px] mt-[46px]">
                {candidate.name ? candidate.name : "<NoName>"}
              </h4>
              <p className="mt-[6px] font-mono">{candidate.jobTitle}</p>
              {candidate.companyName && (
                <p className="mt-[6px] font-mono text-[#928CA6]">
                  at {candidate.companyName}
                </p>
              )}
              <Link
                to={`/chat/${candidateId}`}
                className="h-[42px] w-[110px] rounded-[3px] mt-[8px] bg-secondary px-5 py-[7px] flex items-center justify-center"
              >
                <p className="font-mono text-[13px] leading-[26px] tracking-[-0.28px]">
                  OPEN CHAT
                </p>
              </Link>
            </div>
          </div>
          <div className="mt-[34px]">
            <DisplayTags items={candidateTags} />
          </div>
          {candidate.softSkills && candidate.softSkills?.length > 0 && (
            <div className="mt-[18px]">
              <DisplayTags items={candidate.softSkills} title="Soft skills" />
            </div>
          )}
          {candidate.techSkills && candidate.techSkills?.length > 0 && (
            <div className="mt-[21px]">
              <DisplayTags items={candidate.techSkills} title="Tech skills" />
            </div>
          )}
          {candidate.about && (
            <div className="mt-[31px] max-w-[693px] mr-5">
              <ProfileCard title={"About me:"} content={candidate.about} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
