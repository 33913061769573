import { LogoutOutlined, MessageOutlined } from "@ant-design/icons";
import useWindowSize from "hooks/useWindowSize";

import useAuth from "components/Auth/useAuth";
import DesktopLayout from "components/DesktopLayout";
import ListItem from "components/ListItem";
import UploadProfilePicture from "components/UploadProfilePicture";

import Preview from "../Profile/Preview";
import SettingsMenu from "./SettingsMenu";
import "./style.scss";

const Settings = () => {
  const { userProfile, logout } = useAuth();

  const { isMobile } = useWindowSize();

  const name = userProfile?.name;

  if (isMobile) {
    return (
      <div className="profile-mobile-wrapper lg:py-8 lg:px-16">
        <div className={`profile__header-responsive-wrapper`}>
          <UploadProfilePicture photoUrl={userProfile?.photoUrl} />
          <div>
            <div className="profile-mobile-fullname">{name}</div>
          </div>
        </div>

        <SettingsMenu />

        <div className="px-6">
          <ListItem icon={MessageOutlined} link="/chat">
            Chat
          </ListItem>

          <ListItem icon={LogoutOutlined} onClick={logout} centered danger>
            LOG OUT
          </ListItem>
        </div>
      </div>
    );
  }

  return <DesktopLayout leftColumn={<div>asd</div>} mainColumn={<Preview />} />;
};

export default Settings;
