import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [loadingIsMobile, setLoadingIsMobile] = useState<boolean>(true);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsMobile(window.innerWidth < 1024);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    setLoadingIsMobile(false);

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return { ...windowSize, isMobile, loadingIsMobile };
};

export default useWindowSize;
