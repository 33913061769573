import { useEffect, useRef } from "react";

import styles from "./style.module.scss";

type Props = {
  scroll?: boolean;
  left?: string;
  top?: string;
};

const ActiveMark = ({ scroll = false, left, top }: Props) => {
  const selectedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!scroll) return;
    selectedRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [selectedRef, scroll]);

  return (
    <div className={styles.outsideOval} ref={selectedRef} style={{ left, top }}>
      <div className={styles.insideOval} />
    </div>
  );
};

export default ActiveMark;
