import { useEffect, useState } from "react";

import classNames from "clsx";

import {
  JobFilters,
  JobStatusEnum,
  useGetJobsForInternalRecruiterQuery,
} from "generated/graphql";

import JobList from "components/CardList/JobList";
import Tabs from "components/common/Tabs/Tabs";
import Loading from "components/Loading";
import SearchFilters, { defaultFilters } from "components/SearchFilters";
import SkillsSelect from "components/SkillsSelect/SkillsSelect";

import useFilterJobs from "hooks/useFilterJobs";
import { useFiltersAndSortForQuery } from "hooks/useFilterJobsAndQuery";

import { getNumberOfFiltersApplied } from "utils";

import styles from "./styles.module.scss";

const JobsForInternalRecruiterMobile = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState("active");
  const [loadingRefetch, setLoadingRefetch] = useState<boolean>(false);
  // const [filters, setFilters] = useState(defaultFilters);

  const { data, loading } = useGetJobsForInternalRecruiterQuery({
    variables: {},
    fetchPolicy: "cache-and-network",
  });
  const { filters, onToggleSkill, handleApplyFilters, search, setSearch } =
    useFiltersAndSortForQuery(false);

  const jobs = data?.jobsForInternalRecruiter?.map((i) => i.jobAd) || [];

  // const [search, setSearch] = useState<string>("");

  const filteredJobs = useFilterJobs(jobs, search);
  const skillFilter = (item: any) => {
    if (filters?.skills?.length === 0) return true;
    return item?.jobSkills?.some(
      (ele: any) => filters?.skills?.indexOf(ele) !== -1
    );
  };
  const experiencesFilter = (item: any) => {
    if (filters?.experiences?.length === 0) return true;
    return filters?.experiences?.some((ele) => ele === item?.experience);
  };
  const fieldsFilter = (item: any) => {
    if (filters?.fields?.length === 0) return true;
    return filters?.fields?.some((ele) => ele === item?.field);
  };
  const formatsFilter = (item: any) => {
    if (filters?.formats?.length === 0) return true;
    return filters?.formats?.some((ele) => ele === item?.format);
  };
  const minSalaryFilter = (item: any) => {
    if (!filters?.minSalary) return true;
    return item?.minSalary > filters?.minSalary;
  };
  const secondFilteredJobs = filteredJobs
    .filter(skillFilter)
    .filter(experiencesFilter)
    .filter(fieldsFilter)
    .filter(formatsFilter)
    .filter(minSalaryFilter);

  const activeJobs = secondFilteredJobs.filter(
    (job) => job.status === JobStatusEnum.Active
  );

  const finishedJobs = secondFilteredJobs.filter(
    (job) => job.status === JobStatusEnum.Finished
  );

  const onChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const renderFilters = () => (
    <div>
      <SearchFilters
        filters={filters}
        numberOfFilters={getNumberOfFiltersApplied(filters)}
        filterText={search}
        setFilterText={setSearch}
        onApply={handleApplyFilters}
        loading={loading || loadingRefetch}
      />
    </div>
  );
  const renderSkillFilters = () => {
    return (
      <div className="flex flex-col  lg:h-[180px] gap-2 my-4 ">
        <span className="lg:px-0 text-base tracking-tight">Skills</span>
        <div className="flex gap-2  overflow-x-scroll  hide-scrollbar  lg:flex-wrap">
          <SkillsSelect
            selected={filters.skills ?? []}
            handleToggle={onToggleSkill}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="h-full  overflow-y-hidden">
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        tabs={[
          {
            key: "active",
            title: "Active",
            content: (
              <div className={classNames("p-4 h-full", styles.jobsTab)}>
                {renderFilters()}
                {renderSkillFilters()}
                <div className="overflow-y-scroll p-0 hide-scrollbar">
                  {data ? <JobList jobs={activeJobs!} /> : <Loading />}
                </div>
              </div>
            ),
          },
          {
            key: "finished",
            title: "Finished",
            content: (
              <div className={classNames("p-4 h-full ", styles.jobsTab)}>
                {renderFilters()}
                {renderSkillFilters()}

                <div className="h-full overflow-y-scroll p-0 hide-scrollbar">
                  {data ? <JobList jobs={finishedJobs!} /> : <Loading />}
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default JobsForInternalRecruiterMobile;
