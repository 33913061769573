import {
  JobStatusEnum,
  useGetJobsForInternalRecruiterQuery,
} from "generated/graphql";

import JobListSideBar from "pages/JobPosts/desktop/JobListSideBar";

import SearchFilters from "components/SearchFilters";
import SkillsSelect from "components/SkillsSelect/SkillsSelect";

import useFilterJobs from "hooks/useFilterJobs";
import { useFiltersAndSortForQuery } from "hooks/useFilterJobsAndQuery";

import { getNumberOfFiltersApplied } from "utils";

import Loading from "../../../components/Loading";

const JobsForInternalRecruiter = (): JSX.Element => {
  const {
    filters,

    onToggleSkill,
    handleApplyFilters,
    search,
    setSearch,
  } = useFiltersAndSortForQuery();
  const { data, loading } = useGetJobsForInternalRecruiterQuery({
    variables: {},
    fetchPolicy: "network-only",
  });

  const jobs = data?.jobsForInternalRecruiter?.map((i) => i.jobAd) || [];
  const filteredJobs = useFilterJobs(jobs, search);
  const skillFilter = (item: any) => {
    if (filters?.skills?.length === 0) return true;
    return item?.jobSkills?.some(
      (ele: any) => filters?.skills?.indexOf(ele) !== -1
    );
  };
  const experiencesFilter = (item: any) => {
    if (filters?.experiences?.length === 0) return true;
    return filters?.experiences?.some((ele) => ele === item?.experience);
  };
  const fieldsFilter = (item: any) => {
    if (filters?.fields?.length === 0) return true;
    return filters?.fields?.some((ele) => ele === item?.field);
  };
  const formatsFilter = (item: any) => {
    if (filters?.formats?.length === 0) return true;
    return filters?.formats?.some((ele) => ele === item?.format);
  };
  const minSalaryFilter = (item: any) => {
    if (!filters?.minSalary) return true;
    return item?.minSalary > filters?.minSalary;
  };
  const secondFilteredJobs = filteredJobs
    .filter(skillFilter)
    .filter(experiencesFilter)
    .filter(fieldsFilter)
    .filter(formatsFilter)
    .filter(minSalaryFilter);
  const activeJobs = secondFilteredJobs.filter(
    (job) => job.status === JobStatusEnum.Active
  );

  const finishedJobs = secondFilteredJobs.filter(
    (job) => job.status === JobStatusEnum.Finished
  );
  console.log(filters, "filter");
  console.log(secondFilteredJobs, "ye");
  if (loading) return <Loading />;
  return (
    <div>
      <div className="flex flex-col gap-4 pl-[22px] pr-[15px] pt-[26px]">
        <SearchFilters
          filters={filters}
          numberOfFilters={getNumberOfFiltersApplied(filters)}
          filterText={search}
          setFilterText={setSearch}
          onApply={handleApplyFilters}
          loading={loading}
          placeholder="Job title, keywords, skills"
        />
        <div className="flex flex-col lg:h-[180px] gap-2 mb-4 ">
          <span className="px-4 lg:px-0 text-base tracking-tight">Skills</span>
          <div className="flex gap-2 overflow-x-scroll hide-scrollbar lg:flex-wrap">
            <SkillsSelect
              selected={filters.skills ?? []}
              handleToggle={onToggleSkill}
            />
          </div>
        </div>
      </div>
      <JobListSideBar activeJobs={activeJobs} finishedJobs={finishedJobs} />
    </div>
  );
};

export default JobsForInternalRecruiter;
