import { FunctionComponent } from "react";

import { Navigate } from "react-router-dom";

import {
  RoleEnum,
  useGetJobsForCandidateQuery,
  useGetJobsForCompanyQuery,
  useGetJobsForInternalRecruiterQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";
import Loading from "components/Loading";
import NoJobsAvailable from "components/NoJobsAvailable";

import SomethingWentWrong from "../../../components/Errors/SomethingWentWrong";

const FirstJobForCompany = () => {
  const { data, loading, error } = useGetJobsForCompanyQuery();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  if (!data) {
    return <SomethingWentWrong />;
  }

  if (data.jobsForCompany.length === 0) {
    return <NoJobsAvailable />;
  }

  return <Navigate to={`/jobs/${data.jobsForCompany[0].id}`} replace />;
};

const FirstJobForInternalRecruiter = () => {
  const { data, loading, error } = useGetJobsForInternalRecruiterQuery();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  if (!data) {
    return <SomethingWentWrong />;
  }

  if (data.jobsForInternalRecruiter.length === 0) {
    return <NoJobsAvailable />;
  }

  return (
    <Navigate
      to={`/jobs/${data.jobsForInternalRecruiter[0].jobAd.id}`}
      replace
    />
  );
};

const FirstJobForCandidate = () => {
  const { data, loading, error } = useGetJobsForCandidateQuery({});

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  if (!data) {
    return <SomethingWentWrong />;
  }

  if (data.jobsForCandidate.length === 0) {
    return <NoJobsAvailable />;
  }

  return <Navigate to={`/job/${data.jobsForCandidate[0].jobAd.id}`} replace />;
};

const FirstJob: FunctionComponent = () => {
  const { user } = useAuth();

  switch (user?.activeRole) {
    case RoleEnum.CompanyUser:
      return <FirstJobForCompany />;

    case RoleEnum.InternalRecruiter:
      return <FirstJobForInternalRecruiter />;

    case RoleEnum.Candidate:
      return <FirstJobForCandidate />;

    default:
      return null;
  }
};

export default FirstJob;
