import { RoleEnum } from "generated/graphql";

import Job from "pages/Job";

import useAuth from "components/Auth/useAuth";

import JobForCompanyPage from "./JobForCompanyPage";

const JobPage = () => {
  const { user } = useAuth();

  switch (user?.activeRole) {
    case RoleEnum.CompanyUser:
    case RoleEnum.InternalRecruiter:
      return <JobForCompanyPage />;
    case RoleEnum.Candidate:
      return <Job />;
    default:
      return null;
  }
};

export default JobPage;
