import { useState } from "react";

import { defaultAddJob } from ".";
import { Button } from "antd";
import clsx from "clsx";

import {
  ExperienceEnum,
  FieldEnum,
  GetCompaniesQuery,
  JobTypeEnum,
  RoleEnum,
  useGetJobSkillsQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";
import For from "components/common/For";
import Input from "components/Form/Input";
import RadioTags from "components/Form/RadioTags";
import FormSelect from "components/Form/Select";
import FormSlider from "components/Form/Slider";
import Textarea from "components/Form/Textarea";

import { formatNumber } from "utils";

import styles from "./styles.module.scss";

type Props = {
  onContinue: Function;
  companies?: GetCompaniesQuery["companies"];
  addJobData: typeof defaultAddJob;
  setAddJobData: (data: typeof defaultAddJob) => void;
};

const Step1 = ({
  onContinue,
  companies,
  addJobData,
  setAddJobData,
}: Props): JSX.Element => {
  const { user } = useAuth();

  const [newJobSkillInput, setNewJobSkillInput] = useState("");
  const [newJobSkills, setNewJobSkills] = useState<string[]>([]);

  const { data } = useGetJobSkillsQuery({
    fetchPolicy: "cache-and-network",
  });
  const skills = data?.getListOfJobSkills ?? [];

  const allJobSkills = [...skills, ...newJobSkills];

  return (
    <>
      {user?.activeRole === RoleEnum.InternalRecruiter && companies ? (
        <FormSelect name="companyId" label="Company" options={companies} />
      ) : null}

      <Input name="title" label="Job title" />

      <Input name="location" label="Location" />

      <div className="custom-form-label-wrapper">
        <h4>
          Salary range<sup>*</sup>
        </h4>
        <div>
          ${formatNumber(addJobData.salary[0])} -{" $"}
          {formatNumber(addJobData.salary[1])}
        </div>
      </div>
      <FormSlider
        name="salary"
        validator={(_: any, salary: [number, number]): Promise<any> => {
          if (salary[1] > salary[0]) {
            return Promise.resolve();
          }
          return Promise.reject("Salary must be a range");
        }}
      />

      <RadioTags
        values={Object.values(FieldEnum)}
        selectedValue={addJobData.field}
        fieldName="field"
        fieldLabel="Job category"
      />

      <RadioTags
        values={Object.values(ExperienceEnum)}
        selectedValue={addJobData.experience}
        fieldName="experience"
        fieldLabel="Experience"
      />
      <RadioTags
        values={Object.values(JobTypeEnum)}
        selectedValue={addJobData.format}
        fieldName="format"
        fieldLabel="Job type"
      />

      <div className="flex flex-col gap-3 mb-10">
        <div className="text-white">Skills</div>
        <div className="flex gap-2 flex-wrap">
          <For items={allJobSkills} keyFunc={(skill) => skill}>
            {(skill: string) => {
              const isSelected = (addJobData.jobSkills ?? []).includes(skill);
              return (
                <div
                  className={clsx(
                    styles.hover,
                    "p-3 cursor-pointer rounded-sm capitalize text-[13px] border ",
                    isSelected
                      ? "bg-[#5362E9] text-white border-[#5362E9]"
                      : "border-grey text-grey "
                  )}
                  onClick={() => {
                    if (isSelected) {
                      setAddJobData({
                        ...addJobData,
                        jobSkills: addJobData.jobSkills.filter(
                          (v: any) => v !== skill
                        ),
                      });
                    } else {
                      setAddJobData({
                        ...addJobData,
                        jobSkills: [...addJobData.jobSkills, skill],
                      });
                    }
                  }}
                >
                  {skill}
                </div>
              );
            }}
          </For>
        </div>
        <div className="flex gap-3 items-center">
          <input
            placeholder="Add skill"
            name="newJobSkillInput"
            type="text"
            value={newJobSkillInput}
            onChange={(e) => setNewJobSkillInput(e.target.value)}
            className="h-full p-3 rounded-sm w-auto bg-transparent border border-grey text-grey"
          />
          <button
            className={clsx(
              "w-8 h-8 p-3 cursor-pointer rounded-full capitalize text-[13px] flex items-center justify-center ",
              "bg-[#5362E9] text-white ",
              styles.blackHover
            )}
            onClick={() => {
              const loweredInput = newJobSkillInput.toLowerCase();
              if (newJobSkillInput !== "") {
                if (!allJobSkills.includes(loweredInput)) {
                  setNewJobSkills([...newJobSkills, loweredInput]);
                }
                setNewJobSkillInput("");

                setAddJobData({
                  ...addJobData,
                  jobSkills: [...addJobData.jobSkills, loweredInput],
                });
              }
            }}
          >
            +
          </button>
        </div>
      </div>

      <Textarea
        name="description"
        label="Description"
        placeholder="Type the job description..."
      />

      <Textarea
        name="responsibilities"
        label="Responsibilities"
        placeholder="Type the job responsibilities..."
      />

      <Textarea
        name="requirements"
        label="Requirements"
        placeholder="Type the job requirements..."
      />

      <Textarea
        name="preferred"
        label="Preferred"
        placeholder="Type the job preferred..."
      />

      <Textarea
        name="benefits"
        label="Benefits"
        placeholder="Type the job benefits..."
      />

      <Button
        className={clsx(
          "auth__button-mobile margin-bottom-desktop",
          styles.opacityHover
        )}
        onClick={() => onContinue()}
        color="primary"
        htmlType="button"
        type="primary"
        shape="round"
        size="large"
      >
        NEXT
      </Button>
    </>
  );
};

export default Step1;
