import { FunctionComponent } from "react";

import { Form, Radio, Tag } from "antd";

type Props = {
  values: any[];
  selectedValue: any;
  fieldName: string;
  fieldLabel: string;
  required?: boolean;
};

const RadioTags: FunctionComponent<Props> = ({
  values,
  selectedValue,
  fieldName,
  fieldLabel,
  required = true,
}) => {
  return (
    <Form.Item
      name={fieldName}
      label={
        fieldLabel ? (
          <div>
            {fieldLabel}
            {required ? <sup>*</sup> : null}
          </div>
        ) : undefined
      }
      rules={[
        {
          required,
          message: `${fieldLabel} is missing`,
          validateTrigger: "onSubmit",
        },
      ]}
    >
      <Radio.Group className="ant-tag-group">
        {values.map((value) => {
          const isSelected = value === selectedValue;
          return (
            <Radio key={value} value={value}>
              <Tag
                className={`custom-ant-tag selectable-tag ${
                  isSelected ? "ant-mobile-tag-selected" : ""
                }`}
              >
                {value.replaceAll("_", " ")}
              </Tag>
            </Radio>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioTags;
