import { CSSProperties, ReactNode } from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "clsx";
import { Link } from "react-router-dom";

import "./style.scss";

type Props = {
  icon: typeof InfoCircleOutlined;
  children: ReactNode;
  link?: string;
  onClick?: Function;
  centered?: boolean;
  danger?: boolean;
  style?: CSSProperties;
};

const ListItem = ({
  icon,
  children,
  link,
  onClick,
  centered,
  danger,
  style,
}: Props): JSX.Element => {
  const Icon = icon;

  const handleClick = (args: any) => {
    if (onClick) {
      onClick(args);
    }
  };

  const child = (
    <div
      className={classNames("list-item-wrapper", {
        disabled: !(link || onClick),
        "list-item-wrapper-centered": centered,
        "list-item-wrapper-danger": danger,
      })}
      onClick={handleClick}
      style={style}
    >
      <Icon />
      {children}
    </div>
  );

  if (link) {
    return (
      <Link to={link} style={{ color: "#fff" }}>
        {child}
      </Link>
    );
  }

  return child;
};

export default ListItem;
