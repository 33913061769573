import { useEffect, useRef, useState } from "react";

import { Spin } from "antd";
import { AutoCenter, Empty } from "antd-mobile";
import dayjs from "dayjs";

import { GetNotificationsQuery } from "generated/graphql";

import Loading from "components/Loading";

import useNotifications from "hooks/useNotifications";

import NotificationItem from "./NotificationItem";
import "./style.scss";

const Notifications = (): JSX.Element => {
  const todayRef = useRef(null);
  const olderRef = useRef(null);
  const isDone = useRef(false);
  const pageNumber = useRef(2);
  const [fetchLoading, setFetchLoading] = useState(false);
  const resultsPerPage = 10;
  const {
    notifications: totalData,
    loading,
    refetch,
  } = useNotifications({
    subscribe: true,
    pageNumber: 1,
    resultsPerPage,
  });
  const notifications = totalData?.notifications;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState<
    GetNotificationsQuery["notifications"]["notifications"]
  >([]);

  const todayNotifications = data?.filter((notification) => {
    const date = new Date(notification.date);
    const now = new Date();
    const isSameYear = date.getFullYear() === now.getFullYear();
    const isSameMonth = date.getMonth() === now.getMonth();
    const isSameDate = date.getDate() === now.getDate();
    return isSameDate && isSameMonth && isSameYear;
  });
  const olderNotifications = data?.filter((notification) => {
    const date = new Date(notification.date);
    const now = new Date();
    const isSameYear = date.getFullYear() === now.getFullYear();
    const isSameMonth = date.getMonth() === now.getMonth();
    const isSameDate = date.getDate() === now.getDate();

    return !isSameDate || !isSameMonth || !isSameYear;
  });

  useEffect(() => {
    if (notifications && data?.length === 0 && pageNumber.current === 2)
      setData(notifications);
  }, [notifications]);

  const fetch = async () => {
    if (data)
      console.log(
        "data",
        data,
        "totaldata",
        totalData,
        "pagenumber",
        pageNumber,
        "isDone",
        isDone
      );

    if (isDone.current) return;

    try {
      setFetchLoading(true);
      const refetchData = await refetch({
        pageNumber: pageNumber.current,
        resultsPerPage,
      });

      if (
        refetchData?.data?.notifications?.notifications?.length &&
        refetchData?.data?.notifications?.notifications?.length !== 0
      ) {
        pageNumber.current++;
        setData((prev) => [
          ...(prev ? (prev as any) : []),
          ...(refetchData.data.notifications.notifications
            ? refetchData.data.notifications.notifications
            : []),
        ]);
        if (
          refetchData?.data?.notifications?.notifications?.length <
          resultsPerPage
        )
          isDone.current = true;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetchLoading(false);
    }
  };

  const observer = useRef(
    new IntersectionObserver(async (entries, observer) => {
      if (entries[0].isIntersecting) {
        await fetch();
      }

      // io.unobserve(entry.target)
    })
  );
  useEffect(() => {
    if (isDone.current) return;

    const currentObserver = observer.current;
    if (olderRef.current) currentObserver.observe(olderRef?.current);
    if (todayRef.current) currentObserver.observe(todayRef?.current);

    return () => {
      currentObserver && currentObserver.disconnect();
    };
  }, [olderRef?.current, todayRef?.current]);

  if (loading && pageNumber.current === 2) {
    return <Loading />;
  }

  return (
    <>
      {notifications?.length === 0 ? (
        <Empty
          style={{ height: "100%" }}
          description={
            <div>
              <AutoCenter>
                <h3>No new notifications</h3>
              </AutoCenter>
            </div>
          }
        />
      ) : (
        <main className="notifications__wrapper">
          {todayNotifications && todayNotifications.length > 0 ? (
            <>
              <div className="notification__date-title">Today</div>
              {todayNotifications?.filter(Boolean).map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  refetchNotifications={refetch}
                />
              ))}

              {fetchLoading ? (
                <div className="w-full h-[100px] flex justify-center items-center ">
                  <Spin size="large" />
                </div>
              ) : null}
            </>
          ) : null}
          <div className="w-full h-3" ref={todayRef}></div>

          {olderNotifications && olderNotifications.length > 0 ? (
            <>
              <div className="notification__date-title">Older</div>

              {olderNotifications?.filter(Boolean)?.map((notification) => (
                <div>
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    refetchNotifications={refetch}
                  />
                </div>
              ))}

              {fetchLoading ? (
                <div className="w-full h-[100px] flex justify-center items-center ">
                  <Spin size="large" />
                </div>
              ) : null}
            </>
          ) : null}
          <div className="w-full h-3" ref={olderRef}></div>
        </main>
      )}
    </>
  );
};

export default Notifications;
