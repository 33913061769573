type Props = {
  when: boolean | null | undefined;
  children: JSX.Element;
  fallback?: JSX.Element;
};

const Show = ({
  when = false,
  children,
  fallback,
}: Props): JSX.Element | null => {
  return when ? children : fallback ?? null;
};

export default Show;
