import { useState } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import dorseLogo from "images/dorse_logo.svg";

import useAuth from "components/Auth/useAuth";

import { sha256 } from "utils";

import styles from "./styles.module.scss";

const ResetPassword = (): JSX.Element => {
  const [pwView, setPwView] = useState(false);
  const [pwViewSecond, setPwViewSecond] = useState(false);
  const { regeneratePassword, error } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getValues,
    register,
    handleSubmit,

    formState: { errors, touchedFields },
  } = useForm<{ password: string; confirmPassword: string }>({
    mode: "all",
  });

  const { password, confirmPassword } = getValues();

  const onSubmit = async () => {
    try {
      if (password !== confirmPassword) {
        toast.error("Passwords do not match");
      } else if (password && confirmPassword) {
        const sha256Password = await sha256(password);
        await regeneratePassword(id!, sha256Password, sha256Password);
        navigate("/forgot/success");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full flex justify-center items-center h-full">
      <Link to="/landing">
        <img
          src={dorseLogo}
          alt=""
          className="absolute top-[58px] left-[calc(50%-62px)] lg:top-[70px] lg:left-[137px] cursor-pointer"
        />
      </Link>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full items-center max-w-[472px] font-sans px-[29px] pt-[92px] lg:pt-0px "
      >
        <h1 className="w-full font-sora font-bold text-[24px] xs:text-[27px] lg:text-[31px] leading-[37px] lg:leading-8 mb-1 lg:mb-3 tracking-[-0.4px] lg:tracking-0 ">
          Create new password
        </h1>

        <p className="text-[#DADADA] font-sans text-[14px] leading-[16px] lg:text-[16px] lg:leading-[19px] mb-[39px] lg:mb-[47px]">
          Your new password must be different from previous used passwords.
        </p>
        <div className="w-full mb-[35px]">
          <label
            htmlFor="password"
            className="font-sans text-[12px] leading-[14px] lg:text-[14px] lg:leading-[16px]"
          >
            Password
          </label>
          <div className="relative border-primary border-b h-[32px] mt-[1px]">
            <input
              id="password"
              type={pwView ? "text" : "password"}
              placeholder="Password"
              className="bg-transparent placeholder-opacity-50 placeholder-[#C4C4C4] text-[#C4C4C4] w-full h-4 mb-[9px]"
              {...register("password", { required: true })}
            />
            <button
              type="button"
              onClick={() => {
                setPwView((v) => !v);
              }}
            >
              <img
                className={clsx(
                  "absolute right-[16px] top-[50%] translate-y-[-50%]"
                )}
                src={!pwView ? "/icons/eye-crossed.svg" : "/icons/eye.svg"}
              />
            </button>
          </div>
        </div>
        <div className="w-full mb-[45px] lg:mb-[88px]">
          <label
            htmlFor="confirmPassword"
            className="font-sans text-[12px] leading-[14px] lg:text-[14px] lg:leading-[16px]"
          >
            Confirm Password
          </label>
          <div className="relative border-primary border-b h-[32px] mt-[1px]">
            <input
              id="confirmPassword"
              type={pwViewSecond ? "text" : "password"}
              placeholder="Confirm Password"
              className="bg-transparent placeholder-opacity-50 placeholder-[#C4C4C4] text-[#C4C4C4] w-full h-4 mb-[9px]"
              {...register("confirmPassword", { required: true })}
            />
            <button
              type="button"
              onClick={() => {
                setPwViewSecond((v) => !v);
              }}
            >
              <img
                className={clsx(
                  "absolute right-[16px] top-[50%] translate-y-[-50%]"
                )}
                src={
                  !pwViewSecond ? "/icons/eye-crossed.svg" : "/icons/eye.svg"
                }
                alt=""
              />
            </button>
          </div>
        </div>
        <button
          type="submit"
          className={clsx(
            "text-sans text-[16px] leading-[21px] font-medium text-white capitalize cursor-pointer w-full max-w-[327px] h-[54px] flex justify-center align-center bg-[#5362E9] items-center rounded-[40px] mb-[25px]  transition-all",
            styles.opacityHover
          )}
        >
          RESET PASSWORD
        </button>
        <Link
          className={clsx(
            "tracking-[ -0.173252px] font-sans text-[#D0C9D6] underline text-[14px] leading-[15px]",
            styles.underlineHover
          )}
          to="/signin"
        >
          Back to login
        </Link>
      </form>
    </div>
  );
};

export default ResetPassword;
