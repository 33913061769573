import React, { FunctionComponent } from "react";

export const PrivacyPolicy: FunctionComponent = () => {
  return (
    <p className="text-sm text-txtGrey font-sans leading-[16px]">
      Signing up for a Dorse account means you agree to the{" "}
      <a onClick={() => alert("todo")} className="inline underline">
        Privacy Policy
      </a>{" "}
      and{" "}
      <a onClick={() => alert("todo")} className="inline underline">
        Terms of Service
      </a>
      .
    </p>
  );
};
