import { useEffect, useRef } from "react";

import { ApolloQueryResult } from "@apollo/client";

import {
  Exact,
  GetNotificationCreatedDocument,
  GetNotificationsQuery,
  useGetNotificationsQuery,
} from "generated/graphql";

import useAuth from "components/Auth/useAuth";

// TODO : Figure out a way to make this into a singleton. Not ideal method atm
let subscribed = false;

interface Props {
  pageNumber: number;
  resultsPerPage: number;
  subscribe?: boolean;
}
const useNotifications = ({
  pageNumber,
  resultsPerPage,
  subscribe,
}: Props): {
  notifications?: GetNotificationsQuery["notifications"];
  loading: boolean;
  unread?: number;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            pageNumber: number;
            resultsPerPage: number;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetNotificationsQuery>>;
} => {
  const { token } = useAuth();
  const subRef = useRef<boolean>(false);
  const { data, loading, refetch, subscribeToMore } = useGetNotificationsQuery({
    fetchPolicy: "network-only",
    variables: {
      pageNumber,
      resultsPerPage,
    },
  });
  useEffect(() => {
    if (subscribe && !subscribed) {
      subscribed = true;
      subscribeToMore({
        document: GetNotificationCreatedDocument,
        variables: {
          jwtToken: token,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          // @ts-ignore
          const newNotification = subscriptionData.data.notificationCreated;
          const prevNotifications = prev.notifications.notifications || [];
          return Object.assign({}, prev, {
            notifications: [newNotification, ...prevNotifications],
          });
        },
      });
    }
  }, [subscribeToMore, subscribe, token]);

  const unread = data?.notifications.totalUnread;

  return { notifications: data?.notifications, loading, unread, refetch };
};

export default useNotifications;
