import { Card } from "antd";

import { CandidateProfile, GetApplicationQuery } from "generated/graphql";

import BlueTag from "components/BlueTag/BlueTag";
import CandidateTags from "components/CandidateProfile/CandidateTags";
import For from "components/common/For";
import Show from "components/common/Show";
import EndorsedBy from "components/EndorsedBy";

import { isDef } from "utils";
import { DeepExtractTypeSkipArrays } from "utils/type-utils";

type Props = {
  candidateProfile: CandidateProfile;
  reference?: DeepExtractTypeSkipArrays<
    GetApplicationQuery,
    ["application", "reference"]
  > | null;
};

const CandidateInformation = ({
  candidateProfile,
  reference,
}: Props): JSX.Element => {
  const { about, techSkills, softSkills } = candidateProfile;

  return (
    <div className="flex flex-col gap-5">
      <CandidateTags candidateProfile={candidateProfile} />
      {reference && (
        <>
          <EndorsedBy
            id={reference.referrer.id}
            name={reference.referrer.name}
          />

          <For items={reference.answers ?? []} keyFunc={(answer) => answer.id}>
            {(answer) => (
              <Card
                key={answer.id}
                title={answer.question.text}
                bordered={false}
              >
                {answer.text}
              </Card>
            )}
          </For>
        </>
      )}

      <Show when={softSkills && softSkills?.length > 0}>
        <div className="flex gap-3 items-center">
          <span className="font-sora text-sm leading-4 text-[#D0C9D6]">
            Soft skills
          </span>
          <For items={softSkills ?? []} keyFunc={(skill) => skill}>
            {(skill) => <BlueTag tag={skill} />}
          </For>
        </div>
      </Show>

      <Show when={techSkills && techSkills?.length > 0}>
        <div className="flex gap-3 items-center">
          <span className="font-sora text-sm leading-4 text-[#D0C9D6]">
            Tech skills
          </span>

          <For items={techSkills ?? []} keyFunc={(skill) => skill}>
            {(skill) => <BlueTag tag={skill} key={skill} />}
          </For>
        </div>
      </Show>

      <Show when={isDef(about)}>
        <div className="rounded-[5px] bg-[#343141] p-6 flex flex-col gap-3">
          <div className="font-sora text-sm font-normal leading-4 text-[#D0C9D6]">
            About me:
          </div>
          <div className="font-mono text-sm font-normal leading-[18px] text-white">
            {about}
          </div>
        </div>
      </Show>
    </div>
  );
};

export default CandidateInformation;
