import clsx from "clsx";
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
} from "react-hook-form";

import { emailRegex, linkedinRegex } from "utils";

interface FormProp {
  name: string;
  email: string;
  linkedinUrl?: string;
  githubUrl?: string;
  relationship: string;
  stand_out: string;
  incognitoMode: boolean;
}
interface InputProps {
  title:
    | "name"
    | "email"
    | "linkedinUrl"
    | "githubUrl"
    | "relationship"
    | "stand_out"
    | "incognitoMode";
  className?: string;
  required?: boolean;
  register: UseFormRegister<FormProp>;
  isError?: boolean;
  regex?: RegExp;
}

export const Input = ({
  title,
  className,
  required = true,
  register,
  isError = false,
  regex,
}: InputProps) => {
  return (
    <div
      className={clsx(
        "flex flex-col justify-center  w-full h-[51px] bg-[#4A465B] rounded-[4px] px-4 py-2",
        className
      )}
    >
      <span
        className={clsx("uppercase font-mono text-[12px] leading-4 ", {
          "text-[red]": isError,
          "text-[#ADA7C1]": !isError,
        })}
      >
        {title}
        {required ? "*" : null}
      </span>
      <input
        {...register(title, {
          required: true,
          pattern: regex,
        })}
        name={title}
        className="bg-transparent font-mono leading-[22px] text-[14px] h-full text-white"
      ></input>
    </div>
  );
};

export const InputMultiline = ({
  title,
  className,
  required = true,
  register,
  isError,
}: InputProps) => {
  return (
    <div
      className={clsx(
        "flex flex-col justify-center  w-full h-[171px] bg-[#4A465B] rounded-[4px] px-4 py-2",
        className
      )}
    >
      <span
        className={clsx("uppercase font-mono text-[12px] leading-4  ", {
          "text-[red]": isError,
          "text-[#ADA7C1]": !isError,
        })}
      >
        {title}
        {required ? "*" : null}
      </span>

      <textarea
        {...register(title, { required: true })}
        className="outline-0  bg-transparent font-mono leading-[22px] text-[14px] h-full text-white"
      ></textarea>
    </div>
  );
};
