import * as React from "react";
import { FunctionComponent, MutableRefObject } from "react";

//  https://stackoverflow.com/questions/58341787/intersectionobserver-with-react-hooks
export const RunWhenOnScreen: FunctionComponent<{
  observingRef: MutableRefObject<HTMLElement | undefined>;
}> = ({ children, observingRef }) => {
  const [isOnScreen, setIsOnScreen] = React.useState(false);
  const observerRef = React.useRef<IntersectionObserver>();

  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  React.useEffect(() => {
    if (!observerRef.current || !observingRef?.current) return;
    observerRef.current.observe(observingRef.current);

    return () => {
      (observerRef.current as IntersectionObserver).disconnect();
    };
  }, [observingRef]);
  return <>{isOnScreen && children}</>;
};
