import { useParams } from "react-router-dom";

import "./style.scss";

const Apply = (): JSX.Element => {
  const { jobAdId } = useParams();

  return <div>apply to {jobAdId}</div>;
};

export default Apply;
