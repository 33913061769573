import { ReactNode } from "react";

import { Input as AntInput, Form } from "antd";
import { NamePath } from "rc-field-form/es/interface";

import "./style.scss";

interface Props {
  name: NamePath;
  label?: ReactNode;
  required?: boolean;
  requiredMessage?: string;
  hideSup?: boolean;
  placeholder?: string;
}

const FormTextArea = ({
  name,
  label,
  required = true,
  requiredMessage,
  hideSup,
  placeholder,
}: Props): JSX.Element => {
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <div style={{ color: "#fff" }}>
            {label}
            {required && !hideSup ? <sup>*</sup> : null}
          </div>
        ) : undefined
      }
      prefixCls="custom-form-textarea"
      rules={[
        {
          required,
          message: requiredMessage || `${label ? label : "Field"} is missing`,
          validateTrigger: "onSubmit",
        },
      ]}
    >
      <AntInput.TextArea
        placeholder={placeholder}
        className="custom-input-textarea"
        autoSize={{ minRows: 4 }}
      />
    </Form.Item>
  );
};

export default FormTextArea;
