import React, {
  Dispatch,
  Fragment,
  FunctionComponent,
  SetStateAction,
} from "react";

import { Menu, Transition } from "@headlessui/react";
import cn from "clsx";
import clsx from "clsx";
import { useForm, UseFormHandleSubmit } from "react-hook-form";

import { BlockchainEnum } from "generated/graphql";

import { BLOCKCHAIN_TOKENS, ONBOARDING_BASE } from "app-constants";

import {
  OnboardingBasic,
  OnboardingInputs,
  OnboardingStepProps,
} from "components/onboarding/types";

import { linkedinRegex } from "utils";

import { OnboardingWrapper } from "./OnboardingWrapper";
import styles from "./styles.module.scss";

export const inputClass =
  "bg-transparent font-mono placeholder-gray-500 mt-[1px] w-full h-[22px]";

/**
 * TODO : blockchain address validation
 * TODO : github validation
 */
export const BasicInfo: FunctionComponent<OnboardingStepProps> = ({
  decimalProgress,
  setState,
  state,
  setStep,
}) => {
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [blockchain, setBlockchain] = React.useState<BlockchainEnum>(
    state?.basic?.blockchain
  );
  const {
    getValues: getFormValues,
    register,
    formState: { errors, touchedFields },
    handleSubmit,
  } = useForm<OnboardingBasic>({
    mode: "all",
  });

  const getValues = () => {
    const values = getFormValues();
    values.blockchain = blockchain;
    return values;
  };

  // const ready =
  //   (touchedFields.name || state.basic.name !== "") &&
  //   (touchedFields.linkedin || state.basic.linkedin !== "") &&
  //   !Object.keys(errors).length;

  const handleOnchange = () => {
    window?.localStorage?.setItem(ONBOARDING_BASE, JSON.stringify(getValues()));
  };
  return (
    <OnboardingWrapper
      decimalProgress={decimalProgress}
      title="Hi Dorser! Before you start endorsing, tell us about yourself"
    >
      <div className="mt-[51px] w-full flex flex-col gap-4">
        <OnboardingInput
          errored={!!errors.name || (submitAttempted && !getValues().name)}
          errorMessage="Full name is required"
          label="FULL NAME"
          mandatory
        >
          <input
            type="text"
            placeholder={"Awesome Dorser Magnificent aka LFG"}
            className={inputClass}
            defaultValue={state.basic.name}
            {...register("name", {
              onChange: handleOnchange,
              required: true,
            })}
          />
        </OnboardingInput>
        <OnboardingInput label="LOCATION(For better job suggestions)">
          <input
            type="text"
            placeholder={"Omicron Persei"}
            className={inputClass}
            defaultValue={state.basic.location}
            {...register("location", {
              onChange: handleOnchange,
            })}
          />
        </OnboardingInput>
        <OnboardingInput
          errored={
            !!errors.linkedin || (submitAttempted && !getValues().linkedin)
          }
          errorMessage={
            errors?.linkedin?.type === "required"
              ? "Linkedin url is required"
              : "Not a valid linkedin url ex) linkedin.com/in/dorser"
          }
          label="LINKEDIN (URL)"
          mandatory
        >
          <input
            type="text"
            placeholder={"https://www.linkedin.com/in/dorser"}
            className={inputClass}
            defaultValue={state.basic.linkedin}
            {...register("linkedin", {
              required: true,
              pattern: linkedinRegex,
              onChange: handleOnchange,
            })}
          />
        </OnboardingInput>
        <OnboardingInput label="GITHUB">
          <input
            type="text"
            placeholder={"Github ID"}
            defaultValue={state.basic.github}
            className={inputClass}
            {...register("github", {
              onChange: handleOnchange,
            })}
          />
        </OnboardingInput>
        <OnboardingInput label={"BLOCKCHAIN PUBLIC ADDRESS(For rewards)"}>
          <div className="flex items-center flex-grow gap-2">
            <BlockchainMenu
              blockchain={blockchain}
              setBlockchain={setBlockchain}
            />
            <input
              type="text"
              placeholder={placeholderMap[blockchain]}
              className={inputClass}
              defaultValue={state.basic.blockchainAddress}
              {...register("blockchainAddress", {
                onChange: handleOnchange,
              })}
            />
          </div>
        </OnboardingInput>
        <div
          className={cn("mt-[125px] flex items-center justify-center w-full")}
          onClick={() => setSubmitAttempted(true)}
        >
          <NextButton
            next={() => setStep((v) => v + 1)}
            handleSubmit={handleSubmit}
            setState={setState}
          />
        </div>
      </div>
    </OnboardingWrapper>
  );
};

const BlockchainMenu: FunctionComponent<{
  blockchain: BlockchainEnum;
  setBlockchain: Dispatch<SetStateAction<BlockchainEnum>>;
}> = ({ blockchain, setBlockchain }) => {
  return (
    <div className="relative">
      <Menu>
        <Menu.Button
          className={
            (clsx("relative  text-sm px-[8.5px] border rounded-sm"),
            styles.hover)
          }
        >
          <p className=" transition-colors duration-200">
            {BLOCKCHAIN_TOKENS[blockchain]}
          </p>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute top-[2px] left-[50%] translate-x-[-50%] flex flex-col items-center bg-[#4A465B] border border-opacity-50 border shadow-container rounded-sm">
            {Object.values(BlockchainEnum).map((blockchain) => (
              <Menu.Item key={blockchain}>
                <button
                  onClick={() => setBlockchain(blockchain)}
                  className={
                    (clsx(
                      "px-2 last:border-0 border-b text-sm  transition-colors duration-200"
                    ),
                    styles.secondaryHover)
                  }
                >
                  {BLOCKCHAIN_TOKENS[blockchain]}
                </button>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const NextButton: FunctionComponent<{
  next: () => void;
  handleSubmit: UseFormHandleSubmit<OnboardingBasic>;
  setState: Dispatch<SetStateAction<OnboardingInputs>>;
}> = ({ setState, handleSubmit, next }) => {
  const onSubmit = (e: React.BaseSyntheticEvent) => {
    handleSubmit(
      (values) => {
        setState((prevState) => ({
          ...prevState,
          basic: {
            ...prevState.basic,
            ...values,
          },
        }));
        next();
      },
      (e) => console.log("errors", e)
    )(e);
  };
  return (
    <button
      onClick={onSubmit}
      className={clsx(
        "w-[327px] h-[54px] bg-primary rounded-full flex items-center justify-center",
        styles.opacityHover
      )}
    >
      <p className="font-mono font-[500] text-base">CONTINUE</p>
    </button>
  );
};

export const OnboardingInput: FunctionComponent<{
  mandatory?: boolean;
  label: string;
  errored?: boolean;
  errorMessage?: string;
}> = ({
  errored = false,
  errorMessage = "",
  label,
  mandatory = false,
  children,
}) => {
  return (
    <div
      className={cn(
        "transition-all duration-200 max-h-[92px] relative w-full pt-[7px] pb-[6px] px-4 rounded-[4px] bg-[#4A465B] flex flex-col items-start",
        errored ? "pb-6" : ""
      )}
    >
      <label
        className={cn(
          "h-4 font-mono text-xs leading-[16px]",
          errored ? "text-[#E63D70]" : "text-[#ADA7C1]"
        )}
      >
        {label}
        {mandatory && "*"}
      </label>
      {children}
      <p
        className={cn(
          "absolute bottom-1 left-4 font-mono transition-all overflow-hidden text-xs text-[#E63D70]",
          errored ? "max-h-[20px]" : "max-h-0"
        )}
      >
        {errorMessage}
      </p>
    </div>
  );
};

const placeholderMap = {
  [BlockchainEnum.Bitcoin]: "bc1...",
  [BlockchainEnum.Solana]: "sol...",
  [BlockchainEnum.Ethereum]: "0x...",
};
