import classNames from "clsx";
import { useNavigate } from "react-router-dom";

import Button from "components/Button";

import styles from "./styles.module.scss";

const NoJobsAvailable = () => {
  const navigate = useNavigate();
  return (
    <div className="grid gap-5 w-full h-full place-content-center justify-items-center">
      <span className={styles.firstJobEmptyTitle}>
        Oh crap, you’ve got nothing
      </span>
      <div className={classNames("grid gap-1", styles.firstJobEmptySubtitle)}>
        <span>Our platform looks like a ghost city,</span>
        <span>please do something</span>
      </div>
      <div className="w-4/6">
        <Button
          label="ADD A JOB POST"
          onClick={() => {
            navigate("/add");
          }}
        />
      </div>
    </div>
  );
};

export default NoJobsAvailable;
