import { useMemo, useState } from "react";

import Fuse from "fuse.js";

import { EndorsementType } from "pages/MyEndorsements";

export const useFilterEndorsements = (endorsements: EndorsementType[]) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "candidate.name", weight: 4 },
        { name: "candidate.profile.location", weight: 2 },
        { name: "reference.referrer.name", weight: 2 },
        { name: "status", weight: 2 },
        { name: "jobAd.title", weight: 2 },
        { name: "jobAd.company.name", weight: 2 },
      ],
    };

    return new Fuse(endorsements, options);
  }, [endorsements]);

  const filteredEndorsements = useMemo(() => {
    if (filterText.length < 2) {
      return endorsements;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, endorsements, fuse]);

  return {
    filterText,
    setFilterText,
    filteredEndorsements,
  };
};
