import useWindowSize from "hooks/useWindowSize";
import { useParams } from "react-router-dom";

import Preview from "pages/Profile/Preview";
import UserProfile from "pages/Profile/UserProfile";

import useAuth from "components/Auth/useAuth";
import DesktopLayout from "components/DesktopLayout";

const Profile = (): JSX.Element => {
  const { userProfile } = useAuth();
  const { profileID } = useParams();

  const { isMobile } = useWindowSize();

  // if (!profileID) {
  //   navigate("/profile/preview");
  // }

  const renderProfile = () => {
    if (!profileID || userProfile?.id === profileID) {
      return <Preview />;
    }

    return <UserProfile profileID={profileID!} />;
  };

  if (isMobile) {
    return renderProfile();
  }

  return <DesktopLayout mainColumn={renderProfile()} />;
};

export default Profile;
