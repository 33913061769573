import { RoleEnum } from "generated/graphql";

import JobsForCompanyMobile from "pages/JobPosts/Mobile/JobsForCompany";
import JobsForInternalRecruiterMobile from "pages/JobPosts/Mobile/JobsForInternalRecruiter";

import useAuth from "components/Auth/useAuth";

const JobPostsMobile = (): JSX.Element | null => {
  const { user } = useAuth();

  if (user?.activeRole === RoleEnum.CompanyUser) {
    return <JobsForCompanyMobile />;
  }

  if (user?.activeRole === RoleEnum.InternalRecruiter) {
    return <JobsForInternalRecruiterMobile />;
  }

  return null;
};

export default JobPostsMobile;
