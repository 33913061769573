import React, { FunctionComponent } from "react";

import { SignupHeader } from "pages/Authentication/SignUpPage";

import { Progressbar } from "components/common/Progressbar";

export const OnboardingWrapper: FunctionComponent<{
  decimalProgress: number;
  title: string;
}> = ({ children, decimalProgress, title }) => {
  return (
    <>
      <SignupHeader />
      <div className="grid grid-cols-[1fr,472px,1fr]">
        <div />
        <section className="mt-[147px]">
          <div className="w-full mb-[58px] relative">
            <Progressbar width="100%" decimalProgress={decimalProgress} />
          </div>
          <h2 className="font-sora font-[700] text-[26px] text-center leading-tight">
            {title}
          </h2>
          {children}
        </section>
        <div />
      </div>
    </>
  );
};
