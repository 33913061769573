import { Avatar } from "antd";
import { Card } from "antd-mobile";
import dayjs from "dayjs";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import useAuth from "../Auth/useAuth";
import "./style.scss";

type Props = {
  id: string;
  userId: string;
  name: string;
  photoUrl?: string | null;
  date: string;
  text: string;
};

const Note = ({
  id,
  userId,
  photoUrl,
  date,
  text,
  name,
}: Props): JSX.Element => {
  const { user } = useAuth();
  const day = dayjs(date);

  return (
    <div className="message-card-wrapper" id={`note-${id}`}>
      <Avatar
        className="message-avatar"
        src={photoUrl || DEFAULT_USER_PHOTO_URL}
        size={60}
      />
      <Card
        className={`message-card ${
          userId === user?.id ||
          userId === "804f75b9-d0ce-40bb-aa6e-a088e2929263"
            ? "message-own-card"
            : ""
        }`}
      >
        <div className="message-name">{name}</div>
        <div className="message-text">{text}</div>
        <div className="message-footer">
          <div>{day.format("MMM D")}</div>
          <div>{day.format("h:mm A")}</div>
        </div>
      </Card>
    </div>
  );
};

export default Note;
