import { FC, useCallback, useMemo, useRef, useState } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import "@solana/wallet-adapter-ant-design/styles.css";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider } from "@solana/wallet-adapter-react";
import "@solana/wallet-adapter-react-ui/styles.css";
import { clusterApiUrl } from "@solana/web3.js";
import { InputNumber, message } from "antd";
import { Button } from "antd-mobile";
import { FloatingPanelRef } from "antd-mobile/es/components/floating-panel";

import { RoleEnum, StakerProfile, WalletInfo } from "generated/graphql";

import { BLOCKCHAIN_ICONS, BLOCKCHAIN_TOKENS } from "app-constants";

import useAuth from "components/Auth/useAuth";
import FloatingPanel from "components/FloatingPanel";
import NavBar from "components/NavBar";

import usePhantomWallet from "hooks/usePhantomWallet";

import "./style.scss";

const StakeWrapper: FC = () => {
  const { userProfile } = useAuth();
  const network = WalletAdapterNetwork.Testnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  if (!userProfile || userProfile.role !== RoleEnum.Staker) {
    return null;
  }

  return (
    <ConnectionProvider endpoint={endpoint}>
      <Stake />
    </ConnectionProvider>
  );
};

function Stake(): JSX.Element {
  const { userProfile } = useAuth();
  const [stakeValue, setStakeValue] = useState<number>(0);
  const ref = useRef<FloatingPanelRef>(null);

  const stakerProfile = userProfile as StakerProfile & {
    walletsInfo: Array<WalletInfo>;
  }; // as StakerProfile;
  const walletInfo = stakerProfile?.walletsInfo?.[0];
  const blockchainToken = BLOCKCHAIN_TOKENS[walletInfo.blockchain];
  const iconSrc = BLOCKCHAIN_ICONS[walletInfo.blockchain];

  const { provider, triggerGetProvider, balance } = usePhantomWallet();

  const setRef = useCallback((node) => {
    // @ts-ignore
    ref.current = node;
  }, []);

  const open = useCallback(async () => {
    try {
      const res = await triggerGetProvider();
      if (res) {
        ref.current?.setHeight(window.innerHeight);
      }
    } catch (err) {
      if (err) {
        message.error("asd");
      }
    }
  }, [triggerGetProvider]);

  const close = useCallback(() => {
    ref.current?.setHeight(0);
  }, []);

  const handleInfo = () => {
    // TODO
  };

  const onStake = async () => {
    if (!provider) {
      message.warning("Please install the Phantom Browser extension");
    } else {
    }
  };

  const anchors = [0, window.innerHeight];

  const loading = false; // TODO: change when staking implemented

  // lamports to SOL
  const solBalance = balance ? balance / 1_000_000_000 : 0;

  return (
    <>
      <Button color="warning" block onClick={open}>
        Stake
      </Button>

      <FloatingPanel ref={setRef} anchors={anchors} className="stake__wrapper">
        <NavBar
          title="Stake"
          onBack={close}
          right={<QuestionCircleOutlined onClick={handleInfo} />}
        />
        <div className="stake__body">
          <div className="">
            <h4>Account balance</h4>
            <div className="stake__balance">
              <div>{balance ? solBalance : "-"}</div>
              SOL
            </div>

            <div className="stake__input-wrapper">
              <div className="stake__input-column">
                <h5>Total stake</h5>
                <InputNumber
                  className="stake__input"
                  value={stakeValue}
                  onChange={setStakeValue}
                  bordered={false}
                  min={0}
                  max={solBalance}
                  parser={(value) => Number(value)}
                />
              </div>
              <div className="stake__currency-column">
                {/*<h5>Balance: {solBalance - stakeValue}</h5>*/}
                <h5> </h5>
                <div className="stake__currency">
                  <img src={iconSrc} alt={blockchainToken} width={24} />
                  {blockchainToken}
                </div>
              </div>
            </div>

            <Button
              color="primary"
              block
              size="large"
              onClick={onStake}
              loading={loading}
              disabled={loading}
              className="stake__apply-button"
            >
              STAKE
            </Button>
          </div>
        </div>
      </FloatingPanel>
    </>
  );
}

export default StakeWrapper;
