interface Props {
  line: string;
  limit: number;
}
const cutAndEllipsis = ({ line, limit }: Props) => {
  if (line.length > limit) return line.substring(0, limit) + "...";
  return line;
};

export default cutAndEllipsis;
