import { useState } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Alert, Card, Form, FormInstance, Tooltip } from "antd";
import { Button } from "antd";
import { ButtonSize, ButtonType } from "antd/es/button";
import clsx from "clsx";
import ReactMarkdown from "react-markdown";

import {
  ExperienceEnum,
  FieldEnum,
  JobAd,
  JobTypeEnum,
  ReferenceQuestionnaire,
  useGetJobSkillsQuery,
} from "generated/graphql";

import deleteIcon from "images/icons/delete.svg";

import For from "components/common/For";
import FormInput from "components/Form/Input";
import FormRadio from "components/Form/Radio";
import FormSlider from "components/Form/Slider";
import FormTextArea from "components/Form/Textarea";

import useWindowSize from "hooks/useWindowSize";

import { formatNumber } from "utils";

import styles from "./EditJob.module.scss";
import "./style.scss";

export const getDefaultEditJob = (
  jobAd: Omit<JobAd, "numberOfApplications" | "createdBy">
) => ({
  benefits: jobAd.benefits,
  companyId: jobAd.company.id,
  description: jobAd.description,
  experience: jobAd.experience,
  field: jobAd.field,
  format: jobAd.format,
  location: jobAd.location,
  maxSalary: jobAd.maxSalary,
  minSalary: jobAd.minSalary,
  preferred: jobAd.preferred,
  referenceQuestions: jobAd.referenceQuestions,
  requirements: jobAd.requirements,
  responsibilities: jobAd.responsibilities,
  title: jobAd.title,
  currency: "$",
  editable: true,
  salary: [jobAd.minSalary, jobAd.maxSalary],
  isRemote: jobAd.isRemote,
  jobSkills: jobAd.jobSkills ?? [],
});

type Props = {
  jobAd: Omit<JobAd, "numberOfApplications" | "createdBy">;
  form: FormInstance<any>;
  isEditing: boolean;
  saveEditing: (jobAd: ReturnType<typeof getDefaultEditJob>) => void;

  cancelEditing: VoidFunction;
};

const EditJob = ({
  jobAd,
  form,
  isEditing,
  saveEditing,
  cancelEditing,
}: Props): JSX.Element => {
  const defaultEditJob = getDefaultEditJob(jobAd);
  const { isMobile } = useWindowSize();

  const [newJobSkillInput, setNewJobSkillInput] = useState("");
  const [newJobSkills, setNewJobSkills] = useState<string[]>([]);

  const { data } = useGetJobSkillsQuery({
    fetchPolicy: "cache-and-network",
  });
  const skills = data?.getListOfJobSkills ?? [];

  const allJobSkills = [...skills, ...newJobSkills];

  const [editJobValues, setEditJobValues] = useState(defaultEditJob);

  const onValuesChange = (changed: any) => {
    const { salary, referenceQuestions, ...others } = changed;

    const newReferenceQuestions = editJobValues.referenceQuestions.map(
      ({ __typename, ...question }: ReferenceQuestionnaire) => question
    );
    if (referenceQuestions) {
      for (let i = 0; i < changed.referenceQuestions.length; i++) {
        const question = changed.referenceQuestions[i];
        if (question) {
          newReferenceQuestions[i] = {
            ...newReferenceQuestions[i],
            text: question.text,
          };
        }
      }
    }

    setEditJobValues({
      ...editJobValues,
      ...others,
      referenceQuestions: newReferenceQuestions,
      ...(salary && {
        salary: [...salary],
        minSalary: salary[0],
        maxSalary: salary[1],
      }),
    });
  };

  const onAddReferenceQuestion = () => {
    setEditJobValues({
      ...editJobValues,
      referenceQuestions: [
        ...editJobValues.referenceQuestions,
        { id: Math.random().toString(), text: "" },
      ],
    });
  };

  const onRemoveReferenceQuestion = (id: string) => {
    setEditJobValues({
      ...editJobValues,
      referenceQuestions: editJobValues.referenceQuestions.filter(
        (question: ReferenceQuestionnaire) => question.id !== id
      ),
    });
  };

  const renderButton = () => {
    const commonProps: { type: ButtonType; size: ButtonSize } = {
      type: "primary",
      size: "large",
    };

    if (isEditing) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: 8,
          }}
        >
          {!isMobile ? (
            <Button
              {...commonProps}
              className={styles.opacityHover}
              onClick={() => {
                cancelEditing();
              }}
              danger
              shape="round"
            >
              CANCEL
            </Button>
          ) : undefined}
          <Button
            {...commonProps}
            className={styles.opacityHover}
            onClick={() => {
              saveEditing(editJobValues);
            }}
            shape="round"
          >
            SAVE
          </Button>
        </div>
      );
    }
    return (
      <Button {...commonProps} danger onClick={() => alert("delete job")}>
        DELETE JOB
      </Button>
    );
  };

  return (
    <Form
      form={form}
      initialValues={{
        ...editJobValues,
        salary: [jobAd.minSalary, jobAd.maxSalary],
      }}
      onValuesChange={onValuesChange}
    >
      <FormInput name="title" label="Job title" />
      <FormInput name="location" label="Location" />

      <FormRadio
        name="field"
        label="Job category"
        values={Object.values(FieldEnum)}
        selectedValue={editJobValues.field}
      />

      <FormRadio
        name="format"
        label="Job type"
        values={Object.values(JobTypeEnum)}
        selectedValue={editJobValues.format}
      />

      <FormRadio
        name="experience"
        label="Experience"
        values={Object.values(ExperienceEnum)}
        selectedValue={editJobValues.experience}
      />

      <div className="flex flex-col gap-3 mb-10">
        <div className="text-white">Skills</div>
        <div className="flex gap-2 flex-wrap">
          <For items={allJobSkills} keyFunc={(skill) => skill}>
            {(skill: string) => {
              const isSelected = (editJobValues.jobSkills ?? []).includes(
                skill
              );
              return (
                <div
                  className={clsx(
                    styles.hover,
                    "p-3 cursor-pointer rounded-sm capitalize text-[13px] border ",
                    isSelected
                      ? "bg-[#5362E9] text-white border-[#5362E9]"
                      : "border-grey text-grey "
                  )}
                  onClick={() => {
                    if (isSelected) {
                      setEditJobValues({
                        ...editJobValues,
                        jobSkills: editJobValues.jobSkills.filter(
                          (v: any) => v !== skill
                        ),
                      });
                    } else {
                      setEditJobValues({
                        ...editJobValues,
                        jobSkills: [...editJobValues.jobSkills, skill],
                      });
                    }
                  }}
                >
                  {skill}
                </div>
              );
            }}
          </For>
        </div>
        <div className="flex gap-3 items-center">
          <input
            placeholder="Add skill"
            name="newJobSkillInput"
            type="text"
            value={newJobSkillInput}
            onChange={(e) => setNewJobSkillInput(e.target.value)}
            className="h-full p-3 rounded-sm w-auto bg-transparent border border-grey text-grey"
          />
          <button
            className={clsx(
              styles.blackHover,
              "w-8 h-8 p-3 cursor-pointer rounded-full capitalize text-[13px] flex items-center justify-center ",
              "bg-[#5362E9] text-white "
            )}
            onClick={() => {
              const loweredInput = newJobSkillInput.toLowerCase();
              if (newJobSkillInput !== "") {
                if (!allJobSkills.includes(loweredInput)) {
                  setNewJobSkills([...newJobSkills, loweredInput]);
                }
                setNewJobSkillInput("");

                setEditJobValues({
                  ...editJobValues,
                  jobSkills: [...editJobValues.jobSkills, loweredInput],
                });
              }
            }}
          >
            +
          </button>
        </div>
      </div>

      <div className="custom-form-label-wrapper">
        <h4>Salary range</h4>
        <div>
          ${formatNumber(editJobValues.minSalary)} -{" $"}
          {formatNumber(editJobValues.maxSalary)}
        </div>
      </div>

      <FormSlider
        name="salary"
        validator={(_: any, salary: [number, number]): Promise<any> => {
          if (salary[1] > salary[0]) {
            return Promise.resolve();
          }
          return Promise.reject("Salary must be a range");
        }}
      />

      {[
        "Description",
        "Responsibilities",
        "Requirements",
        "Preferred",
        "Benefits",
      ].map((label) => (
        <FormTextArea
          key={label}
          name={label.toLowerCase()}
          label={label}
          requiredMessage={`${label} is missing`}
          placeholder={`Type the job ${label.toLowerCase()}...`}
        />
      ))}

      <h3>Reference questionnaire</h3>
      {!jobAd.editable && (
        <Alert
          message="Can no longer edit questions"
          type="warning"
          action={
            <Tooltip
              placement="bottomRight"
              title="This questionnaire has already been answered by some user"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          }
        />
      )}

      {editJobValues.referenceQuestions.map(
        (question: ReferenceQuestionnaire, idx: number) => (
          <div key={question.id}>
            {jobAd.editable ? (
              <FormTextArea
                name={["referenceQuestions", idx, "text"]}
                label={
                  <div className={"add-job__question-label"}>
                    <div>
                      {`Question ${idx + 1}`}
                      <sup>*</sup>
                    </div>
                    <img
                      src={deleteIcon}
                      alt="Delete"
                      className="add-job__delete-question-icon"
                      onClick={() => onRemoveReferenceQuestion(question.id)}
                    />
                  </div>
                }
                requiredMessage={`Text is missing for question ${idx + 1}`}
                hideSup
              />
            ) : (
              <Card
                className="job__job-information-card"
                title={`Question ${idx + 1}`}
                bordered={false}
              >
                <ReactMarkdown className="markdown">
                  {question.text}
                </ReactMarkdown>
              </Card>
            )}
          </div>
        )
      )}

      {jobAd.editable ? (
        <Button
          className="mb-4"
          onClick={onAddReferenceQuestion}
          type="primary"
          shape="round"
        >
          ADD QUESTION
        </Button>
      ) : null}

      {renderButton()}
    </Form>
  );
};

export default EditJob;
