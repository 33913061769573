import { FunctionComponent, useEffect, useState } from "react";

import { ApolloError } from "@apollo/client";
import { useParams } from "react-router-dom";

import {
  CandidateProfile,
  RoleEnum,
  useGetReferenceForInternalRecruiterLazyQuery,
  useGetReferralLazyQuery,
} from "generated/graphql";

import Referee from "pages/Referral/Referee";
import ReferenceForInternalRecruiter from "pages/Referral/ReferenceForInternalRecruiter";
import Referrer from "pages/Referral/Referrer";

import useAuth from "components/Auth/useAuth";
import Loading from "components/Loading";

type Props = {
  referralId?: string;
  rootPage?: string;
};

const Referral: FunctionComponent<Props> = ({ referralId, rootPage }) => {
  const { user, userProfile } = useAuth();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError | null>(null);
  const [reference, setReference] = useState<any>(null);

  const _referralId = referralId || params.referralId!;

  const [getReferenceForCandidate] = useGetReferralLazyQuery({
    variables: { referenceId: _referralId },
  });

  const [getReferenceForInternalRecruiter] =
    useGetReferenceForInternalRecruiterLazyQuery({
      variables: { referenceId: _referralId },
    });

  useEffect(() => {
    setLoading(true);
    if (user?.activeRole === RoleEnum.Candidate) {
      getReferenceForCandidate()
        .then(({ data, error }) => {
          if (data) {
            setReference(data.referenceForCandidate);
          }
          if (error) {
            setError(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (user?.activeRole === RoleEnum.InternalRecruiter) {
      getReferenceForInternalRecruiter()
        .then(({ data, error }) => {
          if (data) {
            setReference(data.referenceForRecruiter);
          }
          if (error) {
            setError(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    getReferenceForCandidate,
    getReferenceForInternalRecruiter,
    user?.activeRole,
    _referralId,
  ]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>ERROR: {error.message}</div>;
  }

  if (!reference) {
    return <div>No data: FIX</div>;
  }

  const {
    reference: { id, referrer, answers },
    candidateName,
    linkedinUrl,
    jobAd,
    referee,
    status,
  } = reference;

  const refereeProfile = (referee ?? {
    name: candidateName,
    linkedin: linkedinUrl,
  }) as CandidateProfile;

  if (referrer?.id === userProfile?.id) {
    return (
      <Referrer
        jobAd={jobAd}
        referee={refereeProfile}
        status={status}
        answers={answers}
        referenceId={id}
        rootPage={rootPage}
      />
    );
  }

  if (referee?.id === userProfile?.id) {
    return (
      <Referee
        jobAd={jobAd}
        referrer={referrer as CandidateProfile}
        answers={answers}
        referenceId={id}
        status={status}
        rootPage={rootPage}
      />
    );
  }

  return (
    <ReferenceForInternalRecruiter
      jobAd={jobAd}
      referrer={referrer as CandidateProfile}
      referee={refereeProfile}
      answers={answers}
      referenceId={id}
      status={status}
      rootPage={rootPage}
    />
  );
};

export default Referral;
