import { Suspense } from "react";

import classNames from "clsx";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";

import { RoleEnum } from "generated/graphql";

import AddJob from "pages/AddJob";
import Application from "pages/Application";
import JobPostApplication from "pages/Application/JobPostApplication";
import Apply from "pages/Apply";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import { Onboarding } from "pages/Authentication/Onboarding";
import ResetPassword from "pages/Authentication/ResetPassword";
import ResetPasswordSuccess from "pages/Authentication/ResetPasswordSuccess";
import SignIn from "pages/Authentication/SignIn";
import SignInWithLinkedin from "pages/Authentication/SignInWithLinkedin";
import { SignUpPage } from "pages/Authentication/SignUpPage";
import Chat from "pages/Chat";
import ChatList from "pages/Chat/ChatList";
import Conversation from "pages/Conversation";
import EditProfile from "pages/EditProfile";
import EmptyPage from "pages/Empty";
import Endorse from "pages/Endorse/Endorse";
import Endorsement from "pages/Endorsement";
import Endorsements from "pages/Endorsements";
import EndorsementsMobile from "pages/Endorsements";
import FindJobs from "pages/FindJobs/FindJobs";
import Job from "pages/Job";
import JobPage from "pages/JobPage/desktop";
import FirstJobPage from "pages/JobPage/desktop/FirstJob";
import JobPageMobile from "pages/JobPage/Mobile";
import JobPosts from "pages/JobPosts/desktop";
import JobPostsMobile from "pages/JobPosts/Mobile";
import Landing from "pages/Landing/Landing";
import MyApplications from "pages/MyApplications";
import MyEndorsements from "pages/MyEndorsements";
import Notifications from "pages/Notifications";
import Profile from "pages/Profile";
import ProfilePreview from "pages/Profile/Preview";
import Refer from "pages/Refer";
import Referral from "pages/Referral";
import { SearchCandidatesPage } from "pages/SearchCandidates";
import Unsubscribe from "pages/Unsubscribe";

import RequireAuth from "components/Auth/RequireAuth";
import useAuth from "components/Auth/useAuth";
import Show from "components/common/Show";
import DesktopLayout from "components/DesktopLayout";
import LoadingCentered from "components/Loading/LoadingCentered";
import TabBar from "components/TabBar";
import TopBar from "components/TopBar";

import useGetUserOnStart from "hooks/useGetUserOnStart";
import { useReferrals } from "hooks/useReferrals";
import useWindowSize from "hooks/useWindowSize";

import "./App.scss";
import Settings from "./pages/Settings";

const ProtectedLayout = () => {
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};

const LoadingLayout = () => {
  return (
    <Suspense fallback={<LoadingCentered />}>
      <Outlet />
    </Suspense>
  );
};

const AppLayout = () => {
  const { isLoggedIn } = useAuth();
  return (
    <div className="">
      <TopBar />
      <div
        className={classNames("mainWrapper", {
          "mainWrapper-notLoggedIn": !isLoggedIn,
        })}
      >
        <div className="w-full h-full relative">
          <Outlet />
        </div>
      </div>
      <TabBar />
    </div>
  );
};

// TODO : refactor this so that you can see which routes are used by which role
const Main = () => {
  const { isLoggedIn, user } = useAuth();
  const { isMobile } = useWindowSize();
  const isRecruiter = user?.activeRole === RoleEnum.InternalRecruiter;
  return (
    <Routes>
      <Route element={<LoadingLayout />}>
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <Navigate to="/find-jobs?isRemote=true" />
            ) : (
              <Navigate to="/landing" />
            )
          }
        />
        <Route path="/landing" element={<Landing />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signin/linkedin" element={<SignInWithLinkedin />} />
        <Route
          path="/signup/confirm/:id"
          element={<SignUpPage isEmailVerified />}
        />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signup/profile" element={<Onboarding />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/forgot/reset/:id" element={<ResetPassword />} />
        <Route path="/forgot/success" element={<ResetPasswordSuccess />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route element={<AppLayout />}>
          <Route path="/find-jobs" element={<FindJobs />} />
          <Route path="/job/:jobAdId" element={<Job />} />

          <Route element={<ProtectedLayout />}>
            <Route
              path="/search-candidates"
              element={<SearchCandidatesPage />}
            />
            <Route path="/my-applications" element={<MyApplications />}>
              <Route index element={<MyApplications />} />
              <Route
                path="/my-applications/:applicationId"
                element={<Application showTags />}
              />
            </Route>
            <Route path="/endorsements" element={<Endorsements />}>
              <Route index element={<Endorsements />} />

              <Route
                path="/endorsements/:endorsementId"
                element={<Endorsement rootPage="/endorsements" />}
              />
            </Route>

            <Route path="/my-endorsements" element={<MyEndorsements />}>
              <Route index element={<MyEndorsements />} />
              <Route
                path="/my-endorsements/referral/:referralId"
                element={<Referral rootPage="/my-endorsements" />}
              />
              <Route
                path="/my-endorsements/application/:applicationId"
                element={<Application rootPage="/my-endorsements" />}
              />
              <Route
                path="/my-endorsements/endorsement/:endorsementId"
                element={<Endorsement rootPage="/my-endorsements" />}
              />
            </Route>

            <Route path="/profile">
              <Route
                index
                element={
                  isMobile ? <Settings /> : <Navigate to={"/profile/preview"} />
                }
              />
              <Route path="preview" element={<ProfilePreview />} />
              <Route path="edit" element={<EditProfile />} />
            </Route>

            <Route path="/profile/:profileID" element={<Profile />} />
            <Route path="/profile/user/:userId" element={<Profile />} />
            <Route
              path="/jobs"
              element={
                <DesktopLayout
                  leftColumn={!isMobile && <JobPosts />}
                  mainColumn={<Outlet />}
                />
              }
            >
              <Route
                index
                element={isMobile ? <JobPostsMobile /> : <FirstJobPage />}
              />
              <Route path=":jobAdId">
                <Route
                  index
                  element={isMobile ? <JobPageMobile /> : <JobPage />}
                />

                <Route
                  path="application/:applicationId"
                  element={<JobPostApplication />}
                />
              </Route>
            </Route>

            <Route path="/endorsements" element={<EndorsementsMobile />} />

            <Route path="/job" element={<Outlet />}>
              <Route index element={<FirstJobPage />} />
              <Route path="/job/:jobAdId" element={<Job />} />
              <Route path="/job/:jobAdId/edit" element={<Job edit />} />
            </Route>

            <Route path="/job/:jobAdId/apply" element={<Apply />} />
            <Route path="/job/:jobAdId/endorse" element={<Refer />} />
            <Route path="/endorse" element={<Endorse />} />
            <Route path="/add" element={<AddJob />} />

            <Route path="/referral/:referralId" element={<Referral />} />

            <Route path="/chat" element={<Chat />}>
              <Route index element={<ChatList />} />
              <Route path=":otherProfileId" element={<Conversation />} />
            </Route>

            <Route path="/notifications" element={<Notifications />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<EmptyPage />} />
    </Routes>
  );
};

function App() {
  const canEnter = useGetUserOnStart();
  useReferrals();

  return (
    <>
      <div className="App">
        <Show
          when={canEnter}
          fallback={
            <div className="w-screen h-screen">
              <LoadingCentered />
            </div>
          }
        >
          <Main />
        </Show>
      </div>
      <ToastContainer
        position="top-center"
        closeOnClick
        newestOnTop
        hideProgressBar
        autoClose={2000}
        transition={Flip}
        pauseOnFocusLoss={false}
        draggable={false}
        theme="dark"
      />
    </>
  );
}

export default App;
