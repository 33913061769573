import classNames from "clsx";
import { Link } from "react-router-dom";

import {
  CandidateProfile,
  CompanyHrProfile,
  RoleEnum,
} from "generated/graphql";

import { ReactComponent as BackIcon } from "images/icons/back.svg";

import CandidateEditProfile from "pages/EditProfile/CandidateEditProfile";
import CompanyHrEditProfile from "pages/EditProfile/CompanyHrEditProfile";

import useAuth from "components/Auth/useAuth";
import NavBar from "components/NavBar";
import UploadProfilePicture from "components/UploadProfilePicture";

import useWindowSize from "hooks/useWindowSize";

import styles from "./styles.module.scss";

const EditProfile = (): JSX.Element => {
  const { user, userProfile } = useAuth();
  const { isMobile } = useWindowSize();

  const renderEditProfile = () => {
    if (user?.activeRole === RoleEnum.Candidate) {
      return <CandidateEditProfile profile={userProfile as CandidateProfile} />;
    }

    return <CompanyHrEditProfile profile={userProfile as CompanyHrProfile} />;
  };

  if (isMobile) {
    return (
      <>
        <NavBar title="Edit profile" backArrow={null} backTo="/profile" />
        <div className="p-6">{renderEditProfile()}</div>
      </>
    );
  }

  return (
    <div className="">
      <div className="flex items-center gap-[35px] mb-8 pt-7">
        <Link to={"/profile"} className="pl-[22px]">
          <BackIcon />
        </Link>
        <h3 className={classNames(styles.pageTitle)}>Edit Profile</h3>
      </div>
      <div className="grid gap-3 lg:px-16 lg:py-7">
        <UploadProfilePicture photoUrl={userProfile?.photoUrl} showButtons />
        <h2 className={styles.userName}>{user?.name}</h2>
      </div>
      <div className="lg:px-16 lg:py-7">{renderEditProfile()}</div>
    </div>
  );
};

export default EditProfile;
