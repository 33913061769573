import { Dispatch, Fragment, SetStateAction, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { BsXLg } from "react-icons/bs";

import { ExperienceEnum, FieldEnum, JobTypeEnum } from "generated/graphql";

import FiltersBody from "components/SearchFilters/FiltersBody";
import SearchFiltersInput from "components/SearchFilters/SearchFiltersInput";

import { defaultFilters, SearchFiltersProps } from "./index";
import styles from "./styles.module.scss";

interface SearchFiltersMobileProps extends SearchFiltersProps {
  handleToggleField: (field: FieldEnum) => void;
  handleToggleExperience: (experience: ExperienceEnum) => void;
  handleToggleFormat: (format: JobTypeEnum) => void;
  handleChangeMinSalary: (minSalary: number) => void;
  handleClear: (callback?: VoidFunction) => void;
  filters: typeof defaultFilters;
  setFilters: Dispatch<SetStateAction<typeof defaultFilters>>;
  onApplyFilters: (newFilters: typeof defaultFilters) => void;
  numberOfFilters: number;
  isCategory?: boolean;
  isSalary?: boolean;
  isExperience?: boolean;
  isType?: boolean;
}

const SearchFilters = ({
  filters,
  filterText,
  setFilterText,
  handleToggleField,
  handleToggleExperience,
  handleToggleFormat,
  handleChangeMinSalary,
  handleClear,
  onApplyFilters,
  placeholder,
  numberOfFilters,
  isCategory,
  isSalary,
  isExperience,
  isType,
}: SearchFiltersMobileProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleApply = () => {
    onApplyFilters(filters);
    close();
  };

  return (
    <>
      <SearchFiltersInput
        open={open}
        filterText={filterText}
        setFilterText={setFilterText}
        placeholder={placeholder}
        numberOfFiltersApplied={numberOfFilters}
      />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#282646] bg-opacity-80 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col gap-9 w-full max-w-lg transform overflow-hidden rounded-2xl bg-[#121121] pt-[22px] px-8 pb-8 shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium leading-6 text-white"
                  >
                    <div className="flex justify-between ">
                      <span className=" font-sora text-[21px] leading-[26px] font-bold tracking-[-0.4px]">
                        Filters
                      </span>
                      <button onClick={close}>
                        <BsXLg />
                      </button>
                    </div>
                  </Dialog.Title>

                  <FiltersBody
                    filters={filters}
                    handleToggleField={handleToggleField}
                    handleToggleExperience={handleToggleExperience}
                    handleToggleFormat={handleToggleFormat}
                    handleChangeMinSalary={handleChangeMinSalary}
                    isCategory={isCategory}
                    isSalary={isSalary}
                    isExperience={isExperience}
                    isType={isType}
                  />

                  <div className="w-full flex gap-3 justify-end">
                    <button
                      className={clsx(
                        "bg-transparent py-[10px] px-[21px] rounded-[40px] text-[11px] font-bold leading-[14px]",
                        "disabled:opacity-50 disabled:cursor-not-allowed opacity-80",
                        styles.hover
                      )}
                      onClick={() => handleClear()}
                      disabled={numberOfFilters === 0}
                    >
                      CLEAR ALL
                    </button>

                    <button
                      className={clsx(
                        "bg-[#5362E9] py-[10px] px-[34px] rounded-[40px] text-[11px] font-bold leading-[14px]",
                        "bg-opacity-90 ",
                        styles.hover
                      )}
                      onClick={handleApply}
                    >
                      APPLY
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SearchFilters;
