import { Card } from "antd";

import { InternalRecruiterProfile } from "generated/graphql";

import { DORSE_COMPANY } from "app-constants";

import "./style.scss";

type Props = {
  internalRecruiterProfile: InternalRecruiterProfile;
};

const InternalRecruiterInformation = ({
  internalRecruiterProfile,
}: Props): JSX.Element => {
  const { about, name } = internalRecruiterProfile;

  const company = DORSE_COMPANY;

  return (
    <div className="children-bottom-margin">
      {about ? (
        <Card title={`About ${name?.split(" ")?.[0]}`} bordered={false}>
          {about}
        </Card>
      ) : null}
      {company?.description ? (
        <Card title={`About ${company.name}`} bordered={false}>
          {company.description}
        </Card>
      ) : null}
    </div>
  );
};

export default InternalRecruiterInformation;
