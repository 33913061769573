import logo from "images/dorse_logo.svg";
import github from "images/landing/github.svg";
import linkedin from "images/landing/linkedin.svg";
import mail from "images/landing/mail.svg";
import twitter from "images/landing/twitter.svg";

import styles from "./Footer.module.scss";

const Footer = (): JSX.Element => {
  return (
    <div className="bg-[#161844] h-[105px] md:h-[120px] flex flex-col sm:flex-row items-center justify-between gap-1.5 items-center pb-[18px] pt-8 sm:py-0 sm:px-8 md:px-16 lg:px-[96px]">
      <div className={styles.social}>
        <a href="mailto:lfg@dorse.io">
          <img src={mail} alt="mail" />
        </a>
        <a
          href="https://www.linkedin.com/company/dorse/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} alt="linkedin" />
        </a>
        <a
          href="https://twitter.com/dorseit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} alt="twitter" />
        </a>
        <a
          href="https://github.com/madrugada-labs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={github} alt="github" />
        </a>
      </div>
      <img
        src={logo}
        alt="logo"
        className="hidden md:block h-[23px] sm:h-30px"
      />
      <div className={styles.copyright}>2022 All right reserved</div>
    </div>
  );
};

export default Footer;
