import { FunctionComponent, useState } from "react";

import { Player } from "@lottiefiles/react-lottie-player";
import clsx from "clsx";
import { Fade } from "react-awesome-reveal";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import {
  OrderEnum,
  SortJobsByKeyEnum,
  useGetJobsPublicQuery,
} from "generated/graphql";

import blueArrow from "images/landing/blueArrow.svg";
import endorsed from "images/landing/endorsed.svg";

import For from "components/common/For";
import Show from "components/common/Show";
import JobGrid from "components/JobGrid/JobGrid";
import LoadingCentered from "components/Loading/LoadingCentered";
import SkillsSelect from "components/SkillsSelect/SkillsSelect";

import useFilterJobs from "hooks/useFilterJobs";

import useAuth from "../../components/Auth/useAuth";
import Footer from "./Footer";
import styles from "./Landing.module.scss";
import Navbar from "./Navbar/Navbar";

const Landing: FunctionComponent = () => {
  return (
    <div className="bg-[#121121]">
      <Marquee />
      <Navbar />
      <div className="w-full max-w-[1440px] mx-auto">
        <MainSection />
        <EndorsingPeople />
        <SearchJobsArea />
        <StartEndorsing />
      </div>
      <Footer />
    </div>
  );
};

const SearchJobsArea: FunctionComponent = () => {
  const [skills, setSkills] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");

  const filters = {
    skills,
  };

  const handleToggleSkill = (skill: string) => {
    if (skills.includes(skill)) {
      setSkills(skills.filter((s) => s !== skill));
    } else {
      setSkills([...skills, skill]);
    }
  };

  const { data, loading } = useGetJobsPublicQuery({
    variables: {
      sortBy: { key: SortJobsByKeyEnum.Created, order: OrderEnum.Desc },
      filters,
    },
  });

  const jobs = data?.jobsPublic ?? [];
  const filteredJobs = useFilterJobs(jobs, search);

  return (
    <Fade>
      <div className="flex flex-col gap-5 mt-[97px] md:mt-40 md:px-16">
        <div className="flex flex-col px-8">
          <p className="font-sora font-[600] text-[24px] sm:text-[30px] md:text-[48px] leading-tight">
            Start endorsing colleagues
            <span className="hidden sm:block text-[#9d9d9d]">
              Or start looking for jobs
            </span>
            <span className="block sm:hidden text-[#9d9d9d]">Find jobs</span>
          </p>
        </div>
        <div>
          <div className="flex flex-col gap-8">
            <div className="px-8">
              <div className="grid grid-cols-[auto,1fr] gap-3 items-center h-[58px] md:h-[94px] bg-[#121121] border border-[#4A465B] rounded-[12px] md:rounded-[21px] px-[15px] md:px-[30px]">
                <FiSearch className={styles.searchIcon} />
                <input
                  className={styles.searchInput}
                  type="text"
                  placeholder="Job title, keywords, skills"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <p className="font-mono text-lg md:text-3xl px-8 mb-4 leading-none">
                Find Jobs by Skills
              </p>
              <div
                className={clsx(
                  "flex gap-2 overflow-x-scroll hide-scrollbar lg:flex-wrap lg:px-8",
                  styles.skillsListSpacing
                )}
              >
                <SkillsSelect
                  selected={skills}
                  handleToggle={handleToggleSkill}
                />
              </div>
            </div>
          </div>
          <div className="mt-[29px] md:mt-[76px] ">
            <Show when={!loading} fallback={<LoadingCentered />}>
              <Show
                when={filteredJobs.length > 0}
                fallback={
                  <div className="font-bold text-2xl px-8 md:px-16">
                    No Jobs Found
                  </div>
                }
              >
                <JobGrid jobs={filteredJobs} moreFirstSpacing={false} />
              </Show>
            </Show>
          </div>
        </div>
      </div>
    </Fade>
  );
};

const StartEndorsing: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <Fade>
      <div className="px-8 md:px-16 mt-[94px] mb-[95px] md:my-44 flex flex-col items-center">
        <span className={styles.lastText}>
          If you don't endorse, someone else will...
        </span>
        <button
          className="mt-[18px] md:mt-[34px] degraded rounded-full min-w-[243px] md:min-w-[255px] min-h-[54px] flex items-center justify-center"
          onClick={() => {
            navigate("/signup");
          }}
        >
          <p className="font-mono font-[700] text-[13px] md:text-[16px]">
            START ENDORSING
          </p>
        </button>
      </div>
    </Fade>
  );
};

const Marquee: FunctionComponent = () => {
  return (
    <div className="bg-[#5660E5] z-20">
      <div className={styles.marquee}>
        <div className={styles.track}>
          <div className="flex gap-6 sm:gap-12 md:gap-24 z-20">
            <For
              items={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
              keyFunc={(i) => String(i)}
            >
              {() => (
                <>
                  <div className="flex gap-4 items-center z-20">
                    <span className="font-sora font-[400] text-[#121121] text-xs">
                      Cash paid to our network so far in 2022
                    </span>
                    <span className={styles.rewardsPaidNumber}>$140k</span>
                  </div>
                  <div className="flex gap-4 items-center z-20">
                    <span className="font-sora font-[400] text-[#121121] text-xs">
                      Amount we pay per placed endorsed candidate
                    </span>
                    <span className={styles.outstandingRewardsNumber}>
                      up to $20k
                    </span>
                  </div>
                </>
              )}
            </For>
          </div>
        </div>
      </div>
    </div>
  );
};

const MainSection: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  return (
    <Fade>
      <div className="w-full grid gap-x-12 items-center grid-cols-1 md:grid-cols-[minmax(100px,max-content),1fr] sm:max-w-[75vw] md:max-w-[1139px] mx-auto px-8 md:px-16">
        <div className="md:max-w-[545px] w-full">
          <h2 className="mb-6 font-sora font-[700] leading-tight text-[38px] sm:text-[48px] md:text-[64px] text-center md:text-left">
            Endorsed by your network
          </h2>
          <div className="flex items-center justify-center">
            <p className="max-w-[245px] sm:max-w-[100vw] text-center md:text-left mb-[28px] sm:mb-[46px] w-full font-[400] font-mono text-[12px] sm:text-[16px] md:text-[20px] text-[#B2B1B3] tracking-tight">
              We flip the script on recruiting...
              <br />
              no more small finder's fees, we're paying you serious amounts of
              cash for your endorsement!
            </p>
          </div>

          <div className="w-full flex flex-col items-center lg:items-start justify-center md:w-75 gap-3">
            <button
              className="degraded rounded-full min-w-[243px] md:min-w-[304px] min-h-[52px] flex items-center justify-center"
              onClick={() => {
                navigate("/find-jobs");
              }}
            >
              <p className="font-mono font-[700] text-[13px] md:text-[14px]">
                FIND JOBS AND ENDORSE
              </p>
            </button>
          </div>
        </div>
        <div className="hidden mt-[56px] md:mt-0 items-center justify-center w-full md:block md:ml-auto">
          <img
            src={endorsed}
            alt="Endorsed"
            className="w-[60vw] min-w-[270px] md:w-[430px] md:ml-auto"
          />
        </div>
      </div>
    </Fade>
  );
};

const EndorsingPeople = () => {
  return (
    <Fade>
      <div className="px-4 mt-[133px] sm:mt-40 flex flex-col items-center">
        <div className="w-full flex flex-col gap-1.5 md:gap-3 text-center text-lg font-semibold mb-[35px] md:mb-5">
          <p className="text-primary font-[600] font-mono text-[12px] sm:text-[20px] tracking-tight leading-none">
            ENDORSING PEOPLE
          </p>
          <p className={styles.howDorseWorksText}>How Dorse works</p>
        </div>
        <div className="grid md:grid-cols-[minmax(160px,309px),54px,minmax(240px,555px),54px,minmax(160px,302px)] max-w-[1248px]">
          <section className="w-full flex items-center justify-center mb-[7px] md:mb-8">
            <div className="flex flex-col items-center mb-4 max-w-[270px]">
              <Player
                autoplay
                loop
                src="https://assets5.lottiefiles.com/packages/lf20_otwkmzu4.json"
                className="w-28 h-28"
              />
              <p className="font-sora font-[700] text-[21px] md:text-[24px] text-center leading-none pb-4">
                Endorse colleagues
              </p>
              <p className="font-mono text-xs md:text-sm text-center text-[#dadada]">
                Show your colleague some love! Tell us why they would be an
                incredible asset in a new role. If your endorsement is powerful
                enough, you get paid!
              </p>
            </div>
          </section>
          <div className={styles.arrow}>
            <img src={blueArrow} alt="Blue arrow" />
          </div>
          <section className="w-full flex items-center justify-center mb-[9px] md:mb-24">
            <div className="flex flex-col items-center gap-4 max-w-[470px]">
              <Player
                autoplay
                loop
                src="https://assets6.lottiefiles.com/packages/lf20_rsqhglyn.json"
                className="w-[201px] h-[201px] md:w-28 md:h-28"
              />
              <span className={styles.howItWorksTitles}>
                Our team will find <br className="md:hidden" /> a perfect role
                for them
              </span>
              <p className="max-w-[357px] font-mono text-sm text-center text-[#dadada] tracking-tigher">
                When we have a role that matches your endorsements
                qualifications
                <br />
                We reachout
              </p>
            </div>
          </section>
          <div className={styles.arrow}>
            <img src={blueArrow} alt="Blue arrow" />
          </div>
          <section className="w-full flex items-center justify-center md:mb-[120px]">
            <div className="flex flex-col items-center gap-4 max-w-[270px]">
              <Player
                autoplay
                loop
                src="https://assets1.lottiefiles.com/packages/lf20_1snz9wmy.json"
                className="w-[154px] h-[154px] md:w-28 md:h-28"
              />
              <span className={styles.howItWorksTitles}>Get paid</span>
              <p className="font-mono text-sm text-center text-[#dadada]">
                When your endorsement accepts an offer, we pay you{" "}
                <span className="text-secondary">50% of the placement fee</span>
                . That's between 15-20K USD!
              </p>
            </div>
          </section>
        </div>
      </div>
    </Fade>
  );
};

export default Landing;
