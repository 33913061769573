import { Component, ErrorInfo } from "react";

interface Props {
  children: JSX.Element;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  // public state: State = {
  //   hasError: false,
  // };

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center items-center">
          {/* <SomethingWentWrong />
          <Button type="primary">
            <Link to="/">Back home</Link>
          </Button> */}
          <div className="flex flex-col justify-center items-center gap-[30px] sm:gap-[44px]">
            <img
              src="/images/error-ghost.png"
              className="w-[170px] sm:w-full"
            />
            <p className="font-sora font-semibold text-[19px] leading-[24px] sm:text-[26px] sm:leading-[33px] text-center">
              There was an internal error,
              <br />
              please try again
            </p>

            <button
              type="button"
              onClick={() => window.location.replace("/")}
              className="uppercase w-[calc(100%-30px)]  sm:px-0 sm:w-[326px] h-[42px] sm:h-[54px] rounded-[40px] font-sans text-[13px] sm:text-[16px] bg-[#5362E9] cursor leading-[21px] font-medium"
            >
              GO HOME
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
