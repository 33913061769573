import classNames from "clsx";
import { Link } from "react-router-dom";

import { ReactComponent as PencilIcon } from "images/icons/pencil.svg";

import useAuth from "components/Auth/useAuth";
import CandidateHeader from "components/CandidateProfile/CandidateHeader";
import CandidateInformation from "components/CandidateProfile/CandidateInformation";
import CompanyHrHeader from "components/CompanyHrProfile/CompanyHrHeader";
import CompanyHrInformation from "components/CompanyHrProfile/CompanyHrInformation";
import InternalRecruiterHeader from "components/InternalRecruiterProfile/InternalRecruiterHeader";
import InternalRecruiterInformation from "components/InternalRecruiterProfile/InternalRecruiterInformation";
import NavBar from "components/NavBar";

import useWindowSize from "hooks/useWindowSize";

const ProfilePreview = (): JSX.Element => {
  const { userProfile } = useAuth();

  const { isMobile } = useWindowSize();

  const renderTitle = () => {
    if (isMobile) return null;
    return (
      <div className="flex items-center gap-6 mb-[42px]">
        <h3 className="font-sora font-semibold text-[28px] leading-9 opacity-90 mix-blend-normal">
          Preview Profile
        </h3>
        <Link to={"/profile/edit"}>
          <PencilIcon />
        </Link>
      </div>
    );
  };

  const renderNavBar = () => {
    if (!isMobile) return null;
    return (
      <NavBar
        title="Preview profile"
        backArrow={null}
        backTo={isMobile ? "/profile" : null}
      />
    );
  };

  const renderProfile = () => {
    switch (userProfile?.__typename) {
      case "CandidateProfile":
        return (
          <>
            <CandidateHeader candidateProfile={userProfile} />
            <div className="px-6 lg:px-0">
              <CandidateInformation candidateProfile={userProfile} />
            </div>
          </>
        );

      case "CompanyHrProfile":
        return (
          <>
            <CompanyHrHeader companyHrProfile={userProfile} />
            <div className="px-6 lg:px-0">
              <CompanyHrInformation companyHrProfile={userProfile} />
            </div>
          </>
        );

      case "InternalRecruiterProfile":
        return (
          <>
            <InternalRecruiterHeader internalRecruiterProfile={userProfile} />
            <div className="px-6 lg:px-0">
              <InternalRecruiterInformation
                internalRecruiterProfile={userProfile}
              />
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="lg:px-16 lg:py-6 mb-8">
      {renderNavBar()}
      {renderTitle()}
      {renderProfile()}
    </div>
  );
};

export default ProfilePreview;
