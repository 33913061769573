import { useEffect } from "react";

import isArray from "lodash/isArray";
import { useNavigate } from "react-router-dom";

import { RoleEnum } from "generated/graphql";

import useAuth from "components/Auth/useAuth";

type RoleWithEmpty = RoleEnum | "";

export const useCheckAuth = (
  fallbackRoute: string,
  checkRole: RoleWithEmpty | RoleWithEmpty[] = ""
) => {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useAuth();
  useEffect(() => {
    if (!user && isLoggedIn) return;
    if (!user?.activeRole) {
      //  doesn't have a role
      if (
        !isArray(checkRole) ||
        (isArray(checkRole) && !checkRole.includes(""))
      ) {
        navigate(fallbackRoute);
      }
    }
    const role = user?.activeRole as RoleEnum;
    if (role === checkRole || (isArray(checkRole) && checkRole.includes(role)))
      return;

    navigate(fallbackRoute);
  }, [user?.activeRole]);
};
