import { useEffect, useState } from "react";

import { Form, message } from "antd";

import {
  CompanyHrProfile,
  ExperienceEnum,
  FieldEnum,
  InputQuestion,
  JobTypeEnum,
  Maybe,
  RoleEnum,
  useCreateJobAdMutation,
  useGetCompaniesLazyQuery,
} from "generated/graphql";

import Step1 from "pages/AddJob/Step1";
import Step2 from "pages/AddJob/Step2";

import useAuth from "components/Auth/useAuth";
import NavBar from "components/NavBar";

import AddedJob from "./AddedJob";

export const defaultAddJob = {
  companyId: "",
  title: "",
  location: "",
  description: "",
  responsibilities: "",
  requirements: "",
  preferred: "",
  benefits: "",
  field: FieldEnum.Engineering as FieldEnum,
  salary: [0, 0],
  experience: ExperienceEnum.MidLevel as ExperienceEnum,
  format: JobTypeEnum.FullTime as JobTypeEnum,
  isRemote: true,
  currency: "$",
  editable: true,
  referenceQuestions: [] as (InputQuestion & { tempId: string })[],
  jobSkills: [] as string[],
};

const AddJob = (): JSX.Element => {
  const { user, userProfile } = useAuth();
  const [form] = Form.useForm();

  const [addJobData, setAddJobData] = useState(defaultAddJob);
  const [step, setStep] = useState<1 | 2>(1);

  const [createdJobId, setCreatedJobId] = useState<Maybe<string>>(null);

  const [createJobAd, { loading }] = useCreateJobAdMutation({
    onCompleted: (data) => {
      if (data?.createJobAd) {
        setCreatedJobId(data.createJobAd);
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const onSubmit = () => {
    const {
      companyId,
      title,
      location,
      description,
      responsibilities,
      requirements,
      preferred,
      benefits,
      field,
      experience,
      format,
      isRemote,
      salary,
      currency,
      editable,
      referenceQuestions,
      jobSkills,
    } = addJobData;

    createJobAd({
      variables: {
        jobAdData: {
          companyId:
            companyId || (userProfile as CompanyHrProfile)?.company?.id,
          title,
          location,
          field,
          experience,
          format,
          isRemote,
          minSalary: salary[0],
          maxSalary: salary[1],
          description,
          responsibilities,
          requirements,
          preferred,
          benefits,
          currency,
          editable,
          referenceQuestions: referenceQuestions.map((question) => ({
            text: question.text,
          })),
          jobSkills,
        },
      },
    });
  };

  const [getCompanies, { data }] = useGetCompaniesLazyQuery();

  const companies = data?.companies;

  useEffect(() => {
    if (user?.activeRole !== RoleEnum.InternalRecruiter) return;
    getCompanies();
  }, [getCompanies, user?.activeRole]);

  const onAddReferenceQuestion = () => {
    setAddJobData({
      ...addJobData,
      referenceQuestions: [
        ...addJobData.referenceQuestions,
        { tempId: Math.random().toString(), text: "" },
      ],
    });
  };

  const onRemoveReferenceQuestion = (id: string) => {
    setAddJobData({
      ...addJobData,
      referenceQuestions: addJobData.referenceQuestions.filter(
        (question) => question.tempId !== id
      ),
    });
  };

  const onBack = () => {
    setStep((prevStep) => {
      if (prevStep > 0) {
        return (prevStep - 1) as 1 | 2;
      }
      return prevStep;
    });
  };

  const onContinue = () => {
    form
      .validateFields()
      .then((res) => {
        setStep((prevStep) => {
          if (prevStep < 2) {
            return (prevStep + 1) as 1 | 2;
          }
          return prevStep;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onValuesChange = (changed: any) => {
    const { salary, referenceQuestions, ...others } = changed;

    const newReferenceQuestions = addJobData.referenceQuestions.map(
      ({ __typename, ...question }: any) => question
    );

    if (referenceQuestions) {
      for (let i = 0; i < changed.referenceQuestions.length; i++) {
        const question = changed.referenceQuestions[i];
        if (question) {
          newReferenceQuestions[i] = {
            ...newReferenceQuestions[i],
            text: question.text,
          };
        }
      }
    }

    setAddJobData({
      ...addJobData,
      referenceQuestions: newReferenceQuestions,
      ...others,
      ...(salary && { salary: [...salary] }),
    });
  };

  const renderNavbarTitle = () => {
    if (step === 1) return "Add a job post";
    if (step === 2) return "Reference questions";
    return "Add a job post";
  };

  const prevStep = () => {
    setStep((prevStep) => {
      if (prevStep > 1) {
        return (prevStep - 1) as 1 | 2;
      }
      return prevStep;
    });
  };

  const renderStep = () => {
    if (step === 1) {
      return (
        <Step1
          onContinue={onContinue}
          companies={companies || []}
          addJobData={addJobData}
          setAddJobData={onValuesChange}
        />
      );
    }

    return (
      <Step2
        loading={loading}
        onBack={onBack}
        onAddQuestion={onAddReferenceQuestion}
        onRemoveQuestion={onRemoveReferenceQuestion}
        addJobData={addJobData}
      />
    );
  };

  if (createdJobId) return <AddedJob createdJobId={createdJobId} />;

  return (
    <main className="lg:py-8 lg:px-16">
      <NavBar
        title={renderNavbarTitle()}
        backTo={step === 1 || createdJobId ? null : ""}
        onBack={() => {
          prevStep();
        }}
      />
      <Form
        form={form}
        preserve
        onFinish={onSubmit}
        initialValues={{
          ...defaultAddJob,
          salary: [0, 0],
        }}
        onValuesChange={onValuesChange}
        className="padding-mobile"
      >
        {renderStep()}
      </Form>
    </main>
  );
};

export default AddJob;
