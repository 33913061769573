import { FunctionComponent, useCallback, useState } from "react";

import { useBeforeunload } from "react-beforeunload";

import { BlockchainEnum, ExperienceEnum, FieldEnum } from "generated/graphql";

import {
  ONBOARDING_BASE,
  ONBOARDING_FIELD,
  ONBOARDING_SKILLS,
} from "app-constants";

import {
  BasicInfo,
  CompleteOnboarding,
  FieldSelect,
  OnboardingInputs,
  SkillsSelect,
} from "components/onboarding";

export const Onboarding: FunctionComponent = () => {
  const localInfo = JSON.parse(
    window?.localStorage?.getItem(ONBOARDING_BASE) as string
  );
  const localInfoField = JSON.parse(
    window?.localStorage?.getItem(ONBOARDING_FIELD) as string
  );
  const localInfoSkills = JSON.parse(
    window?.localStorage?.getItem(ONBOARDING_SKILLS) as string
  );

  const [state, setState] = useState<OnboardingInputs>(
    localInfo
      ? {
          ...initialOnboardingInputs,
          basic: {
            ...initialOnboardingInputs.basic,
            ...localInfo,
          },
          field: {
            ...initialOnboardingInputs.field,
            ...localInfoField,
          },
          skills: {
            ...initialOnboardingInputs.skills,
            ...localInfoSkills,
          },
        }
      : initialOnboardingInputs
  );
  const [step, setStep] = useState<number>(0);

  useBeforeunload(() => {
    if (step < 3) {
      return "You have unsaved changes.";
    }
  });

  switch (step) {
    case 0:
      return (
        <BasicInfo
          setStep={setStep}
          state={state}
          setState={setState}
          decimalProgress={0.3855}
        />
      );
    case 1:
      return (
        <FieldSelect
          setStep={setStep}
          state={state}
          setState={setState}
          decimalProgress={0.70127}
        />
      );
    case 2:
      return (
        <SkillsSelect
          setStep={setStep}
          state={state}
          setState={setState}
          decimalProgress={0.9406}
        />
      );
    default:
      return <CompleteOnboarding state={state} />;
  }
};

const initialOnboardingInputs: OnboardingInputs = {
  basic: {
    name: "",
    location: "",
    linkedin: "",
    github: "",
    photoUrl: "",
    blockchain: BlockchainEnum.Solana,
    blockchainAddress: "",
  },
  field: {
    field: [FieldEnum.Engineering],
    jobTitle: "",
    companyName: "",
    experience: ExperienceEnum.Senior,
  },
  skills: {
    about: "",
    techSkills: [],
    softSkills: [],
    candidateSkills: [],
  },
};
