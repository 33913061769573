import { FunctionComponent } from "react";

import clsx from "clsx";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Merge } from "type-fest";

import { DialogControls, DialogWrapper } from "components/modals/common";

import styles from "./modals.module.scss";

export const AddSkillDialog: FunctionComponent<
  Merge<
    DialogControls,
    {
      setOpen: (open: boolean) => void;
      addSkill: (newSkill: string) => void;
      title: string;
    }
  >
> = ({ open, setOpen, addSkill, title }) => {
  const {
    getValues,
    register,
    formState: { errors, touchedFields },
    resetField,
  } = useForm<{ skill: string }>({
    mode: "all",
  });

  const onClick = () => {
    if (getValues().skill) {
      addSkill(getValues().skill);
      resetField("skill");
      setOpen(false);
    } else {
      toast.error("Missing skill input");
    }
  };
  return (
    <DialogWrapper open={open} setOpen={setOpen}>
      <div className="fixed z-10 inset-0 flex items-center justify-center p-4">
        <div className="h-[307px] w-[375px] flex flex-col items-center px-4 pt-[38px] pb-[31px] bg-[#121121] rounded-lg">
          <h2 className="font-sora text-[21px] leading-[20px] font-[700]">
            {title}
          </h2>
          <input
            {...register("skill", {
              required: true,
            })}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onClick();
                e.preventDefault();
              }
            }}
            placeholder="Add your skill"
            className="bg-transparent rounded-md border border-[#4A465B] mt-[18px] py-[14px] px-5 w-full font-mono placeholder-[#4A465B]"
          />
          <div className="mt-6 w-full">
            <button
              onClick={onClick}
              className={
                (clsx(
                  "transition-opacity duration-200 w-full rounded-full h-[52px] bg-primary flex items-center justify-center"
                ),
                styles.hover)
              }
            >
              <p className="font-mono font-[700]">ADD SKILL</p>
            </button>
            <button
              onClick={() => setOpen(false)}
              className={clsx(
                "transition-opacity duration-200 mt-4 w-full rounded-full h-[52px] bg-box flex items-center justify-center",
                styles.hover
              )}
            >
              <p className="font-mono font-[700]">CANCEL</p>
            </button>
          </div>
        </div>
      </div>
    </DialogWrapper>
  );
};
