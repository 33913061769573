import { FormEvent, FunctionComponent, useState } from "react";

import { Spin } from "antd";
import { useForm } from "react-hook-form";

type SendButtonProps = {
  placeholder?: string;
  onSubmit: ({ message }: { message: string }) => Promise<any>;
  autoFocus?: boolean;
};

const SendButton: FunctionComponent<SendButtonProps> = ({
  placeholder,
  onSubmit,
  autoFocus = false,
}) => {
  const {
    getValues: getFormValues,
    register,
    formState: { errors, touchedFields },
    setFocus,
    setValue,
  } = useForm<{ input: string }>({
    mode: "all",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const _onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const input = getFormValues("input");
    if (!input) return;
    setLoading(true);
    const data = await onSubmit({ message: input });

    if (data) {
      setValue("input", "");
      setLoading(false);
      setFocus("input", { shouldSelect: true });
    }
  };

  return (
    <form
      className="relative w-full h-full"
      onSubmit={_onSubmit}
      style={{ flex: 1 }}
    >
      <input
        placeholder={placeholder}
        className="w-full h-[30px] lg:h-[38px] placeholder-white text-[12px] lg:text-[14px] py-3 px-5 bg-[#343141] rounded-full"
        {...register("input")}
      />
      <button
        onClick={(e) => _onSubmit(e as unknown as FormEvent<HTMLFormElement>)}
        className="absolute right-0 top-0 w-[41px] lg:w-[53px] h-[30px] lg:h-[38px] bg-[#6979F8] rounded-full flex items-center justify-center"
      >
        {loading ? (
          <Spin size="small" />
        ) : (
          <img
            className="w-5 lg:w-[27px] h-5 lg:h-[27px]"
            src="/icons/send-icon.svg"
          />
        )}
      </button>
    </form>
  );
};

export default SendButton;
