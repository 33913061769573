import { useState } from "react";

import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { AutoCenter } from "antd-mobile";
import classNames from "clsx";
import clsx from "clsx";
import Resizer from "react-image-file-resizer";

import { useUploadProfilePictureMutation } from "generated/graphql";

import { DEFAULT_USER_PHOTO_URL } from "app-constants";

import styles from "./styles.module.scss";

type Props = {
  photoUrl?: string | undefined | null;
  showButtons?: boolean;
};

const UploadProfilePicture = ({
  photoUrl: initialPhotoUrl,
  showButtons,
}: Props) => {
  const [photoUrl, setPhotoUrl] = useState<string | undefined | null>(
    initialPhotoUrl
  );
  const [loadingUploadProfilePicture, setLoadingUploadProfilePicture] =
    useState<boolean>(false);
  const [uploadProfilePicture] = useUploadProfilePictureMutation();

  const onUploadProfilePicture = ({
    target: {
      validity,
      files: [file],
    },
  }: any) => {
    if (validity.valid) {
      setLoadingUploadProfilePicture(true);

      // resize image
      Resizer.imageFileResizer(
        file,
        240,
        240,
        "PNG",
        100,
        0,
        (compressedFile) => {
          uploadProfilePicture({
            variables: {
              profilePicture: compressedFile,
            },
          })
            .then(({ data }) => {
              if (data?.uploadProfilePicture) {
                setPhotoUrl(data.uploadProfilePicture);
              }
            })
            .catch((e) => {
              console.log("Error resizing", e);
              uploadProfilePicture({
                variables: {
                  profilePicture: file,
                },
              }).then(({ data }) => {
                if (data?.uploadProfilePicture) {
                  setPhotoUrl(data.uploadProfilePicture);
                }
              });
            })
            .finally(() => {
              setLoadingUploadProfilePicture(false);
            });
        },
        "file"
      );
    }
  };

  return (
    <div className="flex gap-5 items-center">
      <AvatarWithButton
        photoUrl={photoUrl}
        onUploadProfilePicture={onUploadProfilePicture}
        loading={loadingUploadProfilePicture}
      />
      {showButtons && (
        <div className="flex gap-2 items-center">
          <label>
            <input type="file" onChange={onUploadProfilePicture} />
            <div
              className={clsx(
                "py-1 px-6 rounded-2xl bg-[#5362E9] cursor-pointer",
                styles.hover
              )}
            >
              <span className={styles.changeButtonText}>Change</span>
            </div>
          </label>
          {/* <div className="py-1 px-6 rounded-2xl cursor-pointer">
            <span className={styles.removeButtonText}>Remove</span>
          </div> */}
        </div>
      )}
    </div>
  );
};

const AvatarWithButton = ({
  photoUrl,
  onUploadProfilePicture,
  loading,
}: {
  photoUrl?: string | undefined | null;
  onUploadProfilePicture: ({
    target: {
      validity,
      files: [file],
    },
  }: any) => void;
  loading: boolean;
}) => {
  return (
    <div className={styles.wrapper}>
      <AutoCenter>
        <img
          className={"w-[135px] h-[135px] rounded-[87px] object-cover"}
          src={photoUrl || DEFAULT_USER_PHOTO_URL}
          alt="avatar"
        />
      </AutoCenter>
      <AutoCenter className={classNames(styles.fileInput)}>
        <label>
          <input type="file" onChange={onUploadProfilePicture} />
          {!loading ? (
            <CameraOutlined className={styles.icon} />
          ) : (
            <LoadingOutlined className={styles.icon} />
          )}
        </label>
      </AutoCenter>
    </div>
  );
};

export default UploadProfilePicture;
