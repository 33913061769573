import React from "react";

type ForProps<T> = {
  items: T[];
  children: (item: T, index: number) => React.ReactNode;
  keyFunc: (item: T, index: number) => string;
};

const For = <T,>({ items, children, keyFunc }: ForProps<T>) => {
  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={keyFunc(item, index)}>
          {children(item, index)}
        </React.Fragment>
      ))}
    </>
  );
};

export default For;
