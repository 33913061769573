import { ReactNode } from "react";

import { Avatar } from "antd";
import { Link, useLocation } from "react-router-dom";

import ActiveMark from "components/ActiveMark";
import Card from "components/common/Card/Card";
import Show from "components/common/Show";

import { formatRelativeDate } from "utils";

import styles from "./styles.module.scss";

type Props = {
  id: string;
  link: string;
  photoUrl: string;
  title: ReactNode;
  subtitle: ReactNode;
  footer: ReactNode;
  score?: ReactNode;
  tag?: ReactNode;
  date: string;
};

const EndorsementCard = ({
  id,
  link,
  photoUrl,
  title,
  subtitle,
  footer,
  score,
  tag,
  date,
}: Props) => {
  const location = useLocation();
  return (
    <Link key={id} to={link} className="relative">
      <Show when={location.pathname.includes(id)}>
        <ActiveMark scroll left="calc(-24px - 37px / 2)" />
      </Show>

      <Card
        left={<Avatar src={photoUrl} size={60} shape="square" />}
        center={{
          first: <span className={styles.title}>{title}</span>,
          second: <span className={styles.subtitle}>{subtitle}</span>,
          third: <span className={styles.footer}>{footer}</span>,
        }}
        right={{
          first: <span className={styles.score}>{score}</span>,
          second: <div className={styles.tag}>{tag}</div>,
          third: (
            <span className={styles.date}>{formatRelativeDate(date)}</span>
          ),
        }}
      />
    </Link>
  );
};

export default EndorsementCard;
