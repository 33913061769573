import * as React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { UnsubEmailMutation, useUnsubEmailMutation } from "generated/graphql";

import { SignupHeader, SignupWrapper } from "pages/Authentication/SignUpPage";

export default function Unsubscribe() {
  const [params, _] = useSearchParams();
  const id = params.get("id") ?? ("" as string);
  const navigate = useNavigate();
  if (!id) {
    navigate("/");
  }

  const [triggerUnsubscribe] = useUnsubEmailMutation({
    fetchPolicy: "network-only",
    onError: ({ graphQLErrors }) => {
      for (let error of graphQLErrors) {
        toast.error(error.message);
        toast.error("Invalid unsubscribe link");
        navigate("/");
      }
    },
    onCompleted: (data: UnsubEmailMutation) => {
      if (data.unsubscribeEmailUpdates) {
        toast.success("Successfully unsubscribed - we're sad to hear this 😢");
        navigate("/");
      }
    },
  });
  return (
    <div className="relative w-full h-full">
      <SignupHeader />
      <SignupWrapper>
        <div className="flex flex-col items-center">
          <h4 className="font-sora text-[26px] mb-10 font-[700]">Unsub Page</h4>
          <button
            onClick={() => {
              triggerUnsubscribe({ variables: { encryptedUserId: id } });
            }}
            className="bg-primary rounded-full h-[54px] flex items-center justify-center hover:opacity-80 duration-200 transition-opacity"
          >
            <p className="font-mono font-[500] px-20">Unsubscribe</p>
          </button>
        </div>
      </SignupWrapper>
    </div>
  );
}
