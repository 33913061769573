import { useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import {
  ExperienceEnum,
  FieldEnum,
  JobFilters,
  JobTypeEnum,
  OrderEnum,
  SortJobsByKeyEnum,
} from "generated/graphql";

export const useFiltersAndSortForQuery = (isRemoteField = true) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const plainFilters: Partial<JobFilters> = {
    skills: searchParams.get("skills")?.split(",") || [],
    experiences: (searchParams.get("experiences")?.split(",") ||
      []) as ExperienceEnum[],
    fields: (searchParams.get("fields")?.split(",") || []) as FieldEnum[],
    formats: (searchParams.get("formats")?.split(",") || []) as JobTypeEnum[],
    // isRemote: searchParams.get("isRemote") === "true" ? true : false,
    minSalary: Number(searchParams.get("minSalary")) || 0,
  };
  const filters = isRemoteField
    ? {
        ...plainFilters,
        isRemote: searchParams.get("isRemote") === "true" ? true : false,
      }
    : plainFilters;
  const sortBy = {
    key: (searchParams.get("sortBy") ||
      SortJobsByKeyEnum.Created) as SortJobsByKeyEnum,
    order: OrderEnum.Desc,
  };

  const search = searchParams.get("search") || "";

  const setParams = (params: {
    filters: JobFilters;
    sortBy: { key: SortJobsByKeyEnum; order: OrderEnum };
    search: string;
  }) => {
    const parsedPlainFilters = {
      experiences: params.filters.experiences?.join(",") || undefined,
      skills: params.filters.skills?.join(",") || undefined,
      fields: params.filters.fields?.join(",") || undefined,
      formats: params.filters.formats?.join(",") || undefined,

      minSalary:
        params.filters.minSalary && params.filters.minSalary > 0
          ? params.filters.minSalary.toString()
          : undefined,
    };
    const parsedFilters = isRemoteField
      ? {
          ...parsedPlainFilters,
          isRemote: params.filters.isRemote ? "true" : "false",
        }
      : parsedPlainFilters;

    const parsedSortBy =
      params.sortBy.key.toString() !== SortJobsByKeyEnum.Created.toString()
        ? params.sortBy.key.toString()
        : undefined;

    const parsedSearch = params.search || undefined;

    const isValidSearchParam = (searchParam: [string, string | undefined]) => {
      return searchParam[1] !== undefined;
    };
    const newSearchParams = Object.entries({
      ...parsedFilters,
      sortBy: parsedSortBy,
      search: parsedSearch,
    }).filter(isValidSearchParam) as [string, string][];

    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    setParams({
      filters: {
        ...filters,

        // isRemote: searchParams.get("isRemote") === "false" ? false : true,
      },
      sortBy,
      search,
    });
  }, []);

  const setFilters = (fn: (prev: JobFilters) => JobFilters) => {
    const newFilters = fn(filters);

    setParams({
      filters: newFilters,
      sortBy,
      search,
    });
  };

  const onSetSortBy = (key: SortJobsByKeyEnum) => {
    setParams({
      filters,
      sortBy: { key, order: OrderEnum.Desc },
      search,
    });
  };

  const setSearch = (search: string) => {
    setParams({
      filters,
      sortBy,
      search,
    });
  };

  const onToggleRemote = () => {
    setFilters((prev) => ({ ...prev, isRemote: !prev.isRemote }));
  };

  const onToggleSkill = (skill: string) => {
    setFilters((prev) => ({
      ...prev,
      skills: prev.skills?.includes(skill)
        ? prev.skills.filter((s) => s !== skill)
        : [...(prev.skills ?? []), skill],
    }));
  };

  const handleApplyFilters = (newFilters: JobFilters) => {
    setFilters((prev) => ({
      ...newFilters,
      isRemote: prev.isRemote,
      skills: prev.skills,
    }));
  };

  return {
    filters,
    onSetSortBy,
    onToggleRemote,
    onToggleSkill,
    handleApplyFilters,
    sortBy,
    search,
    setSearch,
    searchParams,
  };
};
