import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import { ReactComponent as ThreeDots } from "images/icons/menu-three-dots.svg";

import styles from "./JobDropdownOptions.module.scss";

const JobDropdownOptions = (props: { jobId: string }) => {
  return (
    <Menu as="div" className="ml-3 absolute top-4 right-6 text-left mt-3">
      <Menu.Button className=" justify-center rounded-md bg-black p-3">
        <ThreeDots />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-1 w-44 origin-top-right rounded-md bg-[#343141] overflow-hidden">
          <Menu.Item>
            <Link
              to={`/job/${props.jobId}`}
              className={classNames(
                "cursor-pointer p-4 font-medium ",
                "flex justify-between items-center gap-10",
                styles.hover
              )}
            >
              <span className="text-white">View</span>
              <span className="text-grey">
                <FiArrowUpRight />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link
              to={`/job/${props.jobId}/edit`}
              className={classNames(
                "cursor-pointer p-4 font-medium ",
                "flex justify-between items-center gap-10",
                styles.hover
              )}
            >
              <span className="text-white">Edit</span>
              <span className="text-grey">
                <FiArrowUpRight />
              </span>
            </Link>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default JobDropdownOptions;
