import { ReactNode } from "react";

import { AutoComplete, Form } from "antd";
import { RuleObject, RuleRender } from "antd/lib/form";
import { ShouldUpdate } from "rc-field-form/es/Field";
import { NamePath } from "rc-field-form/es/interface";

import "./style.scss";

interface Props {
  name: NamePath;
  label?: ReactNode;
  required?: boolean;
  requiredMessage?: string;
  shouldUpdate?: ShouldUpdate<any>;
  validator?: (
    rule: RuleObject,
    value: any,
    callback: (error?: string | undefined) => void
  ) => any | undefined;
  rules?: (RuleObject | RuleRender)[];
  options: (any & { id: string; email: string })[];
  onChange: (value: string) => Promise<void>;
  onSelect: (id: string) => void;
}

const FormAutocomplete = ({
  name,
  label,
  required = true,
  requiredMessage,
  shouldUpdate,
  validator,
  rules,
  options,
  onChange,
  onSelect,
}: Props): JSX.Element => {
  return (
    <Form.Item
      name={name}
      label={
        label ? (
          <div>
            {label}
            {required ? <sup>*</sup> : null}
          </div>
        ) : undefined
      }
      shouldUpdate={shouldUpdate}
      prefixCls="custom-form-input"
      rules={[
        {
          required,
          message: requiredMessage || `${label ? label : "Field"} is missing`,
          validateTrigger: "onSubmit",
          validator,
        },
        ...(rules || []),
      ]}
    >
      <AutoComplete
        options={options.map((option) => ({
          value: option.id,
          label: option[name as string],
        }))}
        defaultActiveFirstOption
        className="custom-input-border-bottom"
        dropdownClassName="custom-dropdown-wrapper"
        onSearch={onChange}
        onSelect={onSelect}
      />
    </Form.Item>
  );
};

export default FormAutocomplete;
