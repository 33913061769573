import { useMemo, useState } from "react";

import Fuse from "fuse.js";

import { GetConversationsQuery } from "../generated/graphql";

export const useSearchChat = (
  conversations?: GetConversationsQuery["conversations"]
) => {
  const [filterText, setFilterText] = useState<string>("");

  const fuse = useMemo(() => {
    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      keys: [
        { name: "messages.text", weight: 4 },
        { name: "with.name", weight: 4 },
      ],
    };

    return new Fuse(conversations || [], options);
  }, [conversations]);

  const filteredConversations = useMemo(() => {
    if (filterText.length < 2) {
      return conversations;
    }

    return fuse.search(filterText).map(({ item }) => item);
  }, [filterText, conversations, fuse]);

  return {
    filterText,
    setFilterText,
    filteredConversations,
  };
};
