import { useEffect, useState } from "react";

import { CheckOutlined } from "@ant-design/icons";
import { Button, Form, Result } from "antd";
import { toast } from "react-toastify";

import logo from "images/dorse_logo.svg";

import useAuth from "components/Auth/useAuth";
import FormInput from "components/Form/Input";

import { isValidEmail } from "utils";

import "./style.scss";

type Values = {
  email: string;
};

const ForgotPassword = (): JSX.Element => {
  const { regeneratePasswordRequest, error } = useAuth();
  const [form] = Form.useForm();
  const [showEmailOk, setShowEmailOk] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const initialValues = {
    email: "",
  };

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message);
    }
  }, [error]);

  const onSubmit = async ({ email }: Values) => {
    try {
      await regeneratePasswordRequest(email);
      setDone(true);
    } catch (e) {}
  };

  const onValuesChange = (changed: any, { email }: Values) => {
    if (isValidEmail(email)) {
      setShowEmailOk(true);
    } else {
      setShowEmailOk(false);
    }
  };

  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="flex flex-col w-11/12 max-w-lg">
        <div className="auth__brand-title-wrapper">
          <div className="auth__brand-title">
            <img src={logo} alt="" />
          </div>
        </div>
        {!done ? (
          <>
            <Form
              form={form}
              onFinish={onSubmit}
              initialValues={initialValues}
              onValuesChange={onValuesChange}
            >
              <FormInput
                name="email"
                label="Email"
                requiredMessage="Email is missing or invalid"
                email
                addonAfter={
                  <CheckOutlined
                    style={{ fontSize: "16px" }}
                    className={
                      !showEmailOk ? "hidden-addon" : "color__secondary"
                    }
                  />
                }
              />

              <Button
                className="auth__button-auth auth__button-mobile gradient-background"
                color="success"
                htmlType="submit"
                block
                shape="round"
                size="large"
              >
                RECOVER PASSWORD
              </Button>
            </Form>
          </>
        ) : (
          <Result
            status={!error ? "success" : "error"}
            title={!error ? "Email sent" : error.message}
            subTitle={
              !error
                ? "An email has just been sent. When you receive it, please follow the link to enter a new password."
                : "There has been an error. Please try again."
            }
          />
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
