import clsx from "clsx";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import dorseLogo from "images/dorse_logo.svg";
import resetPassword from "images/icons/reset-password-success.svg";

import "./style.scss";
import styles from "./styles.module.scss";

const ResetPasswordSuccess = (): JSX.Element => {
  return (
    <div className="w-full flex justify-center items-center h-full">
      <Link to="/landing">
        <img
          src={dorseLogo}
          alt=""
          className="absolute top-[70px] left-[137px] cursor-pointer"
        />
      </Link>
      <div className="flex flex-col w-full justify-center items-center max-w-[472px] font-sans px-[29px]">
        <div className="w-[65px] h-[65px] bg-[#59C180] rounded-[9.2px] mb-[26px] lg:mb-[38px]">
          <img src={resetPassword} alt="" />
        </div>

        <h1 className="font-sora font-bold text-[26px] lg:text-[31px] leading-8 mb-[15px] lg:mb-3">
          Password Reset
        </h1>

        <p className="text-[#DADADA] font-sans text-center text-[13px]  ss:text-[15px] lg:text-[16px] leading-[19px] mb-[33px] lg:mb-[40px]">
          Your password has been successfully reset.
          <br />
          Click below to log in magically.
        </p>
        <Link
          to="/"
          className={
            (clsx(
              "text-sans text-[16px] leading-[21px] font-medium text-white capitalize cursor-pointer w-full max-w-[327px] h-[54px] flex justify-center align-center bg-[#5362E9] items-center rounded-[40px] mb-[25px]  transition-all"
            ),
            styles.opacityHover)
          }
        >
          CONTINUE
        </Link>
        <Link
          className={clsx(
            "tracking-[ -0.173252px] font-sans text-[#D0C9D6] underline text-[14px] leading-[15px]"
          )}
          to="/signin"
        >
          Back to login
        </Link>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
