import { Card } from "antd";

import { CompanyHrProfile } from "generated/graphql";

import "./style.scss";

type Props = {
  companyHrProfile: CompanyHrProfile;
};

const CompanyHrInformation = ({ companyHrProfile }: Props): JSX.Element => {
  const { about, name, company } = companyHrProfile;

  return (
    <div className="children-bottom-margin">
      {about ? (
        <Card title={`About ${name?.split(" ")?.[0]}`} bordered={false}>
          {about}
        </Card>
      ) : null}
      {company?.description ? (
        <Card title={`About ${company.name}`} bordered={false}>
          {company.description}
        </Card>
      ) : null}
    </div>
  );
};

export default CompanyHrInformation;
