import { ReactNode } from "react";

import Show from "components/common/Show";

import styles from "./TwoRows.module.scss";

type Row = {
  title: ReactNode;
  component: ReactNode;
  showWhen?: boolean;
};

type Props = {
  first: Row;
  second: Row;
};

const TwoRows = ({ first, second }: Props) => {
  return (
    <div className={styles.container}>
      <Show when={first.showWhen}>
        <div className={styles.titleAndList}>
          <h3 className={styles.title}>{first.title}</h3>
          <div className={styles.list}>{first.component}</div>
        </div>
      </Show>
      <Show when={second.showWhen}>
        <div className={styles.titleAndList}>
          <h3 className={styles.title}>{second.title}</h3>
          <div className={styles.list}>{second.component}</div>
        </div>
      </Show>
    </div>
  );
};

export default TwoRows;
