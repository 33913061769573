import { useParams } from "react-router-dom";
import { JobAdCompany } from "types";

import JobList from "components/CardList/JobList";
import TwoRows from "components/TwoRows/TwoRows";

import styles from "./JobListSideBar.module.scss";

type JobListSideBarProps = {
  activeJobs: JobAdCompany[];
  finishedJobs: JobAdCompany[];
};

const JobListSideBar = ({ activeJobs, finishedJobs }: JobListSideBarProps) => {
  const { jobAdId } = useParams();

  if (activeJobs.length === 0 && finishedJobs.length === 0) {
    return (
      <div className="p-6">
        <h3 className={styles.emptyTitle}>There are no job posts, yet.</h3>
      </div>
    );
  }

  return (
    <TwoRows
      first={{
        showWhen: activeJobs.length > 0,
        title: "Active",
        component: <JobList jobs={activeJobs} selectedJobId={jobAdId} />,
      }}
      second={{
        showWhen: finishedJobs.length > 0,
        title: "Finished",
        component: <JobList jobs={finishedJobs} selectedJobId={jobAdId} />,
      }}
    />
  );
};

export default JobListSideBar;
